import { TextField } from "@mui/material";
import TreeSelect, { DefaultOption, FreeSoloNode } from "mui-tree-select";
import React, { useEffect, useState } from "react";
import { Node } from "./NodeClass";

function CustomValueSelector({ options, handleOnChange, value }) {
    const [currentValue, setCurrentValue] = useState(
        value ? new Node(options.find((el) => el.name === value)) : null
    );
    const renderInput = (params) => (
        <TextField {...params} size="small" sx={{ width: "160px" }} />
    );

    useEffect(() => {
        if (currentValue) {
            handleOnChange(currentValue.value.name);
        }
    }, [currentValue]);

    useEffect(() => {
        Node.data = options;
    }, [options]);

    return (
        <TreeSelect
            getChildren={(node) => {
                return node
                    ? node?.getChildren()
                    : options.reduce((prev, curr) => {
                          if (
                              curr.parent &&
                              curr.parent.id &&
                              !prev.find((el) => el.value.id === curr.parent.id)
                          ) {
                              return [
                                  ...prev,
                                  new Node({
                                      ...curr.parent,
                                      selectable: false,
                                  }),
                              ];
                          } else if (
                              !curr.parent &&
                              curr.id &&
                              !prev.find((el) => el.value.id === curr.id)
                          ) {
                              return [
                                  ...prev,
                                  new Node({ ...curr, selectable: true }),
                              ];
                          }
                          return prev;
                      }, []);
            }}
            getParent={(node) => {
                return node?.getParent();
            }}
            getOptionDisabled={(option) => {
                if (!option.isBranch()) {
                    return false;
                }
                const children = option.getChildren();
                return !children?.length;
            }}
            isBranch={(node) => {
                return node?.isBranch();
            }}
            isOptionEqualToValue={(option, value) => {
                return option instanceof FreeSoloNode
                    ? false
                    : option?.isEqual(value);
            }}
            renderInput={renderInput}
            onChange={(event, node) => {
                setCurrentValue(node);
            }}
            value={currentValue}
        />
    );
}

export default CustomValueSelector;
