import React, { useEffect, useState } from "react";
import "./FillReportForm.scss";
import usePrevious from "../../../../hooks/usePrevious";
import useWorkflowContext from "../../../../hooks/useWorkflowContext";
import { Box, Button, Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";
import FillReportFormDialog from "./fillReportFormDialog/FillReportFormDialog";

function FillReportForm({ content, save }) {
    const [isOpen, setIsOpen] = useState(false);
    const previousIsOpen = usePrevious(isOpen);
    const { blockDeleteIncr, blockDeleteDecr } = useWorkflowContext();

    useEffect(() => {
        if (previousIsOpen && !isOpen) {
            blockDeleteDecr();
        } else if (!previousIsOpen && isOpen) {
            blockDeleteIncr();
        }
    }, [isOpen]);

    return (
        <Box width={"100%"} className="fill-report-form-container">
            {isOpen && (
                <FillReportFormDialog
                    content={content}
                    save={save}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                />
            )}
            <Box display="flex" flexDirection="row" alignItems={"top"}>
                <Grid container p={2} spacing={2}>
                    <Grid item xs={12}>
                        <Box
                            height="40px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Button
                                size="large"
                                color="white"
                                className="pick-button"
                                onClick={() => {
                                    setIsOpen(true);
                                }}
                            >
                                <FormattedMessage id="FILL_REPORT_FORM:FORM" />
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default FillReportForm;
