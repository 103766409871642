import { Box, Button } from "@mui/material";
import { useReactFlow } from "reactflow";
import React, { useCallback } from "react";
import "./NodeButton.scss";
import { stringify } from "../../../utils/general";
import { nanoid } from "nanoid";
import { useIntl } from "react-intl";
import {
    Article,
    Edit,
    Email,
    FactCheck,
    QuestionMark,
    RateReview,
    StopCircle,
    Summarize,
    Timer,
} from "@mui/icons-material";

function NodeButton({ type, data }) {
    const reactFlowInstance = useReactFlow();
    const intl = useIntl();

    const onDragStart = useCallback(
        (event) => {
            event.dataTransfer.setData("application/reactflow", type);
            event.dataTransfer.setData("data", stringify(data ?? {}));
            event.dataTransfer.effectAllowed = "move";
        },
        [data, type]
    );

    const renderButton = useCallback(() => {
        let CustomIcon = null;
        switch (type) {
            case "AND":
                CustomIcon = ({ className, color }) => (
                    <Box
                        className={className}
                        sx={{
                            bgcolor: `${color}.main`,
                            color: "white.main",
                            height: "100%",
                            width: "100%",
                            borderRadius: "10px",
                            fontWeight: "bold",
                            fontSize: "20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        &
                    </Box>
                );
                break;
            case "OR":
                CustomIcon = ({ className, color }) => (
                    <Box
                        className={className}
                        sx={{
                            bgcolor: `${color}.main`,
                            color: "white.main",
                            height: "100%",
                            width: "100%",
                            borderRadius: "10px",
                            fontWeight: "bold",
                            fontSize: "20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        | |
                    </Box>
                );
                break;
            case "MAIL":
                CustomIcon = Email;
                break;
            case "SLEEP":
                CustomIcon = Timer;
                break;
            case "CONDITION":
                CustomIcon = QuestionMark;
                break;
            case "FORM":
                CustomIcon = Article;
                break;
            case "COMMENT":
                CustomIcon = RateReview;
                break;
            case "EDIT_SITE":
                CustomIcon = Edit;
                break;
            case "CHECKLIST_BUILD":
                CustomIcon = FactCheck;
                break;
            case "FILL_REPORT":
                CustomIcon = Summarize;
                break;
            case "END":
                CustomIcon = StopCircle;
                break;

            default:
                CustomIcon = React.Fragment;
        }
        return <CustomIcon className="icon" fontSize="large" color="primary" />;
    }, [type]);

    return (
        <Button
            className="tool-icon"
            onDragStart={onDragStart}
            onClick={() => {
                reactFlowInstance.addNodes({
                    id: `${nanoid()}${nanoid()}`,
                    type: type,
                    data: data,
                    position: { x: 0, y: 0 },
                });
            }}
            title={intl.formatMessage({
                id: `WORKFLOW:NODES:TYPES:${type}`,
            })}
        >
            {/* <Box
                component="img"
                src={image}
                height={35}
                width={35}
                style={{ objectFit: "contain" }}
            ></Box> */}
            {renderButton()}
        </Button>
    );
}

export default NodeButton;
