import React, { useEffect, useState } from "react";
import "./Users.scss";
import { Avatar } from "@mui/material";
import {
    Add,
    Delete,
    Edit,
    Info,
    Lock,
    PowerSettingsNew,
} from "@mui/icons-material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import DataContainer from "../../components/dataContainer/DataContainer";
import { useIntl } from "react-intl";
import AddModal from "../../components/addModal/AddModal";
import {
    activateOfficer,
    createLocalOfficer,
    deactivateOfficer,
    deleteOfficer,
    getOfficers,
    updateOfficer,
} from "../../api/officerAccount";
import { stringAvatar } from "../../utils/avatar";
import { getRoles } from "../../api/role";
import { useConfirm } from "material-ui-confirm";
import DataValidationError from "../../errors/DataValidationError";
import DataTable from "../../components/dataTable/DataTable";
import { camelToSnakeUpperCase } from "../../utils/general";
import CheckinHistory from "./checkinHistory/CheckinHistory";
import useAbility from "../../hooks/useAbility";

const Users = () => {
    //#region States
    const { can } = useAbility();
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dataRows, setDataRows] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [roles, setRoles] = useState([]);
    const [modalLoading, setModalLoading] = useState(false);
    const [selectedCheckinUser, setSelectedCheckinUser] = useState(null);
    const [openCheckinDetails, setOpenChekinDetails] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const selectedUser = dataRows.find((row) => row.id === selectedUserId);

    //#region other Hooks
    const intl = useIntl();
    const confirm = useConfirm();
    //#endregion

    //#region useEffect
    useEffect(() => {
        setupRoles();
        getData();
    }, []);
    //#endregion

    //#region Handlers
    const handleCloseAdd = () => {
        setIsModalOpen(false);
    };
    const handleOpenAdd = () => {
        setIsModalOpen(true);
    };
    const handleCloseUpdate = () => {
        setSelectedUserId(null);
    };
    const handleOpenUpdate = (user) => {
        setSelectedUserId(user);
    };
    const getData = async () => {
        setLoading(true);
        try {
            let data = await getOfficers();
            setDataRows(
                data.map((row) => ({
                    ...row,
                    ...row.officerInfo,
                    id: row._id,
                    _id: row._id,
                }))
            );
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        } finally {
            setLoading(false);
        }
    };
    const setupRoles = async () => {
        try {
            let { roles } = await getRoles();
            setRoles(roles);
        } catch (error) {}
    };
    const addUser = async (data) => {
        try {
            setModalLoading(true);
            data = { ...data, roles: JSON.stringify(data.roles) };
            await createLocalOfficer(data);
            toast.success(intl.formatMessage({ id: "USERS:USER_ADDED" }));
            setIsModalOpen(false);
            await getData();
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    if (field.kind === "unique") {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:UNIQUE" },
                                {
                                    field: intl.formatMessage({
                                        id: `USERS:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    } else {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:FORMAT" },
                                {
                                    field: intl.formatMessage({
                                        id: `USERS:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    }
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        } finally {
            setModalLoading(false);
        }
    };

    const updateUser = async (data) => {
        try {
            setModalLoading(true);
            data = { ...data, roles: JSON.stringify(data.roles) };
            await updateOfficer(selectedUserId, data);
            toast.success(intl.formatMessage({ id: "USERS:USER_UPDATED" }));
            setSelectedUserId(null);
            await getData();
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    if (field.kind === "unique") {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:UNIQUE" },
                                {
                                    field: intl.formatMessage({
                                        id: `USERS:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    } else {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:FORMAT" },
                                {
                                    field: intl.formatMessage({
                                        id: `USERS:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    }
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        } finally {
            setModalLoading(false);
        }
    };

    const deleteUser = async (params) => {
        try {
            await confirm({
                title: intl.formatMessage({ id: "WARNING" }),
                description: intl.formatMessage(
                    { id: "USERS:USER_DELETE_CONFIRM" },
                    { name: params.row.username }
                ),
                confirmationText: intl.formatMessage({ id: "GLOBAL:CONFIRM" }),
                cancellationText: intl.formatMessage({ id: "GLOBAL:CANCEL" }),
            });
        } catch (error) {
            return;
        }
        setLoading(true);
        try {
            await deleteOfficer(params.row._id);
            toast.success(intl.formatMessage({ id: "USERS:USER_DELETED" }));
            await getData();
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        } finally {
            setLoading(false);
        }
    };
    const activateUser = async (params) => {
        setLoading(true);
        try {
            await activateOfficer(params.row._id);
            toast.success(intl.formatMessage({ id: "USERS:USER_ACTIVATED" }));
            await getData();
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        } finally {
            setLoading(false);
        }
    };
    const deactivateUser = async (params) => {
        setLoading(true);
        try {
            await deactivateOfficer(params.row._id);
            toast.success(intl.formatMessage({ id: "USERS:USER_DEACTIVATED" }));
            await getData();
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        } finally {
            setLoading(false);
        }
    };
    //#endregion

    //#region constants
    const dataColumns = [
        {
            field: "actions",
            type: "actions",
            minWidth: 50,
            maxWidth: 50,
            getActions: (params) => [
                ...(can("CHECKINS_PREVIEW", "USERS")
                    ? [
                          <GridActionsCellItem
                              icon={<Info />}
                              onClick={() => {
                                  if (
                                      params.row._id !==
                                      selectedCheckinUser?._id
                                  ) {
                                      setSelectedCheckinUser(params.row);
                                  }
                                  setOpenChekinDetails(true);
                              }}
                              label={intl.formatMessage({
                                  id: "ACTIONS:CHECKINS",
                              })}
                              showInMenu
                          />,
                      ]
                    : []),
                ...(can(["ACTIVATE", "DEACTIVATE"], "USERS")
                    ? [
                          params.row.active && can("ACTIVATE", "USERS") ? (
                              <GridActionsCellItem
                                  icon={<Lock />}
                                  onClick={() => deactivateUser(params)}
                                  label={intl.formatMessage({
                                      id: "ACTIONS:DEACTIVATE",
                                  })}
                                  showInMenu
                              />
                          ) : (
                              can("DEACTIVATE", "USERS") && (
                                  <GridActionsCellItem
                                      icon={<PowerSettingsNew />}
                                      onClick={() => activateUser(params)}
                                      label={intl.formatMessage({
                                          id: "ACTIONS:ACTIVATE",
                                      })}
                                      showInMenu
                                  />
                              )
                          ),
                      ]
                    : []),
                ...(can("UPDATE", "USERS")
                    ? [
                          <GridActionsCellItem
                              icon={<Edit />}
                              onClick={() => {
                                  handleOpenUpdate(params.row.id);
                              }}
                              label={intl.formatMessage({ id: "ACTIONS:EDIT" })}
                              showInMenu
                          />,
                      ]
                    : []),
                ...(can("DELETE", "USERS")
                    ? [
                          <GridActionsCellItem
                              icon={<Delete />}
                              onClick={() => deleteUser(params)}
                              label={intl.formatMessage({
                                  id: "ACTIONS:DELETE",
                              })}
                              showInMenu
                          />,
                      ]
                    : []),
            ],
        },
        {
            field: "active",
            headerName: intl.formatMessage({ id: "USERS:STATE" }),
            type: "boolean",
            minWidth: 100,
        },
        {
            field: "image",
            headerName: intl.formatMessage({ id: "USERS:IMAGE" }),
            renderCell: (params) =>
                params.value ? (
                    <Avatar
                        src={`data:image;base64,${params.value}`}
                        alt="avatar"
                        sx={{ width: 40, height: 40 }}
                    />
                ) : (
                    <Avatar
                        {...stringAvatar(
                            `${params.row.firstName} ${params.row.lastName}`
                        )}
                    />
                ),
            minWidth: 100,
            maxWidth: 100,
            height: 50,
        },
        {
            field: "username",
            headerName: intl.formatMessage({ id: "USERS:USERNAME" }),
            flex: 1,
            minWidth: 160,
        },
        {
            field: "firstName",
            headerName: intl.formatMessage({ id: "USERS:FIRST_NAME" }),
            flex: 1,
            minWidth: 170,
        },
        {
            field: "lastName",
            headerName: intl.formatMessage({ id: "USERS:LAST_NAME" }),
            flex: 1,
            minWidth: 170,
        },
        {
            field: "phone",
            headerName: intl.formatMessage({ id: "USERS:PHONE" }),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "email",
            headerName: intl.formatMessage({ id: "USERS:EMAIL" }),
            flex: 1,
            minWidth: 250,
        },
        {
            field: "employer",
            headerName: intl.formatMessage({ id: "USERS:EMPLOYER" }),
            flex: 1,
            minWidth: 250,
        },
        {
            field: "function",
            headerName: intl.formatMessage({ id: "USERS:FUNCTION" }),
            flex: 1,
            minWidth: 250,
        },
        {
            field: "lang",
            headerName: intl.formatMessage({ id: "USERS:LANG" }),
            minWidth: 100,
            renderCell: (params) =>
                params.value === "fr-FR" ? (
                    <img
                        loading="lazy"
                        width="30"
                        src={`https://flagcdn.com/w40/fr.png`}
                        srcSet={`https://flagcdn.com/w40/fr.png 2x`}
                        alt=""
                    />
                ) : (
                    <img
                        loading="lazy"
                        width="30"
                        src={`https://flagcdn.com/w40/gb.png`}
                        srcSet={`https://flagcdn.com/w40/gb.png 2x`}
                        alt=""
                    />
                ),
        },
    ];

    const headerButtons = [
        ...(can("CREATE", "USERS")
            ? [
                  {
                      name: intl.formatMessage({ id: "USERS:CREATE" }),
                      props: {
                          onClick: handleOpenAdd,
                          variant: "contained",
                          color: "secondary",
                          endIcon: <Add />,
                      },
                  },
              ]
            : []),
    ];
    const modalFields = [
        {
            name: "image",
            label: intl.formatMessage({ id: "USERS:IMAGE" }),
            type: "image",
        },
        {
            name: "username",
            label: intl.formatMessage({ id: "USERS:USERNAME" }),
            type: "text",
            rules: {
                required: intl.formatMessage({
                    id: "FORM:ERROR_MESSAGES:REQUIRED",
                }),
            },
        },
        {
            name: "password",
            label: intl.formatMessage({ id: "USERS:PASSWORD" }),
            type: "password",
            rules: {
                required: intl.formatMessage({
                    id: "FORM:ERROR_MESSAGES:REQUIRED",
                }),
            },
        },
        {
            name: "confirmPassword",
            label: intl.formatMessage({ id: "USERS:CONFIRM_PASSWORD" }),
            type: "password",
            rules: {
                required: intl.formatMessage({
                    id: "FORM:ERROR_MESSAGES:REQUIRED",
                }),
            },
        },
        {
            name: "firstName",
            label: intl.formatMessage({ id: "USERS:FIRST_NAME" }),
            type: "text",
            rules: {
                required: intl.formatMessage({
                    id: "FORM:ERROR_MESSAGES:REQUIRED",
                }),
            },
        },
        {
            name: "lastName",
            label: intl.formatMessage({ id: "USERS:LAST_NAME" }),
            type: "text",
            rules: {
                required: intl.formatMessage({
                    id: "FORM:ERROR_MESSAGES:REQUIRED",
                }),
            },
        },
        {
            name: "phone",
            label: intl.formatMessage({ id: "USERS:PHONE" }),
            type: "tel",
            rules: {
                required: intl.formatMessage({
                    id: "FORM:ERROR_MESSAGES:REQUIRED",
                }),
                pattern: {
                    value: /^([+])?\d+$/,
                    message: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                    }),
                },
            },
        },
        {
            name: "email",
            label: intl.formatMessage({ id: "USERS:EMAIL" }),
            type: "email",
            rules: {
                required: intl.formatMessage({
                    id: "FORM:ERROR_MESSAGES:REQUIRED",
                }),
                pattern: {
                    value: /^[a-zA-Z0-9.! #$%&'*+/=? ^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$/,
                    message: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                    }),
                },
            },
        },
        {
            name: "employer",
            label: intl.formatMessage({ id: "USERS:EMPLOYER" }),
            type: "text",
            rules: {},
        },
        {
            name: "function",
            label: intl.formatMessage({ id: "USERS:FUNCTION" }),
            type: "text",
            rules: {},
        },
        {
            name: "roles",
            label: intl.formatMessage({ id: "USERS:ROLES" }),
            type: "autocomplete",
            rules: {},
            defaultValue: [],
            options: roles.map((role) => ({
                id: role._id,
                value: role._id,
                label: role.name,
            })),
            fullWidth: true,
        },
        {
            name: "inform",
            label: intl.formatMessage({ id: "USERS:INFORM" }),
            type: "switch",
            defaultValue: true,
            fullWidth: true,
        },
    ];

    var updateFields = selectedUser
        ? {
              image: selectedUser.image,
              username: selectedUser.username,
              password: "",
              confirmPassword: "",
              firstName: selectedUser.firstName,
              lastName: selectedUser.lastName,
              phone: selectedUser.phone,
              email: selectedUser.email,
              roles: roles
                  .filter((role) => selectedUser.roles.includes(role._id))
                  .map((role) => ({
                      id: role._id,
                      value: role._id,
                      label: role.name,
                  })),
          }
        : null;
    //#endregion

    return (
        <DataContainer
            buttons={headerButtons}
            onRefresh={() => {
                setupRoles();
                getData();
            }}
        >
            {isModalOpen && (
                <AddModal
                    open={isModalOpen}
                    handleClose={handleCloseAdd}
                    fields={modalFields}
                    title={intl.formatMessage({ id: "USERS:CREATE" })}
                    submit={addUser}
                    buttons={[
                        {
                            props: {
                                type: "submit",
                                variant: "contained",
                                color: "secondary",
                            },
                            label: intl.formatMessage({ id: "GLOBAL:SUBMIT" }),
                        },
                    ]}
                    buttonsBoxProps={[]}
                    loading={modalLoading}
                />
            )}
            {updateFields && (
                <AddModal
                    open={!!updateFields}
                    handleClose={handleCloseUpdate}
                    fields={modalFields
                        .filter((field) =>
                            Object.keys(updateFields).includes(field.name)
                        )
                        .map((field) => ({
                            ...field,
                            defaultValue: updateFields[field.name],
                            rules: ["password", "confirmPassword"].includes(
                                field.name
                            )
                                ? {}
                                : field.rules,
                        }))}
                    title={intl.formatMessage({ id: "USERS:UPDATE" })}
                    submit={updateUser}
                    buttons={[
                        {
                            props: {
                                type: "submit",
                                variant: "contained",
                                color: "secondary",
                            },
                            label: intl.formatMessage({ id: "GLOBAL:SUBMIT" }),
                        },
                    ]}
                    buttonsBoxProps={[]}
                    loading={modalLoading}
                />
            )}
            <CheckinHistory
                open={openCheckinDetails}
                user={selectedCheckinUser}
                handleClose={() => {
                    setOpenChekinDetails(false);
                }}
            />
            {can("CONSULT", "USERS") ? (
                <DataTable
                    loading={loading}
                    dataColumns={dataColumns}
                    dataRows={dataRows}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    noRowsMsg="USERS:NO_USERS"
                    NoResultsMsg="USERS:NO_RESULTS"
                />
            ) : (
                <>THIS IS A PLACEHOLDER</>
            )}
        </DataContainer>
    );
};

export default Users;
