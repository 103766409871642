import React, { useCallback, useMemo } from "react";
import { ExpandMore } from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Divider,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import useWorkflowContext from "../../../hooks/useWorkflowContext";
import "./BuilderSettings.scss";
import FieldsForm from "./fieldsForm/FieldsForm";
import { useIntl } from "react-intl";
import FormBuilder from "./formBuilder/FormBuilder";
import { useEdges } from "reactflow";
import useFormatNodes from "../../../hooks/useFormatNodes";
import { checkIfReachable } from "../../../utils/workflow";
import ConditionBuilder from "./conditionBuilder/ConditionBuilder";
import EditSiteComposer from "./editSiteComposer/EditSiteComposer";
import FillReportForm from "./fillReportForm/FillReportForm";

const BuilderSettings = () => {
    const {
        selectedNode,
        settings,
        setSettings,
        draggingNode,
        workflowPhases,
    } = useWorkflowContext();
    const intl = useIntl();
    const formattedNodes = useFormatNodes();
    const edges = useEdges();
    const formNodes = useMemo(() => {
        return formattedNodes.filter((node) => node.type === "FORM");
    }, [formattedNodes]);

    const dynamicData = useMemo(() => {
        if (selectedNode && formattedNodes && edges) {
            const result = {};
            formNodes.forEach((node) => {
                if (
                    node.id !== selectedNode &&
                    checkIfReachable(
                        formattedNodes,
                        edges,
                        node.id,
                        selectedNode,
                        [{ type: "FORM", sourceHandle: "ERROR" }]
                    )
                ) {
                    result[node.id] = {
                        id: node.id,
                        name: node.data.settings.name,
                        description: node.data.settings.description,
                        content: node.data.settings.content.reduce(
                            (prev, curr) => ({ ...prev, [curr.id]: curr }),
                            {}
                        ),
                    };
                }
            });
            return result;
        }
    }, [selectedNode, formNodes, edges]);

    const renderNodeSettings = useCallback(() => {
        switch (settings[selectedNode].kind) {
            case "fields": {
                return (
                    <FieldsForm
                        fields={settings[selectedNode].fields}
                        save={(data) => {
                            setSettings({
                                ...settings,
                                [selectedNode]: {
                                    ...settings[selectedNode],
                                    ...data,
                                },
                            });
                        }}
                        dynamicData={dynamicData}
                    />
                );
            }
            case "formGenerator": {
                return (
                    <FormBuilder
                        formContent={settings[selectedNode].content}
                        save={(data) => {
                            setSettings({
                                ...settings,
                                [selectedNode]: {
                                    ...settings[selectedNode],
                                    ...data,
                                },
                            });
                        }}
                    />
                );
            }
            case "conditionGenerator": {
                return (
                    <ConditionBuilder
                        conditionContent={settings[selectedNode].content}
                        save={(data) => {
                            setSettings({
                                ...settings,
                                [selectedNode]: {
                                    ...settings[selectedNode],
                                    ...data,
                                },
                            });
                        }}
                        dynamicData={dynamicData}
                    />
                );
            }
            case "editSiteComposer": {
                return (
                    <EditSiteComposer
                        dynamicData={dynamicData}
                        content={settings[selectedNode].content}
                        save={(data) => {
                            setSettings({
                                ...settings,
                                [selectedNode]: {
                                    ...settings[selectedNode],
                                    ...data,
                                },
                            });
                        }}
                    />
                );
            }
            case "fillReportForm": {
                return (
                    <FillReportForm
                        content={settings[selectedNode].content}
                        save={(data) => {
                            setSettings({
                                ...settings,
                                [selectedNode]: {
                                    ...settings[selectedNode],
                                    ...data,
                                },
                            });
                        }}
                    />
                );
            }

            default:
                return <></>;
        }
    }, [dynamicData, settings, setSettings, selectedNode]);

    return (
        <div
            className={`builder-settings-container${
                draggingNode ? " hide" : ""
            }`}
        >
            {selectedNode && settings && settings[selectedNode] ? (
                <div
                    className="settings-content"
                    key={selectedNode ?? "hidden"}
                >
                    <Accordion
                        defaultExpanded={true}
                        square={true}
                        sx={{ backgroundColor: "transparent!important" }}
                        elevation={0}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMore color="white" />}
                        >
                            <Typography color="white.main">
                                <FormattedMessage id="BUILDER_SETTINGS:GENERAL_INFORMATIONS" />
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: 0 }}>
                            <Grid container p={2} spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        size="small"
                                        label={intl.formatMessage({
                                            id: `BUILDER_SETTINGS:TASK_NAME`,
                                        })}
                                        fullWidth
                                        multiline={false}
                                        color="white"
                                        inputProps={{
                                            style: { color: "white" },
                                        }}
                                        InputLabelProps={{
                                            style: { color: "#ffffffdd" },
                                        }}
                                        value={
                                            settings[selectedNode]?.name || ""
                                        }
                                        onChange={(event) => {
                                            setSettings({
                                                ...settings,
                                                [selectedNode]: {
                                                    ...settings[selectedNode],
                                                    name: event.target.value,
                                                },
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        size="small"
                                        label={intl.formatMessage({
                                            id: `BUILDER_SETTINGS:TASK_DESCRIPTION`,
                                        })}
                                        fullWidth
                                        multiline={true}
                                        color="white"
                                        inputProps={{
                                            style: { color: "white" },
                                        }}
                                        InputLabelProps={{
                                            style: { color: "#ffffffdd" },
                                        }}
                                        value={
                                            settings[selectedNode]
                                                ?.description || ""
                                        }
                                        onChange={(event) => {
                                            setSettings({
                                                ...settings,
                                                [selectedNode]: {
                                                    ...settings[selectedNode],
                                                    description:
                                                        event.target.value,
                                                },
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        size="small"
                                        fullWidth
                                        options={workflowPhases}
                                        noOptionsText={""}
                                        getOptionLabel={(option) =>
                                            workflowPhases.find(
                                                (phase) =>
                                                    phase._id === option._id
                                            )?.name ?? option.name
                                        }
                                        isOptionEqualToValue={(
                                            option,
                                            value
                                        ) => {
                                            return option._id === value._id;
                                        }}
                                        value={
                                            settings[selectedNode]?.step || null
                                        }
                                        onChange={(e, data) => {
                                            setSettings({
                                                ...settings,
                                                [selectedNode]: {
                                                    ...settings[selectedNode],
                                                    step: data,
                                                },
                                            });
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...{
                                                    ...params,
                                                    InputLabelProps: {
                                                        ...params.InputLabelProps,
                                                        style: {
                                                            color: "#ffffffdd",
                                                        },
                                                    },
                                                    inputProps: {
                                                        ...params.inputProps,
                                                        style: {
                                                            color: "white",
                                                        },
                                                    },
                                                }}
                                                color="white"
                                                label={intl.formatMessage({
                                                    id: `BUILDER_SETTINGS:TASK_PHASE`,
                                                })}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Divider />
                    <Accordion
                        defaultExpanded={true}
                        square={true}
                        sx={{ backgroundColor: "transparent!important" }}
                        elevation={0}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMore color="white" />}
                        >
                            <Typography color="white.main">
                                <FormattedMessage id="BUILDER_SETTINGS:TASK_SETTINGS" />
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: 0 }}>
                            {renderNodeSettings()}
                        </AccordionDetails>
                    </Accordion>
                    <Divider />
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default BuilderSettings;
