import React, { useMemo } from "react";
import { verifyNodeFields } from "../utils/workflow";
import useWorkflowContext from "./useWorkflowContext";

function useNodeVerif(id) {
    const { settings } = useWorkflowContext();

    const currentSettings = useMemo(() => settings?.[id], [settings, id]);

    const isValid = useMemo(() => {
        switch (currentSettings?.kind) {
            case "fields":
                return verifyNodeFields(currentSettings.fields);
            default:
                return true;
        }
    }, [settings, id]);

    return isValid;
}

export default useNodeVerif;
