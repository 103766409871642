import React from "react";
import { useContext } from "react";
import { NotificationsContext } from "../contexts/NotificationsContext";

function useNotifications() {
    const context = useContext(NotificationsContext);
    return context;
}

export default useNotifications;
