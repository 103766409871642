import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    FormControlLabel,
    Grid,
    TextField,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import "./FormBuilder.scss";
import { FormattedMessage, useIntl } from "react-intl";
import FormBuilderDialog from "./formBuilderDialog/FormBuilderDialog";
import usePrevious from "../../../../hooks/usePrevious";
import useWorkflowContext from "../../../../hooks/useWorkflowContext";
import { useEffect } from "react";
import { camelToSnakeUpperCase } from "../../../../utils/general";

function FormBuilder({ formContent, save }) {
    const [isOpen, setIsOpen] = useState(false);
    const previousIsOpen = usePrevious(isOpen);
    const {
        blockDeleteIncr,
        blockDeleteDecr,
        selectedNode,
        settings,
        setSettings,
    } = useWorkflowContext();

    const intl = useIntl();

    const possibleTargets = useMemo(
        () => [
            "clientCoordinator",
            "internalCoordinators",
            "technicians",
            "measurers",
            "managers",
        ],
        []
    );

    useEffect(() => {
        if (previousIsOpen && !isOpen) {
            blockDeleteDecr();
        } else if (!previousIsOpen && isOpen) {
            blockDeleteIncr();
        }
    }, [isOpen]);

    return (
        <Box width={"100%"} className="form-builder-container">
            {isOpen && (
                <FormBuilderDialog
                    formContent={formContent}
                    save={save}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                />
            )}
            <Box display="flex" flexDirection="row" alignItems={"top"}>
                <Grid container p={2} spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <TextField
                                size="small"
                                label={intl.formatMessage({
                                    id: "FORM_BUILDER:TIMEOUT",
                                })}
                                type="number"
                                fullWidth
                                value={settings[selectedNode]?.timeout || 0}
                                onChange={(e) => {
                                    setSettings({
                                        ...settings,
                                        [selectedNode]: {
                                            ...settings[selectedNode],
                                            timeout: e.target.value,
                                        },
                                    });
                                }}
                                color="white"
                                inputProps={{
                                    ...(!settings[selectedNode]?.rdv
                                        ? { min: 0 }
                                        : {}),
                                    style: {
                                        color: "white",
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: "#ffffffdd",
                                    },
                                }}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        size="small"
                                        sx={{
                                            color: "#ffffff",
                                            "&.Mui-checked": {
                                                color: "#ffffff",
                                            },
                                        }}
                                        checked={!!settings[selectedNode]?.rdv}
                                        onChange={(e) => {
                                            setSettings({
                                                ...settings,
                                                [selectedNode]: {
                                                    ...settings[selectedNode],
                                                    rdv: e.target.checked,
                                                },
                                            });
                                            if (
                                                !e.target.checked &&
                                                settings[selectedNode]
                                                    ?.timeout < 0
                                            ) {
                                                setSettings({
                                                    ...settings,
                                                    [selectedNode]: {
                                                        ...settings[
                                                            selectedNode
                                                        ],
                                                        timeout: 0,
                                                    },
                                                });
                                            }
                                        }}
                                    />
                                }
                                label="RDV"
                                labelPlacement="start"
                                componentsProps={{
                                    typography: {
                                        sx: { color: "#ffffff" },
                                        fontSize: 16,
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            size="small"
                            fullWidth
                            options={possibleTargets}
                            noOptionsText={""}
                            isOptionEqualToValue={(option, value) => {
                                return option === value;
                            }}
                            getOptionLabel={(option) =>
                                intl.formatMessage({
                                    id: `FORM_BUILDER:FORM_TARGETS:${camelToSnakeUpperCase(
                                        option ?? ""
                                    )}`,
                                })
                            }
                            multiple={true}
                            filterSelectedOptions
                            value={settings[selectedNode]?.targets || []}
                            onChange={(e, data) => {
                                setSettings({
                                    ...settings,
                                    [selectedNode]: {
                                        ...settings[selectedNode],
                                        targets: data,
                                    },
                                });
                            }}
                            renderTags={(value, getTagProps) => {
                                return value.map((option, index) => (
                                    <Chip
                                        key={index}
                                        size="small"
                                        sx={{ marginTop: "5px" }}
                                        label={
                                            option
                                                ? intl.formatMessage({
                                                      id: `FORM_BUILDER:FORM_TARGETS:${camelToSnakeUpperCase(
                                                          option ?? ""
                                                      )}`,
                                                  })
                                                : ""
                                        }
                                        onDelete={
                                            getTagProps({ index }).onDelete
                                        }
                                        color="white"
                                    />
                                ));
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...{
                                        ...params,
                                        InputLabelProps: {
                                            ...params.InputLabelProps,
                                            style: {
                                                color: "#ffffffdd",
                                            },
                                        },
                                        inputProps: {
                                            ...params.inputProps,
                                            style: {
                                                color: "white",
                                            },
                                        },
                                    }}
                                    color="white"
                                    label={intl.formatMessage({
                                        id: `FORM_BUILDER:FORM_TARGETS`,
                                    })}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            height="40px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Button
                                size="large"
                                color="white"
                                className="pick-button"
                                onClick={() => {
                                    setIsOpen(true);
                                }}
                            >
                                <FormattedMessage id="FORM_BUILDER:BUILDER" />
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default FormBuilder;
