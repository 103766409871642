import React from "react";
import { Handle } from "reactflow";
import useVerifyConnection from "../../../../../hooks/useVerifyConnection";
import "./StartNode.scss";

function StartNode() {
    const verifyConnection = useVerifyConnection();
    return (
        <>
            <Handle
                type="source"
                position="right"
                isValidConnection={verifyConnection}
            />
            <div className="start-node-container"></div>
        </>
    );
}

export default StartNode;
