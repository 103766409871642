import React from "react";
import { Handle } from "reactflow";
import useVerifyConnection from "../../../../../hooks/useVerifyConnection";
import "./OrNode.scss";

function OrNode({ selected }) {
    const verifyConnection = useVerifyConnection();

    return (
        <>
            <Handle
                type="source"
                position="right"
                isValidConnection={verifyConnection}
            />
            <div className={`or-node-container${selected ? " selected" : ""}`}>
                <div className="or-content">OR</div>
            </div>
            <Handle
                type="target"
                position="left"
                isValidConnection={verifyConnection}
            />
        </>
    );
}

export default OrNode;
