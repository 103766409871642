import { useCallback } from "react";
import { useEdges, useReactFlow } from "reactflow";

function useVerifyConnection() {
    const reactFlowInstance = useReactFlow();
    const edges = useEdges();
    const verify = useCallback(
        (connection) => {
            let { maxOutput } = reactFlowInstance.getNode(
                connection.source
            ).data;
            let { maxInput } = reactFlowInstance.getNode(
                connection.target
            ).data;
            return (
                (!maxOutput ||
                    edges.filter((edge) => edge.source === connection.source)
                        .length < maxOutput) &&
                (!maxInput ||
                    edges.filter((edge) => edge.target === connection.target)
                        .length < maxInput)
            );
        },
        [edges, reactFlowInstance]
    );
    return verify;
}

export default useVerifyConnection;
