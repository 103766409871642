import {
    AddOutlined,
    CancelOutlined,
    CircleTwoTone,
    ExpandMore,
    Replay,
    Save,
} from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertTitle,
    Box,
    Card,
    Checkbox,
    Divider,
    Fade,
    FormControlLabel,
    IconButton,
    SpeedDial,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { createRole, deleteRole, getRoles, updateRole } from "../../api/role";
import Loading from "../../components/loading/Loading";
import { compare } from "../../utils/compare";
import { extract } from "../../utils/extract";
import "./Roles.scss";
import { usePrompt } from "../../hooks/usePrompt";
import { groupBy } from "../../utils/general";
import useAbility  from "../../hooks/useAbility";

var defaultRoles = [];

function Roles() {
    const intl = useIntl();
    const {can} = useAbility()
    const confirm = useConfirm();
    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [selectedRole, setSelectedRole] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [error, setError] = useState("");

    const grouppedPermissions = groupBy(permissions.sort((a,b)=>{
        if(a.group && !b.group) return 1
        if(!a.group && b.group) return -1
        else return 0
    }));

    // usePrompt(
    // 	intl.formatMessage({ id: "WARNING:CHANGE_LOSS" }),
    // 	roles.some((role) => role.changed)
    // );

    usePrompt(
        roles.some((role) => role.changed),
        intl.formatMessage({ id: "ROLES:CHANGE_LOSS_WARNING:TITLE" }),
        intl.formatMessage({ id: "ROLES:CHANGE_LOSS_WARNING:DESCRIPTION" }),
        intl.formatMessage({ id: "ROLES:CHANGE_LOSS_WARNING:CONFIRM" }),
        intl.formatMessage({ id: "ROLES:CHANGE_LOSS_WARNING:CANCEL" })
    );

    useEffect(() => {
        setup();
    }, []);

    const setup = async () => {
        try {
            setPageLoading(true);
            setError("");
            let { roles, permissions } = await getRoles();
            setPermissions(
                permissions.sort((p1, p2) =>
                    p1.category < p2.category ? -1 : 1
                )
            );
            setRoles(roles);
            defaultRoles = roles.map((role) =>
                extract(role, ["_id", "name", "permissions"], ["permissions"])
            );
        } catch (error) {
            setError(error.message);
        } finally {
            setPageLoading(false);
        }
    };

    const addRole = async () => {
        try {
            setLoading(true);
            let t = Date.now();
            let role = await createRole({
                name: `New_Role_${t}`,
                permissions: [],
            });
            let selected = roles.length;
            defaultRoles.push(extract(role, ["_id", "name", "permissions"]));
            setRoles([...roles, role]);
            setSelectedRole(selected);
            toast.success(intl.formatMessage({ id: "ROLES:ROLE_ADDED" }));
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        } finally {
            setLoading(false);
        }
    };

    const saveRole = async () => {
        try {
            setLoading(true);
            await updateRole(roles[selectedRole]);
            let defaultRole = defaultRoles.find(
                (role) => role._id === roles[selectedRole]._id
            );
            defaultRole.name = roles[selectedRole].name;
            defaultRole.permissions = roles[selectedRole].permissions;
            setRoles(
                roles.map((role, index) =>
                    index === selectedRole
                        ? {
                              ...role,
                              changed: false,
                          }
                        : role
                )
            );
            toast.success(intl.formatMessage({ id: "ROLES:ROLE_UPDATED" }));
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        } finally {
            setLoading(false);
        }
    };

    const removeRole = async (index) => {
        try {
            await confirm({
                description: intl.formatMessage(
                    { id: "ROLES:ROLE_DELETE_CONFIRM" },
                    { name: roles[index].name }
                ),
            });
        } catch (error) {
            return;
        }
        try {
            setLoading(true);
            await deleteRole(roles[index]._id);
            if (index === selectedRole) {
                setSelectedRole(-1);
            } else if (selectedRole > index) {
                setSelectedRole(selectedRole - 1);
            }
            setRoles(roles.filter((_, i) => i !== index));
            toast.success(intl.formatMessage({ id: "ROLES:ROLE_DELETED" }));
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        } finally {
            setLoading(false);
        }
    };

    const setPermissionState = (permission, selected) => {
        if (selected) {
            setRoles(
                roles.map((role, index) => {
                    return index === selectedRole
                        ? {
                              ...role,
                              permissions: [
                                  ...role.permissions,
                                  permission.code,
                              ],
                              changed: !compare(defaultRoles[selectedRole], {
                                  ...extract(role, ["_id", "name"]),
                                  permissions: [
                                      ...role.permissions,
                                      permission.code,
                                  ].sort(),
                              }),
                          }
                        : role;
                })
            );
        } else {
            setRoles(
                roles.map((role, index) => {
                    return index === selectedRole
                        ? {
                              ...role,
                              permissions: role.permissions.filter(
                                  (perm) => perm !== permission.code
                              ),
                              changed: !compare(defaultRoles[selectedRole], {
                                  ...extract(role, ["_id", "name"]),
                                  permissions: [
                                      ...role.permissions.filter(
                                          (perm) => perm !== permission.code
                                      ),
                                  ].sort(),
                              }),
                          }
                        : role;
                })
            );
        }
    };

    const handleResourceSelect = (resource, group) => (event) => {
        let resourcePermissions = [];
        if (group) {
            if (!resource) {
                resourcePermissions = Object.values(
                    grouppedPermissions[group]
                ).reduce((prev, curr) => [...prev, ...curr], []);
            } else {
                resourcePermissions = grouppedPermissions[group][resource];
            }
        } else {
            resourcePermissions = grouppedPermissions[resource];
        }
        if (event.target.checked) {
            setRoles(
                roles.map((role, index) =>
                    index === selectedRole
                        ? {
                              ...role,
                              permissions: [
                                  ...role.permissions.filter(
                                      (permission) =>
                                          !resourcePermissions.find(
                                              (perm) => perm.code === permission
                                          )
                                  ),
                                  ...resourcePermissions.map(
                                      (permission) => permission.code
                                  ),
                              ],
                              changed: !compare(defaultRoles[selectedRole], {
                                  ...extract(role, ["_id", "name"]),
                                  permissions: [
                                      ...role.permissions.filter(
                                          (permission) =>
                                              !resourcePermissions.find(
                                                  (perm) =>
                                                      perm.code === permission
                                              )
                                      ),
                                      ...resourcePermissions.map(
                                          (permission) => permission.code
                                      ),
                                  ].sort(),
                              }),
                          }
                        : role
                )
            );
        } else {
            setRoles(
                roles.map((role, index) =>
                    index === selectedRole
                        ? {
                              ...role,
                              permissions: role.permissions.filter(
                                  (permission) =>
                                      !resourcePermissions.find(
                                          (perm) => perm.code === permission
                                      )
                              ),
                              changed: !compare(defaultRoles[selectedRole], {
                                  ...extract(role, ["_id", "name"]),
                                  permissions: [
                                      ...role.permissions.filter(
                                          (permission) =>
                                              !resourcePermissions.find(
                                                  (perm) =>
                                                      perm.code === permission
                                              )
                                      ),
                                  ].sort(),
                              }),
                          }
                        : role
                )
            );
        }
    };

    const handlePermissionsSelect = (event) => {
        if (event.target.checked) {
            setRoles(
                roles.map((role, index) =>
                    index === selectedRole
                        ? {
                              ...role,
                              permissions: [
                                  ...permissions.map(
                                      (permission) => permission.code
                                  ),
                              ],
                              changed: !compare(defaultRoles[selectedRole], {
                                  ...extract(role, ["_id", "name"]),
                                  permissions: [
                                      ...permissions.map(
                                          (permission) => permission.code
                                      ),
                                  ].sort(),
                              }),
                          }
                        : role
                )
            );
        } else {
            setRoles(
                roles.map((role, index) =>
                    index === selectedRole
                        ? {
                              ...role,
                              permissions: [],
                              changed: !compare(defaultRoles[selectedRole], {
                                  ...extract(role, ["_id", "name"]),
                                  permissions: [],
                              }),
                          }
                        : role
                )
            );
        }
    };

    const handleRoleNameBlur = (event) => {
        setRoles(
            roles.map((role, index) =>
                selectedRole === index
                    ? {
                          ...role,
                          name: event.target.value,
                          changed: !compare(defaultRoles[selectedRole], {
                              name: event.target.value,
                              ...extract(
                                  role,
                                  ["_id", "permissions"],
                                  ["permissions"]
                              ),
                          }),
                      }
                    : role
            )
        );
    };

    return (
        <Fade in={true} timeout={2000}>
            <Box
                className="roles-container"
                sx={{ flexDirection: error ? "column" : "row" }}
            >
                {loading && (
                    <Loading
                        container={{
                            position: "absolute",
                            zIndex: 99999,
                            backgroundColor: "#ffffff90",
                        }}
                        loader={{
                            height: "100px!important",
                            width: "100px!important",
                        }}
                    />
                )}
                {pageLoading ? (
                    <Loading
                        loader={{
                            height: "100px!important",
                            width: "100px!important",
                        }}
                    />
                ) : error ? (
                    <Alert severity="error">
                        <AlertTitle>
                            <FormattedMessage id="ERROR" />
                        </AlertTitle>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <FormattedMessage id={error} />
                            &nbsp;&nbsp;
                            <Replay
                                sx={{ cursor: "pointer" }}
                                onClick={setup}
                            />
                        </Box>
                    </Alert>
                ) : (
                    <React.Fragment>
                        <Box
                            className="roles"
                            onClick={() => {
                                setSelectedRole(-1);
                            }}
                            sx={{
                                width: {
                                    xs: "150px",
                                    sm: "250px",
                                    md: "300px",
                                },
                            }}
                        >
                            {roles.map((role, index) => (
                                <Card
                                    key={index}
                                    className={`role${
                                        selectedRole === index
                                            ? " selected"
                                            : ""
                                    }`}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setSelectedRole(index);
                                    }}
                                    elevation={1}
                                >
                                    {can("DELETE","ROLES") && <IconButton
                                        className="remove"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            removeRole(index);
                                        }}
                                    >
                                        <CancelOutlined />
                                    </IconButton>}
                                    <Typography
                                        variant="span"
                                        className="role-name"
                                    >
                                        {role.name}
                                    </Typography>
                                    {role.changed && index !== selectedRole && (
                                        <CircleTwoTone
                                            className="change-hint"
                                            fontSize="small"
                                        />
                                    )}
                                </Card>
                            ))}
                        </Box>
                        <Box className="role-details">
                            {selectedRole !== -1 ? (
                                <React.Fragment>
                                    <Box className="role-name-container">
                                        <TextField
                                            key={selectedRole}
                                            variant="outlined"
                                            label={`${intl.formatMessage({
                                                id: "ROLES:ROLE_NAME",
                                            })} *`}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={!can("CREATE_AND_UPDATE","ROLES")}
                                            defaultValue={
                                                roles[selectedRole].name
                                            }
                                            onBlur={handleRoleNameBlur}
                                            fullWidth
                                        ></TextField>
                                        {can("CREATE_AND_UPDATE","ROLES") && <IconButton
                                            className="save-role"
                                            onClick={() => {
                                                saveRole();
                                            }}
                                            disabled={
                                                !roles[selectedRole].changed
                                            }
                                            color="secondary"
                                        >
                                            <Save fontSize="large" />
                                        </IconButton>}
                                    </Box>
                                    <div className="permissions">
                                        <div className="permissions-title">
                                            <div>
                                                <FormattedMessage id="ROLES:PERMISSIONS" />
                                            </div>
                                            <div>
                                                <span>
                                                    <FormattedMessage id="ROLES:SELECT_ALL" />
                                                </span>
                                                <Switch
                                                    checked={compare(
                                                        [
                                                            ...permissions.map(
                                                                (permission) =>
                                                                    permission.code
                                                            ),
                                                        ].sort(),
                                                        [
                                                            ...roles[
                                                                selectedRole
                                                            ].permissions,
                                                        ].sort()
                                                    )}
                                                    disabled={!can("CREATE_AND_UPDATE","ROLES")}
                                                    onChange={
                                                        handlePermissionsSelect
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="permissions-body">
                                            {Object.keys(
                                                grouppedPermissions
                                            ).map((key, index) => {
                                                if (
                                                    Array.isArray(
                                                        grouppedPermissions[key]
                                                    )
                                                )
                                                    return (
                                                        <div
                                                            className="permission-element"
                                                            key={index}
                                                        >
                                                            <div className="permission-element-left">
                                                                <div className="permission-title">
                                                                    <FormattedMessage
                                                                        id={`ROLES:RESOURCES:${key}`}
                                                                    />
                                                                </div>
                                                                <div className="permission-desc"></div>
                                                            </div>
                                                            <div className="resource-actions">
                                                                {grouppedPermissions[
                                                                    key
                                                                ].map(
                                                                    (
                                                                        permission,
                                                                        i
                                                                    ) => (
                                                                        <FormControlLabel
                                                                            key={
                                                                                i
                                                                            }
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={roles[
                                                                                        selectedRole
                                                                                    ].permissions.includes(
                                                                                        permission.code
                                                                                    )}
                                                                                    disabled={!can("CREATE_AND_UPDATE","ROLES")}
                                                                                    onChange={(
                                                                                        event
                                                                                    ) => {
                                                                                        setPermissionState(
                                                                                            permission,
                                                                                            event
                                                                                                .target
                                                                                                .checked
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label={intl.formatMessage(
                                                                                {
                                                                                    id: `ROLES:ACTIONS:${permission.action}`,
                                                                                }
                                                                            )}
                                                                            labelPlacement="start"
                                                                        />
                                                                    )
                                                                )}
                                                            </div>
                                                            <div className="permission-active">
                                                                <Switch
                                                                    checked={
                                                                        roles[
                                                                            selectedRole
                                                                        ]
                                                                            ? grouppedPermissions[
                                                                                  key
                                                                              ].every(
                                                                                  (
                                                                                      permission
                                                                                  ) =>
                                                                                      roles[
                                                                                          selectedRole
                                                                                      ].permissions.includes(
                                                                                          permission.code
                                                                                      )
                                                                              )
                                                                            : false
                                                                    }
                                                                    disabled={!can("CREATE_AND_UPDATE","ROLES")}
                                                                    onChange={handleResourceSelect(
                                                                        key
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                else
                                                    return (
                                                        <Accordion key={index} >
                                                            <AccordionSummary
                                                                expandIcon={
                                                                    <ExpandMore />
                                                                }
                                                            >
                                                                <Box
                                                                    display={
                                                                        "flex"
                                                                    }
                                                                    width="100%"
                                                                    flex={1}
                                                                    justifyContent="space-between"
                                                                >
                                                                    <Typography>
                                                                        <FormattedMessage
                                                                            id={`ROLES:GROUPS:${key}`}
                                                                        />
                                                                    </Typography>
                                                                    <Switch
                                                                        checked={
                                                                            roles[
                                                                                selectedRole
                                                                            ]
                                                                                ? Object.values(
                                                                                      grouppedPermissions[
                                                                                          key
                                                                                      ]
                                                                                  ).every(
                                                                                      (
                                                                                          resource
                                                                                      ) =>
                                                                                          resource.every(
                                                                                              (
                                                                                                  permission
                                                                                              ) =>
                                                                                                  roles[
                                                                                                      selectedRole
                                                                                                  ].permissions.includes(
                                                                                                      permission.code
                                                                                                  )
                                                                                          )
                                                                                  )
                                                                                : false
                                                                        }
                                                                        onChange={handleResourceSelect(
                                                                            null,
                                                                            key
                                                                        )}
                                                                        disabled={!can("CREATE_AND_UPDATE","ROLES")}

                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.stopPropagation();
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                {Object.keys(
                                                                    grouppedPermissions[
                                                                        key // group
                                                                    ]
                                                                ).map(
                                                                    (
                                                                        resource,
                                                                        j
                                                                    ) => {
                                                                        return (
                                                                            <div
                                                                                className="permission-element"
                                                                                key={
                                                                                    j
                                                                                }
                                                                            >
                                                                                <div className="permission-element-left">
                                                                                    <div className="permission-title">
                                                                                        <FormattedMessage
                                                                                            id={`ROLES:RESOURCES:${resource}`}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="permission-desc"></div>
                                                                                </div>
                                                                                <div className="resource-actions">
                                                                                    {grouppedPermissions[
                                                                                        key
                                                                                    ][
                                                                                        resource
                                                                                    ].map(
                                                                                        (
                                                                                            permission,
                                                                                            i
                                                                                        ) => (
                                                                                            <FormControlLabel
                                                                                                key={
                                                                                                    i
                                                                                                }
                                                                                                control={
                                                                                                    <Checkbox
                                                                                                        checked={roles[
                                                                                                            selectedRole
                                                                                                        ].permissions.includes(
                                                                                                            permission.code
                                                                                                        )}
                                                                                                        disabled={!can("CREATE_AND_UPDATE","ROLES")}

                                                                                                        onChange={(
                                                                                                            event
                                                                                                        ) => {
                                                                                                            setPermissionState(
                                                                                                                permission,
                                                                                                                event
                                                                                                                    .target
                                                                                                                    .checked
                                                                                                            );
                                                                                                        }}
                                                                                                    />
                                                                                                }
                                                                                                label={intl.formatMessage(
                                                                                                    {
                                                                                                        id: `ROLES:ACTIONS:${permission.action}`,
                                                                                                    }
                                                                                                )}
                                                                                                labelPlacement="start"
                                                                                            />
                                                                                        )
                                                                                    )}
                                                                                </div>
                                                                                <div className="permission-active">
                                                                                    <Switch
                                                                                        checked={
                                                                                            roles[
                                                                                                selectedRole
                                                                                            ]
                                                                                                ? grouppedPermissions[
                                                                                                      key
                                                                                                  ][
                                                                                                      resource
                                                                                                  ].every(
                                                                                                      (
                                                                                                          permission
                                                                                                      ) =>
                                                                                                          roles[
                                                                                                              selectedRole
                                                                                                          ].permissions.includes(
                                                                                                              permission.code
                                                                                                          )
                                                                                                  )
                                                                                                : false
                                                                                        }
                                                                                        disabled={!can("CREATE_AND_UPDATE","ROLES")}
                                                                                        onChange={handleResourceSelect(
                                                                                            resource,
                                                                                            key
                                                                                        )}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    );
                                            })}
                                        </div>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Alert severity="info">
                                        <AlertTitle>
                                            <FormattedMessage id="INFO" />
                                        </AlertTitle>
                                        <FormattedMessage
                                            id={"ROLES:NO_ROLE_SELECTED"}
                                        />
                                    </Alert>
                                </Box>
                            )}
                        </Box>
                        <SpeedDial
                            ariaLabel="SpeedDial"
                            sx={{ position: "absolute", bottom: 30, right: 30 }}
                            icon={<AddOutlined />}
                            onClick={addRole}
                        ></SpeedDial>
                    </React.Fragment>
                )}
            </Box>
        </Fade>
    );
}

export default Roles;
