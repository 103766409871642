import "./Clients.scss";
import React, { useEffect, useState } from "react";
import { Avatar, Box, Card } from "@mui/material";
import { Add, Delete, Edit, Info } from "@mui/icons-material";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import DataContainer from "../../components/dataContainer/DataContainer";
import {
    CustomNoRowsOverlay,
    CustomLoadingOverlay,
} from "../../utils/gridOverlays";
import { useIntl } from "react-intl";
import AddModal from "../../components/addModal/AddModal";
import { useConfirm } from "material-ui-confirm";
import { stringAvatar } from "../../utils/avatar";
import {
    createClient,
    deleteClient,
    getClients,
    updateClient,
} from "../../api/client";
import DataValidationError from "../../errors/DataValidationError";
import { camelToSnakeUpperCase } from "../../utils/general";
import useAbility from "../../hooks/useAbility";

const Clients = () => {
    //#region States
    const { can } = useAbility();
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dataRows, setDataRows] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [modalLoading, setModalLoading] = useState(false);
    const [selectedClientId, setSelectedClientId] = useState(null);
    const selectedClient = dataRows.find((row) => row.id === selectedClientId);
    //#endregion

    //#region other Hooks
    const intl = useIntl();
    const confirm = useConfirm();
    //#endregion

    //#region useEffect
    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {}, [isModalOpen]);
    //#endregion

    //#region Handlers
    const handleCloseAdd = () => {
        setIsModalOpen(false);
    };
    const handleOpenAdd = () => {
        setIsModalOpen(true);
    };

    const handleCloseUpdate = () => {
        setSelectedClientId(null);
    };
    const handleOpenUpdate = (client) => {
        setSelectedClientId(client);
    };

    const getData = async () => {
        setLoading(true);
        try {
            let data = await getClients();
            setDataRows(
                data.map((row) => ({
                    ...row,
                    ...row.officerInfo,
                    id: row._id,
                }))
            );
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        }
        setLoading(false);
    };

    const addClient = async (data) => {
        try {
            setModalLoading(true);
            await createClient(data);
            toast.success(intl.formatMessage({ id: "CLIENTS:CLIENT_ADDED" }));
            setIsModalOpen(false);
            await getData();
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    if (field.kind === "unique") {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:UNIQUE" },
                                {
                                    field: intl.formatMessage({
                                        id: `CLIENTS:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    } else {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:FORMAT" },
                                {
                                    field: intl.formatMessage({
                                        id: `CLIENTS:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    }
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        } finally {
            setModalLoading(false);
        }
    };

    const update = async (data) => {
        try {
            setModalLoading(true);
            await updateClient(selectedClientId, data);
            toast.success(intl.formatMessage({ id: "CLIENTS:CLIENT_UPDATED" }));
            setSelectedClientId(null);
            await getData();
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    if (field.kind === "unique") {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:UNIQUE" },
                                {
                                    field: intl.formatMessage({
                                        id: `CLIENTS:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    } else {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:FORMAT" },
                                {
                                    field: intl.formatMessage({
                                        id: `CLIENTS:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    }
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        } finally {
            setModalLoading(false);
        }
    };

    const deleteSelectedClient = async (params) => {
        try {
            await confirm({
                title: intl.formatMessage({ id: "WARNING" }),
                description: intl.formatMessage(
                    { id: "CLIENTS:CLIENT_DELETE_CONFIRM" },
                    { name: params.row.name }
                ),
                confirmationText: intl.formatMessage({ id: "GLOBAL:CONFIRM" }),
                cancellationText: intl.formatMessage({ id: "GLOBAL:CANCEL" }),
            });
        } catch (error) {
            return;
        }
        setLoading(true);
        try {
            await deleteClient(params.row.id);
            toast.success(intl.formatMessage({ id: "CLIENTS:CLIENT_DELETED" }));
            await getData();
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        } finally {
            setLoading(false);
        }
    };
    //#endregion

    //#region constants
    const dataColumns = [
        {
            field: "actions",
            type: "actions",
            minWidth: 50,
            maxWidth: 50,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<Info />}
                    onClick={() => {}}
                    label={intl.formatMessage({ id: "ACTIONS:DETAILS" })}
                    showInMenu
                />,
                ...(can("UPDATE", "CLIENTS")
                    ? [
                          <GridActionsCellItem
                              icon={<Edit />}
                              onClick={() => {
                                  handleOpenUpdate(params.row.id);
                              }}
                              label={intl.formatMessage({ id: "ACTIONS:EDIT" })}
                              showInMenu
                          />,
                      ]
                    : []),
                ...(can("DELETE", "CLIENTS")
                    ? [
                          <GridActionsCellItem
                              icon={<Delete />}
                              onClick={() => deleteSelectedClient(params)}
                              label={intl.formatMessage({
                                  id: "ACTIONS:DELETE",
                              })}
                              showInMenu
                          />,
                      ]
                    : []),
            ],
        },
        {
            field: "logo",
            headerName: intl.formatMessage({ id: "CLIENTS:LOGO" }),
            renderCell: (params) =>
                params.value ? (
                    <Avatar
                        src={`data:image;base64,${params.value}`}
                        alt="avatar"
                        sx={{ width: 40, height: 40 }}
                    />
                ) : (
                    <Avatar
                        {...stringAvatar(
                            `${params.row.firstName} ${params.row.lastName}`
                        )}
                    />
                ),
            minWidth: 100,
            maxWidth: 100,
            height: 50,
        },
        {
            field: "name",
            headerName: intl.formatMessage({ id: "CLIENTS:NAME" }),
            flex: 1,
            minWidth: 160,
        },
        {
            field: "phone",
            headerName: intl.formatMessage({ id: "CLIENTS:PHONE" }),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "email",
            headerName: intl.formatMessage({ id: "CLIENTS:EMAIL" }),
            flex: 1,
            minWidth: 250,
        },
        {
            field: "color",
            headerName: intl.formatMessage({ id: "CLIENTS:COLOR" }),
            flex: 1,
            minWidth: 250,
            renderCell: (params) => (
                <Box
                    bgcolor={params.value}
                    padding="5px"
                    borderRadius={2}
                    sx={{
                        color: (theme) =>
                            theme.palette.getContrastText(params.value),
                    }}
                >
                    {params.value}
                </Box>
            ),
        },
    ];

    const headerButtons = [
        ...(can("CREATE", "CLIENTS")
            ? [
                  {
                      name: intl.formatMessage({ id: "CLIENTS:CREATE" }),
                      props: {
                          onClick: handleOpenAdd,
                          variant: "contained",
                          startIcon: <Add />,
                      },
                  },
              ]
            : []),
    ];
    const modalFields = [
        {
            name: "logo",
            label: intl.formatMessage({ id: "CLIENTS:LOGO" }),
            type: "image",
            fullWidth: false,
        },
        {
            name: "color",
            label: intl.formatMessage({ id: "CLIENTS:COLOR" }),
            type: "color",
            rules: {
                required: intl.formatMessage({
                    id: "FORM:ERROR_MESSAGES:REQUIRED",
                }),
            },
            fullWidth: false,
            defaultValue: "#ffffff",
        },
        {
            name: "name",
            label: intl.formatMessage({ id: "CLIENTS:NAME" }),
            type: "text",
            rules: {
                required: intl.formatMessage({
                    id: "FORM:ERROR_MESSAGES:REQUIRED",
                }),
            },
            fullWidth: true,
        },
        {
            name: "phone",
            label: intl.formatMessage({ id: "CLIENTS:PHONE" }),
            type: "tel",
            rules: {
                required: intl.formatMessage({
                    id: "FORM:ERROR_MESSAGES:REQUIRED",
                }),
                pattern: {
                    value: /^([+])?\d+$/,
                    message: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                    }),
                },
            },
        },
        {
            name: "email",
            label: intl.formatMessage({ id: "CLIENTS:EMAIL" }),
            type: "email",
            rules: {
                required: intl.formatMessage({
                    id: "FORM:ERROR_MESSAGES:REQUIRED",
                }),
                pattern: {
                    value: /^[a-zA-Z0-9.! #$%&'*+/=? ^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$/,
                    message: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                    }),
                },
            },
        },
    ];

    var updateFields = selectedClient
        ? {
              logo: selectedClient.logo,
              color: selectedClient.color,
              name: selectedClient.name,
              phone: selectedClient.phone,
              email: selectedClient.email,
          }
        : null;
    //#endregion

    return (
        <DataContainer
            buttons={headerButtons}
            onRefresh={() => {
                getData();
            }}
        >
            {isModalOpen && (
                <AddModal
                    open={isModalOpen}
                    handleClose={handleCloseAdd}
                    fields={modalFields}
                    title={intl.formatMessage({ id: "CLIENTS:CREATE" })}
                    submit={addClient}
                    buttons={[
                        {
                            props: {
                                type: "submit",
                                variant: "contained",
                                color: "secondary",
                            },
                            label: intl.formatMessage({ id: "GLOBAL:SUBMIT" }),
                        },
                    ]}
                    buttonsBoxProps={[]}
                    loading={modalLoading}
                />
            )}
            {updateFields && (
                <AddModal
                    open={!!updateFields}
                    handleClose={handleCloseUpdate}
                    fields={modalFields
                        .filter((field) =>
                            Object.keys(updateFields).includes(field.name)
                        )
                        .map((field) => ({
                            ...field,
                            defaultValue: updateFields[field.name],
                        }))}
                    title={intl.formatMessage({ id: "CLIENTS:UPDATE" })}
                    submit={update}
                    buttons={[
                        {
                            props: {
                                type: "submit",
                                variant: "contained",
                                color: "secondary",
                            },
                            label: intl.formatMessage({ id: "GLOBAL:SUBMIT" }),
                        },
                    ]}
                    buttonsBoxProps={[]}
                    loading={modalLoading}
                />
            )}
            {can("CONSULT", "CLIENTS") ? (
                <Card sx={{ height: "100%" }}>
                    <DataGrid
                        loading={loading}
                        columns={dataColumns}
                        rows={dataRows}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) =>
                            setPageSize(newPageSize)
                        }
                        rowsPerPageOptions={[10, 20, 30, 50, 100]}
                        components={{
                            Toolbar: GridToolbar,
                            LoadingOverlay: CustomLoadingOverlay,
                            NoRowsOverlay: () =>
                                CustomNoRowsOverlay(
                                    intl.formatMessage({
                                        id: "CLIENTS:NO_CLIENTS",
                                    })
                                ),
                            NoResultsOverlay: () =>
                                CustomNoRowsOverlay(
                                    intl.formatMessage({
                                        id: "CLIENTS:NO_RESULTS",
                                    })
                                ),
                        }}
                        disableSelectionOnClick
                        onCellDoubleClick={() => {}}
                    />
                </Card>
            ) : (
                <>THIS IS A PLACEHODER</>
            )}
        </DataContainer>
    );
};

export default Clients;
