import "./GlobalVariables.scss";
import React, { useEffect, useState } from "react";
import { Card } from "@mui/material";
import { Add, Delete, Edit, Info } from "@mui/icons-material";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import DataContainer from "../../components/dataContainer/DataContainer";
import {
    CustomNoRowsOverlay,
    CustomLoadingOverlay,
} from "../../utils/gridOverlays";
import { useIntl } from "react-intl";
import AddModal from "../../components/addModal/AddModal";
import { useConfirm } from "material-ui-confirm";
import {
    createGlobalVariable,
    deleteGlobalVariable,
    getGlobalVariables,
    updateGlobalVariable,
} from "../../api/globalVariable";
import DataValidationError from "../../errors/DataValidationError";
import { camelToSnakeUpperCase } from "../../utils/general";
import useAbility from "../../hooks/useAbility";
const GlobalVariables = () => {
    const { can } = useAbility();
    //#region States
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dataRows, setDataRows] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [modalLoading, setModalLoading] = useState(false);
    const [selectedVariableId, setSelectedVariableId] = useState(null);
    const selectedVariable = dataRows.find(
        (row) => row.id === selectedVariableId
    );
    //#endregion

    //#region other Hooks
    const intl = useIntl();
    const confirm = useConfirm();
    //#endregion

    //#region useEffect
    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {}, [isModalOpen]);
    //#endregion

    //#region Handlers
    const handleCloseAdd = () => {
        setIsModalOpen(false);
    };
    const handleOpenAdd = () => {
        setIsModalOpen(true);
    };
    const handleCloseUpdate = () => {
        setSelectedVariableId(null);
    };
    const handleOpenUpdate = (globalVariable) => {
        setSelectedVariableId(globalVariable);
    };

    const getData = async () => {
        setLoading(true);
        try {
            let data = await getGlobalVariables();
            setDataRows(
                data.map((row) => ({
                    ...row,
                    ...row.officerInfo,
                    id: row._id,
                }))
            );
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        }
        setLoading(false);
    };

    const addGlobalVariable = async (data) => {
        try {
            setModalLoading(true);
            await createGlobalVariable(data);
            toast.success(
                intl.formatMessage({
                    id: "GLOBAL_VARIABLES:GLOBAL_VARIABLE_ADDED",
                })
            );
            setIsModalOpen(false);
            await getData();
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    if (field.kind === "unique") {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:UNIQUE" },
                                {
                                    field: intl.formatMessage({
                                        id: `GLOBAL_VARIABLES:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    } else {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:FORMAT" },
                                {
                                    field: intl.formatMessage({
                                        id: `GLOBAL_VARIABLES:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    }
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        } finally {
            setModalLoading(false);
        }
    };

    const updateVariable = async (data) => {
        try {
            setModalLoading(true);
            await updateGlobalVariable(selectedVariableId, data);
            toast.success(
                intl.formatMessage({
                    id: "GLOBAL_VARIABLES:GLOBAL_VARIABLE_UPDATED",
                })
            );
            setSelectedVariableId(null);
            await getData();
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    if (field.kind === "unique") {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:UNIQUE" },
                                {
                                    field: intl.formatMessage({
                                        id: `GLOBAL_VARIABLES:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    } else {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:FORMAT" },
                                {
                                    field: intl.formatMessage({
                                        id: `GLOBAL_VARIABLES:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    }
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        } finally {
            setModalLoading(false);
        }
    };

    const deleteSelectedGlobalVariable = async (params) => {
        try {
            await confirm({
                title: intl.formatMessage({ id: "WARNING" }),
                description: intl.formatMessage(
                    { id: "GLOBAL_VARIABLES:GLOBAL_VARIABLE_DELETE_CONFIRM" },
                    { name: params.row.name }
                ),
                confirmationText: intl.formatMessage({ id: "GLOBAL:CONFIRM" }),
                cancellationText: intl.formatMessage({ id: "GLOBAL:CANCEL" }),
            });
        } catch (error) {
            return;
        }
        setLoading(true);
        try {
            await deleteGlobalVariable(params.row.id);
            toast.success(
                intl.formatMessage({
                    id: "GLOBAL_VARIABLES:GLOBAL_VARIABLE_DELETED",
                })
            );
            await getData();
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        } finally {
            setLoading(false);
        }
    };
    //#endregion

    //#region constants
    const dataColumns = [
        {
            field: "actions",
            type: "actions",
            minWidth: 50,
            maxWidth: 50,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<Info />}
                    onClick={() => {}}
                    label={intl.formatMessage({ id: "ACTIONS:DETAILS" })}
                    showInMenu
                />,
                ...(can("UPDATE", "GLOBAL_VARIABLES")
                    ? [
                          <GridActionsCellItem
                              icon={<Edit />}
                              onClick={() => {
                                  handleOpenUpdate(params.row.id);
                              }}
                              label={intl.formatMessage({ id: "ACTIONS:EDIT" })}
                              showInMenu
                          />,
                      ]
                    : []),
                ...(can("DELETE", "GLOBAL_VARIABLES")
                    ? [
                          <GridActionsCellItem
                              icon={<Delete />}
                              onClick={() =>
                                  deleteSelectedGlobalVariable(params)
                              }
                              label={intl.formatMessage({
                                  id: "ACTIONS:DELETE",
                              })}
                              showInMenu
                          />,
                      ]
                    : []),
            ],
        },
        {
            field: "name",
            headerName: intl.formatMessage({ id: "GLOBAL_VARIABLES:NAME" }),
            flex: 1,
            minWidth: 160,
        },
        {
            field: "value",
            headerName: intl.formatMessage({ id: "GLOBAL_VARIABLES:VALUE" }),
            flex: 2,
            minWidth: 250,
        },
        {
            field: "description",
            headerName: intl.formatMessage({
                id: "GLOBAL_VARIABLES:DESCRIPTION",
            }),
            flex: 2,
            minWidth: 300,
        },
    ];

    const headerButtons = [
        ...(can("CREATE", "GLOBAL_VARIABLES")
            ? [
                  {
                      name: intl.formatMessage({
                          id: "GLOBAL_VARIABLES:CREATE",
                      }),
                      props: {
                          onClick: handleOpenAdd,
                          variant: "contained",
                          startIcon: <Add />,
                      },
                  },
              ]
            : []),
    ];
    const modalFields = [
        {
            name: "name",
            label: intl.formatMessage({ id: "GLOBAL_VARIABLES:NAME" }),
            type: "text",
            rules: {
                required: intl.formatMessage({
                    id: "FORM:ERROR_MESSAGES:REQUIRED",
                }),
                pattern: {
                    value: /^.+$/,
                    message: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                    }),
                },
            },
        },
        {
            name: "value",
            label: intl.formatMessage({ id: "GLOBAL_VARIABLES:VALUE" }),
            type: "text",
            rules: {
                required: intl.formatMessage({
                    id: "FORM:ERROR_MESSAGES:REQUIRED",
                }),
            },
        },
        {
            name: "description",
            label: intl.formatMessage({ id: "GLOBAL_VARIABLES:DESCRIPTION" }),
            type: "text",
            fullWidth: true,
            multiline: true,
        },
    ];

    var updateFields = selectedVariable
        ? {
              name: selectedVariable.name,
              value: selectedVariable.value,
              description: selectedVariable.description,
          }
        : null;
    //#endregion

    return (
        <DataContainer
            buttons={headerButtons}
            onRefresh={() => {
                getData();
            }}
        >
            {isModalOpen && (
                <AddModal
                    open={isModalOpen}
                    handleClose={handleCloseAdd}
                    fields={modalFields}
                    title={intl.formatMessage({
                        id: "GLOBAL_VARIABLES:CREATE",
                    })}
                    submit={addGlobalVariable}
                    buttons={[
                        {
                            props: {
                                type: "submit",
                                variant: "contained",
                                color: "secondary",
                            },
                            label: intl.formatMessage({ id: "GLOBAL:SUBMIT" }),
                        },
                    ]}
                    buttonsBoxProps={[]}
                    loading={modalLoading}
                />
            )}
            {updateFields && (
                <AddModal
                    open={!!updateFields}
                    handleClose={handleCloseUpdate}
                    fields={modalFields
                        .filter((field) =>
                            Object.keys(updateFields).includes(field.name)
                        )
                        .map((field) => ({
                            ...field,
                            defaultValue: updateFields[field.name],
                        }))}
                    title={intl.formatMessage({
                        id: "GLOBAL_VARIABLES:UPDATE",
                    })}
                    submit={updateVariable}
                    buttons={[
                        {
                            props: {
                                type: "submit",
                                variant: "contained",
                                color: "secondary",
                            },
                            label: intl.formatMessage({ id: "GLOBAL:SUBMIT" }),
                        },
                    ]}
                    buttonsBoxProps={[]}
                    loading={modalLoading}
                />
            )}

            {can("CONSULT", "GLOBAL_VARIABLES") ? (
                <Card sx={{ height: "100%" }}>
                    <DataGrid
                        loading={loading}
                        columns={dataColumns}
                        rows={dataRows}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) =>
                            setPageSize(newPageSize)
                        }
                        rowsPerPageOptions={[10, 20, 30, 50, 100]}
                        components={{
                            Toolbar: GridToolbar,
                            LoadingOverlay: CustomLoadingOverlay,
                            NoRowsOverlay: () =>
                                CustomNoRowsOverlay(
                                    intl.formatMessage({
                                        id: "GLOBAL_VARIABLES:NO_GLOBAL_VARIABLES",
                                    })
                                ),
                            NoResultsOverlay: () =>
                                CustomNoRowsOverlay(
                                    intl.formatMessage({
                                        id: "GLOBAL_VARIABLES:NO_RESULTS",
                                    })
                                ),
                        }}
                        disableSelectionOnClick
                        onCellDoubleClick={() => {}}
                    />
                </Card>
            ) : (
                <>THIS IS A PLACEHOLDER</>
            )}
        </DataContainer>
    );
};

export default GlobalVariables;
