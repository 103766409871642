import React from "react";
import { Handle } from "reactflow";
import useVerifyConnection from "../../../../../hooks/useVerifyConnection";
import "./EndNode.scss";

function EndNode({ selected, isValid }) {
    const verifyConnection = useVerifyConnection();

    return (
        <>
            <Handle
                type="target"
                position="left"
                isValidConnection={verifyConnection}
            />
            <div className={`end-node-container${selected ? " selected" : ""}`}>
                <div className="end-node-inner"></div>
            </div>
        </>
    );
}

export default EndNode;
