import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import ReactFlow, {
    addEdge,
    applyEdgeChanges,
    applyNodeChanges,
    Background,
    Controls,
    MarkerType,
    MiniMap,
    useReactFlow,
} from "reactflow";
import { usePrompt } from "../../../hooks/usePrompt";
import { compare } from "../../../utils/compare";
import { FormattedMessage, useIntl } from "react-intl";
import useWorkflowContext from "../../../hooks/useWorkflowContext";
import "./Flow.scss";
import { camelToSnakeUpperCase, parse } from "../../../utils/general";
import useFormatNodes from "../../../hooks/useFormatNodes";
import { verifyWorkflow } from "../../../utils/workflow";
import { createWorkflow, updateWorkflow } from "../../../api/workflow";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DataValidationError from "../../../errors/DataValidationError";
import Loading from "../../../components/loading/Loading";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { nanoid } from "nanoid";
import ContextMenu from "../../../components/contextMenu/ContextMenu";
import { ContentCopy, Delete } from "@mui/icons-material";
import NodeContainer from "./nodes/nodeContainer/NodeContainer";
import "reactflow/dist/style.css";

const Flow = () => {
    const [edges, setEdges] = useState([]);
    const [change, setChange] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hoveredNode, setHoveredNode] = useState(null);
    const [hoveredNodePos, setHoveredNodePos] = useState(null);
    const [contextMenu, setContextMenu] = useState(null);

    const {
        title,
        data,
        setData,
        blockDelete,
        selectedNode,
        setSelectedNode,
        settings,
        setSettings,
        nodes,
        setNodes,
        setDraggingNode,
        showFlowControls,
    } = useWorkflowContext();
    const intl = useIntl();
    const reactFlowInstance = useReactFlow();
    const reactFlowWrapper = useRef(null);
    const nodeTypes = useMemo(
        () => ({
            START: NodeContainer,
            AND: NodeContainer,
            OR: NodeContainer,
            MAIL: NodeContainer,
            SLEEP: NodeContainer,
            CONDITION: NodeContainer,
            FORM: NodeContainer,
            COMMENT: NodeContainer,
            EDIT_SITE: NodeContainer,
            CHECKLIST_BUILD: NodeContainer,
            FILL_REPORT: NodeContainer,
            END: NodeContainer,
        }),
        []
    );
    const deleteKeyCode = useMemo(
        () => (blockDelete ? [] : ["Delete", "Backspace"]),
        [blockDelete]
    );
    const formattedNodes = useFormatNodes();
    const { id } = useParams();

    usePrompt(
        change,
        intl.formatMessage({ id: "WORKFLOW:CHANGE_LOSS_WARNING:TITLE" }),
        intl.formatMessage({ id: "WORKFLOW:CHANGE_LOSS_WARNING:DESCRIPTION" }),
        intl.formatMessage({ id: "WORKFLOW:CHANGE_LOSS_WARNING:CONFIRM" }),
        intl.formatMessage({ id: "WORKFLOW:CHANGE_LOSS_WARNING:CANCEL" }),
        {
            onConfirm: async (save) => {
                if (save) {
                    setLoading(true);
                    let result = await handleSave();
                    setLoading(false);
                    return result;
                }
                return true;
            },
            checkText: intl.formatMessage({
                id: "WORKFLOW:CHANGE_LOSS_WARNING:CHECK_TEXT",
            }),
            defaultChecked: false,
        }
    );

    useEffect(() => {
        setChange(
            (!data && formattedNodes.length > 1) ||
                (data && !compare(data, { nodes: formattedNodes, edges }))
        );
    }, [formattedNodes, edges, data]);

    useEffect(() => {
        if (data) {
            setNodes(data.nodes);
            setEdges(data.edges);
        }
    }, [data]);

    useEffect(() => {
        const handler = (event) => {
            if ((event.key === "d" || event.key === "D") && event.ctrlKey) {
                event.preventDefault();
                if (!selectedNode) {
                    return;
                }
                const node = reactFlowInstance.getNode(selectedNode);

                const position = {
                    x: node.position.x,
                    y: node.position.y + 100,
                };

                reactFlowInstance.addNodes({
                    ...node,
                    position,
                    selected: false,
                    id: `${nanoid()}${nanoid()}`,
                });
            }
        };
        window.addEventListener("keydown", handler);
        return () => {
            window.removeEventListener("keydown", handler);
        };
    }, [reactFlowInstance, selectedNode]);

    const handleSave = () => {
        let nodes = formattedNodes;
        if (
            verifyWorkflow(
                nodes,
                edges,
                reactFlowInstance.getNode("start-node")
            )
        ) {
            if (data) {
                return saveUpdates(id, {
                    nodes,
                    edges,
                    name: title,
                });
            } else {
                return addWorkflow({
                    name: title,
                    nodes,
                    edges,
                });
            }
        } else {
            toast.error(
                intl.formatMessage({ id: "WORKFLOWS:INVALID_WORKFLOW" })
            );
        }
    };

    const addWorkflow = async (data) => {
        try {
            let { _id } = await createWorkflow(data);
            setData({ nodes: data.nodes, edges: data.edges });
            await new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve();
                }, 500);
            });
            toast.success(
                intl.formatMessage({ id: "WORKFLOWS:WORKFLOW_ADDED" })
            );
            return true;
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    if (field.kind === "unique") {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:UNIQUE" },
                                {
                                    field: intl.formatMessage({
                                        id: `WORKFLOWS:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    } else {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:FORMAT" },
                                {
                                    field: intl.formatMessage({
                                        id: `WORKFLOWS:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    }
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        } finally {
        }
    };

    const saveUpdates = async (id, data) => {
        try {
            await updateWorkflow(id, data);
            setData({ nodes: data.nodes, edges: data.edges });
            await new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve();
                }, 500);
            });
            toast.success(
                intl.formatMessage({ id: "WORKFLOWS:WORKFLOW_UPDATED" })
            );
            return true;
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    if (field.kind === "unique") {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:UNIQUE" },
                                {
                                    field: intl.formatMessage({
                                        id: `WORKFLOWS:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    } else {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:FORMAT" },
                                {
                                    field: intl.formatMessage({
                                        id: `WORKFLOWS:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    }
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        } finally {
        }
    };

    const onNodesChange = useCallback(
        (changes) => {
            let validChanges = changes.filter(
                (change) =>
                    (change.type !== "remove" && change.type !== "select") ||
                    change.id !== "start-node"
            );
            setNodes((nds) => applyNodeChanges(validChanges, nds));
            setSettings({
                ...settings,
                ...changes
                    .filter(
                        (change) =>
                            change.type === "add" && change.item.data.settings
                    )
                    .reduce((previous, current) => {
                        return {
                            ...previous,
                            [current.item.id]: current.item.data.settings,
                        };
                    }, {}),
            });
        },
        [setNodes, settings, setSettings]
    );
    const onEdgesChange = useCallback(
        (changes) =>
            setEdges((eds) => {
                console.log(eds);
                return applyEdgeChanges(changes, eds).map((edge) => ({
                    ...edge,
                    interactionWidth: 20,
                    style: { strokeWidth: 2 },
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                        color: "black",
                    },
                }));
            }),
        [setEdges]
    );
    const onConnect = useCallback(
        (connection) =>
            setEdges((eds) =>
                addEdge(connection, eds).map((edge) => ({
                    ...edge,
                    interactionWidth: 20,
                    style: { strokeWidth: 2 },
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                        color: "black",
                    },
                }))
            ),
        [setEdges]
    );
    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
    }, []);
    const onDrop = useCallback(
        (event) => {
            event.preventDefault();
            const reactFlowBounds =
                reactFlowWrapper.current.getBoundingClientRect();
            const type = event.dataTransfer.getData("application/reactflow");

            // check if the dropped element is valid
            if (typeof type === "undefined" || !type) {
                return;
            }

            const position = reactFlowInstance.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            });
            reactFlowInstance.addNodes({
                id: `${nanoid()}${nanoid()}`,
                type: type,
                data: parse(event.dataTransfer.getData("data")),
                position: position,
            });
        },
        [reactFlowInstance, nodes]
    );

    const nodeColor = (node) => {
        switch (node.type) {
            case "input":
                return "red";
            case "default":
                return "#00ff00";
            case "output":
                return "rgb(0,0,255)";
            default:
                return "#eee";
        }
    };

    const handleSelectionChange = ({ nodes, edges }) => {
        if (
            nodes.length !== 1 ||
            edges.length !== 0 ||
            !nodes[0]?.data?.settings
        ) {
            setSelectedNode(null);
        } else if (selectedNode !== nodes[0].id) {
            setSelectedNode(nodes[0].id);
        }
    };

    const actions = useMemo(
        () => [
            {
                items: [
                    {
                        icon: ContentCopy,
                        text: intl.formatMessage({ id: "ACTIONS:DUPLICATE" }),
                        onClick: (data) => {
                            const position = {
                                x: data.position.x,
                                y: data.position.y + 100,
                            };

                            reactFlowInstance.addNodes({
                                ...data,
                                position,
                                selected: false,
                                id: `${nanoid()}${nanoid()}`,
                            });
                        },
                    },
                    {
                        icon: Delete,
                        text: intl.formatMessage({ id: "ACTIONS:DELETE" }),
                        onClick: (data) => {
                            reactFlowInstance.setNodes(
                                nodes.filter((node) => node.id !== data.id)
                            );
                        },
                    },
                ],
            },
        ],
        [intl, reactFlowInstance, nodes]
    );

    return (
        <div className="flow-container" ref={reactFlowWrapper}>
            {loading && (
                <Loading
                    container={{
                        position: "absolute",
                        backgroundColor: "#00000055",
                        zIndex: 99999,
                        top: 0,
                        left: 0,
                    }}
                />
            )}
            {hoveredNodePos && hoveredNode && (
                <Box
                    sx={{
                        backgroundColor: "#696969",
                        ...hoveredNodePos,
                        position: "fixed",
                        zIndex: 99,
                        borderRadius: 2,
                    }}
                >
                    <Box margin="5px">
                        <Typography
                            variant="span"
                            color="white.main"
                            fontSize={14}
                            fontWeight="bold"
                        >
                            <FormattedMessage id="WORKFLOW:NODES:TYPE" />{" "}
                            :&nbsp;
                        </Typography>
                        <Typography
                            variant="span"
                            color="white.main"
                            fontSize={12}
                        >
                            <FormattedMessage
                                id={`WORKFLOW:NODES:TYPES:${hoveredNode.type}`}
                            />
                        </Typography>
                    </Box>
                    {hoveredNode.data.settings?.name && (
                        <Box margin="5px">
                            <Typography
                                variant="span"
                                color="white.main"
                                fontSize={14}
                                fontWeight="bold"
                            >
                                <FormattedMessage id="WORKFLOW:NODES:NAME" />{" "}
                                :&nbsp;
                            </Typography>
                            <Typography
                                variant="span"
                                color="white.main"
                                fontSize={12}
                            >
                                {hoveredNode.data.settings?.name}
                            </Typography>
                        </Box>
                    )}
                    {hoveredNode.data.settings?.description && (
                        <Box margin="5px">
                            <Typography
                                variant="span"
                                color="white.main"
                                fontSize={14}
                                fontWeight="bold"
                            >
                                <FormattedMessage id="WORKFLOW:NODES:DESCRIPTION" />{" "}
                                :&nbsp;
                            </Typography>
                            <Typography
                                variant="span"
                                color="white.main"
                                fontSize={12}
                            >
                                {hoveredNode.data.settings?.description}
                            </Typography>
                        </Box>
                    )}
                </Box>
            )}
            <ReactFlow
                panOnDrag={true}
                panOnScroll={false}
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                nodeTypes={nodeTypes}
                onDragOver={onDragOver}
                onDrop={onDrop}
                onInit={() => {
                    if (!data) {
                        reactFlowInstance.addNodes({
                            id: "start-node",
                            type: "START",
                            data: {},
                            position: { x: 100, y: 250 },
                        });
                    }
                }}
                multiSelectionKeyCode="IntlBackslash"
                deleteKeyCode={deleteKeyCode}
                onSelectionChange={handleSelectionChange}
                onNodeMouseEnter={(event, node) => {
                    let hoveredNode = formattedNodes.find(
                        (element) => element.id === node.id
                    );
                    setHoveredNode(hoveredNode);
                }}
                onNodeMouseMove={(event, node) => {
                    setHoveredNodePos({
                        left: event.pageX + 10,
                        top: event.pageY + 10,
                    });
                }}
                onNodeMouseLeave={(event) => {
                    setHoveredNodePos(null);
                    setHoveredNode(null);
                }}
                onNodeDragStart={() => {
                    setHoveredNodePos(null);
                }}
                onNodeDragStop={() => {
                    setDraggingNode(false);
                }}
                onNodeDrag={() => {
                    setDraggingNode(true);
                }}
                onNodeContextMenu={(event, node) => {
                    event.preventDefault();
                    setContextMenu(
                        contextMenu === null
                            ? {
                                  mouseX: event.clientX + 2,
                                  mouseY: event.clientY - 6,
                                  data: node,
                              }
                            : null
                    );
                }}
            >
                <Background variant="dots" gap={15} size={1.5} />
                {showFlowControls && <Controls />}
                {showFlowControls && (
                    <MiniMap nodeColor={nodeColor} nodeStrokeWidth={3} />
                )}
            </ReactFlow>
            <ContextMenu
                data={contextMenu?.data}
                sections={actions}
                open={contextMenu !== null}
                onClose={() => {
                    setContextMenu(null);
                }}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? {
                              top: contextMenu.mouseY,
                              left: contextMenu.mouseX,
                          }
                        : undefined
                }
            />
        </div>
    );
};

export default Flow;
