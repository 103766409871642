import {
    Abc,
    AccessTime,
    AttachFile,
    CalendarToday,
    FormatAlignLeft,
    List,
    Pin,
    TextFields,
    Today,
    ToggleOn,
} from "@mui/icons-material";
import React, { useMemo } from "react";
import { Droppable } from "react-beautiful-dnd";
import Field from "./field/Field";
import useFormBuilderContext from "../../../../../../hooks/useFormBuilderContext";
import "./FieldsContainer.scss";
import { Box } from "@mui/material";

function FieldsContainer({ isLarge }) {
    const { fields } = useFormBuilderContext();
    const icons = useMemo(
        () => ({
            staticText: <TextFields />,
            text: <Abc />,
            richText: <FormatAlignLeft />,
            number: <Pin />,
            decimalNumber: <Pin />,
            date: <CalendarToday />,
            time: <AccessTime />,
            datetime: <Today />,
            switch: <ToggleOn />,
            select: <List />,
            multiSelect: <List />,
            files: <AttachFile />,
        }),
        []
    );

    return (
        <Droppable droppableId="fields" isDropDisabled>
            {(provided) => (
                <Box
                    className={`fields-container${isLarge ? " large" : ""}`}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    {fields.map((field, index) => (
                        <Field
                            key={index}
                            index={index}
                            icon={icons[field.type]}
                            data={field}
                        />
                    ))}
                    {provided.placeholder}
                </Box>
            )}
        </Droppable>
    );
}

export default FieldsContainer;
