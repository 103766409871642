import React, { useState } from "react";
import "./Notifications.scss";
import { Box, Typography, Button, Divider } from "@mui/material";
import {
    AlternateEmail,
    Assignment,
    AssignmentTurnedIn,
    Comment,
    FactCheck,
    Reply,
} from "@mui/icons-material";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { setNotificationSeen } from "../../../../api/notification";

const iconList = {
    COMMENT: <Comment />,
    COMMENT_MENTION: <AlternateEmail />,
    COMMENT_REPLY: <Reply />,
    // COMMENT_MENTION_REPLY: <Reply />,
    FORM_ASSIGNED: <Assignment />,
    FORM_COMPLETED: <AssignmentTurnedIn />,
    CHECKLIST_BUILD_ASSIGNED: <FactCheck />,
    CHECKLIST_CREATED: <FactCheck />,
};

const SeenWrapper = (props) => {
    return !props.seen ? (
        <Box
            sx={{
                "&:after": {
                    content: '" "',
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    width: "7px",
                    height: "7px",
                    backgroundColor: "blue",
                    borderRadius: "50%",
                },
                position: "relative",
            }}
        >
            {props.children}
        </Box>
    ) : (
        <>{props.children}</>
    );
};

function Notifications({
    notifications,
    onSeeMore,
    allNotifications,
    refreshNotifications,
    hideNotifications,
}) {
    const intl = useIntl();
    const navigate = useNavigate();

    const handleSeeMore = () => {
        if (onSeeMore) {
            onSeeMore();
        }
    };

    const markAsSeen = async (notification) => {
        try {
            await setNotificationSeen(notification);
            await refreshNotifications();
        } catch (error) {}
    };

    const handleNotifClick = (notification) => {
        if (!notification.seen) {
            markAsSeen(notification._id);
        }
        hideNotifications();
        switch (notification.kind) {
            case "COMMENT":
            case "COMMENT_MENTION":
            case "COMMENT_REPLY":
                navigate(
                    `/sites?siteId=${notification.data.siteId}&target=details&tab=comments#${notification.data.commentId}`
                );
                break;
            case "FORM_ASSIGNED":
                navigate(`/tasks?formId=${notification.data.taskId}`);
                break;
            case "FORM_COMPLETED":
                navigate(`/tasks`);
                break;
            case "CHECKLIST_BUILD_ASSIGNED":
                navigate(`/tasks?checklistBuildId=${notification.data.taskId}`);
                break;
            case "CHECKLIST_CREATED":
                navigate(
                    `/sites?siteId=${notification.data.siteId}&target=details&tab=checklist`
                );
                break;
            default:
                break;
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            className="notifications-container"
        >
            {notifications &&
                notifications.map((notification, index) => (
                    <React.Fragment key={notification._id}>
                        <SeenWrapper
                            seen={notification.seen}
                            key={notification.id}
                        >
                            <Box
                                onClick={() => {
                                    handleNotifClick(notification);
                                }}
                                backgroundColor={
                                    notification.seen ? "#eee" : "#fff"
                                }
                                sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                        backgroundColor: "#ddd",
                                    },
                                }}
                            >
                                <Box
                                    pt={1}
                                    pr={1}
                                    pl={1}
                                    display="flex"
                                    width={allNotifications ? "100%" : "300px"}
                                    height=" 80px"
                                >
                                    <Box
                                        width="56px"
                                        pt={1}
                                        pr={1}
                                        pl={1}
                                        boxSizing="border-box"
                                    >
                                        {iconList[notification.kind]}
                                    </Box>
                                    <Box
                                        width="calc(100% - 56px)"
                                        display="flex"
                                        flexDirection="column"
                                        boxSizing="border-box"
                                        p={1}
                                    >
                                        <Typography
                                            variant="span"
                                            fontWeight="bold"
                                            fontSize="16px"
                                            lineHeight="18px"
                                            textOverflow="ellipsis"
                                            width="100%"
                                            maxWidth="100%"
                                            maxHeight="20px"
                                            overflow="hidden"
                                            whiteSpace="nowrap"
                                            title={notification.title}
                                        >
                                            {notification.title}
                                        </Typography>
                                        <Typography
                                            variant="span"
                                            fontSize="14px"
                                            lineHeight="15px"
                                            textOverflow="ellipsis"
                                            width="100%"
                                            maxWidth="100%"
                                            maxHeight="28px"
                                            overflow="hidden"
                                            marginTop="5px"
                                            sx={{
                                                wordWrap: "break-word",
                                                WebkitBoxOrient: "vertical",
                                                WebkitLineClamp: 2,
                                                display: "-webkit-box",
                                            }}
                                        >
                                            {notification.description}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    pr={1}
                                >
                                    <Typography variant="caption">
                                        {new Date(
                                            notification.createdAt
                                        ).toLocaleString("en-GB")}
                                    </Typography>
                                </Box>
                            </Box>
                        </SeenWrapper>
                        <Divider />
                    </React.Fragment>
                ))}
            {onSeeMore && (
                <Box p={1} display="flex" justifyContent="center">
                    <Button color="primary" onClick={handleSeeMore}>
                        {intl.formatMessage({
                            id: "HEADER:SEE_ALL_NOTIFICATIONS",
                        })}
                    </Button>
                </Box>
            )}
        </Box>
    );
}

export default Notifications;
