import { CameraAlt, Refresh } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { Dropzone, DropzoneStatus } from "@mantine/dropzone";
import "./FileInput.scss";
import { FormattedMessage } from "react-intl";

function FileInput({
    name,
    defaultValue,
    onChange,
    label,
    defaultBackgroundColor,
}) {
    const [value, setValue] = useState();

    const handleFileChange = (files) => {
        const newfile = files[0];
        setValue(newfile);
        onChange(newfile);
    };

    return (
        <div className="file-input-container">
            <Dropzone
                onDrop={handleFileChange}
                onReject={(files) => console.log("rejected files", files)}
                multiple={false}
                accept={[".kmz", ".kml"]}
                padding="10px"
                name={name}
            >
                {(status) => (
                    <Box className="dropzone-content">
                        {value ? (
                            value.name
                        ) : (
                            <Typography color="primary" fontSize="20px">
                                <FormattedMessage id="GLOBAL:DRAG_ZONE_MESSAGE" />
                            </Typography>
                        )}
                    </Box>
                )}
            </Dropzone>
        </div>
    );
}

export default FileInput;
