import React, { useEffect, useState } from "react";

function useActiveElement() {
    const [active, setActive] = useState(document.activeElement);

    const handleFocusIn = (e) => {
        setActive(document.activeElement);
    };

    const handleFocusOut = (e) => {
        setActive(null);
    };

    useEffect(() => {
        document.addEventListener("focusin", handleFocusIn);
        document.addEventListener("focusout", handleFocusOut);
        return () => {
            document.removeEventListener("focusin", handleFocusIn);
            document.removeEventListener("focusout", handleFocusOut);
        };
    }, []);

    return active;
}

export default useActiveElement;
