import React, { useContext } from "react";
import { AbilityContext } from "../contexts/AbilityContext";

function useAbility() {
    const { abilities, setAbilities } = useContext(AbilityContext);
    const can = (actions, resource) => {
        if (Array.isArray(actions)) {
            return actions.some(
                (action) =>
                    !!abilities.find(
                        (el) => el.resource === resource && el.action === action
                    )
            );
        } else {
            return !!abilities.find(
                (el) => el.resource === resource && el.action === actions
            );
        }
    };
    return { updateAbilities: setAbilities, can };
}

export default useAbility;
