import React, { useState, useEffect } from "react";
import "./ImagesInput.scss";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import {
    AddCircleOutlineOutlined,
    HighlightOffTwoTone,
    Refresh,
} from "@mui/icons-material";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useIntl } from "react-intl";
import {
    camelToSnakeUpperCase,
    textToBase64,
} from "../../../../../utils/general";
import {
    deleteImageById,
    getSiteImagesById,
    updateImageName,
    updateImageVisibility,
    uploadSiteImages,
} from "../../../../../api/site";
import { toast } from "react-toastify";
import DataValidationError from "../../../../../errors/DataValidationError";
import ImagePreview from "./imagePreview/ImagePreview";
import useAbility from "../../../../../hooks/useAbility";

function ImagesInput({ _id }) {
    const [images, setImages] = useState([]);
    const [loadingImages, setLoadingImages] = useState(true);
    const [selectedImage, setSelectedImage] = useState(-1);
    const [fetchError, setFetchError] = useState(false);
    const { can } = useAbility();
    const intl = useIntl();
    const theme = useTheme();

    useEffect(() => {
        fetchImages();
    }, []);

    const fetchImages = async () => {
        try {
            setImages([]);
            setFetchError(false);
            setLoadingImages(true);
            let images = await getSiteImagesById(_id);
            const token = window.localStorage.getItem("token") || "";
            let newImages = images.map((image) => ({
                ...image,
                fullPath: `${
                    process.env.REACT_APP_BASE_URL
                }/static/sites/${_id}/images/${image.path}?token=${textToBase64(
                    token
                )}`,
            }));
            setImages(newImages);
        } catch (error) {
            setFetchError(true);
            toast.error(intl.formatMessage({ id: error.message }));
        } finally {
            setLoadingImages(false);
        }
    };

    const saveImageName = async (path, name) => {
        try {
            await updateImageName(_id, { path, name });
            setImages(
                images.map((image) =>
                    image.path === path ? { ...image, name } : image
                )
            );
            return true;
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    toast.error(
                        intl.formatMessage(
                            { id: "ERROR:FORMAT" },
                            {
                                field: intl.formatMessage({
                                    id: `SITES:${camelToSnakeUpperCase(
                                        field.path
                                    )}`,
                                }),
                                value: field.value,
                            }
                        )
                    );
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
            return false;
        }
    };

    const saveImageVisibility = async (path, visible) => {
        try {
            await updateImageVisibility(_id, { path, visible });
            setImages(
                images.map((image) =>
                    image.path === path ? { ...image, visible } : image
                )
            );
            return true;
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    toast.error(
                        intl.formatMessage(
                            { id: "ERROR:FORMAT" },
                            {
                                field: intl.formatMessage({
                                    id: `SITES:${camelToSnakeUpperCase(
                                        field.path
                                    )}`,
                                }),
                                value: field.value,
                            }
                        )
                    );
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
            return false;
        }
    };

    const removeImage = async (path) => {
        try {
            await deleteImageById(_id, textToBase64(path));
            setImages(images.filter((iamge) => iamge.path !== path));
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        }
    };

    const handleImagesDrop = async (imgs) => {
        try {
            setLoadingImages(true);
            let newImages = await uploadSiteImages(_id, imgs);
            const token = window.localStorage.getItem("token") || "";
            setImages(
                newImages.map((image) => ({
                    ...image,
                    fullPath: `${
                        process.env.REACT_APP_BASE_URL
                    }/static/sites/${_id}/images/${
                        image.path
                    }?token=${textToBase64(token)}`,
                }))
            );
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    if (field.kind === "unique") {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:UNIQUE" },
                                {
                                    field: intl.formatMessage({
                                        id: `SITES:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    } else {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:FORMAT" },
                                {
                                    field: intl.formatMessage({
                                        id: `SITES:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    }
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        } finally {
            setLoadingImages(false);
        }
    };

    return (
        <Box
            width="100%"
            boxSizing="border-box"
            padding="10px 10px 0 10px"
            className="images-input-container"
        >
            <Dropzone
                onDrop={handleImagesDrop}
                padding="0"
                style={{
                    width: "100%",
                    ...(fetchError
                        ? { borderColor: theme.palette.error.main }
                        : images.length === 0
                        ? {}
                        : {
                              borderColor: theme.palette.primary.main,
                          }),
                }}
                loading={loadingImages}
                accept={IMAGE_MIME_TYPE}
                disabled={
                    fetchError ||
                    !(
                        can("UPLOAD_IMAGES", "ALL_SITES") ||
                        can("UPLOAD_IMAGES", "RELATED_SITES") ||
                        can("UPLOAD_IMAGES", "CREATED_SITES")
                    )
                }
            >
                {(status) =>
                    fetchError ? (
                        <Box
                            className="dropzone-content"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <IconButton
                                color="error"
                                size="large"
                                onClick={fetchImages}
                            >
                                <Refresh fontSize="large" />
                            </IconButton>
                        </Box>
                    ) : images.length === 0 ? (
                        <Box
                            className="dropzone-content"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography color="primary" fontSize="20px">
                                {intl.formatMessage({
                                    id: "GLOBAL:DRAG_ZONE_MESSAGE",
                                })}
                            </Typography>
                        </Box>
                    ) : (
                        <Box className="dropzone-content">
                            <Box
                                display="flex"
                                alignItems="center"
                                gap={1}
                                height="90px"
                                justifyContent="flex-start"
                                flexDirection="row"
                                padding="0 0 0 20px"
                                maxWidth="calc(100% - 100px)"
                                boxSizing="border-box"
                                margin="5px 0"
                                style={{
                                    overflowX: "auto",
                                }}
                            >
                                {(can("VALIDATE_IMAGES", "ALL_SITES") ||
                                can("VALIDATE_IMAGES", "RELATED_SITES") ||
                                can("VALIDATE_IMAGES", "CREATED_SITES")
                                    ? images
                                    : images.filter((image) => image.visible)
                                ).map((image, index) => {
                                    return (
                                        <Box
                                            key={index}
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            title={image.name}
                                            width="60px"
                                            gap={1}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setSelectedImage(index);
                                            }}
                                            sx={{
                                                opacity: image.visible
                                                    ? 1
                                                    : 0.4,
                                            }}
                                        >
                                            <Box
                                                display="flex"
                                                position="relative"
                                                overflow="visible"
                                                width="35px"
                                                minWidth="35px"
                                            >
                                                <img
                                                    width="35px"
                                                    height="35px"
                                                    src={image.fullPath}
                                                />
                                                {(can(
                                                    "RENAME_IMAGES",
                                                    "ALL_SITES"
                                                ) ||
                                                    can(
                                                        "RENAME_IMAGES",
                                                        "RELATED_SITES"
                                                    ) ||
                                                    can(
                                                        "RENAME_IMAGES",
                                                        "CREATED_SITES"
                                                    )) && (
                                                    <IconButton
                                                        style={{
                                                            padding: 0,
                                                            position:
                                                                "absolute",
                                                            color: "red",
                                                            top: "-8px",
                                                            left: "-8px",
                                                        }}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            removeImage(
                                                                image.path
                                                            );
                                                        }}
                                                    >
                                                        <HighlightOffTwoTone fontSize="small" />
                                                    </IconButton>
                                                )}
                                            </Box>
                                            <Typography
                                                component="span"
                                                textOverflow="ellipsis"
                                                width="100%"
                                                maxHeight="13px"
                                                color="primary"
                                                fontSize="13px"
                                                lineHeight="13px"
                                                overflow="hidden"
                                                letterSpacing={0.1}
                                                textAlign="center"
                                            >
                                                {image.name}
                                            </Typography>
                                        </Box>
                                    );
                                })}
                            </Box>
                            {(can("UPLOAD_IMAGES", "ALL_SITES") ||
                                can("UPLOAD_IMAGES", "RELATED_SITES") ||
                                can("UPLOAD_IMAGES", "CREATED_SITES")) && (
                                <IconButton
                                    color="primary"
                                    sx={{
                                        height: "calc(100% - 40px)",
                                        width: "unset",
                                        aspectRatio: "1",
                                        padding: 0,
                                        margin: "20px",
                                        boxSizing: "border-box",
                                    }}
                                >
                                    <AddCircleOutlineOutlined fontSize="large" />
                                </IconButton>
                            )}
                        </Box>
                    )
                }
            </Dropzone>
            {selectedImage >= 0 && selectedImage < images.length && (
                <ImagePreview
                    image={images[selectedImage]}
                    close={() => {
                        setSelectedImage(-1);
                    }}
                    previous={
                        selectedImage > 0
                            ? () => {
                                  setSelectedImage(selectedImage - 1);
                              }
                            : null
                    }
                    next={
                        selectedImage < images.length - 1
                            ? () => {
                                  setSelectedImage(selectedImage + 1);
                              }
                            : null
                    }
                    saveName={saveImageName}
                    saveVisibility={saveImageVisibility}
                />
            )}
        </Box>
    );
}

export default ImagesInput;
