import { useEffect } from "react";
import { createContext, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { getNotifications } from "../api/notification";

export const NotificationsContext = createContext();

export const NotificationsProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const [notificationsError, setNotificationsError] = useState(false);

    const location = useLocation();

    useEffect(() => {
        setupNotifications();
    }, [location]);

    const setupNotifications = useCallback(async () => {
        try {
            setNotificationsError(false);
            let data = await getNotifications();
            setNotifications(data);
        } catch (error) {
            setNotificationsError(true);
            // toast.error(intl.formatMessage({ id: error.message }));
        }
    }, [setNotificationsError, setNotifications]);

    return (
        <NotificationsContext.Provider
            value={{
                notifications,
                notificationsError,
                setupNotifications,
            }}
        >
            {children}
        </NotificationsContext.Provider>
    );
};
