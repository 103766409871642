import DataValidationError from "../errors/DataValidationError";
import { axiosInstance } from "../utils/axios";
import { createWorkflowInstance } from "./workflowInstance";

export const getSites = async () => {
    try {
        let response = await axiosInstance.get("/sites");
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
        return response.data;
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const getSiteById = async (id) => {
    try {
        let response = await axiosInstance.get(`/sites/${id}`);
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
        return response.data;
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const getSiteCommentsById = async (_id) => {
    try {
        let response = await axiosInstance.get(`/sites/${_id}/comments`);
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
        return response.data;
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const getSiteChecklistById = async (_id) => {
    try {
        let response = await axiosInstance.get(`/sites/${_id}/checklist`);
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
        return response.data;
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const getSiteImagesById = async (_id) => {
    try {
        let response = await axiosInstance.get(`/sites/${_id}/images`);
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
        return response.data;
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const getSiteAttachmentsById = async (_id) => {
    try {
        let response = await axiosInstance.get(`/sites/${_id}/attachments`);
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
        return response.data;
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const createSite = async (data) => {
    try {
        let response = await axiosInstance.post("/sites", data);
        if (response.status !== 201) {
            throw new Error("ERROR:OTHER");
        }
        createWorkflowInstance({ site: response.data._id });
        return response.data;
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const updateSite = async (_id, data) => {
    try {
        let response = await axiosInstance.put(`/sites/${_id}`, data);
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const pushComment = async (_id, data) => {
    try {
        let response = await axiosInstance.post(`/sites/${_id}/comments`, data);
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
        return response.data;
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const uploadSiteImages = async (_id, images) => {
    try {
        let formData = new FormData();
        images.forEach((image) => {
            formData.append("images", image);
        });
        let response = await axiosInstance.put(
            `/sites/${_id}/images/upload`,
            formData
        );
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
        return response.data;
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const uploadSiteAttachments = async (_id, attachments) => {
    try {
        let formData = new FormData();
        attachments.forEach((attachment) => {
            formData.append("attachments", attachment);
        });
        let response = await axiosInstance.put(
            `/sites/${_id}/attachments/upload`,
            formData
        );
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
        return response.data;
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const updateChecklist = async (_id, data) => {
    try {
        let response = await axiosInstance.put(`/sites/${_id}/checklist`, data);
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const updateImageName = async (_id, data) => {
    try {
        let response = await axiosInstance.put(
            `/sites/${_id}/images/name`,
            data
        );
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const updateAttachmentName = async (_id, data) => {
    try {
        let response = await axiosInstance.put(
            `/sites/${_id}/attachments/name`,
            data
        );
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const updateImageVisibility = async (_id, data) => {
    try {
        let response = await axiosInstance.put(
            `/sites/${_id}/images/visibility`,
            data
        );
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const updateAttachmentVisibility = async (_id, data) => {
    try {
        let response = await axiosInstance.put(
            `/sites/${_id}/attachments/visibility`,
            data
        );
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const deleteImageById = async (_id, path) => {
    try {
        let response = await axiosInstance.delete(
            `/sites/${_id}/images/${path}`
        );
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const deleteAttachmentById = async (_id, path) => {
    try {
        let response = await axiosInstance.delete(
            `/sites/${_id}/attachments/${path}`
        );
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const deleteComment = async (_id, comment) => {
    try {
        let response = await axiosInstance.delete(
            `/sites/${_id}/comments/${comment}`
        );
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
        return response.data;
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const deleteSite = async (_id) => {
    try {
        let response = await axiosInstance.delete(`/sites/${_id}`);
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const freezeSite = async (_id) => {
    try {
        let response = await axiosInstance.put(`/sites/${_id}/freeze`);
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};
