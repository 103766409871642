import React, { useMemo } from "react";
import "./Sites.scss";
import DataContainer from "../../components/dataContainer/DataContainer";
import { useIntl } from "react-intl";
import SiteCard from "./siteCard/SiteCard";
import DataCards from "../../components/dataCards/DataCards";
import { useState } from "react";
import { useConfirm } from "material-ui-confirm";
import { useEffect } from "react";
import { toast } from "react-toastify";
import DataValidationError from "../../errors/DataValidationError";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import {
    AcUnit,
    Add,
    Delete,
    Edit,
    Info,
    LocationOn,
    PlayArrow,
} from "@mui/icons-material";
import { Box, Card, useMediaQuery, useTheme } from "@mui/material";
import AddModal from "../../components/addModal/AddModal";
import {
    CustomLoadingOverlay,
    CustomNoRowsOverlay,
} from "../../utils/gridOverlays";
import {
    createSite,
    deleteSite,
    freezeSite,
    getSites,
    updateSite,
} from "../../api/site";
import { getClients } from "../../api/client";
import { getDebits } from "../../api/params";
import { camelToSnakeUpperCase, textToBase64 } from "../../utils/general";
import { getCoordinatorsByClient } from "../../api/coordinator";
import { getOfficers } from "../../api/officerAccount";
import { getWorkflows } from "../../api/workflow";
import { getKmlFiles } from "../../api/kml";
import SiteDetails from "./siteCard/siteDetails/SiteDetails";
import KmlPreview from "../../components/kmlPreview/KmlPreview";
import { useSearchParams } from "react-router-dom";
import { createWorkflowInstance } from "../../api/workflowInstance";
import useAbility from "../../hooks/useAbility";
import { useCallback } from "react";

const Sites = () => {
    //#region States
    const { can } = useAbility();
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [clients, setClients] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [debits, setDebits] = useState([]);
    const [coordinators, setCoordinators] = useState([]);
    const [kmlFiles, setKmlFiles] = useState([]);
    const [workflows, setWorkflows] = useState([]);
    const [dataRows, setDataRows] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [modalLoading, setModalLoading] = useState(false);
    const [cardsView, setCardsView] = useState(true);
    const [selectedSiteId, setSelectedSiteId] = useState(null);
    const selectedSite = dataRows.find((row) => row.id === selectedSiteId);
    //#endregion

    //#region other Hooks
    const intl = useIntl();
    const confirm = useConfirm();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [searchParams, setSearchParams] = useSearchParams();
    const currentSiteId = searchParams.get("siteId");
    const currentSite = currentSiteId
        ? dataRows.find((row) => row.id === currentSiteId)
        : null;
    const currentTarget = searchParams.get("target");
    const token = textToBase64(window.localStorage.getItem("token") || "");
    //#endregion

    //#region useEffect
    useEffect(() => {
        setupUsers();
        setupClients();
        setupDebits();
        setupWorkflows();
        setupKmlFiles();
        getData();
    }, []);

    useEffect(() => {
        if (selectedClient) {
            setupCoordinators(selectedClient);
        } else {
            setCoordinators([]);
        }
    }, [selectedClient]);

    useEffect(() => {
        if (isMobile) {
            setCardsView(true);
        }
    }, [isMobile]);
    //#endregion

    //#region Handlers
    const handleCloseAdd = () => {
        setIsModalOpen(false);
    };
    const handleOpenAdd = () => {
        setIsModalOpen(true);
    };

    const handleCloseUpdate = () => {
        setSelectedSiteId(null);
        setCoordinators([]);
        setSelectedClient(null);
    };
    const handleOpenUpdate = async (site) => {
        setSelectedSiteId(site);
    };

    const getData = async () => {
        setLoading(true);
        try {
            let data = await getSites();
            setDataRows(
                data.map((row) => ({
                    ...row,
                    id: row._id,
                    splittedAddress: row.address,
                    latitude: row.address.latitude,
                    longitude: row.address.longitude,
                    address: `${row.address.number ?? ""} ${
                        row.address.street
                    }, ${row.address.postalCode ?? ""} ${row.address.city}, ${
                        row.address.country
                    }`
                        .trim()
                        .replace(/\s\s+/g, " ")
                        .toUpperCase(),
                }))
            );
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        }
        setLoading(false);
    };

    const setupClients = async () => {
        try {
            let clients = await getClients();
            setClients(clients);
        } catch (error) {}
    };

    const setupUsers = async () => {
        try {
            let users = await getOfficers();
            setUsers(users);
        } catch (error) {}
    };

    const setupDebits = async () => {
        try {
            let debits = await getDebits();
            setDebits(debits);
        } catch (error) {}
    };

    const setupKmlFiles = async () => {
        try {
            let kmlFiles = await getKmlFiles();
            setKmlFiles(kmlFiles);
        } catch (error) {}
    };

    const setupCoordinators = async (client) => {
        if (client)
            try {
                let coordinators = await getCoordinatorsByClient(client);
                setCoordinators(coordinators);
            } catch (error) {}
    };

    const setupWorkflows = async () => {
        try {
            let workflows = await getWorkflows({ published: true });
            setWorkflows(workflows);
        } catch (error) {}
    };

    const addSite = async (data) => {
        try {
            setModalLoading(true);
            await createSite(data);
            toast.success(intl.formatMessage({ id: "SITES:SITE_ADDED" }));
            setIsModalOpen(false);
            await getData();
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    if (field.kind === "unique") {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:UNIQUE" },
                                {
                                    field: intl.formatMessage({
                                        id: `SITES:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    } else {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:FORMAT" },
                                {
                                    field: intl.formatMessage({
                                        id: `SITES:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    }
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        } finally {
            setModalLoading(false);
        }
    };

    const update = async (data) => {
        try {
            setModalLoading(true);
            await updateSite(selectedSiteId, data);
            toast.success(intl.formatMessage({ id: "SITES:SITE_UPDATED" }));
            setSelectedSiteId(null);
            await getData();
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    if (field.kind === "unique") {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:UNIQUE" },
                                {
                                    field: intl.formatMessage({
                                        id: `SITES:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    } else {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:FORMAT" },
                                {
                                    field: intl.formatMessage({
                                        id: `SITES:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    }
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        } finally {
            setModalLoading(false);
        }
    };

    const deleteSelectedSite = async (params) => {
        try {
            await confirm({
                title: intl.formatMessage({ id: "WARNING" }),
                description: intl.formatMessage(
                    { id: "SITES:SITE_DELETE_CONFIRM" },
                    { name: params.row.ref }
                ),
                confirmationText: intl.formatMessage({ id: "GLOBAL:CONFIRM" }),
                cancellationText: intl.formatMessage({ id: "GLOBAL:CANCEL" }),
            });
        } catch (error) {
            return;
        }
        setLoading(true);
        try {
            await deleteSite(params.row.id);
            toast.success(intl.formatMessage({ id: "SITES:SITE_DELETED" }));
            await getData();
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        } finally {
            setLoading(false);
        }
    };

    const freezeSelectedSite = async (params) => {
        try {
            await confirm({
                title: intl.formatMessage({ id: "WARNING" }),
                description: intl.formatMessage(
                    { id: "SITES:SITE_FREEZE_CONFIRM" },
                    { name: params.row.ref }
                ),
                confirmationText: intl.formatMessage({ id: "GLOBAL:CONFIRM" }),
                cancellationText: intl.formatMessage({ id: "GLOBAL:CANCEL" }),
            });
        } catch (error) {
            return;
        }
        setLoading(true);
        try {
            await freezeSite(params.row.id);
            toast.success(intl.formatMessage({ id: "SITES:SITE_FROZEN" }));
            await getData();
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        } finally {
            setLoading(false);
        }
    };

    const runWorkflow = async (data) => {
        try {
            await createWorkflowInstance(data);
            toast.success(intl.formatMessage({ id: "SITES:WORKFLOW_RUN" }));
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    if (field.kind === "unique") {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:UNIQUE" },
                                {
                                    field: intl.formatMessage({
                                        id: `SITES:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    } else {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:FORMAT" },
                                {
                                    field: intl.formatMessage({
                                        id: `SITES:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    }
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        } finally {
            setModalLoading(false);
        }
    };
    //#endregion

    //#region constants
    const sortList = useMemo(
        () => [
            {
                name: "state",
                label: intl.formatMessage({ id: "SITES:STATE" }),
            },
            {
                name: "ref",
                label: intl.formatMessage({ id: "SITES:REF" }),
            },
            {
                name: "client",
                label: intl.formatMessage({ id: "SITES:CLIENT" }),
            },
            {
                name: "address",
                label: intl.formatMessage({ id: "SITES:ADDRESS" }),
            },
            {
                name: "createdAt",
                label: intl.formatMessage({ id: "SITES:CREATED_AT" }),
            },
        ],
        [intl]
    );

    const filters = useMemo(
        () => [
            {
                type: "text",
                label: intl.formatMessage({ id: "SITES:REF" }),
                name: "ref",
                verify: (value, filterValue) =>
                    value.toLowerCase().includes(filterValue.toLowerCase()),
            },
            {
                type: "select",
                options: [
                    {
                        value: "NOT_STARTED",
                        text: intl.formatMessage({ id: "SITES:NOT_STARTED" }),
                    },
                    {
                        value: "RUNNING",
                        text: intl.formatMessage({ id: "SITES:RUNNING" }),
                    },
                    {
                        value: "COMPLETED",
                        text: intl.formatMessage({ id: "SITES:COMPLETED" }),
                    },
                    {
                        value: "CANCELLED",
                        text: intl.formatMessage({ id: "SITES:CANCELLED" }),
                    },
                ],
                label: intl.formatMessage({ id: "SITES:STATE" }),
                name: "workflowInstance.state",
                verify: (value, filterValue) => {
                    return (value || "NOT_STARTED")
                        ?.toLowerCase()
                        .includes(filterValue.toLowerCase());
                },
            },
            {
                type: "text",
                label: intl.formatMessage({ id: "SITES:CLIENT" }),
                name: "client",
                verify: (value, filterValue) =>
                    value.name
                        .toLowerCase()
                        .includes(filterValue.toLowerCase()),
            },
            {
                type: "text",
                label: intl.formatMessage({ id: "SITES:ADDRESS" }),
                name: "address",
                verify: (value, filterValue) =>
                    value.toLowerCase().includes(filterValue.toLowerCase()),
            },
            {
                type: "text",
                label: intl.formatMessage({ id: "SITES:CREATED_AT" }),
                name: "createdAt",
                verify: (value, filterValue) =>
                    new Date(value)
                        .toLocaleString("en-GB")
                        .includes(filterValue.toLowerCase()),
            },
            {
                type: "text",
                label: intl.formatMessage({ id: "SITES:CREATED_BY" }),
                name: "createdBy",
                verify: (value, filterValue) =>
                    value.username
                        .toLowerCase()
                        .includes(filterValue.toLowerCase()),
            },
        ],
        [intl]
    );

    const getActions = useCallback(
        (data) => [
            {
                items: [
                    ...(can("RUN_WORKFLOW", "ALL_SITES") ||
                    can("RUN_WORKFLOW", "RELATED_SITES") ||
                    can("RUN_WORKFLOW", "CREATED_SITES")
                        ? [
                              {
                                  icon: PlayArrow,
                                  text: intl.formatMessage({
                                      id: "ACTIONS:RUN_WORKFLOW",
                                  }),
                                  onClick: (data) => {
                                      runWorkflow({ site: data.id });
                                  },
                              },
                          ]
                        : []),
                    ...(can("UPDATE", "ALL_SITES") ||
                    can("UPDATE", "RELATED_SITES") ||
                    can("UPDATE", "CREATED_SITES")
                        ? [
                              {
                                  icon: Edit,
                                  text: intl.formatMessage({
                                      id: "ACTIONS:EDIT",
                                  }),
                                  onClick: (data) => {
                                      handleOpenUpdate(data.id);
                                  },
                              },
                          ]
                        : []),
                    ...(can("DELETE", "ALL_SITES") ||
                    can("DELETE", "RELATED_SITES") ||
                    can("DELETE", "CREATED_SITES")
                        ? [
                              {
                                  icon: Delete,
                                  text: intl.formatMessage({
                                      id: "ACTIONS:DELETE",
                                  }),
                                  onClick: (data) => {
                                      deleteSelectedSite({ row: data });
                                  },
                              },
                          ]
                        : []),
                    ...((can("FREEZE", "ALL_SITES") ||
                        can("FREEZE", "RELATED_SITES") ||
                        can("FREEZE", "CREATED_SITES") ||
                        true) &&
                    !data.frozen
                        ? [
                              {
                                  icon: AcUnit,
                                  text: intl.formatMessage({
                                      id: "ACTIONS:FREEZE",
                                  }),
                                  onClick: (data) => {
                                      freezeSelectedSite({ row: data });
                                  },
                              },
                          ]
                        : []),
                ],
            },
        ],
        [intl]
    );

    const dataColumns = [
        {
            field: "actions",
            type: "actions",
            minWidth: 50,
            maxWidth: 50,
            getActions: (params) => [
                ...(can("RUN_WORKFLOW", "ALL_SITES") ||
                can("RUN_WORKFLOW", "RELATED_SITES") ||
                can("RUN_WORKFLOW", "CREATED_SITES")
                    ? [
                          <GridActionsCellItem
                              icon={<PlayArrow />}
                              onClick={() => {
                                  runWorkflow({ site: params.row.id });
                              }}
                              label={intl.formatMessage({
                                  id: "ACTIONS:RUN_WORKFLOW",
                              })}
                              showInMenu
                          />,
                      ]
                    : []),
                <GridActionsCellItem
                    icon={<Info />}
                    onClick={() => {
                        setSearchParams({
                            siteId: params.row._id,
                            target: "details",
                        });
                    }}
                    label={intl.formatMessage({ id: "ACTIONS:DETAILS" })}
                    showInMenu
                />,
                ...(can("SEE_IN_MAP", "ALL_SITES") ||
                can("SEE_IN_MAP", "RELATED_SITES") ||
                can("SEE_IN_MAP", "CREATED_SITES")
                    ? [
                          <GridActionsCellItem
                              icon={<LocationOn />}
                              onClick={() => {
                                  setSearchParams({
                                      siteId: params.row._id,
                                      target: "map",
                                  });
                              }}
                              label={intl.formatMessage({
                                  id: "SITES:SEE_IN_MAPS",
                              })}
                              showInMenu
                          />,
                      ]
                    : []),
                ...(can("UPDATE", "ALL_SITES") ||
                can("UPDATE", "RELATED_SITES") ||
                can("UPDATE", "CREATED_SITES")
                    ? [
                          <GridActionsCellItem
                              icon={<Edit />}
                              onClick={() => {
                                  handleOpenUpdate(params.row.id);
                              }}
                              label={intl.formatMessage({ id: "ACTIONS:EDIT" })}
                              showInMenu
                          />,
                      ]
                    : []),
                ...(can("DELETE", "ALL_SITES") ||
                can("DELETE", "RELATED_SITES") ||
                can("DELETE", "CREATED_SITES")
                    ? [
                          <GridActionsCellItem
                              icon={<Delete />}
                              onClick={() => deleteSelectedSite(params)}
                              label={intl.formatMessage({
                                  id: "ACTIONS:DELETE",
                              })}
                              showInMenu
                          />,
                      ]
                    : []),
                ...((can("FREEZE", "ALL_SITES") ||
                    can("FREEZE", "RELATED_SITES") ||
                    can("FREEZE", "CREATED_SITES") ||
                    true) &&
                !params.row.frozen
                    ? [
                          <GridActionsCellItem
                              icon={<AcUnit />}
                              onClick={() => freezeSelectedSite(params)}
                              label={intl.formatMessage({
                                  id: "ACTIONS:FREEZE",
                              })}
                              showInMenu
                          />,
                      ]
                    : []),
            ],
        },
        {
            field: "ref",
            headerName: intl.formatMessage({ id: "SITES:REF" }),
            flex: 1,
            minWidth: 140,
        },
        {
            field: "name",
            headerName: intl.formatMessage({ id: "SITES:NAME" }),
            flex: 1,
            minWidth: 160,
        },
        {
            field: "rdv",
            headerName: intl.formatMessage({ id: "SITES:RDV" }),
            flex: 1,
            minWidth: 180,
            valueFormatter: ({ value }) =>
                new Date(value).toLocaleString("en-GB"),
        },
        {
            field: "client",
            headerName: intl.formatMessage({ id: "SITES:CLIENT" }),
            flex: 1,
            minWidth: 160,
            valueFormatter: ({ value }) => value.name,
        },
        {
            field: "contractDate",
            headerName: intl.formatMessage({ id: "SITES:CONTRACT_DATE" }),
            flex: 1,
            minWidth: 150,
            valueFormatter: ({ value }) =>
                new Date(value).toLocaleDateString("en-GB"),
        },
        {
            field: "orderDate",
            headerName: intl.formatMessage({ id: "SITES:ORDER_DATE" }),
            flex: 1,
            minWidth: 150,
            valueFormatter: ({ value }) =>
                new Date(value).toLocaleDateString("en-GB"),
        },
        {
            field: "address",
            headerName: intl.formatMessage({ id: "SITES:ADDRESS" }),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "contactName",
            headerName: intl.formatMessage({ id: "SITES:CONTACT_NAME" }),
            flex: 1,
            minWidth: 160,
        },
        {
            field: "contactPhone",
            headerName: intl.formatMessage({ id: "SITES:CONTACT_PHONE" }),
            flex: 1,
            minWidth: 160,
        },
        {
            field: "contactEmail",
            headerName: intl.formatMessage({ id: "SITES:CONTACT_EMAIL" }),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "bpe",
            headerName: intl.formatMessage({ id: "SITES:BPE" }),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "room",
            headerName: intl.formatMessage({ id: "SITES:ROOM" }),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "rop",
            headerName: intl.formatMessage({ id: "SITES:ROP" }),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "cls",
            headerName: intl.formatMessage({ id: "SITES:CLS" }),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "fci",
            headerName: intl.formatMessage({ id: "SITES:FCI" }),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "clientCoordinator",
            headerName: intl.formatMessage({
                id: "SITES:CLIENT_COORDINATOR",
            }),
            flex: 1,
            minWidth: 160,
            valueFormatter: ({ value }) => value.account.username,
        },
        {
            field: "internalCoordinators",
            headerName: intl.formatMessage({
                id: "SITES:INTERNAL_COORDINATORS",
            }),
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <Box width="100%" sx={{ overflowX: "auto" }}>
                    {params.value.map((user) => user.username).join(" - ")}
                </Box>
            ),
        },
        {
            field: "technicians",
            headerName: intl.formatMessage({ id: "SITES:TECHNICIANS" }),
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <Box width="100%" sx={{ overflowX: "auto" }}>
                    {params.value.map((user) => user.username).join(" - ")}
                </Box>
            ),
        },
        {
            field: "measurers",
            headerName: intl.formatMessage({ id: "SITES:MEASURERS" }),
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <Box width="100%" sx={{ overflowX: "auto" }}>
                    {params.value.map((user) => user.username).join(" - ")}
                </Box>
            ),
        },
        {
            field: "managers",
            headerName: intl.formatMessage({ id: "SITES:MANAGERS" }),
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <Box width="100%" sx={{ overflowX: "auto" }}>
                    {params.value.map((user) => user.username).join(" - ")}
                </Box>
            ),
        },
    ];

    const headerButtons = [
        ...(can("CREATE", "ALL_SITES")
            ? [
                  {
                      name: intl.formatMessage({ id: "SITES:CREATE" }),
                      props: {
                          onClick: handleOpenAdd,
                          variant: "contained",
                          startIcon: <Add />,
                      },
                  },
              ]
            : []),
    ];
    const modalFields = [
        {
            name: "ref",
            group: intl.formatMessage({
                id: "SITES:GROUPS:GENERAL_INFORMATIONS",
            }),
            label: intl.formatMessage({ id: "SITES:REF" }),
            type: "text",
            rules: {
                required: intl.formatMessage({
                    id: "FORM:ERROR_MESSAGES:REQUIRED",
                }),
            },
            fullWidth: isMobile,
        },
        {
            name: "name",
            label: intl.formatMessage({ id: "SITES:NAME" }),
            type: "text",
            rules: {
                required: intl.formatMessage({
                    id: "FORM:ERROR_MESSAGES:REQUIRED",
                }),
            },
            fullWidth: isMobile,
        },
        {
            name: "client",
            label: intl.formatMessage({ id: "SITES:CLIENT" }),
            type: "select",
            rules: {
                required: intl.formatMessage({
                    id: "FORM:ERROR_MESSAGES:REQUIRED",
                }),
            },
            options: clients.map((client) => ({
                id: client._id,
                value: client._id,
                label: client.name,
            })),
            fullWidth: isMobile,
            onChange: (getValues, setValue) => {
                let { value: client } = getValues("client") ?? {};
                let { value: clientCoordinator } =
                    getValues("clientCoordinator") ?? {};
                if (
                    !client ||
                    !clientCoordinator ||
                    client !== selectedSite.clientCoordinator.client
                ) {
                    setValue("clientCoordinator", null);
                }
                setSelectedClient(client);
            },
        },
        {
            name: "clientCoordinator",
            label: intl.formatMessage({ id: "SITES:CLIENT_COORDINATOR" }),
            type: "select",
            rules: {
                required: intl.formatMessage({
                    id: "FORM:ERROR_MESSAGES:REQUIRED",
                }),
            },
            options: coordinators.map((coordinator) => ({
                id: coordinator._id,
                value: coordinator._id,
                label: coordinator.account.username,
            })),
            fullWidth: isMobile,
        },
        {
            name: "rdv",
            label: intl.formatMessage({ id: "SITES:RDV" }),
            type: "datetime-local",
            rules: {},
            fullWidth: isMobile,
            other: {
                InputLabelProps: { shrink: true },
            },
        },
        {
            name: "contractDate",
            label: intl.formatMessage({ id: "SITES:CONTRACT_DATE" }),
            type: "date",
            rules: {
                required: intl.formatMessage({
                    id: "FORM:ERROR_MESSAGES:REQUIRED",
                }),
            },
            fullWidth: isMobile,
            other: {
                InputLabelProps: { shrink: true },
            },
        },
        {
            name: "orderDate",
            label: intl.formatMessage({ id: "SITES:ORDER_DATE" }),
            type: "date",
            rules: {
                required: intl.formatMessage({
                    id: "FORM:ERROR_MESSAGES:REQUIRED",
                }),
            },
            fullWidth: true,
            other: {
                InputLabelProps: { shrink: true },
            },
        },
        {
            name: "debit",
            label: intl.formatMessage({ id: "SITES:DEBIT" }),
            type: "select",
            rules: {
                required: intl.formatMessage({
                    id: "FORM:ERROR_MESSAGES:REQUIRED",
                }),
            },
            options: debits.map((debit) => ({
                id: debit,
                value: debit,
                label: debit,
            })),
            fullWidth: true,
        },
        {
            name: "address",
            group: intl.formatMessage({ id: "SITES:GROUPS:ADDRESS" }),
            label: intl.formatMessage({ id: "SITES:ADDRESS" }),
            type: "location",
            fullWidth: true,
            selectButtonText: intl.formatMessage({
                id: "SITES:SELECT_LOCATION",
            }),
            defaultPickLocation: {
                lat: "48.8566",
                lng: "2.3522",
            },
            labels: {
                lat: intl.formatMessage({ id: "SITES:LATITUDE" }),
                lng: intl.formatMessage({ id: "SITES:LONGITUDE" }),
                number: intl.formatMessage({ id: "SITES:NUMBER" }),
                street: intl.formatMessage({ id: "SITES:STREET" }),
                city: intl.formatMessage({ id: "SITES:CITY" }),
                postalCode: intl.formatMessage({
                    id: "SITES:POSTAL_CODE",
                }),
                country: intl.formatMessage({ id: "SITES:COUNTRY" }),
                furtherInformation: intl.formatMessage({
                    id: "SITES:FURTHER_INFORMATION",
                }),
            },
            names: {
                lat: "address.latitude",
                lng: "address.longitude",
                number: "address.number",
                street: "address.street",
                city: "address.city",
                postalCode: "address.postalCode",
                country: "address.country",
                furtherInformation: "address.furtherInformation",
            },
            defaultValue: { "address.country": "France" },
            rules: {
                lat: {
                    required: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:REQUIRED",
                    }),
                    pattern: {
                        value: /^(\+|-)?(?:90(?:(?:\.0{1,})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,})?))$/,
                        message: intl.formatMessage({
                            id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                        }),
                    },
                },
                lng: {
                    required: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:REQUIRED",
                    }),
                    pattern: {
                        value: /^(\+|-)?(?:180(?:(?:\.0{1,})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,})?))$/,
                        message: intl.formatMessage({
                            id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                        }),
                    },
                },
                number: {
                    pattern: {
                        value: /^\d{1,}$/,
                        message: intl.formatMessage({
                            id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                        }),
                    },
                },
                street: {
                    required: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:REQUIRED",
                    }),
                },
                city: {
                    required: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:REQUIRED",
                    }),
                },
                postalCode: {
                    pattern: {
                        value: /^\d{1,}$/,
                        message: intl.formatMessage({
                            id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                        }),
                    },
                },
                country: {
                    required: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:REQUIRED",
                    }),
                },
                furtherInformation: {},
            },
        },
        {
            name: "contactName",
            group: intl.formatMessage({ id: "SITES:GROUPS:CONTACT" }),
            label: intl.formatMessage({ id: "SITES:CONTACT_NAME" }),
            type: "text",
            rules: {},
            fullWidth: true,
        },
        {
            name: "contactPhone",
            label: intl.formatMessage({ id: "SITES:CONTACT_PHONE" }),
            type: "tel",
            rules: {
                pattern: {
                    value: /^([+])?\d+$/,
                    message: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                    }),
                },
            },
        },
        {
            name: "contactEmail",
            label: intl.formatMessage({ id: "SITES:CONTACT_EMAIL" }),
            type: "email",
            rules: {
                pattern: {
                    value: /^[a-zA-Z0-9.! #$%&'*+/=? ^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$/,
                    message: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                    }),
                },
            },
        },
        {
            name: "internalCoordinators",
            group: intl.formatMessage({ id: "SITES:GROUPS:USERS" }),
            label: intl.formatMessage({
                id: "SITES:INTERNAL_COORDINATORS",
            }),
            type: "autocomplete",
            rules: {},
            options: users.map((user) => ({
                id: user._id,
                value: user._id,
                label: user.username,
            })),
        },
        {
            name: "technicians",
            label: intl.formatMessage({ id: "SITES:TECHNICIANS" }),
            type: "autocomplete",
            rules: {},
            options: users.map((user) => ({
                id: user._id,
                value: user._id,
                label: user.username,
            })),
        },
        {
            name: "measurers",
            label: intl.formatMessage({ id: "SITES:MEASURERS" }),
            type: "autocomplete",
            rules: {},
            options: users.map((user) => ({
                id: user._id,
                value: user._id,
                label: user.username,
            })),
        },
        {
            name: "managers",
            label: intl.formatMessage({ id: "SITES:MANAGERS" }),
            type: "autocomplete",
            rules: {},
            options: users.map((user) => ({
                id: user._id,
                value: user._id,
                label: user.username,
            })),
        },
        {
            name: "bpe",
            group: intl.formatMessage({ id: "SITES:GROUPS:OTHER" }),
            label: intl.formatMessage({ id: "SITES:BPE" }),
            type: "text",
            rules: {},
        },
        {
            name: "room",
            label: intl.formatMessage({ id: "SITES:ROOM" }),
            type: "text",
            rules: {},
        },
        {
            name: "rop",
            label: intl.formatMessage({ id: "SITES:ROP" }),
            type: "text",
            rules: {},
        },
        {
            name: "cls",
            label: intl.formatMessage({ id: "SITES:CLS" }),
            type: "text",
            rules: {},
        },
        {
            name: "fci",
            label: intl.formatMessage({ id: "SITES:FCI" }),
            type: "text",
            rules: {},
            fullWidth: true,
        },
        {
            name: "kmlFiles",
            label: intl.formatMessage({ id: "SITES:KML_FILES" }),
            type: "autocomplete",
            rules: {},
            options: kmlFiles.map((kmlFile) => ({
                id: kmlFile._id,
                value: kmlFile._id,
                label: kmlFile.name,
            })),
        },
        {
            name: "workflow",
            label: intl.formatMessage({ id: "SITES:WORKFLOW" }),
            type: "select",
            rules: {
                required: intl.formatMessage({
                    id: "FORM:ERROR_MESSAGES:REQUIRED",
                }),
            },
            options: workflows.map((workflow) => ({
                id: workflow._id,
                value: workflow._id,
                label: workflow.name,
            })),
        },
    ];

    var updateFields = selectedSite
        ? {
              ref: selectedSite.ref,
              name: selectedSite.name,
              client: {
                  id: selectedSite.client._id,
                  value: selectedSite.client._id,
                  label: selectedSite.client.name,
              },
              clientCoordinator: {
                  id: selectedSite.clientCoordinator._id,
                  value: selectedSite.clientCoordinator._id,
                  label: selectedSite.clientCoordinator.account.username,
              },
              rdv: selectedSite.rdv.split(".")[0].split("T").join(" "),
              contractDate: selectedSite.contractDate.split("T")[0],
              orderDate: selectedSite.orderDate.split("T")[0],
              debit: {
                  id: selectedSite.debit,
                  value: selectedSite.debit,
                  label: selectedSite.debit,
              },
              address: Object.keys(selectedSite.splittedAddress).reduce(
                  (previous, current) => ({
                      ...previous,
                      [`address.${current}`]:
                          selectedSite.splittedAddress[current],
                  }),
                  {}
              ),
              contactName: selectedSite.contactName,
              contactPhone: selectedSite.contactPhone,
              contactEmail: selectedSite.contactEmail,
              bpe: selectedSite.bpe,
              room: selectedSite.room,
              rop: selectedSite.rop,
              cls: selectedSite.cls,
              fci: selectedSite.fci,
              internalCoordinators: selectedSite.internalCoordinators.map(
                  (el) => ({
                      id: el._id,
                      value: el._id,
                      label: el.username,
                  })
              ),
              technicians: selectedSite.technicians.map((el) => ({
                  id: el._id,
                  value: el._id,
                  label: el.username,
              })),
              measurers: selectedSite.measurers.map((el) => ({
                  id: el._id,
                  value: el._id,
                  label: el.username,
              })),
              managers: selectedSite.managers.map((el) => ({
                  id: el._id,
                  value: el._id,
                  label: el.username,
              })),
              kmlFiles: selectedSite.kmlFiles.map((el) => ({
                  id: el._id,
                  value: el._id,
                  label: el.name,
              })),
          }
        : null;
    //#endregion

    return (
        <DataContainer
            buttons={headerButtons}
            onRefresh={() => {
                getData();
            }}
            cardsView={cardsView}
            setCardsView={setCardsView}
        >
            {isModalOpen && (
                <AddModal
                    open={isModalOpen}
                    handleClose={handleCloseAdd}
                    fields={modalFields}
                    title={intl.formatMessage({ id: "SITES:CREATE" })}
                    submit={addSite}
                    buttons={[
                        {
                            props: {
                                type: "submit",
                                variant: "contained",
                                color: "secondary",
                            },
                            label: intl.formatMessage({ id: "GLOBAL:SUBMIT" }),
                        },
                    ]}
                    buttonsBoxProps={[]}
                    loading={modalLoading}
                />
            )}
            {updateFields && clients && coordinators && (
                <AddModal
                    open={!!updateFields}
                    handleClose={handleCloseUpdate}
                    fields={modalFields
                        .filter((field) =>
                            Object.keys(updateFields).includes(field.name)
                        )
                        .map((field) => ({
                            ...field,
                            defaultValue: updateFields[field.name],
                        }))}
                    title={intl.formatMessage({ id: "SITES:UPDATE" })}
                    submit={update}
                    buttons={[
                        {
                            props: {
                                type: "submit",
                                variant: "contained",
                                color: "secondary",
                            },
                            label: intl.formatMessage({ id: "GLOBAL:SUBMIT" }),
                        },
                    ]}
                    buttonsBoxProps={[]}
                    loading={modalLoading}
                />
            )}
            {can("CONSULT", "ALL_SITES") ||
            can("CONSULT", "RELATED_SITES") ||
            can("CONSULT", "CREATED_SITES") ? (
                cardsView ? (
                    <DataCards
                        loading={loading}
                        data={dataRows}
                        DataCard={SiteCard}
                        filters={filters}
                        sortList={sortList}
                        getActions={getActions}
                    />
                ) : (
                    <Card sx={{ height: "100%" }}>
                        <DataGrid
                            loading={loading}
                            columns={dataColumns}
                            rows={dataRows}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) =>
                                setPageSize(newPageSize)
                            }
                            rowsPerPageOptions={[10, 20, 30, 50, 100]}
                            components={{
                                Toolbar: GridToolbar,
                                LoadingOverlay: CustomLoadingOverlay,
                                NoRowsOverlay: () =>
                                    CustomNoRowsOverlay(
                                        intl.formatMessage({
                                            id: "SITES:NO_SITES",
                                        })
                                    ),
                                NoResultsOverlay: () =>
                                    CustomNoRowsOverlay(
                                        intl.formatMessage({
                                            id: "SITES:NO_RESULTS",
                                        })
                                    ),
                            }}
                            disableSelectionOnClick
                            onCellDoubleClick={() => {}}
                        />
                    </Card>
                )
            ) : (
                <>THIS IS A PLACEHOLDER</>
            )}
            {!!currentSite &&
                currentTarget === "details" &&
                (can("CONSULT", "ALL_SITES") ||
                    can("CONSULT", "RELATED_SITES") ||
                    can("CONSULT", "CREATED_SITES")) && (
                    <SiteDetails
                        open={!!currentSite && currentTarget === "details"}
                        handleClose={() => {
                            setSearchParams({});
                        }}
                        data={currentSite}
                    />
                )}
            {!!currentSite &&
                currentTarget === "map" &&
                (can("SEE_IN_MAP", "ALL_SITES") ||
                    can("SEE_IN_MAP", "RELATED_SITES") ||
                    can("SEE_IN_MAP", "CREATED_SITES")) && (
                    <KmlPreview
                        open={!!currentSite && currentTarget === "map"}
                        handleClose={() => {
                            setSearchParams({});
                        }}
                        paths={currentSite.kmlFiles.map(
                            (kmlFile) =>
                                `${process.env.REACT_APP_BASE_URL}/static/kml/${kmlFile.path}?token=${token}`
                        )}
                        lat={currentSite.latitude}
                        lng={currentSite.longitude}
                        showMarker={true}
                    />
                )}
        </DataContainer>
    );
};

export default Sites;
