import { axiosInstance } from "../utils/axios";

export const getRoles = async () => {
	try {
		let response = await axiosInstance.get("/roles");
		if (response.status !== 200) {
			throw new Error("ERROR:OTHER");
		}
		return response.data;
	} catch (error) {
		if (!error.response && error.request) {
			throw new Error("ERROR:CONNECTION");
		} else if (error.response.status === 401) {
			throw new Error("ERROR:UNAUTHORIZED");
		} else if (error.response.status === 403) {
			throw new Error("ERROR:FORBIDDEN");
		} else if (error.response.status === 500) {
			throw new Error("ERROR:SERVER");
		} else {
			throw new Error("ERROR:OTHER");
		}
	}
};

export const createRole = async (role) => {
	try {
		let response = await axiosInstance.post("/roles", role);
		if (response.status !== 201) {
			throw new Error("ERROR:OTHER");
		}
		return response.data;
	} catch (error) {
		if (!error.response && error.request) {
			throw new Error("ERROR:CONNECTION");
		} else if (error.response.status === 401) {
			throw new Error("ERROR:UNAUTHORIZED");
		} else if (error.response.status === 403) {
			throw new Error("ERROR:FORBIDDEN");
		} else if (error.response.status === 500) {
			throw new Error("ERROR:SERVER");
		} else {
			throw new Error("ERROR:OTHER");
		}
	}
};

export const updateRole = async ({ _id, name, permissions }) => {
	try {
		let response = await axiosInstance.put(`/roles/${_id}`, {
			name,
			permissions,
		});
		if (response.status !== 200) {
			throw new Error("ERROR:OTHER");
		}
	} catch (error) {
		if (!error.response && error.request) {
			throw new Error("ERROR:CONNECTION");
		} else if (error.response.status === 401) {
			throw new Error("ERROR:UNAUTHORIZED");
		} else if (error.response.status === 403) {
			throw new Error("ERROR:FORBIDDEN");
		} else if (error.response.status === 404) {
			throw new Error("ERROR:NOTFOUND");
		} else if (error.response.status === 500) {
			throw new Error("ERROR:SERVER");
		} else {
			throw new Error("ERROR:OTHER");
		}
	}
};

export const deleteRole = async (_id) => {
	try {
		let response = await axiosInstance.delete(`/roles/${_id}`);
		if (response.status !== 200) {
			throw new Error("ERROR:OTHER");
		}
	} catch (error) {
		if (!error.response && error.request) {
			throw new Error("ERROR:CONNECTION");
		} else if (error.response.status === 401) {
			throw new Error("ERROR:UNAUTHORIZED");
		} else if (error.response.status === 403) {
			throw new Error("ERROR:UNAUTHORIZED");
		} else if (error.response.status === 404) {
			throw new Error("ERROR:NOTFOUND");
		} else if (error.response.status === 500) {
			throw new Error("ERROR:SERVER");
		} else {
			throw new Error("ERROR:OTHER");
		}
	}
};
