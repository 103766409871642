import React from "react";
import { Handle } from "reactflow";
import "./EditSiteNode.scss";
import { Edit } from "@mui/icons-material";
import useVerifyConnection from "../../../../../hooks/useVerifyConnection";

function EditSiteNode({ selected }) {
    const verifyConnection = useVerifyConnection();

    return (
        <>
            <Handle
                type="source"
                position="right"
                isValidConnection={verifyConnection}
            />
            <div
                className={`edit-site-node-container${
                    selected ? " selected" : ""
                }`}
            >
                <div className="edit-site-content">
                    <Edit fontSize="large" />
                </div>
            </div>
            <Handle
                type="target"
                position="left"
                isValidConnection={verifyConnection}
            />
        </>
    );
}

export default EditSiteNode;
