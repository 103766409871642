export const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
};

export const stringAvatar = (name, textColor) => {
    name = name.toUpperCase();
    let nameWords = name ? name.split("") : [];
    return {
        sx: {
            bgcolor: `${stringToColor(name)}!important`,
            width: "35px",
            height: "35px",
            fontSize: "16px",
            ...(textColor ? { color: textColor } : {}),
        },
        children:
            nameWords.length > 2
                ? `${nameWords[0][0]}${nameWords[1][0]}`
                : nameWords.length === 1
                ? nameWords[0][0]
                : "",
    };
};
