import React, { useState, useEffect } from "react";
import "./WorkflowPhases.scss";
import { Box, Card } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { useIntl } from "react-intl";
import AddModal from "../../components/addModal/AddModal";
import DataContainer from "../../components/dataContainer/DataContainer";
import {
    CustomLoadingOverlay,
    CustomNoRowsOverlay,
} from "../../utils/gridOverlays";
import { useConfirm } from "material-ui-confirm";
import { toast } from "react-toastify";
import { Add, Delete, Edit, Info } from "@mui/icons-material";
import {
    createWorkflowPhase,
    deleteWorkflowPhase,
    getWorkflowPhases,
    updateWorkflowPhase,
} from "../../api/workflowPhase";
import DataValidationError from "../../errors/DataValidationError";
import { camelToSnakeUpperCase } from "../../utils/general";
import useAbility from "../../hooks/useAbility";

function WorkflowPhases() {
    //#region States
    const { can } = useAbility();
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dataRows, setDataRows] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [modalLoading, setModalLoading] = useState(false);
    const [selectedPhaseId, setSelectedPhaseId] = useState(null);
    const selectedPhase = dataRows.find((row) => row.id === selectedPhaseId);
    //#endregion

    //#region other Hooks
    const intl = useIntl();
    const confirm = useConfirm();
    //#endregion

    //#region useEffect
    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {}, [isModalOpen]);
    //#endregion

    //#region Handlers
    const handleCloseAdd = () => {
        setIsModalOpen(false);
    };
    const handleOpenAdd = () => {
        setIsModalOpen(true);
    };
    const handleCloseUpdate = () => {
        setSelectedPhaseId(null);
    };
    const handleOpenUpdate = (user) => {
        setSelectedPhaseId(user);
    };
    const getData = async () => {
        setLoading(true);
        try {
            let data = await getWorkflowPhases();
            setDataRows(
                data.map((row) => ({
                    ...row,
                    ...row.officerInfo,
                    id: row._id,
                }))
            );
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        }
        setLoading(false);
    };

    const addWorkflowPhase = async (data) => {
        try {
            setModalLoading(true);
            await createWorkflowPhase(data);
            toast.success(
                intl.formatMessage({
                    id: "WORKFLOW_PHASES:WORKFLOW_PHASE_ADDED",
                })
            );
            setIsModalOpen(false);
            await getData();
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    if (field.kind === "unique") {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:UNIQUE" },
                                {
                                    field: intl.formatMessage({
                                        id: `WORKFLOW_PHASES:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    } else {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:FORMAT" },
                                {
                                    field: intl.formatMessage({
                                        id: `WORKFLOW_PHASES:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    }
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        } finally {
            setModalLoading(false);
        }
    };

    const updatePhase = async (data) => {
        try {
            setModalLoading(true);
            await updateWorkflowPhase(selectedPhaseId, data);
            toast.success(
                intl.formatMessage({
                    id: "WORKFLOW_PHASES:WORKFLOW_PHASE_UPDATED",
                })
            );
            setSelectedPhaseId(null);
            await getData();
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    if (field.kind === "unique") {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:UNIQUE" },
                                {
                                    field: intl.formatMessage({
                                        id: `WORKFLOW_PHASES:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    } else {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:FORMAT" },
                                {
                                    field: intl.formatMessage({
                                        id: `WORKFLOW_PHASES:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    }
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        } finally {
            setModalLoading(false);
        }
    };

    const deleteSelectedWorkflowPhase = async (params) => {
        try {
            await confirm({
                title: intl.formatMessage({ id: "WARNING" }),
                description: intl.formatMessage(
                    { id: "WORKFLOW_PHASES:WORKFLOW_PHASE_DELETE_CONFIRM" },
                    { name: params.row.name }
                ),
                confirmationText: intl.formatMessage({ id: "GLOBAL:CONFIRM" }),
                cancellationText: intl.formatMessage({ id: "GLOBAL:CANCEL" }),
            });
        } catch (error) {
            return;
        }
        setLoading(true);
        try {
            await deleteWorkflowPhase(params.row.id);
            toast.success(
                intl.formatMessage({
                    id: "WORKFLOW_PHASES:WORKFLOW_PHASE_DELETED",
                })
            );
            await getData();
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        } finally {
            setLoading(false);
        }
    };
    //#endregion

    //#region constants
    const dataColumns = [
        {
            field: "actions",
            type: "actions",
            minWidth: 50,
            maxWidth: 50,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<Info />}
                    onClick={() => {}}
                    label={intl.formatMessage({ id: "ACTIONS:DETAILS" })}
                    showInMenu
                />,
                ...(can("UPDATE", "WORKFLOW_STEPS")
                    ? [
                          <GridActionsCellItem
                              icon={<Edit />}
                              onClick={() => {
                                  handleOpenUpdate(params.row.id);
                              }}
                              label={intl.formatMessage({ id: "ACTIONS:EDIT" })}
                              showInMenu
                          />,
                      ]
                    : []),
                ...(can("DELETE", "WORKFLOW_STEPS")
                    ? [
                          <GridActionsCellItem
                              icon={<Delete />}
                              onClick={() =>
                                  deleteSelectedWorkflowPhase(params)
                              }
                              label={intl.formatMessage({
                                  id: "ACTIONS:DELETE",
                              })}
                              showInMenu
                          />,
                      ]
                    : []),
            ],
        },
        {
            field: "name",
            headerName: intl.formatMessage({ id: "WORKFLOW_PHASES:NAME" }),
            flex: 1,
            minWidth: 160,
        },
        {
            field: "color",
            headerName: intl.formatMessage({ id: "WORKFLOW_PHASES:COLOR" }),
            flex: 1,
            minWidth: 250,
            renderCell: (params) => (
                <Box
                    bgcolor={params.value}
                    padding="5px"
                    borderRadius={2}
                    sx={{
                        color: (theme) =>
                            theme.palette.getContrastText(params.value),
                    }}
                >
                    {params.value}
                </Box>
            ),
        },
        {
            field: "rank",
            headerName: intl.formatMessage({ id: "WORKFLOW_PHASES:RANK" }),
            flex: 1,
        },
    ];

    const headerButtons = [
        ...(can("CREATE", "WORKFLOW_STEPS")
            ? [
                  {
                      name: intl.formatMessage({
                          id: "WORKFLOW_PHASES:CREATE",
                      }),
                      props: {
                          onClick: handleOpenAdd,
                          variant: "contained",
                          startIcon: <Add />,
                      },
                  },
              ]
            : []),
    ];
    const modalFields = [
        {
            name: "name",
            label: intl.formatMessage({ id: "WORKFLOW_PHASES:NAME" }),
            type: "text",
            rules: {
                required: intl.formatMessage({
                    id: "FORM:ERROR_MESSAGES:REQUIRED",
                }),
                pattern: {
                    value: /^.+$/,
                    message: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                    }),
                },
            },
            fullWidth: true,
        },
        {
            name: "color",
            label: intl.formatMessage({ id: "WORKFLOW_PHASES:COLOR" }),
            type: "color",
            rules: {
                required: intl.formatMessage({
                    id: "FORM:ERROR_MESSAGES:REQUIRED",
                }),
            },
            fullWidth: true,
            defaultValue: "#ffffff",
        },
        {
            name: "rank",
            label: intl.formatMessage({ id: "WORKFLOW_PHASES:RANK" }),
            type: "number",
            rules: {
                required: intl.formatMessage({
                    id: "FORM:ERROR_MESSAGES:REQUIRED",
                }),
                valueAsNumber: true,
            },
            fullWidth: true,
        },
    ];

    var updateFields = selectedPhase
        ? {
              name: selectedPhase.name,
              color: selectedPhase.color,
              rank: selectedPhase.rank,
          }
        : null;
    //#endregion

    return (
        <DataContainer
            buttons={headerButtons}
            onRefresh={() => {
                getData();
            }}
        >
            {isModalOpen && (
                <AddModal
                    open={isModalOpen}
                    handleClose={handleCloseAdd}
                    fields={modalFields}
                    title={intl.formatMessage({ id: "WORKFLOW_PHASES:CREATE" })}
                    submit={addWorkflowPhase}
                    buttons={[
                        {
                            props: {
                                type: "submit",
                                variant: "contained",
                                color: "secondary",
                            },
                            label: intl.formatMessage({ id: "GLOBAL:SUBMIT" }),
                        },
                    ]}
                    buttonsBoxProps={[]}
                    loading={modalLoading}
                />
            )}
            {updateFields && (
                <AddModal
                    open={!!updateFields}
                    handleClose={handleCloseUpdate}
                    fields={modalFields
                        .filter((field) =>
                            Object.keys(updateFields).includes(field.name)
                        )
                        .map((field) => ({
                            ...field,
                            defaultValue: updateFields[field.name],
                        }))}
                    title={intl.formatMessage({ id: "WORKFLOW_PHASES:UPDATE" })}
                    submit={updatePhase}
                    buttons={[
                        {
                            props: {
                                type: "submit",
                                variant: "contained",
                                color: "secondary",
                            },
                            label: intl.formatMessage({ id: "GLOBAL:SUBMIT" }),
                        },
                    ]}
                    buttonsBoxProps={[]}
                    loading={modalLoading}
                />
            )}
            {can("CONSULT", "WORKFLOW_STEPS") ? (
                <Card sx={{ height: "100%" }}>
                    <DataGrid
                        loading={loading}
                        columns={dataColumns}
                        rows={dataRows}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) =>
                            setPageSize(newPageSize)
                        }
                        rowsPerPageOptions={[10, 20, 30, 50, 100]}
                        components={{
                            Toolbar: GridToolbar,
                            LoadingOverlay: CustomLoadingOverlay,
                            NoRowsOverlay: () =>
                                CustomNoRowsOverlay(
                                    intl.formatMessage({
                                        id: "WORKFLOW_PHASES:NO_WORKFLOW_PHASES",
                                    })
                                ),
                            NoResultsOverlay: () =>
                                CustomNoRowsOverlay(
                                    intl.formatMessage({
                                        id: "WORKFLOW_PHASES:NO_RESULTS",
                                    })
                                ),
                        }}
                        disableSelectionOnClick
                        onCellDoubleClick={() => {}}
                    />
                </Card>
            ) : (
                <>THIS IS A PLACEHOLDER</>
            )}
        </DataContainer>
    );
}

export default WorkflowPhases;
