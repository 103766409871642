import { createContext, useState } from "react";

export const AbilityContext = createContext();

export const AbilityProvider = ({ children }) => {
    const [abilities, setAbilities] = useState([]);
    return (
        <AbilityContext.Provider
            value={{
                setAbilities,
                abilities,
            }}
        >
            {children}
        </AbilityContext.Provider>
    );
};
