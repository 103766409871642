import {
    Cancel,
    FreeBreakfast,
    LocationOn,
    Login,
    Logout,
    WorkHistory,
} from "@mui/icons-material";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
} from "@mui/lab";
import { Box, Chip, Dialog, IconButton, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { getCheckins } from "../../../api/checkin";
import KmlPreview from "../../../components/kmlPreview/KmlPreview";
import Loading from "../../../components/loading/Loading";
import "./CheckinHistory.scss";

function CheckinHistory({ handleClose, user, open }) {
    const intl = useIntl();
    const [loading, setLoading] = useState(true);
    const [checkinDetails, setCheckinDetails] = useState(null);
    const [latLng, setLatLng] = useState(null);

    useEffect(() => {
        if (!user) {
            setCheckinDetails(null);
            setLoading(false);
        } else {
            getData();
        }
    }, [user]);

    const getData = async () => {
        setLoading(true);
        try {
            const data = await getCheckins({ user: user._id });
            setCheckinDetails(data.reverse());
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        }
        setLoading(false);
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={false}>
            <Box className="checkin-details-container">
                <Box className="header">
                    <Box display="flex" flexDirection="column">
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                        >
                            <Typography
                                variant="h1"
                                color="secondary"
                                fontSize={24}
                                fontWeight="bold"
                            >
                                <FormattedMessage
                                    id="USERS:CHECKINS:TITLE"
                                    values={{
                                        name: `${user?.officerInfo.firstName} ${user?.officerInfo.lastName}`,
                                    }}
                                />
                            </Typography>
                        </Box>
                    </Box>
                    <IconButton onClick={handleClose}>
                        <Cancel fontSize="large" color="quaternary" />
                    </IconButton>
                </Box>
                <Box className="body">
                    {loading ? (
                        <Loading color="quaternary" />
                    ) : (
                        <Box padding="20px">
                            <Timeline position="alternate">
                                {checkinDetails?.map((element, index) => (
                                    <React.Fragment key={index}>
                                        {element.siteOut && (
                                            <TimelineItem>
                                                <TimelineOppositeContent
                                                    sx={{ m: "auto 0" }}
                                                    variant="body2"
                                                >
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "column",
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="h2"
                                                            color="primary"
                                                            fontSize={20}
                                                            fontWeight="bold"
                                                        >
                                                            <FormattedMessage id="USERS:CHECKINS:SITE_OUT" />
                                                        </Typography>
                                                        <Typography color="primary">
                                                            {new Date(
                                                                element.siteOut.timestamps
                                                            ).toLocaleString(
                                                                "en-GB"
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineConnector />
                                                    <TimelineDot color="secondary">
                                                        <Logout />
                                                    </TimelineDot>
                                                    <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent
                                                    sx={{ m: "auto 0" }}
                                                >
                                                    <Box className="image-container">
                                                        <img
                                                            src={`data:image;base64,${element.siteOut.image}`}
                                                            height="100px"
                                                        />
                                                        <Box className="location-container">
                                                            <IconButton
                                                                color="primary"
                                                                onClick={() => {
                                                                    setLatLng({
                                                                        latitude:
                                                                            element
                                                                                .siteOut
                                                                                .latitude,
                                                                        longitude:
                                                                            element
                                                                                .siteOut
                                                                                .longitude,
                                                                    });
                                                                }}
                                                            >
                                                                <LocationOn
                                                                    color="quaternary"
                                                                    fontSize="large"
                                                                />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>
                                                </TimelineContent>
                                            </TimelineItem>
                                        )}
                                        {element.breakEnd && (
                                            <TimelineItem>
                                                <TimelineOppositeContent
                                                    sx={{ m: "auto 0" }}
                                                    variant="body2"
                                                >
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "column",
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="h2"
                                                            color="primary"
                                                            fontSize={20}
                                                            fontWeight="bold"
                                                        >
                                                            <FormattedMessage id="USERS:CHECKINS:BREAK_END" />
                                                        </Typography>
                                                        <Typography color="primary">
                                                            {new Date(
                                                                element.breakEnd.timestamps
                                                            ).toLocaleString(
                                                                "en-GB"
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineConnector />
                                                    <TimelineDot color="secondary">
                                                        <WorkHistory />
                                                    </TimelineDot>
                                                    <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent
                                                    sx={{ m: "auto 0" }}
                                                >
                                                    <Box className="image-container">
                                                        <img
                                                            src={`data:image;base64,${element.breakEnd.image}`}
                                                            height="100px"
                                                        />
                                                        <Box className="location-container">
                                                            <IconButton
                                                                color="primary"
                                                                onClick={() => {
                                                                    setLatLng({
                                                                        latitude:
                                                                            element
                                                                                .breakEnd
                                                                                .latitude,
                                                                        longitude:
                                                                            element
                                                                                .breakEnd
                                                                                .longitude,
                                                                    });
                                                                }}
                                                            >
                                                                <LocationOn
                                                                    color="quaternary"
                                                                    fontSize="large"
                                                                />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>
                                                </TimelineContent>
                                            </TimelineItem>
                                        )}
                                        {element.breakStart && (
                                            <TimelineItem>
                                                <TimelineOppositeContent
                                                    sx={{ m: "auto 0" }}
                                                    variant="body2"
                                                >
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "column",
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="h2"
                                                            color="primary"
                                                            fontSize={20}
                                                            fontWeight="bold"
                                                        >
                                                            <FormattedMessage id="USERS:CHECKINS:BREAK_START" />
                                                        </Typography>
                                                        <Typography color="primary">
                                                            {new Date(
                                                                element.breakStart.timestamps
                                                            ).toLocaleString(
                                                                "en-GB"
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineConnector />
                                                    <TimelineDot color="secondary">
                                                        <FreeBreakfast />
                                                    </TimelineDot>
                                                    <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent
                                                    sx={{ m: "auto 0" }}
                                                >
                                                    <Box className="image-container">
                                                        <img
                                                            src={`data:image;base64,${element.breakStart.image}`}
                                                            height="100px"
                                                        />
                                                        <Box className="location-container">
                                                            <IconButton
                                                                color="primary"
                                                                onClick={() => {
                                                                    setLatLng({
                                                                        latitude:
                                                                            element
                                                                                .breakStart
                                                                                .latitude,
                                                                        longitude:
                                                                            element
                                                                                .breakStart
                                                                                .longitude,
                                                                    });
                                                                }}
                                                            >
                                                                <LocationOn
                                                                    color="quaternary"
                                                                    fontSize="large"
                                                                />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>
                                                </TimelineContent>
                                            </TimelineItem>
                                        )}
                                        {element.siteIn && (
                                            <TimelineItem>
                                                <TimelineOppositeContent
                                                    sx={{ m: "auto 0" }}
                                                    variant="body2"
                                                >
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "column",
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="h2"
                                                            color="primary"
                                                            fontSize={20}
                                                            fontWeight="bold"
                                                        >
                                                            <FormattedMessage id="USERS:CHECKINS:SITE_IN" />
                                                        </Typography>
                                                        <Typography color="primary">
                                                            {new Date(
                                                                element.siteIn.timestamps
                                                            ).toLocaleString(
                                                                "en-GB"
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineConnector />
                                                    <TimelineDot color="secondary">
                                                        <Login />
                                                    </TimelineDot>
                                                    <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent
                                                    sx={{ m: "auto 0" }}
                                                >
                                                    <Box className="image-container">
                                                        <img
                                                            src={`data:image;base64,${element.siteIn.image}`}
                                                            height="100px"
                                                        />
                                                        <Box className="location-container">
                                                            <IconButton
                                                                color="primary"
                                                                onClick={() => {
                                                                    setLatLng({
                                                                        latitude:
                                                                            element
                                                                                .siteIn
                                                                                .latitude,
                                                                        longitude:
                                                                            element
                                                                                .siteIn
                                                                                .longitude,
                                                                    });
                                                                }}
                                                            >
                                                                <LocationOn
                                                                    color="quaternary"
                                                                    fontSize="large"
                                                                />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>
                                                </TimelineContent>
                                            </TimelineItem>
                                        )}
                                    </React.Fragment>
                                ))}
                            </Timeline>
                        </Box>
                    )}
                </Box>
            </Box>
            {latLng && (
                <KmlPreview
                    open={!!latLng}
                    handleClose={() => {
                        setLatLng(null);
                    }}
                    lat={latLng.latitude}
                    lng={latLng.longitude}
                    showMarker={true}
                />
            )}
        </Dialog>
    );
}

export default CheckinHistory;
