import React, { useCallback, useMemo } from "react";
import useFormatNodes from "../../../../../hooks/useFormatNodes";
import useNodeVerif from "../../../../../hooks/useNodeVerif";
import useWorkflowContext from "../../../../../hooks/useWorkflowContext";
import AndNode from "../andNode/AndNode";
import BuildChecklistNode from "../buildChecklistNode/BuildChecklistNode";
import CommentNode from "../commentNode/CommentNode";
import ConditionNode from "../conditionNode/ConditionNode";
import EditSiteNode from "../editSiteNode/EditSiteNode";
import EndNode from "../endNode/EndNode";
import FillReportNode from "../fillReportNode/FillReportNode";
import FormNode from "../formNode/FormNode";
import MailNode from "../mailNode/MailNode";
import OrNode from "../orNode/OrNode";
import SleepNode from "../sleepNode/SleepNode";
import StartNode from "../startNode/StartNode";
import "./NodeContainer.scss";

function NodeContainer({ type, id, selected }) {
    const isValid = useNodeVerif(id);
    const formattedNodes = useFormatNodes();
    const currentNode = useMemo(
        () => formattedNodes.find((node) => node.id === id),
        [formattedNodes]
    );
    const { workflowPhases } = useWorkflowContext();

    const step = useMemo(() => {
        const result =
            workflowPhases.find(
                (phase) => phase._id === currentNode?.data.settings?.step?._id
            ) ?? currentNode?.data.settings?.step;
        return result;
    }, [workflowPhases, currentNode]);

    const renderNode = useCallback(() => {
        switch (type) {
            case "START":
                return <StartNode selected={selected} isValid={isValid} />;
            case "AND":
                return <AndNode selected={selected} isValid={isValid} />;
            case "OR":
                return <OrNode selected={selected} isValid={isValid} />;
            case "MAIL":
                return <MailNode selected={selected} isValid={isValid} />;
            case "SLEEP":
                return <SleepNode selected={selected} isValid={isValid} />;
            case "CONDITION":
                return <ConditionNode selected={selected} isValid={isValid} />;
            case "FORM":
                return <FormNode selected={selected} isValid={isValid} />;
            case "COMMENT":
                return <CommentNode selected={selected} isValid={isValid} />;
            case "EDIT_SITE":
                return <EditSiteNode selected={selected} isValid={isValid} />;
            case "CHECKLIST_BUILD":
                return (
                    <BuildChecklistNode selected={selected} isValid={isValid} />
                );
            case "FILL_REPORT":
                return <FillReportNode selected={selected} isValid={isValid} />;
            case "END":
                return <EndNode selected={selected} isValid={isValid} />;
            default:
                return <></>;
        }
    }, [type, selected, isValid]);

    return (
        <div
            className="node-container"
            style={
                step
                    ? {
                          filter: `drop-shadow(0 0 7px ${step?.color})`,
                      }
                    : {}
            }
        >
            {renderNode()}
        </div>
    );
}

export default NodeContainer;
