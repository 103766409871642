export const verifyWorkflow = (nodes, edges, startNode) => {
    for (const node of nodes) {
        if (
            node.id !== "start-node" &&
            !edges.find((edge) => edge.target === node.id)
        ) {
            return false;
        }
    }
    for (const node of nodes) {
        let check;
        switch (node.type) {
            case "END":
                check = true;
                break;
            case "MAIL":
            case "FORM":
            case "COMMENT":
                check =
                    edges.find(
                        (edge) =>
                            edge.source === node.id &&
                            edge.sourceHandle === "SUCCESS"
                    ) &&
                    edges.find(
                        (edge) =>
                            edge.source === node.id &&
                            edge.sourceHandle === "ERROR"
                    );
                break;
            case "CONDITION":
                check =
                    edges.find(
                        (edge) =>
                            edge.source === node.id &&
                            edge.sourceHandle === "POSITIVE"
                    ) &&
                    edges.find(
                        (edge) =>
                            edge.source === node.id &&
                            edge.sourceHandle === "NEGATIVE"
                    );
                break;
            default:
                check = edges.find((edge) => edge.source === node.id);
                break;
        }
        if (!check) {
            return false;
        }
    }
    return true;
};

export const verifyNodeFields = (fields) => {
    for (let field of Object.values(fields)) {
        if (field.rules) {
            for (let key of Object.keys(field.rules)) {
                switch (key) {
                    case "required":
                        if (
                            !field.value ||
                            (field.value.constructor.name === "Array" &&
                                field.value.length === 0)
                        ) {
                            return false;
                        }
                        break;
                    case "pattern":
                        if (!field.rules[key].value.test(field.value || "")) {
                            return false;
                        }
                        break;
                    case "min":
                        if (
                            parseFloat(field.value) <
                            parseFloat(field.rules[key].value)
                        ) {
                            return false;
                        }
                        break;
                }
            }
        }
    }
    return true;
};

export const checkIfReachable = (
    nodes,
    edges,
    source,
    target,
    preventedSourceHandles
) => {
    const visitedNodes = new Set([source]);
    const goNext = (node) => {
        if (node === target) {
            return true;
        }
        let targets = edges
            .filter((edge) => {
                const currentSource = nodes.find((n) => n.id === edge.source);
                return (
                    edge.source === node &&
                    preventedSourceHandles.every(
                        (v) =>
                            currentSource.type !== v.type ||
                            edge.sourceHandle !== v.sourceHandle
                    )
                );
            })
            .map((edge) => nodes.find((node) => node.id === edge.target));
        for (let currentTarget of targets) {
            if (currentTarget && !visitedNodes.has(currentTarget.id)) {
                visitedNodes.add(currentTarget.id);
                if (goNext(currentTarget.id)) {
                    return true;
                }
            }
        }
        return false;
    };
    return goNext(source);
};

export const runWorkflow = (nodes, edges, startNode) => {};

export const createDynamicFormValue = (nodeId, fieldId) =>
    `#{{${nodeId}::${fieldId}}}`;

export const createSiteFieldValue = (field, subField) =>
    `@{{SITE::${field}::${subField ?? ""}}}`;
