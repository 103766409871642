import { Box, CircularProgress } from "@mui/material";
import React from "react";
import "./Loading.scss";

function Loading({ container, loader, color }) {
    return (
        <Box className="loading-container" sx={container}>
            <CircularProgress
                sx={loader}
                className="loader"
                color={color || "primary"}
            />
        </Box>
    );
}

export default Loading;
