import React, { useState } from "react";
import { useEffect } from "react";
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";

function LocationMarker({ lat, lng, onChange }) {
    const map = useMapEvents({
        click({ latlng }) {
            onChange(latlng.lat, latlng.lng);
            map.flyTo(latlng, map.getZoom());
        },
    });

    useEffect(() => {
        if (lat && lng) {
            map.flyTo([lat, lng], map.getZoom());
        }
    }, [lat, lng]);

    return <Marker position={[lat, lng]}></Marker>;
}

function LeafletPicker({ lat, lng, zoom, onChangeLocation, style }) {
    return (
        <MapContainer center={[lat, lng]} zoom={zoom} style={style}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <LocationMarker lat={lat} lng={lng} onChange={onChangeLocation} />
        </MapContainer>
    );
}

export default LeafletPicker;
