import DataValidationError from "../errors/DataValidationError";
import { axiosInstance } from "../utils/axios";

export const getKmlFiles = async () => {
    try {
        let response = await axiosInstance.get("/kml-files");
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
        return response.data;
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const createKmlFile = async (data) => {
    try {
        let formData = new FormData();
        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        });
        let response = await axiosInstance.post("/kml-files", formData);
        if (response.status !== 201) {
            throw new Error("ERROR:OTHER");
        }
        return response.data;
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const updateKmlFile = async (_id, data) => {
    try {
        let response = await axiosInstance.put(`/kml-files/${_id}`, data);
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const deleteKmlFile = async (_id) => {
    try {
        let response = await axiosInstance.delete(`/kml-files/${_id}`);
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};
