import "./App.css";
import LoginScreen from "./screens/login/Login";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { frFR, enUS } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import {
    clear,
    selectLocale,
    setId,
    setInfo,
    setLocale,
    setPermissions,
} from "./redux/slices/userSlice";
import {
    BrowserRouter,
    Navigate,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from "react-router-dom";
import LayoutScreen from "./screens/layout/Layout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Roles from "./screens/roles/Roles";
import { ConfirmProvider } from "material-ui-confirm";
import Users from "./screens/users/Users";
import Clients from "./screens/clients/Clients";
import Sites from "./screens/sites/Sites";
import BuildWorkflow from "./screens/buildWorkflow/BuildWorkflow";
import Workflows from "./screens/workflows/Workflows";
import GlobalVariables from "./screens/globalVariables/GlobalVariables";
import Coordinators from "./screens/coordinators/Coordinators";
import WorkflowPhases from "./screens/workflowPhases/WorkflowPhases";
import Tasks from "./screens/tasks/Tasks";
import KmlFiles from "./screens/kmlFiles/KmlFiles";
import Dashboard from "./screens/dashboard/Dashboard";
import useAbility from "./hooks/useAbility";
import Loading from "./components/loading/Loading";
import { checkToken } from "./api/auth";
import { SettingsProvider } from "./contexts/Settings";
import { ProfileProvider } from "./contexts/Profile";

const locales = {
    en: "en-US",
    fr: "fr-FR",
    default: "en-US",
};

const parseLocale = (locale) => {
    for (let key of Object.keys(locales)) {
        if (locale.startsWith(key)) {
            return locales[key];
        }
    }
    return locales.default;
};

function App() {
    const ability = useAbility();
    const locale = useSelector(selectLocale);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [theme, setTheme] = useState(null);
    const [messages, setMessages] = useState({});
    const [loading, setLoading] = useState(true);
    const [pageLoading, setPageLoading] = useState(true);
    const [checkTokenLoading, setCheckTokenLoading] = useState(false);

    useEffect(() => {
        dispatch(setLocale(navigator.language));
    }, []);

    useEffect(() => {
        if (locale) {
            setupTheme();
        }
    }, [locale]);

    useEffect(() => {
        setup();
    }, [location.pathname]);

    const setup = async () => {
        if (!window.localStorage.getItem("token")) {
            setPageLoading(true);
            window.localStorage.clear();
            dispatch(clear());
            navigate("/login", {
                replace: true,
                state: {
                    fromApp: true,
                },
            });
            setPageLoading(false);
        } else if (!location.state || !location.state.fromLogin) {
            try {
                setCheckTokenLoading(true);
                let { id, token, info, permissions } = await checkToken();
                window.localStorage.setItem("token", token);
                dispatch(setInfo(info));
                dispatch(setId(id));
                dispatch(setPermissions(permissions));
                ability.updateAbilities(permissions);
                if (info.lang) {
                    changeLang(info.lang);
                }
                setCheckTokenLoading(false);
                setPageLoading(false);
            } catch (error) {
                window.localStorage.clear();
                dispatch(clear());
                setCheckTokenLoading(false);
                navigate("/login", {
                    replace: true,
                    state: {
                        fromApp: true,
                    },
                });
            }
        } else {
            setPageLoading(true);
            window.history.replaceState({}, "");
            setPageLoading(false);
        }
    };

    const changeLang = (locale) => {
        dispatch(setLocale(locale));
    };

    const setupTheme = async () => {
        if (locale) {
            setTheme(
                createTheme(
                    {
                        palette: {
                            primary: {
                                main: "#34375A",
                            },
                            secondary: {
                                main: "#3D59D6",
                            },
                            tertiary: {
                                main: "#66718C",
                            },
                            quaternary: {
                                main: "#8C9DE7",
                            },
                            white: {
                                main: "#FFFFFF",
                                light: "#FFFFFF",
                                main: "#FFFFFF",
                                dark: "#FFFFFF",
                                contrastText: "#000000",
                            },
                            grey: {
                                main: "#787878",
                                light: "#787878",
                                main: "#787878",
                                dark: "#787878",
                                contrastText: "#787878",
                            },
                            not_started: {
                                light: "#00FF00",
                                main: "#00FF00",
                                dark: "#00FF00",
                                contrastText: "#00FF00",
                            },
                            pending: {
                                light: "#F8C100",
                                main: "#F8C100",
                                dark: "#F8C100",
                                contrastText: "#F8C100",
                            },
                            running: {
                                light: "#F8C100",
                                main: "#F8C100",
                                dark: "#F8C100",
                                contrastText: "#F8C100",
                            },
                            completed: {
                                light: "#E36AF4",
                                main: "#E36AF4",
                                dark: "#E36AF4",
                                contrastText: "#E36AF4",
                            },
                            cancelled: {
                                light: "#FF0000",
                                main: "#FF0000",
                                dark: "#FF0000",
                                contrastText: "#FF0000",
                            },
                        },
                    },
                    parseLocale(locale) === "fr-FR"
                        ? frFR
                        : parseLocale(locale) === "en-US"
                        ? enUS
                        : null
                )
            );
            setMessages(
                (await import(`./i18n/${parseLocale(locale)}.json`)).default
            );
            setLoading(false);
        }
    };

    return (
        !loading && (
            <IntlProvider locale={locale} messages={messages}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <ToastContainer
                        position="bottom-left"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ConfirmProvider>
                        <ProfileProvider>
                            <SettingsProvider>
                                {pageLoading ? (
                                    <Loading
                                        loader={{
                                            height: "10vmin!important",
                                            width: "10vmin!important",
                                        }}
                                        container={{
                                            width: "100vw!important",
                                            height: "100vh!important",
                                        }}
                                    />
                                ) : (
                                    <Routes>
                                        <Route
                                            path="/"
                                            element={
                                                <LayoutScreen
                                                    changeLang={changeLang}
                                                    checkTokenLoading={
                                                        checkTokenLoading
                                                    }
                                                />
                                            }
                                        >
                                            <Route
                                                index
                                                element={
                                                    <Navigate
                                                        to={"/dashboard"}
                                                    />
                                                }
                                            ></Route>
                                            <Route
                                                path="/dashboard"
                                                element={<Dashboard />}
                                            ></Route>
                                            {ability.can(
                                                "CONSULT",
                                                "ROLES"
                                            ) && (
                                                <Route
                                                    path="/roles"
                                                    element={<Roles />}
                                                ></Route>
                                            )}
                                            {ability.can(
                                                ["CONSULT", "CREATE"],
                                                "USERS"
                                            ) && (
                                                <Route
                                                    path="/users"
                                                    element={<Users />}
                                                ></Route>
                                            )}
                                            {ability.can(
                                                ["CONSULT", "CREATE"],
                                                "CLIENTS"
                                            ) && (
                                                <Route
                                                    path="/clients"
                                                    element={<Clients />}
                                                ></Route>
                                            )}

                                            {ability.can(
                                                ["CONSULT", "CREATE"],
                                                "CLIENTS"
                                            ) && (
                                                <Route
                                                    path="/coordinators"
                                                    element={<Coordinators />}
                                                ></Route>
                                            )}
                                            {(ability.can(
                                                ["CONSULT", "CREATE"],
                                                "ALL_SITES"
                                            ) ||
                                                ability.can(
                                                    ["CONSULT", "CREATE"],
                                                    "RELATED_SITES"
                                                ) ||
                                                ability.can(
                                                    ["CONSULT", "CREATE"],
                                                    "CREATED_SITES"
                                                )) && (
                                                <Route
                                                    path="/sites"
                                                    element={<Sites />}
                                                ></Route>
                                            )}
                                            {ability.can(
                                                ["CONSULT", "CREATE"],
                                                "KML_FILES"
                                            ) && (
                                                <Route
                                                    path="/kml"
                                                    element={<KmlFiles />}
                                                ></Route>
                                            )}
                                            <Route
                                                path="/tasks"
                                                element={<Tasks />}
                                            ></Route>
                                            {ability.can(
                                                "UPDATE",
                                                "WORKFLOWS"
                                            ) && (
                                                <Route
                                                    path="/workflows/build/:id"
                                                    element={<BuildWorkflow />}
                                                ></Route>
                                            )}
                                            {ability.can(
                                                "CREATE",
                                                "WORKFLOWS"
                                            ) && (
                                                <Route
                                                    path="/workflows/build"
                                                    element={<BuildWorkflow />}
                                                ></Route>
                                            )}
                                            {ability.can(
                                                ["CONSULT", "CREATE"],
                                                "GLOBAL_VARIABLES"
                                            ) && (
                                                <Route
                                                    path="/global-variables"
                                                    element={
                                                        <GlobalVariables />
                                                    }
                                                ></Route>
                                            )}
                                            {ability.can(
                                                ["CONSULT", "CREATE"],
                                                "WORKFLOWS"
                                            ) && (
                                                <Route
                                                    path="/workflows"
                                                    element={<Workflows />}
                                                ></Route>
                                            )}
                                            {ability.can(
                                                ["CONSULT", "CREATE"],
                                                "WORKFLOW_STEPS"
                                            ) && (
                                                <Route
                                                    path="/workflow-phases"
                                                    element={<WorkflowPhases />}
                                                ></Route>
                                            )}
                                        </Route>
                                        <Route
                                            path="/login"
                                            element={<LoginScreen />}
                                        />
                                        <Route
                                            path="*"
                                            element={<>fall back</>}
                                        />
                                    </Routes>
                                )}
                            </SettingsProvider>
                        </ProfileProvider>
                    </ConfirmProvider>
                </ThemeProvider>
            </IntlProvider>
        )
    );
}

export default App;
