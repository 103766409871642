import React from "react";
import { useCallback } from "react";
import { useContext } from "react";
import { ProfileContext } from "../contexts/Profile";

function useProfile() {
    const { isOpen, setIsOpen } = useContext(ProfileContext);

    const openProfile = useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen]);

    const closeProfile = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    return {
        showProfile: isOpen,
        openProfile,
        closeProfile,
    };
}

export default useProfile;
