export class Node {
    static data;
    constructor(value) {
        this.value = value;
    }

    getParent() {
        if (this.value?.parent) {
            return new Node(this.value.parent);
        }
        return null;
    }

    getChildren() {
        if (!this.value?.id || this.value.parent) {
            return null;
        }
        return Node.data
            .filter((el) => el.parent?.id === this.value?.id)
            .map((el) => new Node(el));
    }

    isBranch() {
        return !this.value.parent && !this.value.selectable;
    }

    isEqual(to) {
        return this.value.id === to?.value.id;
    }

    toString() {
        return this.value?.label || this.value?.name || this.value?.id;
    }
}
