import React, { useState, useEffect } from "react";
import "./LocationPicker.scss";
import {
    Box,
    Button,
    Dialog,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    TextField,
} from "@mui/material";
import { Room } from "@mui/icons-material";
import { useFormContext } from "react-hook-form";
import { extractFromPath } from "../../utils/general";
import LeafletPicker from "../leafletPicker/LeafletPicker";
import { flushSync } from "react-dom";
import useDebounce from "../../hooks/useDebounce";

function LocationPicker({
    selectButtonText,
    size,
    getLat,
    getLng,
    getAddress,
    defaultLocation,
    labels,
    names,
    rules,
}) {
    const [open, setOpen] = useState(false);
    const [searchError, setSearchError] = useState(false);
    const [lat, setLat] = useState(defaultLocation.lat);
    const [lng, setLng] = useState(defaultLocation.lng);
    const [latValue, setLatValue] = useState("");
    const [lngValue, setLngValue] = useState("");
    const [searchText, setSearchText] = useState("");
    const [searchPropositions, setSearchPropositions] = useState([]);
    const [searchFocused, setSearchFocused] = useState(false);

    const debouncedSearchText = useDebounce(searchText, 400);

    const {
        setValue,
        watch,
        register,
        formState: { errors },
    } = useFormContext();

    const watchLat = watch(names.lat);
    const watchLng = watch(names.lng);

    useEffect(() => {
        let lat = getLat();
        if (lat) {
            setLat(lat);
            setLatValue(lat);
        }
    }, [watchLat]);

    useEffect(() => {
        let lng = getLng();
        if (lng) {
            setLng(lng);
            setLngValue(lng);
        }
    }, [watchLng]);

    const setPropositions = async (text) => {
        try {
            let response = await fetch(
                `https://nominatim.openstreetmap.org/search?q=${text}&format=json`
            );
            let data = await response.json();
            setSearchPropositions(
                data.slice(0, 5).map((element) => element.display_name)
            );
        } catch (error) {
            setSearchPropositions([]);
        }
    };

    useEffect(() => {
        if (debouncedSearchText && setPropositions) {
            setPropositions(debouncedSearchText);
        } else {
            setSearchPropositions([]);
        }
    }, [debouncedSearchText]);

    const search = async (address) => {
        try {
            let response = await fetch(
                `https://nominatim.openstreetmap.org/search?q=${address}&format=json`
            );
            let data = await response.json();
            if (data.length === 0) {
                setSearchError(true);
                return;
            }
            setSearchError(false);
            const { lat, lon: lng } = data[0];
            setLat(lat);
            setLng(lng);
            setSearchPropositions([]);
        } catch (error) {
            console.log(error);
            setSearchError(true);
        }
    };

    return (
        <Box width={"100%"} className="location-picker-container">
            {open && (
                <Dialog
                    className="location-picker-dialog"
                    maxWidth={false}
                    open={open}
                    onClose={() => {
                        setOpen(false);
                    }}
                    sx={{
                        backgroundColor: "transparent",
                    }}
                    PaperProps={{
                        sx: {
                            backgroundColor: "transparent",
                        },
                    }}
                >
                    <Box className="map-container">
                        <LeafletPicker
                            lat={parseFloat(lat)}
                            lng={parseFloat(lng)}
                            zoom={10}
                            style={{ height: "100%", width: "100%" }}
                            onChangeLocation={(lat, lng) => {
                                flushSync(() => {
                                    setLat(lat);
                                    setLng(lng);
                                });
                            }}
                        />
                        <Box
                            sx={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                zIndex: 999,
                                width: "250px",
                            }}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            <TextField
                                size="small"
                                color="secondary"
                                sx={{
                                    backgroundColor: "white!important",
                                    borderRadius: "4px",
                                }}
                                error={searchError}
                                value={searchText}
                                onChange={(event) => {
                                    setSearchText(event.target.value);
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        event.currentTarget.blur();
                                        search(event.target.value);
                                    }
                                }}
                                onFocus={(event) => {
                                    setSearchFocused(true);
                                    if (event.target.value)
                                        setPropositions(event.target.value);
                                }}
                                onBlur={() => {
                                    setTimeout(() => {
                                        setSearchFocused(false);
                                        setSearchPropositions([]);
                                    }, 500);
                                }}
                                fullWidth={true}
                            />

                            <List
                                dense={true}
                                sx={{
                                    backgroundColor: "white!important",
                                    padding: "0px!important",
                                    ...(searchFocused
                                        ? {}
                                        : { display: "none!important" }),
                                }}
                            >
                                {searchPropositions.map((element, index) => (
                                    <ListItem key={index} disablePadding>
                                        <ListItemButton
                                            onClick={() => {
                                                setSearchText(element);
                                                search(element);
                                            }}
                                        >
                                            <ListItemText
                                                primary={element}
                                                sx={{
                                                    textOverflow: "ellipsis",
                                                    wordWrap: "break-word",
                                                    WebkitBoxOrient: "vertical",
                                                    WebkitLineClamp: 2,
                                                    display: "-webkit-box",
                                                    overflow: "hidden",
                                                }}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setValue(names.lat, lat, {
                                    shouldValidate: true,
                                });
                                setValue(names.lng, lng, {
                                    shouldValidate: true,
                                });
                                setOpen(false);
                            }}
                            sx={{
                                position: "absolute",
                                bottom: "30px",
                                minWidth: "80%",
                                zIndex: 999,
                            }}
                        >
                            {selectButtonText}
                        </Button>
                    </Box>
                </Dialog>
            )}
            <Box display="flex" flexDirection="column">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={2}>
                        <TextField
                            size={size}
                            type="text"
                            label={labels.number}
                            fullWidth
                            error={!!extractFromPath(errors, names.number)}
                            helperText={
                                extractFromPath(errors, names.number)?.message
                            }
                            {...register(names.number, rules.number)}
                            multiline={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField
                            size={size}
                            type="text"
                            label={labels.street}
                            fullWidth
                            error={!!extractFromPath(errors, names.street)}
                            helperText={
                                extractFromPath(errors, names.street)?.message
                            }
                            {...register(names.street, rules.street)}
                            multiline={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField
                            size={size}
                            type="text"
                            label={labels.city}
                            fullWidth
                            error={!!extractFromPath(errors, names.city)}
                            helperText={
                                extractFromPath(errors, names.city)?.message
                            }
                            {...register(names.city, rules.city)}
                            multiline={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TextField
                            size={size}
                            type="text"
                            label={labels.postalCode}
                            fullWidth
                            error={!!extractFromPath(errors, names.postalCode)}
                            helperText={
                                extractFromPath(errors, names.postalCode)
                                    ?.message
                            }
                            {...register(names.postalCode, rules.postalCode)}
                            multiline={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField
                            size={size}
                            type="text"
                            label={labels.country}
                            fullWidth
                            error={!!extractFromPath(errors, names.country)}
                            helperText={
                                extractFromPath(errors, names.country)?.message
                            }
                            {...register(names.country, rules.country)}
                            multiline={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField
                            size={size}
                            type="text"
                            label={labels.furtherInformation}
                            fullWidth
                            error={
                                !!extractFromPath(
                                    errors,
                                    names.furtherInformation
                                )
                            }
                            helperText={
                                extractFromPath(
                                    errors,
                                    names.furtherInformation
                                )?.message
                            }
                            {...register(
                                names.furtherInformation,
                                rules.furtherInformation
                            )}
                            multiline={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Box display="flex">
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        size={size}
                                        type="text"
                                        label={labels.lat}
                                        fullWidth
                                        error={
                                            !!extractFromPath(errors, names.lat)
                                        }
                                        helperText={
                                            extractFromPath(errors, names.lat)
                                                ?.message
                                        }
                                        {...register(names.lat, rules.lat)}
                                        multiline={false}
                                        InputLabelProps={{
                                            shrink: latValue ? true : false,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        size={size}
                                        type="text"
                                        label={labels.lng}
                                        fullWidth
                                        error={
                                            !!extractFromPath(errors, names.lng)
                                        }
                                        helperText={
                                            extractFromPath(errors, names.lng)
                                                ?.message
                                        }
                                        {...register(names.lng, rules.lng)}
                                        multiline={false}
                                        InputLabelProps={{
                                            shrink: lngValue ? true : false,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Box
                                width={size === "small" ? "40px" : "56px"}
                                height={size === "small" ? "40px" : "56px"}
                                minWidth={size === "small" ? "40px" : "56px"}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                marginLeft="16px"
                            >
                                <IconButton
                                    size="large"
                                    color="primary"
                                    className="pick-button"
                                    onClick={() => {
                                        let lat = getLat();
                                        let lng = getLng();
                                        if (lat && lng) {
                                            setLat(lat);
                                            setLng(lng);
                                        } else {
                                            let address = getAddress();
                                            if (address) {
                                                setSearchText(address);
                                                search(address);
                                            }
                                        }
                                        setOpen(true);
                                    }}
                                >
                                    <Room />
                                </IconButton>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default LocationPicker;
