import { Box, Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./FillReportFormDialog.scss";

function FillReportFormDialog({ content, save, isOpen, setIsOpen }) {
    const [data, setData] = useState(content);

    useEffect(() => {
        save({ content: data });
    }, [data]);

    return (
        <Dialog
            className="fill-report-form-dialog"
            maxWidth={false}
            open={isOpen}
            onClose={() => {
                setIsOpen(false);
            }}
            sx={{
                backgroundColor: "transparent",
            }}
            PaperProps={{
                sx: {
                    backgroundColor: "transparent",
                    margin: "0px",
                    maxHeight: "unset",
                },
            }}
        >
            <Box className="dialog-content"></Box>
        </Dialog>
    );
}

export default FillReportFormDialog;
