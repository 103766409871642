import { Info } from "@mui/icons-material";
import { Alert, Box, Card } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { getTasks } from "../../api/task";
import DataContainer from "../../components/dataContainer/DataContainer";
import Loading from "../../components/loading/Loading";
import {
    CustomLoadingOverlay,
    CustomNoRowsOverlay,
} from "../../utils/gridOverlays";
import TaskCard from "./taskCard/TaskCard";
import "./Tasks.scss";

function Tasks() {
    const intl = useIntl();
    const [pendingTasks, setPendingTasks] = useState([]);
    const [pendingTasksLoading, setPendingTasksLoading] = useState(true);
    const [tasksHistory, setTasksHistory] = useState([]);
    const [tasksHistoryLoading, setTasksHistoryLoading] = useState(true);

    useEffect(() => {
        refreshAll();
    }, []);

    const getPendingTasks = async () => {
        console.time("timer");
        setPendingTasksLoading(true);
        try {
            let data = await getTasks(["PENDING", "DRAFT"]);
            setPendingTasks(data);
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        } finally {
            console.timeEnd("timer");
            setPendingTasksLoading(false);
        }
    };

    const getTasksHistory = async () => {
        setTasksHistoryLoading(true);
        try {
            let data = await getTasks("COMPLETED");
            setTasksHistory(data.map((row) => ({ ...row, id: row._id })));
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        } finally {
            setTasksHistoryLoading(false);
        }
    };

    const refreshAll = () => {
        getPendingTasks();
        getTasksHistory();
    };

    const tasksHistoryColumns = useMemo(
        () => [
            {
                field: "actions",
                type: "actions",
                minWidth: 50,
                maxWidth: 50,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<Info />}
                        onClick={() => {}}
                        label={intl.formatMessage({ id: "ACTIONS:DETAILS" })}
                        showInMenu
                    />,
                ],
            },
            {
                field: "kind",
                headerName: intl.formatMessage({ id: "TASKS:KIND" }),
                flex: 1,
                minWidth: 160,
            },
            {
                field: "client",
                headerName: intl.formatMessage({ id: "TASKS:CLIENT" }),
                renderCell: ({ row }) =>
                    row?.workflowInstance?.site?.client?.name,
                flex: 1,
                minWidth: 160,
            },
            {
                field: "siteName",
                headerName: intl.formatMessage({ id: "TASKS:SITE_NAME" }),
                renderCell: ({ row }) => row?.workflowInstance?.site?.name,
                flex: 1,
                minWidth: 160,
            },
            {
                field: "siteRef",
                headerName: intl.formatMessage({ id: "TASKS:SITE_REF" }),
                renderCell: ({ row }) => row?.workflowInstance?.site?.ref,
                flex: 1,
                minWidth: 160,
            },
            {
                field: "createdAt",
                headerName: intl.formatMessage({ id: "SITES:CREATED_AT" }),
                flex: 1,
                minWidth: 180,
                valueFormatter: ({ value }) =>
                    new Date(value).toLocaleString("en-GB"),
            },
        ],
        [intl]
    );

    return (
        <Box className="tasks-container">
            <Box className="pending-tasks-container">
                <DataContainer
                    title={intl.formatMessage({ id: "TASKS:PENDING_TASKS" })}
                    badge={pendingTasks.length}
                    headerProps={{ height: "50px" }}
                    containerProps={{ pb: 1 }}
                    onRefresh={getPendingTasks}
                >
                    <Box className="pending-tasks">
                        {pendingTasksLoading ? (
                            <Loading />
                        ) : pendingTasks.length > 0 ? (
                            pendingTasks.map((task) => (
                                <TaskCard
                                    key={task._id}
                                    data={task}
                                    refreshAll={refreshAll}
                                />
                            ))
                        ) : (
                            <Alert severity="info" sx={{ flex: 1 }}>
                                <FormattedMessage id="TASKS:NO_PENDING_TASKS" />
                            </Alert>
                        )}
                    </Box>
                </DataContainer>
            </Box>
            <Box
                width="100%"
                flex={1}
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
            >
                <Box
                    className="tasks-history-container"
                    sx={{
                        width: { xs: "100%", md: "60%" },
                    }}
                >
                    <DataContainer
                        title={intl.formatMessage({
                            id: "TASKS:TASKS_HISTORY",
                        })}
                        headerProps={{ height: "50px" }}
                        containerProps={{ pt: 1 }}
                        onRefresh={getTasksHistory}
                    >
                        <Card sx={{ height: "100%" }}>
                            <DataGrid
                                density="compact"
                                loading={tasksHistoryLoading}
                                columns={tasksHistoryColumns}
                                rows={tasksHistory}
                                // pageSize={pageSize}
                                // onPageSizeChange={(newPageSize) =>
                                //     setPageSize(newPageSize)
                                // }
                                rowsPerPageOptions={[10, 20, 30, 50, 100]}
                                components={{
                                    Toolbar: GridToolbar,
                                    LoadingOverlay: CustomLoadingOverlay,
                                    NoRowsOverlay: () =>
                                        CustomNoRowsOverlay(
                                            intl.formatMessage({
                                                id: "COORDINATORS:NO_COORDINATORS",
                                            })
                                        ),
                                    NoResultsOverlay: () =>
                                        CustomNoRowsOverlay(
                                            intl.formatMessage({
                                                id: "COORDINATORS:NO_RESULTS",
                                            })
                                        ),
                                }}
                                disableSelectionOnClick
                                onCellDoubleClick={() => {}}
                            />
                        </Card>
                    </DataContainer>
                </Box>
                <Box
                    className="tasks-insights-container"
                    sx={{
                        width: { xs: "100%", md: "40%" },
                    }}
                >
                    <DataContainer
                        title={intl.formatMessage({
                            id: "TASKS:TASKS_INSIGHTS",
                        })}
                        headerProps={{ height: "50px" }}
                    ></DataContainer>
                </Box>
            </Box>
        </Box>
    );
}

export default Tasks;
