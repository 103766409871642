import { axiosInstance } from "../utils/axios";

export const getDebits = async () => {
    try {
        let response = await axiosInstance.get("/params/debits");
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
        return response.data;
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};
