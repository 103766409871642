import React from "react";
import { Card, Box, Typography, Button, Badge } from "@mui/material";
import { LocationOn } from "@mui/icons-material";
import { FormattedMessage, useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";
import useAbility from "../../../hooks/useAbility";
import useNotifications from "../../../hooks/useNotifications";
import { useMemo } from "react";

const CARD_HEIGHT = 240;
const CARD_WIDTH = 220;
const possibleStates = {
    NOT_STARTED: { label: "SITES:NOT_STARTED", color: "#00FF00" },
    RUNNING: { label: "SITES:RUNNING", color: "#F8C100" },
    COMPLETED: { label: "SITES:COMPLETED", color: "#E36AF4" },
    CANCELLED: { label: "SITES:CANCELLED", color: "#FF0000" },
    FROZEN: { label: "SITES:FROZEN", color: "#FF0000" },
};

const SiteCard = ({ data, onContextMenu }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { can } = useAbility();
    const intl = useIntl();
    const { notifications } = useNotifications();

    const count = useMemo(
        () =>
            notifications.filter(
                (notif) =>
                    !notif.seen &&
                    ["COMMENT", "COMMENT_MENTION"].includes(notif.kind) &&
                    notif.data?.site === data._id
            ).length,
        [notifications, data]
    );

    return (
        <React.Fragment>
            <Badge badgeContent={count} color={"error"}>
                <Card
                    sx={{
                        height: CARD_HEIGHT,
                        width: CARD_WIDTH,
                        boxShadow:
                            "-2px 5px 11px #EEF0FF, 2px 5px 11px #EEF0FF, 0px 5px #dfe0ef",
                        pr: 1,
                        pl: 1,
                        pt: 2,
                        pb: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        borderRadius: "7px",
                    }}
                    elevation={1}
                    onContextMenu={onContextMenu}
                >
                    <Box>
                        <Box
                            display="flex"
                            alignItems={"center"}
                            gap={1}
                            mb={0.2}
                        >
                            <Typography
                                fontSize={14}
                                fontWeight={"bold"}
                                color="primary"
                            >
                                <FormattedMessage id="SITES:REF" />
                                &nbsp;:
                            </Typography>
                            <Typography
                                fontSize={14}
                                fontWeight={"bold"}
                                color="secondary"
                                textOverflow="ellipsis"
                                sx={{
                                    WebkitLineClamp: "1",
                                    WebkitBoxOrient: "vertical",
                                    display: "-webkit-box",
                                    overflow: "hidden",
                                }}
                            >
                                {data?.ref}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                backgroundColor: `${
                                    possibleStates[
                                        data.frozen
                                            ? "FROZEN"
                                            : data.workflowInstance?.state ||
                                              "NOT_STARTED"
                                    ].color + "12"
                                }`,
                                borderRadius: "4px",
                                width: 100,
                                height: 20,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            mb={0.5}
                        >
                            <Typography
                                sx={{
                                    color: `${
                                        possibleStates[
                                            data.frozen
                                                ? "FROZEN"
                                                : data?.workflowInstance
                                                      ?.state || "NOT_STARTED"
                                        ].color
                                    }`,
                                    fontSize: 10,
                                    fontWeight: "bold",
                                }}
                            >
                                {intl.formatMessage({
                                    id: possibleStates[
                                        data.frozen
                                            ? "FROZEN"
                                            : data?.workflowInstance?.state ||
                                              "NOT_STARTED"
                                    ].label,
                                })}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            alignItems={"center"}
                            mb={2}
                            gap={1}
                        >
                            <Typography
                                fontSize={12}
                                color="primary"
                                fontWeight="bold"
                                sx={{ whiteSpace: "nowrap" }}
                            >
                                <FormattedMessage id="SITES:CLIENT" />
                                &nbsp;:
                            </Typography>
                            <Typography
                                fontSize={12}
                                color="secondary"
                                textOverflow="ellipsis"
                                sx={{
                                    WebkitLineClamp: "1",
                                    WebkitBoxOrient: "vertical",
                                    display: "-webkit-box",
                                    overflow: "hidden",
                                }}
                            >
                                {data?.client.name}
                            </Typography>
                        </Box>
                        <Box display="flex" mb={1} gap={1}>
                            <Typography
                                fontSize={12}
                                color="primary"
                                fontWeight="bold"
                                sx={{ whiteSpace: "nowrap" }}
                            >
                                <FormattedMessage id="SITES:ADDRESS" />
                                &nbsp;:
                            </Typography>
                            <Typography
                                fontSize={12}
                                color="primary"
                                textOverflow="ellipsis"
                                sx={{
                                    WebkitLineClamp: "2",
                                    WebkitBoxOrient: "vertical",
                                    display: "-webkit-box",
                                    overflow: "hidden",
                                }}
                            >
                                {data?.address}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            alignItems={"center"}
                            mb={1}
                            gap={1}
                        >
                            <Typography
                                fontSize={12}
                                color="primary"
                                fontWeight="bold"
                                sx={{ whiteSpace: "nowrap" }}
                            >
                                <FormattedMessage id="SITES:CREATED_AT" />
                                &nbsp;:
                            </Typography>
                            <Typography
                                fontSize={12}
                                color="primary"
                                textOverflow="ellipsis"
                                sx={{
                                    WebkitLineClamp: "1",
                                    WebkitBoxOrient: "vertical",
                                    display: "-webkit-box",
                                    overflow: "hidden",
                                }}
                            >
                                {new Date(data?.createdAt).toLocaleString(
                                    "en-GB"
                                )}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            alignItems={"center"}
                            mb={1}
                            gap={1}
                        >
                            <Typography
                                fontSize={12}
                                color="primary"
                                fontWeight="bold"
                                sx={{ whiteSpace: "nowrap" }}
                            >
                                <FormattedMessage id="SITES:CREATED_BY" />
                                &nbsp;:
                            </Typography>
                            <Typography
                                fontSize={12}
                                color="primary"
                                textOverflow="ellipsis"
                                sx={{
                                    WebkitLineClamp: "1",
                                    WebkitBoxOrient: "vertical",
                                    display: "-webkit-box",
                                    overflow: "hidden",
                                }}
                            >
                                {data?.createdBy.username}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems={"center"} gap={0.5}>
                        {(can("CONSULT", "ALL_SITES") ||
                            can("CONSULT", "RELATED_SITES") ||
                            can("CONSULT", "CREATED_SITES")) && (
                            <Button
                                variant="outlined"
                                fullWidth
                                color="secondary"
                                sx={{
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    textTransform: "unset",
                                    height: 26,
                                    lineHeight: "10px",
                                }}
                                onClick={() => {
                                    setSearchParams({
                                        siteId: data._id,
                                        target: "details",
                                        tab: "info",
                                    });
                                }}
                            >
                                {intl.formatMessage({
                                    id: "SITES:MORE_DETAILS",
                                })}
                            </Button>
                        )}
                        {(can("SEE_IN_MAP", "ALL_SITES") ||
                            can("SEE_IN_MAP", "RELATED_SITES") ||
                            can("SEE_IN_MAP", "CREATED_SITES")) && (
                            <Button
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                sx={{
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    textTransform: "unset",
                                    height: 26,
                                    backgroundColor: "#EEF0FF",
                                    borderColor: "#EEF0FF",
                                    boxShadow: "none",
                                    color: "#3D59D6",
                                    lineHeight: "10px",
                                }}
                                endIcon={
                                    <LocationOn
                                        sx={{ fontSize: "11px!important" }}
                                    />
                                }
                                onClick={() => {
                                    setSearchParams({
                                        siteId: data._id,
                                        target: "map",
                                    });
                                }}
                            >
                                {intl.formatMessage({
                                    id: "SITES:SEE_IN_MAPS",
                                })}
                            </Button>
                        )}
                    </Box>
                </Card>
            </Badge>
        </React.Fragment>
    );
};

export default SiteCard;
