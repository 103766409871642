import { Comment } from "@mui/icons-material";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import React, { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { getSiteChecklistById, updateChecklist } from "../../../../../api/site";
import Loading from "../../../../../components/loading/Loading";
import { compare } from "../../../../../utils/compare";
import "./Checklist.scss";

function Checklist({ siteId }) {
    const [content, setContent] = useState();
    const [currentContent, setCurrentContent] = useState(content);
    const [loading, setLoading] = useState(true);
    const intl = useIntl();

    const fetchChecklist = async () => {
        try {
            setLoading(true);
            const checklist = await getSiteChecklistById(siteId);
            setContent(checklist);
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchChecklist();
    }, []);

    useEffect(() => {
        setCurrentContent(content);
    }, [content]);

    const isChanged = useMemo(() => {
        return !compare(content, currentContent);
    }, [content, currentContent]);

    const saveChecklist = async () => {
        try {
            setLoading(true);
            await updateChecklist(siteId, { items: currentContent });
            setContent(currentContent);
            toast.success(
                intl.formatMessage({
                    id: "SITES:CHECKLIST_UPDATED",
                })
            );
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        } finally {
            setLoading(false);
        }
    };

    return loading ? (
        <Loading color="primary" />
    ) : (
        <>
            <List
                sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    padding: 0,
                    flex: 1,
                }}
            >
                {currentContent?.map((element, index) => {
                    return (
                        <ListItem key={index} disablePadding>
                            <ListItemButton
                                role={undefined}
                                onClick={() => {
                                    setCurrentContent((v) =>
                                        v.map((e, i) =>
                                            index === i
                                                ? {
                                                      ...e,
                                                      checked: !e.checked,
                                                  }
                                                : e
                                        )
                                    );
                                }}
                                dense
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={!!element.checked}
                                        tabIndex={-1}
                                        disableRipple
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={element.label}
                                    sx={
                                        element.checked
                                            ? { textDecoration: "line-through" }
                                            : {}
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
            {isChanged && (
                <Box
                    display="flex"
                    justifyContent={"center"}
                    gap={2}
                    marginBottom="20px"
                >
                    <Button
                        type="button"
                        variant="outlined"
                        color="error"
                        onClick={() => {
                            setCurrentContent(content);
                        }}
                        disabled={!isChanged}
                    >
                        <FormattedMessage id="ACTIONS:DISCARD_CHANGES" />
                    </Button>
                    <Button
                        type="button"
                        variant="contained"
                        color="secondary"
                        onClick={saveChecklist}
                        disabled={!isChanged}
                    >
                        <FormattedMessage id="ACTIONS:SAVE_CHANGES" />
                    </Button>
                </Box>
            )}
        </>
    );
}

export default Checklist;
