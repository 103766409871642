import { BackupTable, DynamicForm, LocationOn } from "@mui/icons-material";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListSubheader,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { flushSync } from "react-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { camelToSnakeUpperCase } from "../../../../../utils/general";
import {
    createDynamicFormValue,
    createSiteFieldValue,
} from "../../../../../utils/workflow";
import "./DynamicPicker.scss";

function DynamicPicker({
    open,
    handleClose,
    data = {},
    onConfirm,
    dynamicData,
}) {
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedField, setSelectedField] = useState(null);
    const [selectedForm, setSelectedForm] = useState(null);
    const [selectedFormField, setSelectedFormField] = useState(null);
    const [selectedSiteField, setSelectedSiteField] = useState(null);
    const [selectedSiteSubField, setSelectedSiteSubField] = useState(null);
    const [selectionType, setSelectionType] = useState("RESSOURCES");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const intl = useIntl();

    const siteFields = useMemo(() => {
        return {
            ref: {
                label: intl.formatMessage({ id: "SITES:REF" }),
            },
            name: {
                label: intl.formatMessage({ id: "SITES:NAME" }),
            },
            rdv: {
                label: intl.formatMessage({ id: "SITES:RDV" }),
            },
            client: {
                label: intl.formatMessage({ id: "SITES:CLIENT" }),
                subFields: {
                    name: {
                        label: intl.formatMessage({ id: "CLIENTS:NAME" }),
                    },
                    email: {
                        label: intl.formatMessage({ id: "CLIENTS:EMAIL" }),
                    },
                    phone: {
                        label: intl.formatMessage({ id: "CLIENTS:PHONE" }),
                    },
                },
            },
            clientCoordinator: {
                label: intl.formatMessage({ id: "SITES:CLIENT_COORDINATOR" }),
                subFields: {
                    firstName: {
                        label: intl.formatMessage({
                            id: "USERS:FIRST_NAME",
                        }),
                    },
                    lastName: {
                        label: intl.formatMessage({
                            id: "USERS:LAST_NAME",
                        }),
                    },
                    email: {
                        label: intl.formatMessage({ id: "USERS:EMAIL" }),
                    },
                    phone: {
                        label: intl.formatMessage({ id: "USERS:PHONE" }),
                    },
                },
            },
            address: {
                label: intl.formatMessage({ id: "SITES:ADDRESS" }),
            },
            contactName: {
                label: intl.formatMessage({ id: "SITES:CONTACT_NAME" }),
            },
            contactEmail: {
                label: intl.formatMessage({ id: "SITES:CONTACT_EMAIL" }),
            },
            contactPhone: {
                label: intl.formatMessage({ id: "SITES:CONTACT_PHONE" }),
            },
            internalCoordinators: {
                label: intl.formatMessage({
                    id: "SITES:INTERNAL_COORDINATORS",
                }),
                subFields: {
                    firstName: {
                        label: intl.formatMessage({
                            id: "USERS:FIRST_NAME",
                        }),
                    },
                    lastName: {
                        label: intl.formatMessage({
                            id: "USERS:LAST_NAME",
                        }),
                    },
                    email: {
                        label: intl.formatMessage({ id: "USERS:EMAIL" }),
                    },
                    phone: {
                        label: intl.formatMessage({ id: "USERS:PHONE" }),
                    },
                },
            },
            technicians: {
                label: intl.formatMessage({ id: "SITES:TECHNICIANS" }),
                subFields: {
                    firstName: {
                        label: intl.formatMessage({
                            id: "USERS:FIRST_NAME",
                        }),
                    },
                    lastName: {
                        label: intl.formatMessage({
                            id: "USERS:LAST_NAME",
                        }),
                    },
                    email: {
                        label: intl.formatMessage({ id: "USERS:EMAIL" }),
                    },
                    phone: {
                        label: intl.formatMessage({ id: "USERS:PHONE" }),
                    },
                },
            },
            managers: {
                label: intl.formatMessage({ id: "SITES:MANAGERS" }),
                subFields: {
                    firstName: {
                        label: intl.formatMessage({
                            id: "USERS:FIRST_NAME",
                        }),
                    },
                    lastName: {
                        label: intl.formatMessage({
                            id: "USERS:LAST_NAME",
                        }),
                    },
                    email: {
                        label: intl.formatMessage({ id: "USERS:EMAIL" }),
                    },
                    phone: {
                        label: intl.formatMessage({ id: "USERS:PHONE" }),
                    },
                },
            },
            measurers: {
                label: intl.formatMessage({ id: "SITES:MEASURERS" }),
                subFields: {
                    firstName: {
                        label: intl.formatMessage({
                            id: "USERS:FIRST_NAME",
                        }),
                    },
                    lastName: {
                        label: intl.formatMessage({
                            id: "USERS:LAST_NAME",
                        }),
                    },
                    email: {
                        label: intl.formatMessage({ id: "USERS:EMAIL" }),
                    },
                    phone: {
                        label: intl.formatMessage({ id: "USERS:PHONE" }),
                    },
                },
            },
            bpe: {
                label: intl.formatMessage({ id: "SITES:BPE" }),
            },
            room: {
                label: intl.formatMessage({ id: "SITES:ROOM" }),
            },
            rop: {
                label: intl.formatMessage({ id: "SITES:ROP" }),
            },
            cls: {
                label: intl.formatMessage({ id: "SITES:CLS" }),
            },
            fci: {
                label: intl.formatMessage({ id: "SITES:FCI" }),
            },
        };
    }, [intl]);

    useEffect(() => {
        setSelectedItem(null);
        setSelectedField(null);
    }, [selectedModel]);

    useEffect(() => {
        setSelectedFormField(null);
    }, [selectedForm]);

    useEffect(() => {
        setSelectedModel(null);
        setSelectedItem(null);
        setSelectedField(null);
        setSelectedForm(null);
        setSelectedFormField(null);
        setSelectedSiteField(null);
        setSelectedSiteSubField(null);
    }, [selectionType]);

    return (
        <Dialog
            className="dynamic-picker-container"
            open={open}
            onClose={() => {
                flushSync(() => {
                    setSelectedModel(null);
                    setSelectedItem(null);
                    setSelectedField(null);
                });
                handleClose();
            }}
            fullScreen
            PaperProps={{
                style: {
                    maxWidth: "800px",
                    width: "90vw",
                    maxHeight: "90vh",
                    overflow: "hidden",
                    margin: 0,
                },
            }}
        >
            <DialogTitle color="secondary" textAlign="center">
                <FormattedMessage
                    id={`FIELDS_FORM:DYNAMIC_VALUE_PICKER:${selectionType}`}
                />
                <IconButton
                    onClick={() => {
                        setSelectionType("RESSOURCES");
                    }}
                    color={
                        selectionType === "RESSOURCES" ? "primary" : undefined
                    }
                >
                    <BackupTable />
                </IconButton>
                <IconButton
                    onClick={() => {
                        setSelectionType("FORMS");
                    }}
                    color={selectionType === "FORMS" ? "primary" : undefined}
                >
                    <DynamicForm />
                </IconButton>
                <IconButton
                    onClick={() => {
                        setSelectionType("SITE_INFO");
                    }}
                    color={
                        selectionType === "SITE_INFO" ? "primary" : undefined
                    }
                >
                    <LocationOn />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                >
                    {selectionType === "FORMS" ? (
                        <Grid
                            container
                            height="100%"
                            overflow="auto"
                            spacing={0}
                        >
                            <Grid
                                item
                                xs={12}
                                md={6}
                                maxHeight={isMobile ? "unset" : "100%"}
                                overflow={"auto"}
                            >
                                <List
                                    subheader={
                                        <ListSubheader>
                                            <FormattedMessage id="GLOBAL:FORMS" />
                                        </ListSubheader>
                                    }
                                    disablePadding
                                >
                                    {dynamicData &&
                                        Object.keys(dynamicData).map((key) => (
                                            <ListItem key={key}>
                                                <Button
                                                    variant="contained"
                                                    color={
                                                        selectedForm === key
                                                            ? "secondary"
                                                            : "primary"
                                                    }
                                                    onClick={() => {
                                                        setSelectedForm(key);
                                                    }}
                                                    fullWidth
                                                    sx={{
                                                        textTransform: "unset",
                                                    }}
                                                >
                                                    {dynamicData[key].name ||
                                                        key}
                                                </Button>
                                            </ListItem>
                                        ))}
                                </List>
                            </Grid>
                            {!isMobile && (
                                <Divider orientation="vertical" flexItem />
                            )}
                            <Grid
                                item
                                xs={12}
                                md
                                maxHeight={isMobile ? "unset" : "100%"}
                                overflow={"auto"}
                            >
                                <List
                                    subheader={
                                        <ListSubheader>
                                            <FormattedMessage id="GLOBAL:FIELDS" />
                                        </ListSubheader>
                                    }
                                    disablePadding
                                >
                                    {dynamicData &&
                                        selectedForm &&
                                        Object.keys(
                                            dynamicData[selectedForm].content
                                        ).map((key) => (
                                            <ListItem key={key}>
                                                <Button
                                                    variant="contained"
                                                    color={
                                                        selectedFormField ===
                                                        key
                                                            ? "secondary"
                                                            : "primary"
                                                    }
                                                    onClick={() => {
                                                        setSelectedFormField(
                                                            key
                                                        );
                                                    }}
                                                    fullWidth
                                                    sx={{
                                                        textTransform: "unset",
                                                    }}
                                                >
                                                    {dynamicData[selectedForm]
                                                        .content[key].label ||
                                                        dynamicData[
                                                            selectedForm
                                                        ].content[key].name}
                                                </Button>
                                            </ListItem>
                                        ))}
                                </List>
                            </Grid>
                        </Grid>
                    ) : selectionType === "RESSOURCES" ? (
                        <Grid
                            container
                            height="100%"
                            overflow="auto"
                            spacing={0}
                        >
                            <Grid
                                item
                                xs={12}
                                md={4}
                                maxHeight={isMobile ? "unset" : "100%"}
                                overflow={"auto"}
                            >
                                <List
                                    subheader={
                                        <ListSubheader>
                                            <FormattedMessage id="GLOBAL:RESOURCES" />
                                        </ListSubheader>
                                    }
                                    disablePadding
                                >
                                    {data &&
                                        Object.keys(data).map((key) => (
                                            <ListItem key={key}>
                                                <Button
                                                    variant="contained"
                                                    color={
                                                        selectedModel === key
                                                            ? "secondary"
                                                            : "primary"
                                                    }
                                                    onClick={() => {
                                                        setSelectedModel(key);
                                                    }}
                                                    fullWidth
                                                    sx={{
                                                        textTransform: "unset",
                                                    }}
                                                >
                                                    <FormattedMessage
                                                        id={`MODELS:${camelToSnakeUpperCase(
                                                            key
                                                        )}`}
                                                    />
                                                </Button>
                                            </ListItem>
                                        ))}
                                </List>
                            </Grid>
                            {!isMobile && (
                                <Divider orientation="vertical" flexItem />
                            )}
                            <Grid
                                item
                                xs={12}
                                md={4}
                                maxHeight={isMobile ? "unset" : "100%"}
                                overflow={"auto"}
                            >
                                <List
                                    subheader={
                                        <ListSubheader>
                                            <FormattedMessage id="GLOBAL:DATA" />
                                        </ListSubheader>
                                    }
                                    disablePadding
                                >
                                    {data &&
                                        selectedModel !== null &&
                                        data[selectedModel].data.map(
                                            (element, index) => (
                                                <ListItem key={index}>
                                                    <Button
                                                        variant="contained"
                                                        color={
                                                            selectedItem ===
                                                            index
                                                                ? "secondary"
                                                                : "primary"
                                                        }
                                                        onClick={() => {
                                                            setSelectedItem(
                                                                index
                                                            );
                                                        }}
                                                        fullWidth
                                                        sx={{
                                                            textTransform:
                                                                "capitalize",
                                                        }}
                                                    >
                                                        {
                                                            element[
                                                                data[
                                                                    selectedModel
                                                                ].displayField
                                                            ]
                                                        }
                                                    </Button>
                                                </ListItem>
                                            )
                                        )}
                                </List>
                            </Grid>
                            {!isMobile && (
                                <Divider orientation="vertical" flexItem />
                            )}
                            <Grid
                                item
                                xs={12}
                                md
                                maxHeight={isMobile ? "unset" : "100%"}
                                overflow={"auto"}
                            >
                                <List
                                    subheader={
                                        <ListSubheader>
                                            <FormattedMessage id="GLOBAL:FIELDS" />
                                        </ListSubheader>
                                    }
                                    disablePadding
                                >
                                    {data &&
                                        selectedModel !== null &&
                                        data[selectedModel].fields.map(
                                            (field) => (
                                                <ListItem key={field}>
                                                    <Button
                                                        variant="contained"
                                                        color={
                                                            selectedField ===
                                                            field
                                                                ? "secondary"
                                                                : "primary"
                                                        }
                                                        onClick={() => {
                                                            setSelectedField(
                                                                field
                                                            );
                                                        }}
                                                        fullWidth
                                                        sx={{
                                                            textTransform:
                                                                "unset",
                                                        }}
                                                    >
                                                        {`${intl.formatMessage({
                                                            id: `${camelToSnakeUpperCase(
                                                                selectedModel
                                                            )}:${camelToSnakeUpperCase(
                                                                field
                                                            )}`,
                                                        })}${
                                                            selectedItem ===
                                                            null
                                                                ? ""
                                                                : `: ${data[selectedModel].data[selectedItem][field]}`
                                                        }`}
                                                    </Button>
                                                </ListItem>
                                            )
                                        )}
                                </List>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid
                            container
                            height="100%"
                            overflow="auto"
                            spacing={0}
                        >
                            <Grid
                                item
                                xs={12}
                                md={6}
                                maxHeight={isMobile ? "unset" : "100%"}
                                overflow={"auto"}
                            >
                                <List
                                    subheader={
                                        <ListSubheader>
                                            <FormattedMessage id="GLOBAL:FIELDS" />
                                        </ListSubheader>
                                    }
                                    disablePadding
                                >
                                    {siteFields &&
                                        Object.keys(siteFields).map((key) => (
                                            <ListItem key={key}>
                                                <Button
                                                    variant="contained"
                                                    color={
                                                        selectedSiteField ===
                                                        key
                                                            ? "secondary"
                                                            : "primary"
                                                    }
                                                    onClick={() => {
                                                        if (
                                                            selectedSiteField !==
                                                            key
                                                        ) {
                                                            setSelectedSiteField(
                                                                key
                                                            );
                                                            setSelectedSiteSubField(
                                                                null
                                                            );
                                                        }
                                                    }}
                                                    fullWidth
                                                    sx={{
                                                        textTransform: "unset",
                                                    }}
                                                >
                                                    {siteFields[key].label}
                                                </Button>
                                            </ListItem>
                                        ))}
                                </List>
                            </Grid>
                            {!isMobile && (
                                <Divider orientation="vertical" flexItem />
                            )}
                            <Grid
                                item
                                xs={12}
                                md
                                maxHeight={isMobile ? "unset" : "100%"}
                                overflow={"auto"}
                            >
                                <List
                                    subheader={
                                        <ListSubheader>
                                            <FormattedMessage id="GLOBAL:SUB_FIELDS" />
                                        </ListSubheader>
                                    }
                                    disablePadding
                                >
                                    {selectedSiteField &&
                                        siteFields[selectedSiteField]
                                            .subFields &&
                                        Object.keys(
                                            siteFields[selectedSiteField]
                                                .subFields
                                        ).map((key) => (
                                            <ListItem key={key}>
                                                <Button
                                                    variant="contained"
                                                    color={
                                                        selectedSiteSubField ===
                                                        key
                                                            ? "secondary"
                                                            : "primary"
                                                    }
                                                    onClick={() => {
                                                        setSelectedSiteSubField(
                                                            key
                                                        );
                                                    }}
                                                    fullWidth
                                                    sx={{
                                                        textTransform: "unset",
                                                    }}
                                                >
                                                    {
                                                        siteFields[
                                                            selectedSiteField
                                                        ].subFields[key].label
                                                    }
                                                </Button>
                                            </ListItem>
                                        ))}
                                </List>
                            </Grid>
                        </Grid>
                    )}
                    <Button
                        color="secondary"
                        variant="contained"
                        disabled={
                            selectionType === "FORMS"
                                ? selectedForm === null ||
                                  selectedFormField === null
                                : selectionType === "RESSOURCES"
                                ? selectedModel === null ||
                                  selectedItem === null ||
                                  selectedField === null
                                : selectedSiteField === null ||
                                  (selectedSiteSubField === null &&
                                      !!siteFields[selectedSiteField].subFields)
                        }
                        onClick={() => {
                            switch (selectionType) {
                                case "FORMS":
                                    onConfirm(
                                        createDynamicFormValue(
                                            selectedForm,
                                            dynamicData[selectedForm].content[
                                                selectedFormField
                                            ].name
                                        )
                                    );
                                    break;
                                case "RESSOURCES":
                                    onConfirm(
                                        data[selectedModel].data[selectedItem][
                                            selectedField
                                        ]
                                    );
                                    break;
                                case "SITE_INFO":
                                    onConfirm(
                                        createSiteFieldValue(
                                            selectedSiteField,
                                            selectedSiteSubField
                                        )
                                    );
                                    break;
                            }
                            flushSync(() => {
                                setSelectedModel(null);
                                setSelectedItem(null);
                                setSelectedField(null);
                                setSelectedForm(null);
                                setSelectedFormField(null);
                                setSelectedSiteField(null);
                                setSelectedSiteSubField(null);
                            });
                            handleClose();
                        }}
                    >
                        <FormattedMessage id="GLOBAL:CONFIRM" />
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default DynamicPicker;
