import "./KmlFiles.scss";
import React, { Fragment, useEffect, useState } from "react";
import { Card } from "@mui/material";
import { Add, Delete, Edit, Info } from "@mui/icons-material";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import DataContainer from "../../components/dataContainer/DataContainer";
import {
    CustomNoRowsOverlay,
    CustomLoadingOverlay,
} from "../../utils/gridOverlays";
import { useIntl } from "react-intl";
import AddModal from "../../components/addModal/AddModal";
import { useConfirm } from "material-ui-confirm";
import {
    createKmlFile,
    deleteKmlFile,
    getKmlFiles,
    updateKmlFile,
} from "../../api/kml";
import DataValidationError from "../../errors/DataValidationError";
import { camelToSnakeUpperCase, textToBase64 } from "../../utils/general";
import KmlPreview from "../../components/kmlPreview/KmlPreview";
import useAbility from "../../hooks/useAbility";

const KmlFiles = () => {
    //#region States
    const { can } = useAbility();
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dataRows, setDataRows] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [modalLoading, setModalLoading] = useState(false);
    const [kmlPath, setKmlPath] = useState(null);
    const [selectedKmlId, setSelectedKmlId] = useState(null);
    const selectedKml = dataRows.find((row) => row.id === selectedKmlId);
    //#endregion

    //#region other Hooks
    const intl = useIntl();
    const confirm = useConfirm();
    //#endregion

    //#region useEffect
    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {}, [isModalOpen]);
    //#endregion

    //#region Handlers
    const handleCloseAdd = () => {
        setIsModalOpen(false);
    };
    const handleOpenAdd = () => {
        setIsModalOpen(true);
    };
    const handleCloseUpdate = () => {
        setSelectedKmlId(null);
    };
    const handleOpenUpdate = (user) => {
        setSelectedKmlId(user);
    };
    const getData = async () => {
        setLoading(true);
        try {
            let data = await getKmlFiles();
            setDataRows(
                data.map((row) => ({
                    ...row,
                    ...row.officerInfo,
                    id: row._id,
                }))
            );
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        }
        setLoading(false);
    };

    const addKmlFile = async (data) => {
        try {
            setModalLoading(true);
            await createKmlFile(data);
            toast.success(
                intl.formatMessage({
                    id: "KML_FILES:KML_FILE_ADDED",
                })
            );
            setIsModalOpen(false);
            await getData();
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    if (field.kind === "unique") {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:UNIQUE" },
                                {
                                    field: intl.formatMessage({
                                        id: `KML_FILES:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    } else {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:FORMAT" },
                                {
                                    field: intl.formatMessage({
                                        id: `KML_FILES:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    }
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        } finally {
            setModalLoading(false);
        }
    };

    const updateKml = async (data) => {
        try {
            setModalLoading(true);
            await updateKmlFile(selectedKmlId, data);
            toast.success(
                intl.formatMessage({
                    id: "KML_FILES:KML_FILE_UPDATED",
                })
            );
            setSelectedKmlId(null);
            await getData();
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    if (field.kind === "unique") {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:UNIQUE" },
                                {
                                    field: intl.formatMessage({
                                        id: `KML_FILES:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    } else {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:FORMAT" },
                                {
                                    field: intl.formatMessage({
                                        id: `KML_FILES:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    }
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        } finally {
            setModalLoading(false);
        }
    };

    const deleteSelectedKmlFile = async (params) => {
        try {
            await confirm({
                title: intl.formatMessage({ id: "WARNING" }),
                description: intl.formatMessage(
                    { id: "KML_FILES:KML_FILE_DELETE_CONFIRM" },
                    { name: params.row.name }
                ),
                confirmationText: intl.formatMessage({ id: "GLOBAL:CONFIRM" }),
                cancellationText: intl.formatMessage({ id: "GLOBAL:CANCEL" }),
            });
        } catch (error) {
            return;
        }
        setLoading(true);
        try {
            await deleteKmlFile(params.row.id);
            toast.success(
                intl.formatMessage({
                    id: "KML_FILES:KML_FILE_DELETED",
                })
            );
            await getData();
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        } finally {
            setLoading(false);
        }
    };
    //#endregion

    //#region constants
    const dataColumns = [
        {
            field: "actions",
            type: "actions",
            minWidth: 50,
            maxWidth: 50,
            getActions: (params) => [
                ...(can("PREVIEW", "KML_FILES")
                    ? [
                          <GridActionsCellItem
                              icon={<Info />}
                              onClick={() => {
                                  const token =
                                      window.localStorage.getItem("token") ||
                                      "";
                                  setKmlPath(
                                      `${
                                          process.env.REACT_APP_BASE_URL
                                      }/static/kml/${
                                          params.row.path
                                      }?token=${textToBase64(token)}`
                                  );
                              }}
                              label={intl.formatMessage({
                                  id: "ACTIONS:PREVIEW",
                              })}
                              showInMenu
                          />,
                      ]
                    : []),
                ...(can("UPDATE", "KML_FILES")
                    ? [
                          <GridActionsCellItem
                              icon={<Edit />}
                              onClick={() => {
                                  handleOpenUpdate(params.row.id);
                              }}
                              label={intl.formatMessage({ id: "ACTIONS:EDIT" })}
                              showInMenu
                          />,
                      ]
                    : []),
                ...(can("DELETE", "KML_FILES")
                    ? [
                          <GridActionsCellItem
                              icon={<Delete />}
                              onClick={() => deleteSelectedKmlFile(params)}
                              label={intl.formatMessage({
                                  id: "ACTIONS:DELETE",
                              })}
                              showInMenu
                          />,
                      ]
                    : []),
            ],
        },
        {
            field: "name",
            headerName: intl.formatMessage({ id: "KML_FILES:NAME" }),
            flex: 1,
            minWidth: 160,
        },
        {
            field: "description",
            headerName: intl.formatMessage({
                id: "KML_FILES:DESCRIPTION",
            }),
            flex: 2,
            minWidth: 300,
        },
    ];

    const headerButtons = [
        ...(can("CREATE", "KML_FILES")
            ? [
                  {
                      name: intl.formatMessage({ id: "KML_FILES:CREATE" }),
                      props: {
                          onClick: handleOpenAdd,
                          variant: "contained",
                          startIcon: <Add />,
                      },
                  },
              ]
            : []),
    ];
    const modalFields = [
        {
            name: "name",
            label: intl.formatMessage({ id: "KML_FILES:NAME" }),
            type: "text",
            rules: {
                required: intl.formatMessage({
                    id: "FORM:ERROR_MESSAGES:REQUIRED",
                }),
                pattern: {
                    value: /^.+$/,
                    message: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                    }),
                },
            },
            fullWidth: true,
        },
        {
            name: "description",
            label: intl.formatMessage({ id: "KML_FILES:DESCRIPTION" }),
            type: "text",
            fullWidth: true,
            multiline: true,
        },
        {
            name: "kml",
            label: intl.formatMessage({ id: "KML_FILES:FILE" }),
            type: "file",
            fullWidth: true,
            other: {
                InputLabelProps: {
                    shrink: true,
                },
            },
        },
    ];

    var updateFields = selectedKml
        ? {
              name: selectedKml.name,
              description: selectedKml.description,
          }
        : null;
    //#endregion

    return (
        <Fragment>
            <DataContainer
                buttons={headerButtons}
                onRefresh={() => {
                    getData();
                }}
            >
                {isModalOpen && (
                    <AddModal
                        open={isModalOpen}
                        handleClose={handleCloseAdd}
                        fields={modalFields}
                        title={intl.formatMessage({
                            id: "KML_FILES:CREATE",
                        })}
                        submit={addKmlFile}
                        buttons={[
                            {
                                props: {
                                    type: "submit",
                                    variant: "contained",
                                    color: "secondary",
                                },
                                label: intl.formatMessage({
                                    id: "GLOBAL:SUBMIT",
                                }),
                            },
                        ]}
                        buttonsBoxProps={[]}
                        loading={modalLoading}
                    />
                )}
                {updateFields && (
                    <AddModal
                        open={!!updateFields}
                        handleClose={handleCloseUpdate}
                        fields={modalFields
                            .filter((field) =>
                                Object.keys(updateFields).includes(field.name)
                            )
                            .map((field) => ({
                                ...field,
                                defaultValue: updateFields[field.name],
                            }))}
                        title={intl.formatMessage({ id: "KML_FILES:UPDATE" })}
                        submit={updateKml}
                        buttons={[
                            {
                                props: {
                                    type: "submit",
                                    variant: "contained",
                                    color: "secondary",
                                },
                                label: intl.formatMessage({
                                    id: "GLOBAL:SUBMIT",
                                }),
                            },
                        ]}
                        buttonsBoxProps={[]}
                        loading={modalLoading}
                    />
                )}
                {can("CONSULT", "KML_FILES") ? (
                    <Card sx={{ height: "100%" }}>
                        <DataGrid
                            loading={loading}
                            columns={dataColumns}
                            rows={dataRows}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) =>
                                setPageSize(newPageSize)
                            }
                            rowsPerPageOptions={[10, 20, 30, 50, 100]}
                            components={{
                                Toolbar: GridToolbar,
                                LoadingOverlay: CustomLoadingOverlay,
                                NoRowsOverlay: () =>
                                    CustomNoRowsOverlay(
                                        intl.formatMessage({
                                            id: "KML_FILES:NO_KML_FILES",
                                        })
                                    ),
                                NoResultsOverlay: () =>
                                    CustomNoRowsOverlay(
                                        intl.formatMessage({
                                            id: "KML_FILES:NO_RESULTS",
                                        })
                                    ),
                            }}
                            disableSelectionOnClick
                            onCellDoubleClick={() => {}}
                        />
                    </Card>
                ) : (
                    <>THIS IS A PLACEHOLDER</>
                )}
            </DataContainer>
            {!!kmlPath && (
                <KmlPreview
                    open={!!kmlPath}
                    paths={kmlPath ? [kmlPath] : []}
                    handleClose={() => {
                        setKmlPath(null);
                    }}
                />
            )}
        </Fragment>
    );
};

export default KmlFiles;
