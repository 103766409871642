import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
    Collapse,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import "./Menu.scss";
import SubMenu from "./subMenu/SubMenu";
import useAbility from "../../../../hooks/useAbility";

function Menu({ title, items }) {
    const location = useLocation();
    const { can } = useAbility();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const intl = useIntl();
    const [isOpen, setIsOpen] = useState(true);

    return (
        <List disablePadding component={"div"} className="menu-container">
            <ListItemButton
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
                sx={{ display: { xs: "none", md: "flex" } }}
            >
                <ListItemText
                    className="title"
                    primary={intl.formatMessage({ id: `SIDENAV:${title}` })}
                    disableTypography
                />
                {isMobile ? <></> : isOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={isMobile || isOpen} timeout="auto" unmountOnExit>
                <List disablePadding>
                    {items.map((item, index) =>
                        item.subItems && item.subItems.length !== 0
                            ? (!item.abilities ||
                                  item.abilities.some((el) =>
                                      can(el.actions, el.resource)
                                  ) ||
                                  item.subItems.some(
                                      (subItem) =>
                                          !subItem.abilities ||
                                          subItem.abilities.some((el) =>
                                              can(el.actions, el.resource)
                                          )
                                  )) && (
                                  <SubMenu
                                      key={index}
                                      title={item.text}
                                      items={item.subItems.filter(
                                          (subItem) =>
                                              !subItem.abilities ||
                                              subItem.abilities.some((el) =>
                                                  can(el.actions, el.resource)
                                              )
                                      )}
                                      icon={item.icon}
                                      path={
                                          !item.abilities ||
                                          item.abilities.some((el) =>
                                              can(el.actions, el.resource)
                                          )
                                              ? item.path
                                              : "#"
                                      }
                                  />
                              )
                            : (!item.abilities ||
                                  item.abilities.some((el) =>
                                      can(el.actions, el.resource)
                                  )) && (
                                  <Link
                                      to={item.path}
                                      key={index}
                                      {...(item.disabled
                                          ? { className: "disabled-link" }
                                          : {})}
                                  >
                                      <ListItemButton
                                          sx={
                                              isMobile
                                                  ? {
                                                        padding: 0,
                                                    }
                                                  : {
                                                        pl: 2,
                                                        ml: 2,
                                                        mr: 2,
                                                        ...(location.pathname ===
                                                        item.path
                                                            ? {
                                                                  border: `solid 2px ${theme.palette.secondary.main}`,
                                                                  borderRadius:
                                                                      "10px",
                                                              }
                                                            : {}),
                                                    }
                                          }
                                      >
                                          <ListItemIcon
                                              sx={{
                                                  ...(isMobile
                                                      ? {
                                                            width: 50,
                                                            height: 50,
                                                            maxWidth: 50,
                                                            minWidth: 50,
                                                            display: "flex",
                                                            justifyContent:
                                                                "center",
                                                            alignItems:
                                                                "center",
                                                        }
                                                      : {}),
                                                  ...(location.pathname ===
                                                  item.path
                                                      ? {
                                                            color: theme.palette
                                                                .secondary.main,
                                                        }
                                                      : {
                                                            color: `${theme.palette.tertiary.main}!important`,
                                                        }),
                                              }}
                                          >
                                              {item.icon}
                                          </ListItemIcon>
                                          <ListItemText
                                              className="item-text"
                                              primary={intl.formatMessage({
                                                  id: `SIDENAV:${item.text}`,
                                              })}
                                              sx={{
                                                  display: {
                                                      xs: "none",
                                                      md: "flex",
                                                  },
                                                  ...(location.pathname ===
                                                  item.path
                                                      ? {
                                                            color: `${theme.palette.secondary.main}!important`,
                                                        }
                                                      : {
                                                            color: `${theme.palette.tertiary.main}!important`,
                                                        }),
                                              }}
                                              disableTypography
                                          />
                                      </ListItemButton>
                                  </Link>
                              )
                    )}
                </List>
            </Collapse>
        </List>
    );
}

export default Menu;
