import { Close } from "@mui/icons-material";
import {
    Dialog,
    Grid,
    IconButton,
    Slide,
    Button,
    Typography,
    Box,
    Autocomplete,
    TextField,
    Switch,
    FormControlLabel,
    Divider,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import FileInput from "../fileInput/FileInput";
import ImageInput from "../imageInput/ImageInput";
import Loading from "../loading/Loading";
import LocationPicker from "../locationPicker/LocationPicker";
import "./AddModal.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddModal = ({
    handleClose,
    open,
    submit,
    fields,
    title,
    buttons,
    buttonsBoxProps,
    loading,
}) => {
    const formMethods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: fields.reduce(
            (previous, current) => ({
                ...previous,
                ...(current.type === "location"
                    ? Object.values(current.names).reduce(
                          (prev, curr) => ({
                              ...prev,
                              [curr]: current.defaultValue
                                  ? current.defaultValue[curr] ?? ""
                                  : "",
                          }),
                          {}
                      )
                    : {
                          [current.name]:
                              current.defaultValue ??
                              (current.type === "select"
                                  ? null
                                  : current.type === "autocomplete"
                                  ? []
                                  : ""),
                      }),
            }),
            {}
        ),
    });

    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors },
        getValues,
        watch,
        setValue,
    } = formMethods;

    const fieldsToWatch = useMemo(() => {
        return fields
            ? fields
                  .filter((field) => !!field.onChange)
                  .map((field) => field.name)
            : [];
    }, [fields]);

    const customWatcher = watch(fieldsToWatch);

    const [files, setFiles] = useState({});

    const handleFileChange = (name, file) => {
        setFiles({ ...files, [name]: file });
    };

    useEffect(() => {
        if (customWatcher && fieldsToWatch)
            fieldsToWatch.forEach((fieldName) => {
                let field = fields.find(
                    (element) => element.name === fieldName
                );
                field.onChange(getValues, setValue);
            });
    }, customWatcher);

    return (
        <Dialog
            className="add-modal-container"
            maxWidth={"sm"}
            open={open}
            onClose={() => {
                reset();
                handleClose();
            }}
            TransitionComponent={Transition}
        >
            {loading && (
                <Loading
                    color="secondary"
                    container={{
                        position: "absolute",
                        zIndex: 99,
                        backgroundColor: "#ffffff90",
                    }}
                />
            )}
            <Box className="header">
                <Typography
                    variant="h1"
                    color="secondary"
                    fontSize={22}
                    fontWeight="bold"
                >
                    {title}
                </Typography>
                <IconButton
                    onClick={() => {
                        reset();
                        handleClose();
                    }}
                    aria-label="close"
                >
                    <Close color="secondary" fontSize="medium" />
                </IconButton>
            </Box>
            <FormProvider {...formMethods}>
                <form
                    className="modal-form"
                    onSubmit={handleSubmit((data) => {
                        let newData = {};
                        for (let field of fields) {
                            if (field.type === "select" && data[field.name]) {
                                newData[field.name] = data[field.name].value;
                            } else if (
                                field.type === "autocomplete" &&
                                data[field.name]
                            ) {
                                newData[field.name] = data[field.name].map(
                                    (el) => el.value
                                );
                            }
                        }
                        submit({ ...data, ...newData, ...files });
                    })}
                >
                    <Grid container p={2} spacing={2}>
                        {fields.map((el, index) => {
                            return (
                                <React.Fragment key={`field-${index}`}>
                                    {!!el.group && (
                                        <Grid item xs={12}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography
                                                    variant="h6"
                                                    color="secondary"
                                                >
                                                    {el.group} :
                                                </Typography>
                                                <Box sx={{ flex: 1, pl: 1 }}>
                                                    <Divider />
                                                </Box>
                                            </Box>
                                        </Grid>
                                    )}
                                    <Grid
                                        item
                                        xs={12}
                                        md={12 / (el.fullWidth ? 1 : 2)}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                height: "100%",
                                                width: "100%",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            {el.type === "image" ? (
                                                <ImageInput
                                                    label={`${el.label}${
                                                        el.rules?.required
                                                            ? " *"
                                                            : ""
                                                    }`}
                                                    name={el.name}
                                                    onChange={(file) => {
                                                        handleFileChange(
                                                            el.name,
                                                            file
                                                        );
                                                    }}
                                                    defaultValue={
                                                        el.defaultValue
                                                    }
                                                />
                                            ) : el.type === "file" ? (
                                                <FileInput
                                                    label={`${el.label}${
                                                        el.rules?.required
                                                            ? " *"
                                                            : ""
                                                    }`}
                                                    name={el.name}
                                                    onChange={(file) => {
                                                        handleFileChange(
                                                            el.name,
                                                            file
                                                        );
                                                    }}
                                                    defaultValue={
                                                        el.defaultValue
                                                    }
                                                />
                                            ) : el.type === "select" ? (
                                                <Controller
                                                    size="small"
                                                    control={control}
                                                    rules={el.rules}
                                                    name={el.name}
                                                    render={({ field }) => {
                                                        return (
                                                            <Autocomplete
                                                                size="small"
                                                                {...field}
                                                                multiple={false}
                                                                fullWidth
                                                                options={
                                                                    el.options
                                                                }
                                                                noOptionsText={
                                                                    el.noOptionsText
                                                                        ? el.noOptionsText
                                                                        : ""
                                                                }
                                                                getOptionLabel={(
                                                                    option
                                                                ) =>
                                                                    option
                                                                        ? option.label
                                                                        : ""
                                                                }
                                                                isOptionEqualToValue={(
                                                                    option,
                                                                    value
                                                                ) => {
                                                                    return (
                                                                        option.value ===
                                                                        value.value
                                                                    );
                                                                }}
                                                                onChange={(
                                                                    e,
                                                                    data
                                                                ) =>
                                                                    field.onChange(
                                                                        data
                                                                    )
                                                                }
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        label={`${
                                                                            el.label
                                                                        }${
                                                                            el
                                                                                .rules
                                                                                .required
                                                                                ? " *"
                                                                                : ""
                                                                        }`}
                                                                        {...params}
                                                                        error={
                                                                            errors[
                                                                                el
                                                                                    .name
                                                                            ]
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        helperText={
                                                                            errors[
                                                                                el
                                                                                    .name
                                                                            ]
                                                                                ?.message
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        );
                                                    }}
                                                />
                                            ) : el.type === "autocomplete" ? (
                                                <Controller
                                                    size="small"
                                                    control={control}
                                                    rules={el.rules}
                                                    name={el.name}
                                                    render={({ field }) => {
                                                        return (
                                                            <Autocomplete
                                                                size="small"
                                                                {...field}
                                                                multiple={true}
                                                                fullWidth
                                                                noOptionsText={
                                                                    el.noOptionsText
                                                                        ? el.noOptionsText
                                                                        : ""
                                                                }
                                                                options={
                                                                    el.options
                                                                }
                                                                getOptionLabel={(
                                                                    option
                                                                ) =>
                                                                    option.label
                                                                }
                                                                filterSelectedOptions
                                                                isOptionEqualToValue={(
                                                                    option,
                                                                    value
                                                                ) => {
                                                                    return (
                                                                        option.value ===
                                                                        value.value
                                                                    );
                                                                }}
                                                                onChange={(
                                                                    e,
                                                                    data
                                                                ) =>
                                                                    field.onChange(
                                                                        data
                                                                    )
                                                                }
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        label={`${
                                                                            el.label
                                                                        }${
                                                                            el
                                                                                .rules
                                                                                .required
                                                                                ? " *"
                                                                                : ""
                                                                        }`}
                                                                        {...params}
                                                                        error={
                                                                            errors[
                                                                                el
                                                                                    .name
                                                                            ]
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        helperText={
                                                                            errors[
                                                                                el
                                                                                    .name
                                                                            ]
                                                                                ?.message
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        );
                                                    }}
                                                />
                                            ) : el.type === "location" ? (
                                                <LocationPicker
                                                    selectButtonText={
                                                        el.selectButtonText
                                                    }
                                                    size="small"
                                                    getAddress={() =>
                                                        getValues([
                                                            el.names.number,
                                                            el.names.street,
                                                            el.names.postalCode,
                                                            el.names.city,
                                                            el.names.country,
                                                        ])
                                                            .join(" ")
                                                            .trim()
                                                    }
                                                    getLat={() =>
                                                        getValues(el.names.lat)
                                                    }
                                                    getLng={() =>
                                                        getValues(el.names.lng)
                                                    }
                                                    defaultLocation={
                                                        el.defaultPickLocation
                                                    }
                                                    labels={el.labels}
                                                    names={el.names}
                                                    rules={el.rules}
                                                />
                                            ) : el.type === "switch" ? (
                                                <Controller
                                                    size="small"
                                                    control={control}
                                                    rules={{}}
                                                    name={el.name}
                                                    render={({ field }) => {
                                                        return (
                                                            <FormControlLabel
                                                                size="small"
                                                                name={el.name}
                                                                label={
                                                                    <Typography color="primary">
                                                                        {`${
                                                                            el.label
                                                                        }${
                                                                            el
                                                                                .rules
                                                                                ?.required
                                                                                ? " *"
                                                                                : ""
                                                                        }`}
                                                                    </Typography>
                                                                }
                                                                labelPlacement="start"
                                                                checked={
                                                                    field.value
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    field.onChange(
                                                                        event
                                                                            .target
                                                                            .checked
                                                                    );
                                                                }}
                                                                control={
                                                                    <Switch color="secondary" />
                                                                }
                                                            />
                                                        );
                                                    }}
                                                />
                                            ) : (
                                                <TextField
                                                    size="small"
                                                    type={el.type}
                                                    label={`${el.label}${
                                                        el.rules?.required
                                                            ? " *"
                                                            : ""
                                                    }`}
                                                    fullWidth
                                                    error={
                                                        errors[el.name]
                                                            ? true
                                                            : false
                                                    }
                                                    helperText={
                                                        errors[el.name]?.message
                                                    }
                                                    {...register(
                                                        el.name,
                                                        el.rules
                                                    )}
                                                    multiline={
                                                        el.multiline
                                                            ? true
                                                            : false
                                                    }
                                                    {...el.other}
                                                />
                                            )}
                                        </Box>
                                    </Grid>
                                </React.Fragment>
                            );
                        })}
                    </Grid>
                    {buttons && (
                        <Box
                            display="flex"
                            justifyContent={"center"}
                            gap={2}
                            {...buttonsBoxProps}
                            marginBottom="20px"
                        >
                            {buttons.map((el, key) => {
                                return (
                                    <Button key={`btn-${key}`} {...el.props}>
                                        {el.label}
                                    </Button>
                                );
                            })}
                        </Box>
                    )}
                </form>
            </FormProvider>
        </Dialog>
    );
};

export default AddModal;
