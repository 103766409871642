import {
    AddCircleOutline,
    Cancel,
    Delete,
    DragHandle,
} from "@mui/icons-material";
import {
    Alert,
    AlertTitle,
    Autocomplete,
    Box,
    Button,
    Dialog,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useMemo } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { saveTaskDraft, submitTask } from "../../../../api/task";
import DataValidationError from "../../../../errors/DataValidationError";
import { camelToSnakeUpperCase, intersection } from "../../../../utils/general";
import "./BuildChecklist.scss";

function BuildChecklist({ open, handleClose, data, refreshAll }) {
    const [items, setItems] = useState(data.values.items ?? []);
    const intl = useIntl();
    const node = useMemo(
        () => data.workflowInstance.nodes.find((node) => node.id === data.node),
        [data]
    );

    const {
        register,
        unregister,
        handleSubmit,
        reset,
        control,
        formState: { errors },
        getValues,
        watch,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: { label: "", checked: false },
    });

    const onDragEnd = useCallback(
        ({ destination, source }) => {
            if (
                !destination ||
                destination.droppableId !== "checklist" ||
                source.droppableId !== "checklist"
            ) {
                return;
            }
            if (source.index < destination.index) {
                setItems((v) => [
                    ...v.slice(0, source.index),
                    ...v.slice(source.index + 1, destination.index + 1),
                    v[source.index],
                    ...v.slice(destination.index + 1, v.length),
                ]);
            } else if (source.index > destination.index) {
                setItems((v) => [
                    ...v.slice(0, destination.index),
                    v[source.index],
                    ...v.slice(destination.index, source.index),
                    ...v.slice(source.index + 1, v.length),
                ]);
            }
        },
        [setItems]
    );

    const saveDraft = useCallback(async () => {
        try {
            // setModalLoading(true);
            await saveTaskDraft(data._id, { items });
            refreshAll();
            toast.success(intl.formatMessage({ id: "TASKS:TASK_SAVED" }));
            handleClose();
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    if (field.kind === "unique") {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:UNIQUE" },
                                {
                                    field: intl.formatMessage({
                                        id: `TASKS:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    } else {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:FORMAT" },
                                {
                                    field: intl.formatMessage({
                                        id: `TASKS:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    }
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        } finally {
            // setModalLoading(false);
        }
    }, [intl, items, handleClose, refreshAll]);

    const submit = useCallback(async () => {
        try {
            // setModalLoading(true);
            await submitTask(data._id, { items });
            refreshAll();
            toast.success(intl.formatMessage({ id: "TASKS:TASK_SUBMITTED" }));
            handleClose();
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    if (field.kind === "unique") {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:UNIQUE" },
                                {
                                    field: intl.formatMessage({
                                        id: `TASKS:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    } else {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:FORMAT" },
                                {
                                    field: intl.formatMessage({
                                        id: `TASKS:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    }
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        } finally {
            // setModalLoading(false);
        }
    }, [intl, items, handleClose, refreshAll]);

    return (
        <Dialog open={open} maxWidth={false}>
            <Box
                className="build-checklist-container"
                sx={{ width: { xs: "calc(90vw - 64px)", md: "700px" } }}
            >
                <Box className="header">
                    <Box display="flex" flexDirection="column">
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                        >
                            <Typography
                                variant="h1"
                                color="primary"
                                fontSize={24}
                                fontWeight="bold"
                            >
                                <FormattedMessage id="TASKS:BUILD_CHECKLIST" />
                            </Typography>
                            &nbsp;&nbsp;&nbsp;
                            <Typography
                                variant="h1"
                                color="secondary"
                                fontSize={24}
                                fontWeight="bold"
                            >
                                {node?.data?.name}
                            </Typography>
                        </Box>
                        <Typography
                            color="grey.main"
                            fontSize={16}
                            fontWeight="500"
                        >
                            {node?.data?.description}
                        </Typography>
                    </Box>
                    <IconButton onClick={handleClose}>
                        <Cancel fontSize="large" color="secondary" />
                    </IconButton>
                </Box>
                <Box className="body">
                    <form
                        onSubmit={handleSubmit((data) => {
                            setItems((v) => [...v, data]);
                            reset();
                        })}
                    >
                        <Grid container p={2} spacing={2}>
                            <Grid item xs={12}>
                                <Box sx={{ display: "flex", gap: 2 }}>
                                    <TextField
                                        size="small"
                                        type="text"
                                        label={intl.formatMessage({
                                            id: "TASKS:CHECKLIST_BUILD:ITEM_LABEL",
                                        })}
                                        placeholder={intl.formatMessage({
                                            id: "TASKS:CHECKLIST_BUILD:ITEM_LABEL:PLACEHOLDER",
                                        })}
                                        multiline={false}
                                        fullWidth
                                        error={!!errors["label"]}
                                        helperText={
                                            errors["label"]?.message || ""
                                        }
                                        {...register("label", {
                                            required: intl.formatMessage({
                                                id: "FORM:ERROR_MESSAGES:REQUIRED",
                                            }),
                                            validate: (v) =>
                                                !items.find(
                                                    (item) => v === item.label
                                                ),
                                        })}
                                    />
                                    <IconButton
                                        type="submit"
                                        sx={{
                                            aspectRatio: "1",
                                            height: "100%",
                                        }}
                                    >
                                        <AddCircleOutline color="primary" />
                                    </IconButton>
                                </Box>
                            </Grid>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Grid item xs={12}>
                                    <Droppable droppableId="checklist">
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                            >
                                                <Grid container spacing={2}>
                                                    {items.map(
                                                        (item, index) => (
                                                            <Grid
                                                                item
                                                                key={`item-${index}`}
                                                                xs={12}
                                                            >
                                                                <Draggable
                                                                    draggableId={`${item.label}-${index}`}
                                                                    index={
                                                                        index
                                                                    }
                                                                >
                                                                    {(
                                                                        provided,
                                                                        snapshot
                                                                    ) => (
                                                                        <div
                                                                            ref={
                                                                                provided.innerRef
                                                                            }
                                                                            {...provided.draggableProps}
                                                                        >
                                                                            <Box
                                                                                sx={{
                                                                                    display:
                                                                                        "flex",
                                                                                    gap: 2,
                                                                                }}
                                                                            >
                                                                                <IconButton
                                                                                    type="button"
                                                                                    sx={{
                                                                                        aspectRatio:
                                                                                            "1",
                                                                                        height: "100%",
                                                                                    }}
                                                                                    {...provided.dragHandleProps}
                                                                                >
                                                                                    <DragHandle color="primary" />
                                                                                </IconButton>
                                                                                <TextField
                                                                                    size="small"
                                                                                    type="text"
                                                                                    multiline={
                                                                                        false
                                                                                    }
                                                                                    fullWidth
                                                                                    value={
                                                                                        item.label
                                                                                    }
                                                                                />
                                                                                <IconButton
                                                                                    type="button"
                                                                                    sx={{
                                                                                        aspectRatio:
                                                                                            "1",
                                                                                        height: "100%",
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setItems(
                                                                                            (
                                                                                                v
                                                                                            ) =>
                                                                                                v.filter(
                                                                                                    (
                                                                                                        element
                                                                                                    ) =>
                                                                                                        element.label !==
                                                                                                        item.label
                                                                                                )
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <Delete color="error" />
                                                                                </IconButton>
                                                                            </Box>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            </Grid>
                                                        )
                                                    )}
                                                </Grid>
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </Grid>
                            </DragDropContext>
                        </Grid>
                        <Box
                            display="flex"
                            justifyContent={"center"}
                            gap={2}
                            marginBottom="20px"
                        >
                            <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={saveDraft}
                            >
                                <FormattedMessage id="TASKS:SAVE" />
                            </Button>
                            <Button
                                type="button"
                                variant="contained"
                                color="secondary"
                                onClick={submit}
                            >
                                <FormattedMessage id="TASKS:SUBMIT" />
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Box>
        </Dialog>
    );
}

export default BuildChecklist;
