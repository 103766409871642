import React, { useState, useEffect, useRef } from "react";
import "./MentionInput.scss";
import {
    Box,
    FormControl,
    FormHelperText,
    InputLabel,
    Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import useActiveElement from "../../../../../hooks/useActiveElement";
import { useMemo } from "react";
import Tribute from "tributejs";
import { useCallback } from "react";
import usePrevious from "../../../../../hooks/usePrevious";
import {
    generateCommentHTML,
    parseCommentHTML,
} from "../../../../../utils/comment";

function MentionInput({ name, rules, control, label, error }) {
    const intl = useIntl();
    const activeElement = useActiveElement();
    const [inputNode, setInputNode] = useState(null);

    const isInputActive = inputNode && inputNode === activeElement;

    const mentions = useMemo(
        () => [
            {
                value: intl.formatMessage({
                    id: "FIELDS_FORM:COMMENT:TECHNICIANS",
                }),
                key: "TECHNICIANS",
            },
            {
                value: intl.formatMessage({
                    id: "FIELDS_FORM:COMMENT:MEASURERS",
                }),
                key: "MEASURERS",
            },
            {
                value: intl.formatMessage({
                    id: "FIELDS_FORM:COMMENT:MANAGERS",
                }),
                key: "MANAGERS",
            },
            {
                value: intl.formatMessage({
                    id: "FIELDS_FORM:COMMENT:INTERNAL_COORDINATORS",
                }),
                key: "INTERNAL_COORDINATORS",
            },
            {
                value: intl.formatMessage({
                    id: "FIELDS_FORM:COMMENT:CLIENT_COORDINATOR",
                }),
                key: "CLIENT_COORDINATOR",
            },
        ],
        [intl]
    );

    const tribute = useMemo(
        () =>
            new Tribute({
                values: mentions,
                selectTemplate: (item) =>
                    `<span id="${item.original.key}" contenteditable=false class="selected-mention">@${item.original.value}</span>`,
                menuItemTemplate: (item) => item.original.value,
                lookup: (item) => {
                    return item.value;
                },
            }),
        [mentions]
    );

    const previousTribute = usePrevious(tribute);

    const mentionRef = useCallback(
        (node) => {
            setInputNode(node);
            if (node !== null) {
                previousTribute?.detach(node);
                tribute.attach(node);
            }
        },
        [tribute]
    );

    return (
        <Controller
            size="small"
            control={control}
            rules={rules}
            name={name}
            render={({ field, fieldState }) => {
                return (
                    <FormControl
                        className="mention-container"
                        size="small"
                        fullWidth
                    >
                        <InputLabel
                            shrink={isInputActive || field.value.length > 0}
                            children={label}
                            sx={{
                                ...(error
                                    ? {}
                                    : {
                                          color: "#ffffffdd!important",
                                      }),
                            }}
                            required={!!rules?.required}
                            error={!!error}
                        />
                        <Box className="mentions-input-box">
                            <div
                                contentEditable
                                suppressContentEditableWarning
                                ref={mentionRef}
                                className="mention-text-container"
                                dangerouslySetInnerHTML={{
                                    __html: generateCommentHTML(
                                        field.value,
                                        (v) =>
                                            intl.formatMessage({
                                                id: `FIELDS_FORM:COMMENT:${v}`,
                                            })
                                    ),
                                }}
                                onKeyDown={(event) => {
                                    if (
                                        event.key === "Enter" &&
                                        !event.shiftKey
                                    ) {
                                        event.preventDefault();
                                    }
                                }}
                                onBlur={(event) => {
                                    field.onChange(
                                        parseCommentHTML(
                                            event.currentTarget.innerHTML
                                        )
                                    );
                                }}
                            ></div>
                            <fieldset
                                {...(error
                                    ? {
                                          className: "fieldset-error",
                                      }
                                    : {})}
                                aria-hidden="true"
                            >
                                <legend
                                    className={
                                        isInputActive || field.value.length > 0
                                            ? "legend-shrinked"
                                            : "legend-not-shrinked"
                                    }
                                >
                                    <span>
                                        {label}
                                        {rules?.required ? " *" : ""}
                                    </span>
                                </legend>
                            </fieldset>
                        </Box>
                        {!!error && (
                            <FormHelperText error={true}>
                                {intl.formatMessage({
                                    id: error?.message,
                                })}
                            </FormHelperText>
                        )}
                    </FormControl>
                );
            }}
        />
    );
}

export default MentionInput;
