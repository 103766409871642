import DataValidationError from "../errors/DataValidationError";
import { axiosInstance } from "../utils/axios";
import { parse, stringify } from "../utils/general";

export const getWorkflows = async (filter = {}) => {
    try {
        let response = await axiosInstance.get("/workflows", { params: filter });
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
        return parse(response.data.data);
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const getWorkflowById = async (id) => {
    try {
        let response = await axiosInstance.get(`/workflows/${id}`);
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
        return parse(response.data.data);
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const duplicateWorkflowById = async (id) => {
    try {
        let response = await axiosInstance.post(`/workflows/${id}/duplicate`);
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
        return response.data;
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const createWorkflow = async (data) => {
    try {
        let response = await axiosInstance.post("/workflows", {
            data: stringify(data),
        });
        if (response.status !== 201) {
            throw new Error("ERROR:OTHER");
        }
        return response.data;
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const updateWorkflow = async (_id, data) => {
    try {
        let response = await axiosInstance.put(`/workflows/${_id}`, {
            data: stringify(data),
        });
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const renameWorkflow = async (_id, data) => {
    try {
        let response = await axiosInstance.put(`/workflows/${_id}/rename`, data);
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const publishWorkflow = async (_id) => {
    try {
        let response = await axiosInstance.put(`/workflows/${_id}/publish`);
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};

export const deleteWorkflow = async (_id) => {
    try {
        let response = await axiosInstance.delete(`/workflows/${_id}`);
        if (response.status !== 200) {
            throw new Error("ERROR:OTHER");
        }
    } catch (error) {
        if (!error.response && error.request) {
            throw new Error("ERROR:CONNECTION");
        } else {
            switch (error.response.status) {
                case 400:
                    switch (error.response.data.error) {
                        case "DATA_VALIDATION":
                            throw new DataValidationError(
                                error.response.data.fields
                            );
                        default:
                            throw new Error("ERROR:OTHER");
                    }
                case 401:
                    throw new Error("ERROR:UNAUTHORIZED");
                case 403:
                    throw new Error("ERROR:FORBIDDEN");
                case 404:
                    throw new Error("ERROR:NOTFOUND");
                case 500:
                    throw new Error("ERROR:SERVER");
                default:
                    throw new Error("ERROR:OTHER");
            }
        }
    }
};
