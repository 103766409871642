import React, { useState, useEffect } from "react";
import "./AttachmentsInput.scss";
import { Dropzone } from "@mantine/dropzone";
import {
    AddCircleOutlineOutlined,
    HighlightOffTwoTone,
    Refresh,
} from "@mui/icons-material";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useIntl } from "react-intl";
import {
    camelToSnakeUpperCase,
    textToBase64,
} from "../../../../../utils/general";
import {
    deleteAttachmentById,
    getSiteAttachmentsById,
    updateAttachmentName,
    updateAttachmentVisibility,
    uploadSiteAttachments,
} from "../../../../../api/site";
import { toast } from "react-toastify";
import DataValidationError from "../../../../../errors/DataValidationError";
import AttachmentPreview from "./attachmentPreview/AttachmentPreview";
import { defaultStyles, FileIcon } from "react-file-icon";
import useAbility from "../../../../../hooks/useAbility";

function AttachmentsInput({ _id }) {
    const [attachments, setAttachments] = useState([]);
    const [loadingAttachments, setLoadingAttachments] = useState(true);
    const [selectedAttachment, setSelectedAttachment] = useState(-1);
    const [fetchError, setFetchError] = useState(false);
    const { can } = useAbility();
    const intl = useIntl();
    const theme = useTheme();

    useEffect(() => {
        fetchAttachments();
    }, []);

    const fetchAttachments = async () => {
        try {
            setAttachments([]);
            setFetchError(false);
            setLoadingAttachments(true);
            let attachments = await getSiteAttachmentsById(_id);
            const token = window.localStorage.getItem("token") || "";
            let newAttachments = attachments.map((attachment) => ({
                ...attachment,
                fullPath: `${
                    process.env.REACT_APP_BASE_URL
                }/static/sites/${_id}/attachments/${
                    attachment.path
                }?token=${textToBase64(token)}`,
            }));
            setAttachments(newAttachments);
        } catch (error) {
            setFetchError(true);
            toast.error(intl.formatMessage({ id: error.message }));
        } finally {
            setLoadingAttachments(false);
        }
    };

    const saveAttachmentName = async (path, name) => {
        try {
            await updateAttachmentName(_id, { path, name });
            setAttachments(
                attachments.map((attachment) =>
                    attachment.path === path
                        ? { ...attachment, name }
                        : attachment
                )
            );
            return true;
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    toast.error(
                        intl.formatMessage(
                            { id: "ERROR:FORMAT" },
                            {
                                field: intl.formatMessage({
                                    id: `SITES:${camelToSnakeUpperCase(
                                        field.path
                                    )}`,
                                }),
                                value: field.value,
                            }
                        )
                    );
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
            return false;
        }
    };

    const saveAttachmentVisibility = async (path, visible) => {
        try {
            await updateAttachmentVisibility(_id, { path, visible });
            setAttachments(
                attachments.map((attachment) =>
                    attachment.path === path
                        ? { ...attachment, visible }
                        : attachment
                )
            );
            return true;
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    toast.error(
                        intl.formatMessage(
                            { id: "ERROR:FORMAT" },
                            {
                                field: intl.formatMessage({
                                    id: `SITES:${camelToSnakeUpperCase(
                                        field.path
                                    )}`,
                                }),
                                value: field.value,
                            }
                        )
                    );
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
            return false;
        }
    };

    const removeAttachment = async (path) => {
        try {
            await deleteAttachmentById(_id, textToBase64(path));
            setAttachments(attachments.filter((iamge) => iamge.path !== path));
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        }
    };

    const handleAttachmentsDrop = async (imgs) => {
        try {
            setLoadingAttachments(true);
            let newAttachments = await uploadSiteAttachments(_id, imgs);
            const token = window.localStorage.getItem("token") || "";
            setAttachments(
                newAttachments.map((attachment) => ({
                    ...attachment,
                    fullPath: `${
                        process.env.REACT_APP_BASE_URL
                    }/static/sites/${_id}/attachments/${
                        attachment.path
                    }?token=${textToBase64(token)}`,
                }))
            );
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    if (field.kind === "unique") {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:UNIQUE" },
                                {
                                    field: intl.formatMessage({
                                        id: `SITES:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    } else {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:FORMAT" },
                                {
                                    field: intl.formatMessage({
                                        id: `SITES:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    }
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        } finally {
            setLoadingAttachments(false);
        }
    };

    return (
        <Box
            width="100%"
            boxSizing="border-box"
            padding="10px 10px 0 10px"
            className="attachments-input-container"
        >
            <Dropzone
                onDrop={handleAttachmentsDrop}
                padding="0"
                style={{
                    width: "100%",
                    ...(fetchError
                        ? { borderColor: theme.palette.error.main }
                        : attachments.length === 0
                        ? {}
                        : {
                              borderColor: theme.palette.primary.main,
                          }),
                }}
                loading={loadingAttachments}
                disabled={
                    fetchError ||
                    !(
                        can("UPLOAD_ATTACHMENTS", "ALL_SITES") ||
                        can("UPLOAD_ATTACHMENTS", "RELATED_SITES") ||
                        can("UPLOAD_ATTACHMENTS", "CREATED_SITES")
                    )
                }
            >
                {(status) =>
                    fetchError ? (
                        <Box
                            className="dropzone-content"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <IconButton
                                color="error"
                                size="large"
                                onClick={fetchAttachments}
                            >
                                <Refresh fontSize="large" />
                            </IconButton>
                        </Box>
                    ) : attachments.length === 0 ? (
                        <Box
                            className="dropzone-content"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography color="primary" fontSize="20px">
                                {intl.formatMessage({
                                    id: "GLOBAL:DRAG_ZONE_MESSAGE",
                                })}
                            </Typography>
                        </Box>
                    ) : (
                        <Box className="dropzone-content">
                            <Box
                                display="flex"
                                alignItems="center"
                                gap={1}
                                height="90px"
                                justifyContent="flex-start"
                                flexDirection="row"
                                padding="0 0 0 20px"
                                maxWidth="calc(100% - 100px)"
                                boxSizing="border-box"
                                margin="5px 0"
                                style={{
                                    overflowX: "auto",
                                }}
                            >
                                {(can("VALIDATE_ATTACHMENTS", "ALL_SITES") ||
                                can("VALIDATE_ATTACHMENTS", "RELATED_SITES") ||
                                can("VALIDATE_ATTACHMENTS", "CREATED_SITES")
                                    ? attachments
                                    : attachments.filter(
                                          (attachment) => attachment.visible
                                      )
                                ).map((attachment, index) => {
                                    let [ext, ...more] = attachment.path
                                        .split(".")
                                        .reverse();
                                    return (
                                        <Box
                                            key={index}
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            title={attachment.name}
                                            width="60px"
                                            gap={1}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setSelectedAttachment(index);
                                            }}
                                            sx={{
                                                opacity: attachment.visible
                                                    ? 1
                                                    : 0.4,
                                            }}
                                        >
                                            <Box
                                                display="flex"
                                                position="relative"
                                                overflow="visible"
                                                width="25px"
                                                minWidth="35px"
                                            >
                                                <IconButton
                                                    style={{
                                                        width: "25px",
                                                        height: "35px",
                                                        padding: 0,
                                                    }}
                                                >
                                                    <FileIcon
                                                        extension={ext}
                                                        {...defaultStyles[ext]}
                                                    />
                                                </IconButton>
                                                {(can(
                                                    "DELETE_ATTACHMENTS",
                                                    "ALL_SITES"
                                                ) ||
                                                    can(
                                                        "DELETE_ATTACHMENTS",
                                                        "RELATED_SITES"
                                                    ) ||
                                                    can(
                                                        "DELETE_ATTACHMENTS",
                                                        "CREATED_SITES"
                                                    )) && (
                                                    <IconButton
                                                        style={{
                                                            padding: 0,
                                                            position:
                                                                "absolute",
                                                            color: "red",
                                                            top: "-8px",
                                                            left: "-8px",
                                                        }}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            removeAttachment(
                                                                attachment.path
                                                            );
                                                        }}
                                                    >
                                                        <HighlightOffTwoTone fontSize="small" />
                                                    </IconButton>
                                                )}
                                            </Box>
                                            <Typography
                                                component="span"
                                                textOverflow="ellipsis"
                                                width="100%"
                                                maxHeight="13px"
                                                color="primary"
                                                fontSize="13px"
                                                lineHeight="13px"
                                                overflow="hidden"
                                                letterSpacing={0.1}
                                                textAlign="center"
                                            >
                                                {attachment.name}
                                            </Typography>
                                        </Box>
                                    );
                                })}
                            </Box>
                            {(can("UPLOAD_ATTACHMENTS", "ALL_SITES") ||
                                can("UPLOAD_ATTACHMENTS", "RELATED_SITES") ||
                                can("UPLOAD_ATTACHMENTS", "CREATED_SITES")) && (
                                <IconButton
                                    color="primary"
                                    sx={{
                                        height: "calc(100% - 40px)",
                                        width: "unset",
                                        aspectRatio: "1",
                                        padding: 0,
                                        margin: "20px",
                                        boxSizing: "border-box",
                                    }}
                                >
                                    <AddCircleOutlineOutlined fontSize="large" />
                                </IconButton>
                            )}
                        </Box>
                    )
                }
            </Dropzone>
            {selectedAttachment >= 0 &&
                selectedAttachment < attachments.length && (
                    <AttachmentPreview
                        attachments={attachments}
                        selectedAttachment={selectedAttachment}
                        initialActiveDocument={{
                            ...attachments[selectedAttachment],
                            uri: attachments[selectedAttachment].fullPath,
                        }}
                        close={() => {
                            setSelectedAttachment(-1);
                        }}
                        previous={
                            selectedAttachment > 0
                                ? (previousDocument) => {
                                      setSelectedAttachment(
                                          selectedAttachment - 1
                                      );
                                      previousDocument();
                                  }
                                : null
                        }
                        next={
                            selectedAttachment < attachments.length - 1
                                ? (nextDocument) => {
                                      setSelectedAttachment(
                                          selectedAttachment + 1
                                      );
                                      nextDocument();
                                  }
                                : null
                        }
                        saveName={saveAttachmentName}
                        saveVisibility={saveAttachmentVisibility}
                    />
                )}
        </Box>
    );
}

export default AttachmentsInput;
