import { Cancel, ExpandMore } from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Badge,
    Box,
    Checkbox,
    Chip,
    Dialog,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Stack,
    Tab,
    Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { stringAvatar } from "../../../../utils/avatar";
import Comments from "./comments/Comments";
import AttachmentsInput from "./attachmentsInput/AttachmentsInput";
import ImagesInput from "./imagesInput/ImagesInput";
import "./SiteDetails.scss";
import useAbility from "../../../../hooks/useAbility";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import useNotifications from "../../../../hooks/useNotifications";
import { useSearchParams } from "react-router-dom";
import Checklist from "./checklist/Checklist";

function SiteDetails({ open, handleClose, data }) {
    const intl = useIntl();
    const { can } = useAbility();
    const { notifications } = useNotifications();
    const [searchParams, setSearchParams] = useSearchParams();

    const unseenCommentsCount = useMemo(
        () =>
            notifications.filter(
                (notif) =>
                    !notif.seen &&
                    ["COMMENT", "COMMENT_MENTION"].includes(notif.kind) &&
                    notif.data?.site === data._id
            ).length,
        [notifications, data]
    );

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={false}>
            <Box className="site-details-container">
                <Box className="header">
                    <Box display="flex" flexDirection="column">
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                        >
                            <Typography
                                variant="h1"
                                color="primary"
                                fontSize={24}
                                fontWeight="bold"
                            >
                                <FormattedMessage id="SITES:SITE" />
                            </Typography>
                            &nbsp;
                            <Typography
                                variant="h1"
                                color="secondary"
                                fontSize={24}
                                fontWeight="bold"
                            >
                                {data.name}
                            </Typography>
                            &nbsp; &nbsp;
                            <Chip
                                size="small"
                                label={intl.formatMessage({
                                    id: `SITES:${
                                        data.workflowInstance?.state ||
                                        "NOT_STARTED"
                                    }`,
                                })}
                                color={`${(
                                    data.workflowInstance?.state ||
                                    "NOT_STARTED"
                                ).toLowerCase()}`}
                                variant="outlined"
                            />
                            {data.workflowInstance?.state === "RUNNING" && (
                                <React.Fragment>
                                    {data.workflowInstance?.nodes
                                        .filter(
                                            (node) =>
                                                node.isActive && !!node.step
                                        )
                                        .map((node) => (
                                            <Chip
                                                key={node.id}
                                                size="small"
                                                label={node.step.name}
                                                style={{
                                                    color: node.step.color,
                                                    borderColor:
                                                        node.step.color,
                                                    backgroundColor: "white",
                                                    marginLeft: "5px",
                                                }}
                                                variant="outlined"
                                            />
                                        ))}
                                </React.Fragment>
                            )}
                        </Box>
                        <Typography
                            color="grey.main"
                            fontSize={16}
                            fontWeight="500"
                        >
                            <FormattedMessage id="SITES:REF" />
                            &nbsp;#{data.ref}
                        </Typography>
                    </Box>
                    <IconButton onClick={handleClose}>
                        <Cancel fontSize="large" color="quaternary" />
                    </IconButton>
                </Box>
                <Box className="body">
                    <TabContext value={searchParams.get("tab") ?? "info"}>
                        <TabList
                            onChange={(event, value) => {
                                const entries = searchParams.entries();
                                const result = {};
                                for (let [key, value] of entries) {
                                    result[key] = value;
                                }
                                result["tab"] = value;
                                setSearchParams(result);
                            }}
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            <Tab value={"info"} label="Informations" />
                            <Tab value={"users"} label="Utilisateurs" />
                            <Tab value={"files"} label="Fichiers" />
                            <Tab value={"map"} label="Carte" />
                            <Tab value={"checklist"} label="Checklist" />
                            <Tab
                                value={"comments"}
                                label={
                                    <Badge
                                        badgeContent={unseenCommentsCount}
                                        color={"error"}
                                    >
                                        Commentaires
                                    </Badge>
                                }
                            />
                        </TabList>
                        <Box className="tabs-content">
                            <TabPanel value={"info"}>
                                <Grid container spacing={2} className="info">
                                    <Grid item xs={4}>
                                        <Box className="item-container">
                                            <Typography
                                                color="grey.main"
                                                fontSize={16}
                                            >
                                                <FormattedMessage id="SITES:CREATED_BY" />
                                                &nbsp;:
                                            </Typography>
                                            <Typography
                                                color="primary"
                                                fontSize={16}
                                                fontWeight={600}
                                            >
                                                {data.createdBy.username}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box className="item-container">
                                            <Typography
                                                color="grey.main"
                                                fontSize={16}
                                            >
                                                <FormattedMessage id="SITES:RDV" />
                                                &nbsp;:
                                            </Typography>
                                            <Typography
                                                color="primary"
                                                fontSize={16}
                                                fontWeight={600}
                                            >
                                                {new Date(
                                                    data.rdv
                                                ).toLocaleString("en-GB")}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box className="item-container">
                                            <Typography
                                                color="grey.main"
                                                fontSize={16}
                                            >
                                                <FormattedMessage id="SITES:CONTRACT_DATE" />
                                                &nbsp;:
                                            </Typography>
                                            <Typography
                                                color="primary"
                                                fontSize={16}
                                                fontWeight={600}
                                            >
                                                {new Date(
                                                    data.contractDate
                                                ).toLocaleDateString("en-GB")}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box className="item-container">
                                            <Typography
                                                color="grey.main"
                                                fontSize={16}
                                            >
                                                <FormattedMessage id="SITES:ORDER_DATE" />
                                                &nbsp;:
                                            </Typography>
                                            <Typography
                                                color="primary"
                                                fontSize={16}
                                                fontWeight={600}
                                            >
                                                {new Date(
                                                    data.orderDate
                                                ).toLocaleDateString("en-GB")}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box className="item-container">
                                            <Typography
                                                color="grey.main"
                                                fontSize={16}
                                            >
                                                <FormattedMessage id="SITES:CLIENT" />
                                                &nbsp;:
                                            </Typography>
                                            <Typography
                                                color="primary"
                                                fontSize={16}
                                                fontWeight={600}
                                            >
                                                {data.client.name}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box className="item-container">
                                            <Typography
                                                color="grey.main"
                                                fontSize={16}
                                            >
                                                <FormattedMessage id="SITES:DEBIT" />
                                                &nbsp;:
                                            </Typography>
                                            <Typography
                                                color="primary"
                                                fontSize={16}
                                                fontWeight={600}
                                            >
                                                {data.debit}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box className="item-container">
                                            <Typography
                                                color="grey.main"
                                                fontSize={16}
                                            >
                                                <FormattedMessage id="SITES:ADDRESS" />
                                                &nbsp;:
                                            </Typography>
                                            <Typography
                                                color="primary"
                                                fontSize={16}
                                                fontWeight={600}
                                            >
                                                {data.address}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box className="item-container">
                                            <Typography
                                                color="grey.main"
                                                fontSize={16}
                                            >
                                                <FormattedMessage id="SITES:CONTACT_NAME" />
                                                &nbsp;:
                                            </Typography>
                                            <Typography
                                                color="primary"
                                                fontSize={16}
                                                fontWeight={600}
                                            >
                                                {data.contactName}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box className="item-container">
                                            <Typography
                                                color="grey.main"
                                                fontSize={16}
                                            >
                                                <FormattedMessage id="SITES:CONTACT_PHONE" />
                                                &nbsp;:
                                            </Typography>
                                            <Typography
                                                color="primary"
                                                fontSize={16}
                                                fontWeight={600}
                                            >
                                                {data.contactPhone}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box className="item-container">
                                            <Typography
                                                color="grey.main"
                                                fontSize={16}
                                            >
                                                <FormattedMessage id="SITES:CONTACT_EMAIL" />
                                                &nbsp;:
                                            </Typography>
                                            <Typography
                                                color="primary"
                                                fontSize={16}
                                                fontWeight={600}
                                            >
                                                {data.contactEmail}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box className="item-container">
                                            <Typography
                                                color="grey.main"
                                                fontSize={16}
                                            >
                                                <FormattedMessage id="SITES:BPE" />
                                                &nbsp;:
                                            </Typography>
                                            <Typography
                                                color="primary"
                                                fontSize={16}
                                                fontWeight={600}
                                            >
                                                {data.bpe}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box className="item-container">
                                            <Typography
                                                color="grey.main"
                                                fontSize={16}
                                            >
                                                <FormattedMessage id="SITES:ROOM" />
                                                &nbsp;:
                                            </Typography>
                                            <Typography
                                                color="primary"
                                                fontSize={16}
                                                fontWeight={600}
                                            >
                                                {data.room}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box className="item-container">
                                            <Typography
                                                color="grey.main"
                                                fontSize={16}
                                            >
                                                <FormattedMessage id="SITES:ROP" />
                                                &nbsp;:
                                            </Typography>
                                            <Typography
                                                color="primary"
                                                fontSize={16}
                                                fontWeight={600}
                                            >
                                                {data.rop}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box className="item-container">
                                            <Typography
                                                color="grey.main"
                                                fontSize={16}
                                            >
                                                <FormattedMessage id="SITES:CLS" />
                                                &nbsp;:
                                            </Typography>
                                            <Typography
                                                color="primary"
                                                fontSize={16}
                                                fontWeight={600}
                                            >
                                                {data.cls}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box className="item-container">
                                            <Typography
                                                color="grey.main"
                                                fontSize={16}
                                            >
                                                <FormattedMessage id="SITES:FCI" />
                                                &nbsp;:
                                            </Typography>
                                            <Typography
                                                color="primary"
                                                fontSize={16}
                                                fontWeight={600}
                                            >
                                                {data.fci}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={"users"}>
                                <Grid container spacing={2} className="info">
                                    <Grid item xs={12}>
                                        <Box className="item-container">
                                            <Typography
                                                color="grey.main"
                                                fontSize={16}
                                            >
                                                <FormattedMessage id="SITES:CLIENT_COORDINATOR" />
                                                &nbsp;:
                                            </Typography>
                                            <Stack direction="row" spacing={1}>
                                                <Chip
                                                    avatar={
                                                        data.clientCoordinator
                                                            .account.officerInfo
                                                            .image ? (
                                                            <Avatar
                                                                alt={
                                                                    data
                                                                        .clientCoordinator
                                                                        .account
                                                                        .username
                                                                }
                                                                src={`data:image;base64,${data.clientCoordinator.account.officerInfo.image}`}
                                                            />
                                                        ) : (
                                                            <Avatar
                                                                {...stringAvatar(
                                                                    `${data.clientCoordinator.account.officerInfo.firstName} ${data.clientCoordinator.account.officerInfo.lastName}`,
                                                                    "white!important"
                                                                )}
                                                            />
                                                        )
                                                    }
                                                    label={`${data.clientCoordinator.account.officerInfo.firstName} ${data.clientCoordinator.account.officerInfo.lastName}`}
                                                    variant="outlined"
                                                    color="secondary"
                                                />
                                            </Stack>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box className="item-container">
                                            <Typography
                                                color="grey.main"
                                                fontSize={16}
                                            >
                                                <FormattedMessage id="SITES:INTERNAL_COORDINATORS" />
                                                &nbsp;:
                                            </Typography>
                                            <Stack direction="row" spacing={1}>
                                                {data.internalCoordinators.map(
                                                    (
                                                        internalCoordinator,
                                                        index
                                                    ) => (
                                                        <Chip
                                                            key={index}
                                                            avatar={
                                                                internalCoordinator
                                                                    .officerInfo
                                                                    .image ? (
                                                                    <Avatar
                                                                        alt={
                                                                            internalCoordinator.username
                                                                        }
                                                                        src={`data:image;base64,${internalCoordinator.officerInfo.image}`}
                                                                    />
                                                                ) : (
                                                                    <Avatar
                                                                        {...stringAvatar(
                                                                            `${internalCoordinator.officerInfo.firstName} ${internalCoordinator.officerInfo.lastName}`,
                                                                            "white!important"
                                                                        )}
                                                                    />
                                                                )
                                                            }
                                                            label={`${internalCoordinator.officerInfo.firstName} ${internalCoordinator.officerInfo.lastName}`}
                                                            variant="outlined"
                                                            color="secondary"
                                                        />
                                                    )
                                                )}
                                            </Stack>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box className="item-container">
                                            <Typography
                                                color="grey.main"
                                                fontSize={16}
                                            >
                                                <FormattedMessage id="SITES:TECHNICIANS" />
                                                &nbsp;:
                                            </Typography>
                                            <Stack direction="row" spacing={1}>
                                                {data.technicians.map(
                                                    (technician, index) => (
                                                        <Chip
                                                            key={index}
                                                            avatar={
                                                                technician
                                                                    .officerInfo
                                                                    .image ? (
                                                                    <Avatar
                                                                        alt={
                                                                            technician.username
                                                                        }
                                                                        src={`data:image;base64,${technician.officerInfo.image}`}
                                                                    />
                                                                ) : (
                                                                    <Avatar
                                                                        {...stringAvatar(
                                                                            `${technician.officerInfo.firstName} ${technician.officerInfo.lastName}`,
                                                                            "white!important"
                                                                        )}
                                                                    />
                                                                )
                                                            }
                                                            label={`${technician.officerInfo.firstName} ${technician.officerInfo.lastName}`}
                                                            variant="outlined"
                                                            color="secondary"
                                                        />
                                                    )
                                                )}
                                            </Stack>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box className="item-container">
                                            <Typography
                                                color="grey.main"
                                                fontSize={16}
                                            >
                                                <FormattedMessage id="SITES:MEASURERS" />
                                                &nbsp;:
                                            </Typography>
                                            <Stack direction="row" spacing={1}>
                                                {data.measurers.map(
                                                    (measurer, index) => (
                                                        <Chip
                                                            key={index}
                                                            avatar={
                                                                measurer
                                                                    .officerInfo
                                                                    .image ? (
                                                                    <Avatar
                                                                        alt={
                                                                            measurer.username
                                                                        }
                                                                        src={`data:image;base64,${measurer.officerInfo.image}`}
                                                                    />
                                                                ) : (
                                                                    <Avatar
                                                                        {...stringAvatar(
                                                                            `${measurer.officerInfo.firstName} ${measurer.officerInfo.lastName}`,
                                                                            "white!important"
                                                                        )}
                                                                    />
                                                                )
                                                            }
                                                            label={`${measurer.officerInfo.firstName} ${measurer.officerInfo.lastName}`}
                                                            variant="outlined"
                                                            color="secondary"
                                                        />
                                                    )
                                                )}
                                            </Stack>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box className="item-container">
                                            <Typography
                                                color="grey.main"
                                                fontSize={16}
                                            >
                                                <FormattedMessage id="SITES:MANAGERS" />
                                                &nbsp;:
                                            </Typography>
                                            <Stack direction="row" spacing={1}>
                                                {data.managers.map(
                                                    (manager, index) => (
                                                        <Chip
                                                            key={index}
                                                            avatar={
                                                                manager
                                                                    .officerInfo
                                                                    .image ? (
                                                                    <Avatar
                                                                        alt={
                                                                            manager.username
                                                                        }
                                                                        src={`data:image;base64,${manager.officerInfo.image}`}
                                                                    />
                                                                ) : (
                                                                    <Avatar
                                                                        {...stringAvatar(
                                                                            `${manager.officerInfo.firstName} ${manager.officerInfo.lastName}`,
                                                                            "white!important"
                                                                        )}
                                                                    />
                                                                )
                                                            }
                                                            label={`${manager.officerInfo.firstName} ${manager.officerInfo.lastName}`}
                                                            variant="outlined"
                                                            color="secondary"
                                                        />
                                                    )
                                                )}
                                            </Stack>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={"files"}>
                                <Grid container spacing={2} className="info">
                                    <Grid item xs={12}>
                                        <Typography
                                            color="grey.main"
                                            fontSize={16}
                                        >
                                            <FormattedMessage id="SITES:IMAGES" />
                                            &nbsp;:
                                        </Typography>
                                        <ImagesInput _id={data._id} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            color="grey.main"
                                            fontSize={16}
                                        >
                                            <FormattedMessage id="SITES:ATTACHMENTS" />
                                            &nbsp;:
                                        </Typography>
                                        <AttachmentsInput _id={data._id} />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={"map"}>
                                <Box
                                    width="100%"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    marginBottom={"20px"}
                                >
                                    <iframe
                                        width="600"
                                        height="475"
                                        style={{ border: 0 }}
                                        loading="lazy"
                                        allowFullScreen
                                        referrerPolicy="no-referrer-when-downgrade"
                                        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAkBhTU6Tc8FNdu64ZRG4rPm2bin7H7OOI&q=${data.latitude},${data.longitude}`}
                                    ></iframe>
                                </Box>
                            </TabPanel>
                            <TabPanel
                                value={"checklist"}
                                sx={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Checklist
                                    content={data.checklist}
                                    siteId={data._id}
                                />
                            </TabPanel>
                            <TabPanel value={"comments"}>
                                <Comments
                                    _id={data._id}
                                    users={[
                                        data.clientCoordinator.account,
                                        ...data.internalCoordinators,
                                        ...data.managers,
                                        ...data.technicians,
                                        ...data.measurers,
                                    ].reduce(
                                        (previous, current) => [
                                            ...previous,
                                            ...(previous.find(
                                                (el) => el._id === current._id
                                            )
                                                ? []
                                                : [
                                                      {
                                                          ...current,
                                                          ...current.officerInfo,
                                                          _id: current._id,
                                                      },
                                                  ]),
                                        ],
                                        []
                                    )}
                                />
                            </TabPanel>
                        </Box>
                    </TabContext>
                </Box>
            </Box>
        </Dialog>
    );
}

export default SiteDetails;
