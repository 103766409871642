import React from "react";
import "./FormContent.scss";
import { Droppable } from "react-beautiful-dnd";
import FormField from "./formField/FormField";
import useFormBuilderContext from "../../../../../../hooks/useFormBuilderContext";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

function FormContent() {
    const {
        formFields: fields,
        selectedField,
        setSelectedField,
    } = useFormBuilderContext();

    const {
        register,
        unregister,
        handleSubmit,
        reset,
        control,
        formState: { errors },
        getValues,
        watch,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: fields.reduce(
            (previous, current) => ({
                ...previous,
                [current.name]:
                    current.type === "select"
                        ? null
                        : current.type === "multiSelect"
                        ? []
                        : "",
            }),
            {}
        ),
    });

    return (
        <Droppable droppableId="form">
            {(provided) => (
                <div
                    className="form-content-container"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    onClick={() => {
                        setSelectedField(null);
                    }}
                >
                    {fields.map((field, index) => (
                        <FormField
                            key={field.id}
                            index={index}
                            register={register}
                            control={control}
                            error={errors[field.name]}
                            unregister={unregister}
                        />
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
}

export default FormContent;
