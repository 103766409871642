import { AddCircleOutline, Delete } from "@mui/icons-material";
import { Grid, IconButton, TextField } from "@mui/material";
import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useIntl } from "react-intl";
import "./FieldOptionsProperty.scss";

function FieldOptionsProperty({ control, name, errors }) {
    const intl = useIntl();
    const { fields, append, remove } = useFieldArray({
        control,
        name: name,
    });

    return (
        <Grid container p={0} spacing={1}>
            {fields.map((field, index) => (
                <Grid
                    key={`${field.id}-${index}`}
                    item
                    xs={12}
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <Grid container p={0} spacing={1}>
                        <Grid item xs={6} key="value">
                            <Controller
                                size="small"
                                control={control}
                                name={`${name}.${index}.value`}
                                rules={{
                                    required: intl.formatMessage({
                                        id: "FORM:ERROR_MESSAGES:REQUIRED",
                                    }),
                                }}
                                render={({ field }) => (
                                    <TextField
                                        size="small"
                                        type="text"
                                        label={intl.formatMessage({
                                            id: "FORM_BUILDER:PROPERTIES:OPTIONS:VALUE",
                                        })}
                                        fullWidth
                                        error={
                                            !!(
                                                errors[name] &&
                                                errors[name][index] &&
                                                errors[name][index]["value"]
                                            )
                                        }
                                        helperText={
                                            errors[name] &&
                                            errors[name][index] &&
                                            errors[name][index]["value"]
                                                ? errors[name][index]["value"]
                                                      ?.message
                                                : ""
                                        }
                                        multiline={false}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} key="label">
                            <Controller
                                size="small"
                                control={control}
                                name={`${name}.${index}.label`}
                                rules={{
                                    required: intl.formatMessage({
                                        id: "FORM:ERROR_MESSAGES:REQUIRED",
                                    }),
                                }}
                                render={({ field }) => (
                                    <TextField
                                        size="small"
                                        type="text"
                                        label={intl.formatMessage({
                                            id: "FORM_BUILDER:PROPERTIES:OPTIONS:LABEL",
                                        })}
                                        fullWidth
                                        error={
                                            !!(
                                                errors[name] &&
                                                errors[name][index] &&
                                                errors[name][index]["label"]
                                            )
                                        }
                                        helperText={
                                            errors[name] &&
                                            errors[name][index] &&
                                            errors[name][index]["label"]
                                                ? errors[name][index]["label"]
                                                      ?.message
                                                : ""
                                        }
                                        multiline={false}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <IconButton
                        onClick={() => {
                            remove(index);
                        }}
                    >
                        <Delete fontSize="small" color="primary" />
                    </IconButton>
                </Grid>
            ))}
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <IconButton
                    onClick={() => {
                        append({ label: "", value: "" });
                    }}
                >
                    <AddCircleOutline color="primary" />
                </IconButton>
            </Grid>
        </Grid>
    );
}

export default FieldOptionsProperty;
