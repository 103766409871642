import { Box, Button, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import usePrevious from "../../../../hooks/usePrevious";
import useWorkflowContext from "../../../../hooks/useWorkflowContext";
import "./ConditionBuilder.scss";
import ConditionBuilderDialog from "./conditionBuilderDialog/ConditionBuilderDialog";

function ConditionBuilder({ conditionContent, save, dynamicData }) {
    const [isOpen, setIsOpen] = useState(false);
    const previousIsOpen = usePrevious(isOpen);
    const { blockDeleteIncr, blockDeleteDecr } = useWorkflowContext();
    const intl = useIntl();

    useEffect(() => {
        if (previousIsOpen && !isOpen) {
            blockDeleteDecr();
        } else if (!previousIsOpen && isOpen) {
            blockDeleteIncr();
        }
    }, [isOpen]);

    return (
        <Box width={"100%"} className="condition-builder-container">
            {isOpen && (
                <ConditionBuilderDialog
                    save={save}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    conditionContent={conditionContent}
                    dynamicData={dynamicData}
                />
            )}
            <Box display="flex" flexDirection="row" alignItems={"top"}>
                <Grid container p={2} spacing={2}>
                    <Grid item xs={12}>
                        <Box
                            height="40px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Button
                                size="large"
                                color="white"
                                className="pick-button"
                                onClick={() => {
                                    setIsOpen(true);
                                }}
                            >
                                <FormattedMessage id="CONDITION_BUILDER:BUILDER" />
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default ConditionBuilder;
