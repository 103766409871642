import { defaultOperators } from "react-querybuilder";

const fieldTypes = {
    text: {
        operators: defaultOperators.filter((operator) =>
            [
                "=",
                "!=",
                "contains",
                "doesNotContain",
                "beginsWith",
                "doesNotBeginWith",
                "endsWith",
                "doesNotEndWith",
                "in",
                "notIn",
                "null",
                "notNull",
            ].includes(operator.name)
        ),
        valueEditorType: "text",
        valueSources: ["field", "value"],
    },
    number: {
        operators: defaultOperators.filter((operator) =>
            [
                "=",
                "!=",
                ">",
                ">=",
                "<",
                "<=",
                "in",
                "notIn",
                "null",
                "notNull",
            ].includes(operator.name)
        ),
        valueEditorType: "text",
        inputType: "number",
        valueSources: ["field", "value"],
    },
    decimalNumber: {
        operators: defaultOperators.filter((operator) =>
            [
                "=",
                "!=",
                ">",
                ">=",
                "<",
                "<=",
                "in",
                "notIn",
                "null",
                "notNull",
            ].includes(operator.name)
        ),
        valueEditorType: "text",
        inputType: "number",
        valueSources: ["field", "value"],
    },
    date: {
        operators: defaultOperators.filter((operator) =>
            [
                "=",
                "!=",
                ">",
                ">=",
                "<",
                "<=",
                "in",
                "notIn",
                "null",
                "notNull",
            ].includes(operator.name)
        ),
        valueEditorType: "text",
        inputType: "date",
        valueSources: ["field", "value"],
    },
    time: {
        operators: defaultOperators.filter((operator) =>
            [
                "=",
                "!=",
                ">",
                ">=",
                "<",
                "<=",
                "in",
                "notIn",
                "null",
                "notNull",
            ].includes(operator.name)
        ),
        valueEditorType: "text",
        inputType: "time",
        valueSources: ["field", "value"],
    },
    datetime: {
        operators: defaultOperators.filter((operator) =>
            [
                "=",
                "!=",
                ">",
                ">=",
                "<",
                "<=",
                "in",
                "notIn",
                "null",
                "notNull",
            ].includes(operator.name)
        ),
        valueEditorType: "text",
        valueEditorType: "text",
        inputType: "datetime-local",
        valueSources: ["field", "value"],
    },
    switch: {
        operators: defaultOperators.filter((operator) =>
            ["=", "!=", "null", "notNull"].includes(operator.name)
        ),
        valueEditorType: "checkbox",
        valueSources: ["field", "value"],
        defaultValue: false,
    },
    select: {
        operators: defaultOperators.filter((operator) =>
            [
                "=",
                "!=",
                "contains",
                "doesNotContain",
                "beginsWith",
                "doesNotBeginWith",
                "endsWith",
                "doesNotEndWith",
                "in",
                "notIn",
                "null",
                "notNull",
            ].includes(operator.name)
        ),
        valueEditorType: "select",
        valueSources: ["field", "value"],
    },
    multiselect: {
        operators: defaultOperators.filter((operator) =>
            [
                "=",
                "!=",
                "contains",
                "doesNotContain",
                "null",
                "notNull",
            ].includes(operator.name)
        ),
        valueEditorType: "multiselect",
        valueSources: ["field", "value"],
    },
};

export default fieldTypes;
