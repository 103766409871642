import {
    Cancel,
    NavigateBefore,
    NavigateNext,
    VisibilityOffTwoTone,
    VisibilityTwoTone,
} from "@mui/icons-material";
import { Box, IconButton, InputBase } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useIntl } from "react-intl";
import useAbility from "../../../../../../hooks/useAbility";
import "./ImagePreview.scss";

function ImagePreview({
    image,
    next,
    previous,
    close,
    saveName,
    saveVisibility,
}) {
    const [name, setName] = useState(image.name);
    const [error, setError] = useState(false);
    const { can } = useAbility();

    const intl = useIntl();

    useEffect(() => {
        setName(image.name);
    }, [image]);

    return (
        <Box className="image-preview-container">
            <IconButton className="close-button" onClick={close}>
                <Cancel fontSize="large" color="white" />
            </IconButton>
            <Box className="image-container">
                <Box className="image-name-container">
                    <InputBase
                        size="small"
                        value={name}
                        required
                        onChange={(event) => {
                            if (
                                can("RENAME_IMAGES", "ALL_SITES") ||
                                can("RENAME_IMAGES", "RELATED_SITES") ||
                                can("RENAME_IMAGES", "CREATED_SITES")
                            ) {
                                setName(event.target.value);
                            }
                        }}
                        onBlur={async (event) => {
                            if (
                                can("RENAME_IMAGES", "ALL_SITES") ||
                                can("RENAME_IMAGES", "RELATED_SITES") ||
                                can("RENAME_IMAGES", "CREATED_SITES")
                            ) {
                                let result = await saveName(
                                    image.path,
                                    event.target.value
                                );
                                if (result) {
                                    setError(false);
                                } else {
                                    setError(true);
                                }
                            }
                        }}
                        placeholder={intl.formatMessage({
                            id: "SITES:IMAGE_NAME_PLACEHOLDER",
                        })}
                        inputProps={{ className: "name" }}
                        fullWidth
                    ></InputBase>
                </Box>
                <img src={image.fullPath} />
                {(can("VALIDATE_IMAGES", "ALL_SITES") ||
                    can("VALIDATE_IMAGES", "RELATED_SITES") ||
                    can("VALIDATE_IMAGES", "CREATED_SITES")) && (
                    <IconButton
                        className="visibility-button"
                        color={!image.visible ? "error" : "success"}
                        onClick={async (event) => {
                            event.stopPropagation();
                            await saveVisibility(image.path, !image.visible);
                        }}
                    >
                        {!image.visible ? (
                            <VisibilityOffTwoTone fontSize="inherit" />
                        ) : (
                            <VisibilityTwoTone fontSize="inherit" />
                        )}
                    </IconButton>
                )}
            </Box>
            <IconButton
                className={`previous-button${previous ? "" : " disabled"}`}
                onClick={previous}
                disabled={!previous}
            >
                <NavigateBefore fontSize="inherit" color="white" />
            </IconButton>
            <IconButton
                className={`next-button${next ? "" : " disabled"}`}
                onClick={next}
                disabled={!next}
            >
                <NavigateNext fontSize="inherit" color="white" />
            </IconButton>
        </Box>
    );
}

export default ImagePreview;
