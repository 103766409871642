import { CameraAlt } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { Dropzone, DropzoneStatus, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import "./ImageInput.scss";

function ImageInput({
    name,
    defaultValue,
    onChange,
    label,
    defaultBackgroundColor,
}) {
    const [value, setValue] = useState(`data:image;base64,${defaultValue}`);

    const handleImageChange = (files) => {
        const newfile = files[0];
        const fileReader = new FileReader();
        fileReader.onload = async () => {
            setValue(fileReader.result);
            onChange(newfile);
        };
        fileReader.readAsDataURL(newfile);
    };

    return (
        <div className="image-input-container">
            <Dropzone
                onDrop={handleImageChange}
                onReject={(files) => console.log("rejected files", files)}
                accept={IMAGE_MIME_TYPE}
                multiple={false}
                padding="10px"
                name={name}
            >
                {(status) => (
                    <>
                        <div
                            className="image"
                            style={{
                                backgroundImage: `url(${value})`,
                                ...(defaultBackgroundColor
                                    ? {
                                          backgroundColor:
                                              defaultBackgroundColor,
                                      }
                                    : {}),
                            }}
                        >
                            <div className="icon-button">
                                <IconButton>
                                    <CameraAlt />
                                </IconButton>
                            </div>
                        </div>
                    </>
                )}
            </Dropzone>
            <span className="label">{label}</span>
        </div>
    );
}

export default ImageInput;
