import { useMemo } from "react";
import { useCallback } from "react";
import { createContext, useState } from "react";
import { useIntl } from "react-intl";
import AddModal from "../components/addModal/AddModal";

export const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const intl = useIntl();

    const onSubmit = useCallback(() => {}, [intl]);

    const fields = useMemo(
        () => [
            {
                name: "logo",
                label: intl.formatMessage({ id: "SETTINGS:LOGO" }),
                type: "image",
                fullWidth: true,
            },
            {
                name: "companyName",
                label: intl.formatMessage({ id: "SETTINGS:COMPANY_NAME" }),
                type: "text",
                rules: {
                    required: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:REQUIRED",
                    }),
                },
                fullWidth: false,
            },
            {
                name: "contactEmail",
                label: intl.formatMessage({ id: "SETTINGS:CONTACT_EMAIL" }),
                type: "text",
                rules: {
                    required: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:REQUIRED",
                    }),
                },
                fullWidth: false,
            },
            {
                name: "siretNumber",
                label: intl.formatMessage({ id: "SETTINGS:SIRET_NUMBER" }),
                type: "text",
                rules: {
                    required: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:REQUIRED",
                    }),
                },
                fullWidth: false,
            },
            {
                name: "tvaNumber",
                label: intl.formatMessage({ id: "SETTINGS:TVA_NUMBER" }),
                type: "text",
                rules: {
                    required: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:REQUIRED",
                    }),
                },
                fullWidth: false,
            },
            {
                name: "legalStatus",
                label: intl.formatMessage({ id: "SETTINGS:LEGAL_STATUS" }),
                type: "text",
                rules: {
                    required: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:REQUIRED",
                    }),
                },
                fullWidth: false,
            },
            {
                name: "rcs",
                label: intl.formatMessage({ id: "SETTINGS:RCS" }),
                type: "text",
                rules: {
                    required: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:REQUIRED",
                    }),
                },
                fullWidth: false,
            },
            {
                name: "defaultTva",
                label: intl.formatMessage({ id: "SETTINGS:DEFAULT_TVA" }),
                type: "text",
                rules: {
                    required: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:REQUIRED",
                    }),
                },
                fullWidth: false,
            },
            {
                name: "country",
                label: intl.formatMessage({ id: "SETTINGS:COUNTRY" }),
                type: "text",
                rules: {
                    required: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:REQUIRED",
                    }),
                },
                fullWidth: false,
            },
            {
                name: "address",
                group: intl.formatMessage({ id: "SETTINGS:GROUPS:ADDRESS" }),
                label: intl.formatMessage({ id: "SETTINGS:ADDRESS" }),
                type: "location",
                fullWidth: true,
                selectButtonText: intl.formatMessage({
                    id: "SETTINGS:SELECT_LOCATION",
                }),
                defaultPickLocation: {
                    lat: "48.8566",
                    lng: "2.3522",
                },
                labels: {
                    lat: intl.formatMessage({ id: "SETTINGS:LATITUDE" }),
                    lng: intl.formatMessage({ id: "SETTINGS:LONGITUDE" }),
                    number: intl.formatMessage({ id: "SETTINGS:NUMBER" }),
                    street: intl.formatMessage({ id: "SETTINGS:STREET" }),
                    city: intl.formatMessage({ id: "SETTINGS:CITY" }),
                    postalCode: intl.formatMessage({
                        id: "SETTINGS:POSTAL_CODE",
                    }),
                    country: intl.formatMessage({ id: "SETTINGS:COUNTRY" }),
                    furtherInformation: intl.formatMessage({
                        id: "SETTINGS:FURTHER_INFORMATION",
                    }),
                },
                names: {
                    lat: "address.latitude",
                    lng: "address.longitude",
                    number: "address.number",
                    street: "address.street",
                    city: "address.city",
                    postalCode: "address.postalCode",
                    country: "address.country",
                    furtherInformation: "address.furtherInformation",
                },
                defaultValue: { "address.country": "France" },
                rules: {
                    lat: {
                        required: intl.formatMessage({
                            id: "FORM:ERROR_MESSAGES:REQUIRED",
                        }),
                        pattern: {
                            value: /^(\+|-)?(?:90(?:(?:\.0{1,})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,})?))$/,
                            message: intl.formatMessage({
                                id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                            }),
                        },
                    },
                    lng: {
                        required: intl.formatMessage({
                            id: "FORM:ERROR_MESSAGES:REQUIRED",
                        }),
                        pattern: {
                            value: /^(\+|-)?(?:180(?:(?:\.0{1,})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,})?))$/,
                            message: intl.formatMessage({
                                id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                            }),
                        },
                    },
                    number: {
                        pattern: {
                            value: /^\d{1,}$/,
                            message: intl.formatMessage({
                                id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                            }),
                        },
                    },
                    street: {
                        required: intl.formatMessage({
                            id: "FORM:ERROR_MESSAGES:REQUIRED",
                        }),
                    },
                    city: {
                        required: intl.formatMessage({
                            id: "FORM:ERROR_MESSAGES:REQUIRED",
                        }),
                    },
                    postalCode: {
                        pattern: {
                            value: /^\d{1,}$/,
                            message: intl.formatMessage({
                                id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                            }),
                        },
                    },
                    country: {
                        required: intl.formatMessage({
                            id: "FORM:ERROR_MESSAGES:REQUIRED",
                        }),
                    },
                    furtherInformation: {},
                },
            },
            {
                name: "firstName",
                group: intl.formatMessage({
                    id: "SETTINGS:GROUPS:INTERLOCUTOR",
                }),
                label: intl.formatMessage({ id: "SETTINGS:FIRST_NAME" }),
                type: "text",
                rules: {
                    required: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:REQUIRED",
                    }),
                },
                fullWidth: false,
            },
            {
                name: "lastName",
                label: intl.formatMessage({ id: "SETTINGS:LAST_NAME" }),
                type: "text",
                rules: {
                    required: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:REQUIRED",
                    }),
                },
                fullWidth: false,
            },
            {
                name: "email",
                label: intl.formatMessage({ id: "SETTINGS:EMAIL" }),
                type: "text",
                rules: {
                    required: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:REQUIRED",
                    }),
                },
                fullWidth: true,
            },
            {
                name: "mobile",
                label: intl.formatMessage({ id: "SETTINGS:MOBILE" }),
                type: "text",
                rules: {
                    required: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:REQUIRED",
                    }),
                },
                fullWidth: false,
            },
            {
                name: "phone",
                label: intl.formatMessage({ id: "SETTINGS:PHONE" }),
                type: "text",
                rules: {
                    required: intl.formatMessage({
                        id: "FORM:ERROR_MESSAGES:REQUIRED",
                    }),
                },
                fullWidth: false,
            },
        ],
        [intl]
    );

    return (
        <SettingsContext.Provider
            value={{
                isOpen,
                setIsOpen,
            }}
        >
            {children}
            <AddModal
                open={isOpen}
                handleClose={() => {
                    setIsOpen(false);
                }}
                fields={fields}
                title={intl.formatMessage({ id: "SETTINGS:SETTINGS" })}
                submit={onSubmit}
                buttons={[
                    {
                        props: {
                            type: "submit",
                            variant: "contained",
                            color: "secondary",
                        },
                        label: intl.formatMessage({ id: "GLOBAL:SAVE" }),
                    },
                ]}
                buttonsBoxProps={[]}
                loading={isLoading}
            />
        </SettingsContext.Provider>
    );
};
