import axios from "axios";

var axiosInstance;

let generateAxiosInstance = () => {
    axiosInstance = axios.create({
        // baseURL: `${window.env.REACT_APP_BASE_URL}/api`,
        baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
        headers: {
            "Content-Type": "application/json",
        },
    });
    axiosInstance.interceptors.request.use((req) => {
        const token = window.localStorage.getItem("token") || "";
        req.headers.Authorization = token;
        return req;
    });
};

export { axiosInstance, generateAxiosInstance };
