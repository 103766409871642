import React from "react";
import { Handle } from "reactflow";
import useVerifyConnection from "../../../../../hooks/useVerifyConnection";
import "./ConditionNode.scss";

function ConditionNode({ selected }) {
    const verifyConnection = useVerifyConnection();

    return (
        <>
            <Handle
                type="source"
                position="top"
                isValidConnection={verifyConnection}
                className="condition-top-handle"
                id="POSITIVE"
            />
            <Handle
                type="source"
                position="bottom"
                isValidConnection={verifyConnection}
                className="condition-bottom-handle"
                id="NEGATIVE"
            />
            <div
                className={`condition-node-container${
                    selected ? " selected" : ""
                }`}
            >
                <div className="condition-content">?</div>
            </div>
            <Handle
                type="target"
                position="left"
                isValidConnection={verifyConnection}
            />
        </>
    );
}

export default ConditionNode;
