import { Cancel } from "@mui/icons-material";
import {
    Alert,
    AlertTitle,
    Autocomplete,
    Box,
    Button,
    Dialog,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { saveTaskDraft, submitTask } from "../../../../api/task";
import DataValidationError from "../../../../errors/DataValidationError";
import { camelToSnakeUpperCase, intersection } from "../../../../utils/general";
import FilesInput from "./filesInput/FilesInput";
import "./FillForm.scss";

function FillForm({ open, handleClose, data, refreshAll }) {
    const intl = useIntl();

    const rules = useMemo(
        () => ["required", "minLength", "maxLength", "min", "max"],
        []
    );
    const node = data.workflowInstance.nodes.find(
        (node) => node.id === data.node
    );
    const {
        register,
        unregister,
        handleSubmit,
        reset,
        control,
        formState: { errors },
        getValues,
        watch,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: node.data.fields.reduce(
            (previous, current) => ({
                ...previous,
                [current.name]:
                    data.values[current.name] ??
                    (current.type === "select"
                        ? null
                        : current.type === "multiSelect"
                        ? []
                        : ""),
            }),
            {}
        ),
    });

    const renderField = (field) => {
        let error = errors[field.name];
        switch (field.type) {
            case "staticText":
                return (
                    <Alert severity="info">
                        <AlertTitle>{field.label}</AlertTitle>
                        {field.value}
                    </Alert>
                );
            case "text":
                return (
                    <TextField
                        size="small"
                        type="text"
                        label={field.label}
                        placeholder={field.placeholder}
                        multiline={!!field.multiline}
                        fullWidth
                        error={!!error}
                        helperText={error?.message || ""}
                        {...register(
                            field.name,
                            intersection(rules, Object.keys(field)).reduce(
                                (previous, current) => ({
                                    ...previous,
                                    [current]: {
                                        value: field[current] || undefined,
                                        message: intl.formatMessage(
                                            {
                                                id: `FORM:ERROR_MESSAGES:${camelToSnakeUpperCase(
                                                    current
                                                )}`,
                                            },
                                            { value: field[current] }
                                        ),
                                    },
                                }),
                                {}
                            )
                        )}
                    />
                );
            case "number":
                return (
                    <TextField
                        size="small"
                        type="number"
                        label={field.label}
                        placeholder={field.placeholder}
                        fullWidth
                        error={!!error}
                        helperText={error?.message || ""}
                        {...register(
                            field.name,
                            intersection(rules, Object.keys(field)).reduce(
                                (previous, current) => ({
                                    ...previous,
                                    [current]: {
                                        value: field[current] || undefined,
                                        message: intl.formatMessage(
                                            {
                                                id: `FORM:ERROR_MESSAGES:${camelToSnakeUpperCase(
                                                    current
                                                )}`,
                                            },
                                            { value: field[current] }
                                        ),
                                    },
                                }),
                                {
                                    pattern: {
                                        value: /^\d*$/,
                                        message: intl.formatMessage({
                                            id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                                        }),
                                    },
                                }
                            )
                        )}
                    />
                );
            case "decimalNumber":
                return (
                    <TextField
                        size="small"
                        type="number"
                        label={field.label}
                        placeholder={field.placeholder}
                        fullWidth
                        error={!!error}
                        helperText={error?.message || ""}
                        {...register(
                            field.name,
                            intersection(rules, Object.keys(field)).reduce(
                                (previous, current) => ({
                                    ...previous,
                                    [current]: {
                                        value: field[current] || undefined,
                                        message: intl.formatMessage(
                                            {
                                                id: `FORM:ERROR_MESSAGES:${camelToSnakeUpperCase(
                                                    current
                                                )}`,
                                            },
                                            { value: field[current] }
                                        ),
                                    },
                                }),
                                {
                                    pattern: {
                                        value: /^(\d*)|(\d+(\.\d+)?)/,
                                        message: intl.formatMessage({
                                            id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                                        }),
                                    },
                                }
                            )
                        )}
                        inputProps={{ step: 0.1 }}
                    />
                );
            case "date":
                return (
                    <TextField
                        size="small"
                        type="date"
                        label={field.label}
                        placeholder={field.placeholder}
                        fullWidth
                        error={!!error}
                        helperText={error?.message || ""}
                        {...register(
                            field.name,
                            intersection(rules, Object.keys(field)).reduce(
                                (previous, current) => ({
                                    ...previous,
                                    [current]: {
                                        value: field[current] || undefined,
                                        message: intl.formatMessage(
                                            {
                                                id: `FORM:ERROR_MESSAGES:${camelToSnakeUpperCase(
                                                    current
                                                )}`,
                                            },
                                            {
                                                value: new Date(
                                                    field[current]
                                                ).toLocaleDateString("en-GB"),
                                            }
                                        ),
                                    },
                                }),
                                {}
                            )
                        )}
                        InputLabelProps={{ shrink: true }}
                    />
                );
            case "time":
                return (
                    <TextField
                        size="small"
                        type="time"
                        label={field.label}
                        placeholder={field.placeholder}
                        fullWidth
                        error={!!error}
                        helperText={error?.message || ""}
                        {...register(field.name, {
                            ...intersection(rules, Object.keys(field)).reduce(
                                (previous, current) => ({
                                    ...previous,
                                    [current]: {
                                        value: field[current] || undefined,
                                        message: intl.formatMessage(
                                            {
                                                id: `FORM:ERROR_MESSAGES:${camelToSnakeUpperCase(
                                                    current
                                                )}`,
                                            },
                                            {
                                                value: field[current],
                                            }
                                        ),
                                    },
                                }),
                                {}
                            ),
                            validate: {
                                ...(field.min
                                    ? {
                                          min: (value) =>
                                              value >= field.min ||
                                              intl.formatMessage(
                                                  {
                                                      id: "FORM:ERROR_MESSAGES:MIN",
                                                  },
                                                  {
                                                      value: field.min,
                                                  }
                                              ),
                                          max: (value) =>
                                              value <= field.max ||
                                              intl.formatMessage(
                                                  {
                                                      id: "FORM:ERROR_MESSAGES:MAX",
                                                  },
                                                  {
                                                      value: field.max,
                                                  }
                                              ),
                                      }
                                    : {}),
                            },
                        })}
                        InputLabelProps={{ shrink: true }}
                    />
                );
            case "datetime":
                return (
                    <TextField
                        size="small"
                        type="datetime-local"
                        label={field.label}
                        placeholder={field.placeholder}
                        fullWidth
                        error={!!error}
                        helperText={error?.message || ""}
                        {...register(
                            field.name,
                            intersection(rules, Object.keys(field)).reduce(
                                (previous, current) => ({
                                    ...previous,
                                    [current]: {
                                        value: field[current] || undefined,
                                        message: intl.formatMessage(
                                            {
                                                id: `FORM:ERROR_MESSAGES:${camelToSnakeUpperCase(
                                                    current
                                                )}`,
                                            },
                                            {
                                                value: new Date(
                                                    field[current]
                                                ).toLocaleString("en-GB"),
                                            }
                                        ),
                                    },
                                }),
                                {}
                            )
                        )}
                        InputLabelProps={{ shrink: true }}
                    />
                );
            case "switch":
                return (
                    <Box width="100%">
                        <FormControlLabel
                            sx={{ marginLeft: 0 }}
                            size="small"
                            label={<Typography>{field.label}</Typography>}
                            labelPlacement="start"
                            control={
                                <Controller
                                    size="small"
                                    control={control}
                                    name={field.name}
                                    render={({ field }) => (
                                        <Switch
                                            size="small"
                                            color="primary"
                                            inputRef={field.ref}
                                            checked={field.value ?? false}
                                            value={field.value ?? false}
                                            onChange={field.onChange}
                                        />
                                    )}
                                />
                            }
                        />
                    </Box>
                );
            case "select":
                return (
                    <Controller
                        size="small"
                        control={control}
                        rules={intersection(rules, Object.keys(field)).reduce(
                            (previous, current) => ({
                                ...previous,
                                [current]: {
                                    value: field[current] || undefined,
                                    message: intl.formatMessage(
                                        {
                                            id: `FORM:ERROR_MESSAGES:${camelToSnakeUpperCase(
                                                current
                                            )}`,
                                        },
                                        { value: field[current] }
                                    ),
                                },
                            }),
                            {}
                        )}
                        name={field.name}
                        render={({ field: fieldProps }) => {
                            return (
                                <Autocomplete
                                    size="small"
                                    {...fieldProps}
                                    multiple={false}
                                    fullWidth
                                    options={field.options}
                                    noOptionsText={""}
                                    getOptionLabel={(option) =>
                                        option?.label || option?.value || ""
                                    }
                                    isOptionEqualToValue={(option, value) => {
                                        return option.value === value.value;
                                    }}
                                    onChange={(e, data) =>
                                        fieldProps.onChange(data)
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            label={field.label}
                                            {...params}
                                            error={!!error}
                                            helperText={error?.message || ""}
                                        />
                                    )}
                                />
                            );
                        }}
                    />
                );
            case "multiSelect":
                return (
                    <Controller
                        size="small"
                        control={control}
                        rules={intersection(rules, Object.keys(field)).reduce(
                            (previous, current) => ({
                                ...previous,
                                [current]: {
                                    value: field[current] || undefined,
                                    message: intl.formatMessage(
                                        {
                                            id: `FORM:ERROR_MESSAGES:${camelToSnakeUpperCase(
                                                current
                                            )}`,
                                        },
                                        { value: field[current] }
                                    ),
                                },
                            }),
                            {}
                        )}
                        name={field.name}
                        render={({ field: fieldProps }) => {
                            return (
                                <Autocomplete
                                    size="small"
                                    {...fieldProps}
                                    multiple={true}
                                    fullWidth
                                    options={field.options}
                                    noOptionsText={""}
                                    getOptionLabel={(option) =>
                                        option?.label || option?.value || ""
                                    }
                                    isOptionEqualToValue={(option, value) => {
                                        return option.value === value.value;
                                    }}
                                    onChange={(e, data) =>
                                        fieldProps.onChange(data)
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            label={field.label}
                                            {...params}
                                            error={!!error}
                                            helperText={error?.message || ""}
                                        />
                                    )}
                                />
                            );
                        }}
                    />
                );
            case "files":
                return (
                    <>
                        <FormLabel error={!!error}>{field.label}</FormLabel>
                        <FilesInput _id={"xx"} error={!!error} />
                    </>
                );
            default:
                return <></>;
        }
    };

    const saveDraft = async () => {
        const values = getValues();
        try {
            // setModalLoading(true);
            await saveTaskDraft(data._id, values);
            refreshAll();
            toast.success(intl.formatMessage({ id: "TASKS:TASK_SAVED" }));
            handleClose();
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    if (field.kind === "unique") {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:UNIQUE" },
                                {
                                    field: intl.formatMessage({
                                        id: `TASKS:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    } else {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:FORMAT" },
                                {
                                    field: intl.formatMessage({
                                        id: `TASKS:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    }
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        } finally {
            // setModalLoading(false);
        }
    };

    const submit = async (values) => {
        try {
            // setModalLoading(true);
            await submitTask(data._id, values);
            refreshAll();
            toast.success(intl.formatMessage({ id: "TASKS:TASK_SUBMITTED" }));
            handleClose();
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    if (field.kind === "unique") {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:UNIQUE" },
                                {
                                    field: intl.formatMessage({
                                        id: `TASKS:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    } else {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:FORMAT" },
                                {
                                    field: intl.formatMessage({
                                        id: `TASKS:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    }
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        } finally {
            // setModalLoading(false);
        }
    };

    return (
        <Dialog open={open} maxWidth={false}>
            <Box
                className="fill-form-container"
                sx={{ width: { xs: "calc(90vw - 64px)", md: "700px" } }}
            >
                <Box className="header">
                    <Box display="flex" flexDirection="column">
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                        >
                            <Typography
                                variant="h1"
                                color="primary"
                                fontSize={24}
                                fontWeight="bold"
                            >
                                <FormattedMessage id="TASKS:FILL_FORM" />
                            </Typography>
                            &nbsp;&nbsp;&nbsp;
                            <Typography
                                variant="h1"
                                color="secondary"
                                fontSize={24}
                                fontWeight="bold"
                            >
                                {
                                    data.workflowInstance.nodes.find(
                                        (node) => node.id === data.node
                                    )?.data?.name
                                }
                            </Typography>
                        </Box>
                        <Typography
                            color="grey.main"
                            fontSize={16}
                            fontWeight="500"
                        >
                            {
                                data.workflowInstance.nodes.find(
                                    (node) => node.id === data.node
                                )?.data?.description
                            }
                        </Typography>
                    </Box>
                    <IconButton onClick={handleClose}>
                        <Cancel fontSize="large" color="secondary" />
                    </IconButton>
                </Box>
                <Box className="body">
                    <form onSubmit={handleSubmit(submit)}>
                        <Grid container p={2} spacing={2}>
                            {node.data.fields.map((field, index) => (
                                <Grid item key={`field-${index}`} xs={12}>
                                    {renderField(field)}
                                </Grid>
                            ))}
                        </Grid>
                        <Box
                            display="flex"
                            justifyContent={"center"}
                            gap={2}
                            marginBottom="20px"
                        >
                            <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={saveDraft}
                            >
                                <FormattedMessage id="TASKS:SAVE" />
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                            >
                                <FormattedMessage id="TASKS:SUBMIT" />
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Box>
        </Dialog>
    );
}

export default FillForm;
