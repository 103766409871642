import React from "react";
import { useCallback } from "react";
import { useContext } from "react";
import { SettingsContext } from "../contexts/Settings";

function useSettings() {
    const { isOpen, setIsOpen } = useContext(SettingsContext);

    const openSettings = useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen]);

    const closeSettings = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    return {
        showSettings: isOpen,
        openSettings,
        closeSettings,
    };
}

export default useSettings;
