import { SvgIcon } from "@mui/material";
import React from "react";
import { ReactComponent as DashboardIcon } from "../assets/icons/dashboard.svg";
import { ReactComponent as TasksIcon } from "../assets/icons/tasks.svg";
import { ReactComponent as SitesIcon } from "../assets/icons/sites.svg";
import { ReactComponent as WorkflowsIcon } from "../assets/icons/workflows.svg";
import { ReactComponent as ClientsIcon } from "../assets/icons/clients.svg";
import { ReactComponent as AnalyticsIcon } from "../assets/icons/analytics.svg";
import { ReactComponent as ReportsIcon } from "../assets/icons/reports.svg";
import { ReactComponent as UsersIcon } from "../assets/icons/users.svg";
import { ReactComponent as RolesIcon } from "../assets/icons/roles.svg";
import {
    AutoAwesomeMotion,
    Map,
    Public,
    SupervisorAccount,
} from "@mui/icons-material";

function useSvgIcons() {
    return {
        dashboard: <SvgIcon component={DashboardIcon} inheritViewBox />,
        tasks: <SvgIcon component={TasksIcon} inheritViewBox />,
        sites: <SvgIcon component={SitesIcon} inheritViewBox />,
        kmlFiles: <Map />,
        workflows: <SvgIcon component={WorkflowsIcon} inheritViewBox />,
        globalVariables: <Public />,
        workflowPhases: <AutoAwesomeMotion />,
        clients: <SvgIcon component={ClientsIcon} inheritViewBox />,
        coordinators: <SupervisorAccount />,
        analytics: <SvgIcon component={AnalyticsIcon} inheritViewBox />,
        reports: <SvgIcon component={ReportsIcon} inheritViewBox />,
        users: <SvgIcon component={UsersIcon} inheritViewBox />,
        roles: <SvgIcon component={RolesIcon} inheritViewBox />,
    };
}

export default useSvgIcons;
