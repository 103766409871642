import { Card } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React from "react";
import { useIntl } from "react-intl";
import {
    CustomLoadingOverlay,
    CustomNoRowsOverlay,
} from "../../utils/gridOverlays";

function DataTable({
    loading,
    dataColumns,
    dataRows,
    pageSize,
    setPageSize,
    noRowsMsg,
    NoResultsMsg,
    onCellDoubleClick,
}) {
    const intl = useIntl();
    return (
        <Card sx={{ height: "100%" }}>
            <DataGrid
                loading={loading}
                columns={dataColumns}
                rows={dataRows}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 20, 30, 50, 100]}
                components={{
                    Toolbar: GridToolbar,
                    LoadingOverlay: CustomLoadingOverlay,
                    NoRowsOverlay: () =>
                        CustomNoRowsOverlay(
                            intl.formatMessage({ id: noRowsMsg })
                        ),
                    NoResultsOverlay: () =>
                        CustomNoRowsOverlay(
                            intl.formatMessage({ id: NoResultsMsg })
                        ),
                }}
                disableSelectionOnClick
                onCellDoubleClick={onCellDoubleClick}
            />
        </Card>
    );
}

export default DataTable;
