import React, { useState, useEffect } from "react";
import {
    Autocomplete,
    Box,
    Button,
    Chip,
    FormControl,
    Grid,
    InputLabel,
    TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import ImageInput from "../../../../components/imageInput/ImageInput";
import "./FieldsForm.scss";
import { useIntl } from "react-intl";
import {
    camelToSnakeUpperCase,
    copyAnyObject,
} from "../../../../utils/general";
import MdInput from "./mdInput/MdInput";
import Loading from "../../../../components/loading/Loading";
import MultiEmailInput from "./multiEmailInput/MultiEmailInput";
import { compare } from "../../../../utils/compare";
import { Colorize } from "@mui/icons-material";
import DynamicPicker from "./dynamicPicker/DynamicPicker";
import useWorkflowContext from "../../../../hooks/useWorkflowContext";
import MentionInput from "./mentionInput/MentionInput";

function FieldsForm({ fields, save, dynamicData }) {
    const {
        register,
        reset,
        control,
        formState: { errors },
        watch,
        getValues,
        setValue,
    } = useForm({
        mode: "onChange",
        defaultValues: Object.keys(fields).reduce(
            (previous, current) => ({
                ...previous,
                ...{
                    [current]:
                        fields[current].value ??
                        (fields[current].type === "select"
                            ? null
                            : fields[current].type === "autocomplete"
                            ? []
                            : fields[current].type === "multiEmail"
                            ? []
                            : fields[current].type === "md"
                            ? { htmlContent: "", attachments: [] }
                            : ""),
                },
            }),
            {}
        ),
    });

    const intl = useIntl();
    const [images, setImages] = useState({});
    const [loading, setLoading] = useState(true);
    const [selectedPickField, setSelectedPickField] = useState(null);

    const { selection: pickData } = useWorkflowContext();

    const watchAll = watch();

    useEffect(() => {
        if (save) {
            let newFields = copyAnyObject(fields);
            let data = {
                ...getValues(),
                ...images,
            };
            Object.keys(data).forEach((key) => {
                newFields[key].value = data[key];
            });
            if (!compare(newFields, fields)) {
                save({ fields: newFields });
            }
        }
    }, [watchAll, images]);

    useEffect(() => {
        reset();
        setTimeout(() => {
            setLoading(false);
        }, 500);
        return () => {
            reset();
        };
    }, []);

    const handleImageChange = (name, image) => {
        setImages({ ...images, [name]: image });
    };

    const handlePick = (field, type, value) => {
        switch (type) {
            case "text":
            case "mention":
                setValue(field, `${getValues(field)}${value}`, {
                    shouldValidate: true,
                });
                break;
            case "email":
                setValue(field, value, {
                    shouldValidate: true,
                });
                break;
            case "multiEmail":
                setValue(field, [...getValues(field), value], {
                    shouldValidate: true,
                });
                break;
        }
    };

    return (
        <form className="settings-form-container">
            {loading && (
                <Loading
                    container={{
                        backgroundColor: "transparent",
                        padding: "30px",
                    }}
                    color="white"
                />
            )}
            {!loading && (
                <Grid container p={2} spacing={2}>
                    {Object.keys(fields).map((key, index) => {
                        return (
                            <Grid
                                item
                                key={`field-${index}`}
                                xs={12}
                                md={12 / (fields[key].fullWidth ? 1 : 2)}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        height: "100%",
                                        width: "100%",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    {fields[key].type === "image" ? (
                                        <ImageInput
                                            label={intl.formatMessage({
                                                id: `FIELDS_FORM:${camelToSnakeUpperCase(
                                                    key
                                                )}`,
                                            })}
                                            name={key}
                                            onChange={(image) => {
                                                handleImageChange(key, image);
                                            }}
                                        />
                                    ) : fields[key].type === "select" ? (
                                        <Controller
                                            size="small"
                                            control={control}
                                            rules={fields[key].rules}
                                            name={key}
                                            render={({ field }) => {
                                                return (
                                                    <Autocomplete
                                                        size="small"
                                                        {...field}
                                                        multiple={false}
                                                        fullWidth
                                                        options={
                                                            fields[key].options
                                                        }
                                                        noOptionsText={""}
                                                        getOptionLabel={(
                                                            option
                                                        ) =>
                                                            option
                                                                ? option.label
                                                                : ""
                                                        }
                                                        isOptionEqualToValue={(
                                                            option,
                                                            value
                                                        ) => {
                                                            return (
                                                                option.value ===
                                                                value.value
                                                            );
                                                        }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                label={intl.formatMessage(
                                                                    {
                                                                        id: `FIELDS_FORM:${camelToSnakeUpperCase(
                                                                            key
                                                                        )}`,
                                                                    }
                                                                )}
                                                                {...params}
                                                                error={
                                                                    errors[key]
                                                                        ? true
                                                                        : false
                                                                }
                                                                helperText={
                                                                    errors[key]
                                                                        ? intl.formatMessage(
                                                                              {
                                                                                  id: errors[
                                                                                      key
                                                                                  ]
                                                                                      ?.message,
                                                                              },
                                                                              {
                                                                                  value: fields[
                                                                                      key
                                                                                  ]
                                                                                      .rules[
                                                                                      errors[
                                                                                          key
                                                                                      ]
                                                                                          .type
                                                                                  ]
                                                                                      .value,
                                                                              }
                                                                          )
                                                                        : ""
                                                                }
                                                            />
                                                        )}
                                                    />
                                                );
                                            }}
                                        />
                                    ) : fields[key].type === "autocomplete" ? (
                                        <Controller
                                            size="small"
                                            control={control}
                                            rules={fields[key].rules}
                                            name={key}
                                            render={({ field }) => {
                                                const options = fields[
                                                    key
                                                ].options.map((option) => {
                                                    return {
                                                        value: option.value,
                                                        label: option.translate
                                                            ? intl.formatMessage(
                                                                  {
                                                                      id: option.label,
                                                                  }
                                                              )
                                                            : option.label,
                                                    };
                                                });
                                                return (
                                                    <Autocomplete
                                                        size="small"
                                                        {...field}
                                                        value={field.value.map(
                                                            (el) =>
                                                                options.find(
                                                                    (option) =>
                                                                        option.value ===
                                                                        el
                                                                )
                                                        )}
                                                        multiple={true}
                                                        fullWidth
                                                        noOptionsText={""}
                                                        options={options}
                                                        getOptionLabel={(
                                                            option
                                                        ) => option.label}
                                                        filterSelectedOptions
                                                        isOptionEqualToValue={(
                                                            option,
                                                            value
                                                        ) => {
                                                            return (
                                                                option.value ===
                                                                value.value
                                                            );
                                                        }}
                                                        onChange={(e, data) => {
                                                            field.onChange(
                                                                data.map(
                                                                    (el) =>
                                                                        el.value
                                                                )
                                                            );
                                                        }}
                                                        renderTags={(
                                                            value,
                                                            getTagProps
                                                        ) => {
                                                            return value.map(
                                                                (
                                                                    option,
                                                                    index
                                                                ) => (
                                                                    <Chip
                                                                        key={
                                                                            index
                                                                        }
                                                                        size="small"
                                                                        sx={{
                                                                            marginTop:
                                                                                "5px",
                                                                        }}
                                                                        label={
                                                                            option?.label ??
                                                                            ""
                                                                        }
                                                                        onDelete={
                                                                            getTagProps(
                                                                                {
                                                                                    index,
                                                                                }
                                                                            )
                                                                                .onDelete
                                                                        }
                                                                        color="white"
                                                                    />
                                                                )
                                                            );
                                                        }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...{
                                                                    ...params,
                                                                    InputLabelProps:
                                                                        {
                                                                            ...params.InputLabelProps,
                                                                            style: {
                                                                                color: "#ffffffdd",
                                                                            },
                                                                        },
                                                                    inputProps:
                                                                        {
                                                                            ...params.inputProps,
                                                                            style: {
                                                                                color: "white",
                                                                            },
                                                                        },
                                                                }}
                                                                color="white"
                                                                label={intl.formatMessage(
                                                                    {
                                                                        id: `FIELDS_FORM:${camelToSnakeUpperCase(
                                                                            key
                                                                        )}`,
                                                                    }
                                                                )}
                                                                error={
                                                                    errors[key]
                                                                        ? true
                                                                        : false
                                                                }
                                                                helperText={
                                                                    errors[key]
                                                                        ? intl.formatMessage(
                                                                              {
                                                                                  id: errors[
                                                                                      key
                                                                                  ]
                                                                                      ?.message,
                                                                              },
                                                                              {
                                                                                  value: fields[
                                                                                      key
                                                                                  ]
                                                                                      .rules[
                                                                                      errors[
                                                                                          key
                                                                                      ]
                                                                                          .type
                                                                                  ]
                                                                                      .value,
                                                                              }
                                                                          )
                                                                        : ""
                                                                }
                                                            />
                                                        )}
                                                    />
                                                );
                                            }}
                                        />
                                    ) : fields[key].type === "md" ? (
                                        <MdInput
                                            size="small"
                                            title={intl.formatMessage({
                                                id: `FIELDS_FORM:${camelToSnakeUpperCase(
                                                    key
                                                )}`,
                                            })}
                                            label={intl.formatMessage({
                                                id: `FIELDS_FORM:${camelToSnakeUpperCase(
                                                    key
                                                )}`,
                                            })}
                                            rules={{}}
                                            name={key}
                                            control={control}
                                            error={errors[key]}
                                            dynamicData={dynamicData}
                                            pickData={
                                                fields[key].selectable
                                                    ? Object.keys(
                                                          fields[key]
                                                              .selectionModels
                                                      ).reduce(
                                                          (
                                                              previous,
                                                              current
                                                          ) => ({
                                                              ...previous,
                                                              [current]: {
                                                                  fields: fields[
                                                                      key
                                                                  ]
                                                                      .selectionModels[
                                                                      current
                                                                  ].fields,
                                                                  data: pickData[
                                                                      current
                                                                  ],
                                                                  displayField:
                                                                      fields[
                                                                          key
                                                                      ]
                                                                          .selectionModels[
                                                                          current
                                                                      ]
                                                                          .displayField,
                                                              },
                                                          }),
                                                          {}
                                                      )
                                                    : null
                                            }
                                        />
                                    ) : fields[key].type === "multiEmail" ? (
                                        <MultiEmailInput
                                            name={key}
                                            rules={fields[key].rules}
                                            control={control}
                                            label={intl.formatMessage({
                                                id: `FIELDS_FORM:${camelToSnakeUpperCase(
                                                    key
                                                )}`,
                                            })}
                                            error={errors[key]}
                                        />
                                    ) : fields[key].type === "mention" ? (
                                        <MentionInput
                                            name={key}
                                            rules={fields[key].rules}
                                            control={control}
                                            label={intl.formatMessage({
                                                id: `FIELDS_FORM:${camelToSnakeUpperCase(
                                                    key
                                                )}`,
                                            })}
                                            error={errors[key]}
                                        />
                                    ) : (
                                        <Controller
                                            control={control}
                                            name={key}
                                            rules={fields[key].rules}
                                            render={({ field }) => (
                                                <TextField
                                                    type={fields[key].type}
                                                    {...field}
                                                    value={field.value || ""}
                                                    size="small"
                                                    label={`${intl.formatMessage(
                                                        {
                                                            id: `FIELDS_FORM:${camelToSnakeUpperCase(
                                                                key
                                                            )}`,
                                                        }
                                                    )}${
                                                        fields[key].rules
                                                            ?.required
                                                            ? " *"
                                                            : ""
                                                    }`}
                                                    fullWidth
                                                    error={
                                                        errors[key]
                                                            ? true
                                                            : false
                                                    }
                                                    helperText={
                                                        errors[key]
                                                            ? intl.formatMessage(
                                                                  {
                                                                      id: errors[
                                                                          key
                                                                      ]
                                                                          ?.message,
                                                                  },
                                                                  {
                                                                      value: fields[
                                                                          key
                                                                      ].rules[
                                                                          errors[
                                                                              key
                                                                          ].type
                                                                      ].value,
                                                                  }
                                                              )
                                                            : ""
                                                    }
                                                    multiline={
                                                        fields[key].multiline
                                                            ? true
                                                            : false
                                                    }
                                                    color="white"
                                                    inputProps={{
                                                        style: {
                                                            color: "white",
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        style: {
                                                            ...(errors[key]
                                                                ? {}
                                                                : {
                                                                      color: "#ffffffdd",
                                                                  }),
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    )}
                                    {fields[key].selectable &&
                                        fields[key].type !== "md" && (
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                sx={{
                                                    padding: "0px!important",
                                                    minWidth: "40px!important",
                                                    width: "40px!important",
                                                    height: "40px!important",
                                                    marginLeft:
                                                        "10px!important",
                                                }}
                                                onClick={() => {
                                                    setSelectedPickField({
                                                        ...fields[key],
                                                        name: key,
                                                    });
                                                }}
                                            >
                                                <Colorize />
                                            </Button>
                                        )}
                                </Box>
                            </Grid>
                        );
                    })}
                </Grid>
            )}
            <DynamicPicker
                open={selectedPickField !== null}
                handleClose={() => {
                    setSelectedPickField(null);
                }}
                onConfirm={(value) => {
                    handlePick(
                        selectedPickField.name,
                        selectedPickField.type,
                        value
                    );
                }}
                data={
                    selectedPickField
                        ? Object.keys(selectedPickField.selectionModels).reduce(
                              (previous, current) => ({
                                  ...previous,
                                  [current]: {
                                      fields: selectedPickField.selectionModels[
                                          current
                                      ].fields,
                                      data: pickData[current],
                                      displayField:
                                          selectedPickField.selectionModels[
                                              current
                                          ].displayField,
                                  },
                              }),
                              {}
                          )
                        : {}
                }
                dynamicData={dynamicData}
            />
        </form>
    );
}

export default FieldsForm;
