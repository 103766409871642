import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "./header/Header";
import Sidenav from "./sidenav/Sidenav";
import "./Layout.scss";

import { FormattedMessage } from "react-intl";
import Loading from "../../components/loading/Loading";
import { NotificationsProvider } from "../../contexts/NotificationsContext";

function LayoutScreen({ checkTokenLoading, changeLang }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box className="layout-container">
            <NotificationsProvider>
                <React.Fragment>
                    <Header className="top" />
                    <Box className="center">
                        <Sidenav />
                        <Box className="content">
                            {checkTokenLoading ? (
                                <Loading
                                    loader={{
                                        height: "10vmin!important",
                                        width: "10vmin!important",
                                    }}
                                />
                            ) : (
                                <Outlet />
                            )}
                        </Box>
                    </Box>
                    <Box className="bottom">
                        <Typography variant="span" className="copyrights">
                            <FormattedMessage
                                id={`LAYOUT:COPYRIGHTS${
                                    isMobile ? "_SHORT" : ""
                                }`}
                            />
                        </Typography>
                        <Box className="language">
                            <button onClick={() => changeLang("en-US")}>
                                English
                            </button>
                            &nbsp;.&nbsp;
                            <button onClick={() => changeLang("fr-FR")}>
                                Français
                            </button>
                        </Box>
                    </Box>
                </React.Fragment>
            </NotificationsProvider>
        </Box>
    );
}

export default LayoutScreen;
