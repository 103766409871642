import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { useContext, useEffect, useCallback, useRef } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 *
 * @param  blocker
 * @param  when
 * @see https://reactrouter.com/api/useBlocker
 */
export function useBlocker(blocker, when = true) {
    const { navigator } = useContext(NavigationContext);

    useEffect(() => {
        if (!when) return;

        const unblock = navigator.block((tx) => {
            const autoUnblockingTx = {
                ...tx,
                retry() {
                    // Automatically unblock the transition so it can play all the way
                    // through before retrying it. TODO: Figure out how to re-enable
                    // this block if the transition is cancelled for some reason.
                    unblock();
                    tx.retry();
                },
            };

            blocker(autoUnblockingTx);
        });

        return unblock;
    }, [navigator, blocker, when]);
}
/**
 * Prompts the user with an Alert before they leave the current screen.
 *
 * @param  message
 * @param  when
 */
export function usePrompt(
    when,
    title,
    description,
    confirmationText,
    cancellationText,
    { onConfirm, onCancel, checkText, defaultChecked } = {}
) {
    const checkRef = useRef(null);
    const confirm = useConfirm();
    const blocker = useCallback(
        async (tx) => {
            // eslint-disable-next-line no-alert
            try {
                await confirm({
                    title,
                    confirmationText,
                    cancellationText,
                    content: (
                        <Box>
                            <Typography>{description}</Typography>
                            {typeof checkText === "string" && (
                                <FormControlLabel
                                    size="small"
                                    label={
                                        <Typography color="secondary">
                                            {checkText}
                                        </Typography>
                                    }
                                    labelPlacement="start"
                                    control={
                                        <Switch
                                            color="secondary"
                                            defaultChecked={defaultChecked}
                                            inputRef={checkRef}
                                        />
                                    }
                                />
                            )}
                        </Box>
                    ),
                    titleProps: { color: "secondary" },
                    confirmationButtonProps: { color: "error" },
                    cancellationButtonProps: { color: "secondary" },
                });
                if (onConfirm) {
                    let result = await onConfirm(checkRef.current.checked);
                    if (!result) return;
                }
                tx.retry();
            } catch (error) {}
        },
        [
            title,
            description,
            confirmationText,
            cancellationText,
            onConfirm,
            onCancel,
            checkText,
        ]
    );

    useBlocker(blocker, when);
}
