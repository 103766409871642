import React from "react";
import { Card, Box, Typography, Button } from "@mui/material";
import { LocationOn } from "@mui/icons-material";
import { FormattedMessage, useIntl } from "react-intl";
// import TaskDetails from "./taskDetails/TaskDetails";
import { useSearchParams } from "react-router-dom";
import TaskDetails from "./taskDetails/TaskDetails";
import FillForm from "./fillForm/FillForm";
import BuildChecklist from "./buildChecklist/BuildChecklist";

const CARD_HEIGHT = 220;
const CARD_WIDTH = 200;
const possibleStates = {
    PENDING: { label: "TASKS:PENDING", color: "#F8C100" },
    DRAFT: { label: "TASKS:DRAFT", color: "#ff0000" },
    COMPLETED: { label: "TASKS:COMPLETED", color: "#E36AF4" },
};

const TaskCard = ({ data, onContextMenu, refreshAll }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const intl = useIntl();

    const handleTask = () => {
        switch (data.kind) {
            case "FORM":
                setSearchParams({ formId: data._id });
                break;
            case "CHECKLIST_BUILD":
                setSearchParams({ checklistBuildId: data._id });
                break;
        }
    };

    return (
        <React.Fragment>
            {searchParams.get("taskId") === data._id && (
                <TaskDetails
                    open={searchParams.get("taskId") === data._id}
                    handleClose={() => {
                        setSearchParams({});
                    }}
                    data={data}
                />
            )}
            {searchParams.get("formId") === data._id && (
                <FillForm
                    open={searchParams.get("formId") === data._id}
                    handleClose={() => {
                        setSearchParams({});
                    }}
                    data={data}
                    refreshAll={refreshAll}
                />
            )}
            {searchParams.get("checklistBuildId") === data._id && (
                <BuildChecklist
                    open={searchParams.get("checklistBuildId") === data._id}
                    handleClose={() => {
                        setSearchParams({});
                    }}
                    data={data}
                    refreshAll={refreshAll}
                />
            )}
            <Card
                sx={{
                    height: CARD_HEIGHT,
                    width: CARD_WIDTH,
                    boxShadow:
                        "-2px 5px 11px #EEF0FF, 2px 5px 11px #EEF0FF, 0px 5px #dfe0ef",
                    pr: 1,
                    pl: 1,
                    pt: 2,
                    pb: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    borderRadius: "7px",
                    flexShrink: 0,
                }}
                elevation={1}
                onContextMenu={onContextMenu}
            >
                <Box>
                    <Box
                        sx={{
                            backgroundColor: `${
                                data?.state
                                    ? possibleStates[data.state].color + "12"
                                    : "#0000005"
                            }`,
                            borderRadius: "4px",
                            width: 100,
                            height: 20,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                        mb={1}
                    >
                        <Typography
                            sx={{
                                color: `${
                                    data?.state
                                        ? possibleStates[data.state].color
                                        : "black"
                                }`,
                                fontSize: 10,
                                fontWeight: "bold",
                            }}
                        >
                            {data?.state
                                ? intl.formatMessage({
                                      id: possibleStates[data.state].label,
                                  })
                                : ""}
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems={"center"} mb={1} gap={1}>
                        <Typography
                            fontSize={12}
                            color="primary"
                            fontWeight="bold"
                            sx={{ whiteSpace: "nowrap" }}
                        >
                            <FormattedMessage id="TASKS:KIND" />
                            &nbsp;:
                        </Typography>
                        <Typography
                            fontSize={12}
                            color="secondary"
                            textOverflow="ellipsis"
                            sx={{
                                WebkitLineClamp: "1",
                                WebkitBoxOrient: "vertical",
                                display: "-webkit-box",
                                overflow: "hidden",
                            }}
                        >
                            <FormattedMessage id={`TASKS:KIND:${data?.kind}`} />
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems={"center"} mb={1} gap={1}>
                        <Typography
                            fontSize={12}
                            color="primary"
                            fontWeight="bold"
                            sx={{ whiteSpace: "nowrap" }}
                        >
                            <FormattedMessage id="TASKS:CLIENT" />
                            &nbsp;:
                        </Typography>
                        <Typography
                            fontSize={12}
                            color="primary"
                            textOverflow="ellipsis"
                            sx={{
                                WebkitLineClamp: "1",
                                WebkitBoxOrient: "vertical",
                                display: "-webkit-box",
                                overflow: "hidden",
                            }}
                        >
                            {data?.workflowInstance?.site?.client?.name}
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems={"center"} mb={1} gap={1}>
                        <Typography
                            fontSize={12}
                            color="primary"
                            fontWeight="bold"
                            sx={{ whiteSpace: "nowrap" }}
                        >
                            <FormattedMessage id="TASKS:SITE_NAME" />
                            &nbsp;:
                        </Typography>
                        <Typography
                            fontSize={12}
                            color="primary"
                            textOverflow="ellipsis"
                            sx={{
                                WebkitLineClamp: "1",
                                WebkitBoxOrient: "vertical",
                                display: "-webkit-box",
                                overflow: "hidden",
                            }}
                        >
                            {data?.workflowInstance?.site?.name}
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems={"center"} mb={1} gap={1}>
                        <Typography
                            fontSize={12}
                            color="primary"
                            fontWeight="bold"
                            sx={{ whiteSpace: "nowrap" }}
                        >
                            <FormattedMessage id="TASKS:SITE_REF" />
                            &nbsp;:
                        </Typography>
                        <Typography
                            fontSize={12}
                            color="primary"
                            textOverflow="ellipsis"
                            sx={{
                                WebkitLineClamp: "1",
                                WebkitBoxOrient: "vertical",
                                display: "-webkit-box",
                                overflow: "hidden",
                            }}
                        >
                            {data?.workflowInstance?.site?.ref}
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems={"center"} mb={1} gap={1}>
                        <Typography
                            fontSize={12}
                            color="primary"
                            fontWeight="bold"
                            sx={{ whiteSpace: "nowrap" }}
                        >
                            <FormattedMessage id="TASKS:CREATED_AT" />
                            &nbsp;:
                        </Typography>
                        <Typography
                            fontSize={12}
                            color="primary"
                            textOverflow="ellipsis"
                            sx={{
                                WebkitLineClamp: "1",
                                WebkitBoxOrient: "vertical",
                                display: "-webkit-box",
                                overflow: "hidden",
                            }}
                        >
                            {new Date(data?.createdAt).toLocaleString("en-GB")}
                        </Typography>
                    </Box>
                </Box>
                <Box display="flex" alignItems={"center"} gap={0.5}>
                    <Button
                        variant="outlined"
                        fullWidth
                        color="secondary"
                        sx={{
                            fontSize: 10,
                            fontWeight: "bold",
                            textTransform: "unset",
                            height: 26,
                            lineHeight: "10px",
                        }}
                        onClick={() => {
                            setSearchParams({ taskId: data._id });
                        }}
                    >
                        {intl.formatMessage({ id: "TASKS:MORE_DETAILS" })}
                    </Button>
                    <Button
                        variant="outlined"
                        fullWidth
                        color="secondary"
                        sx={{
                            fontSize: 10,
                            fontWeight: "bold",
                            textTransform: "unset",
                            height: 26,
                            lineHeight: "10px",
                        }}
                        onClick={handleTask}
                    >
                        {intl.formatMessage({ id: "TASKS:HANDLE_TASK" })}
                    </Button>
                </Box>
            </Card>
        </React.Fragment>
    );
};

export default TaskCard;
