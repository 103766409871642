export const parseCommentHTML = (html) => {
    const result = html
        .toString()
        .replaceAll(
            /<span id="([A-Z_]*?)" contenteditable="false" class="selected-mention">@(.*?)<\/span>/g,
            "@{{$1}}"
        )
        .replaceAll("&nbsp;", " ")
        .replaceAll("<br>", "\n");
    return result;
};

export const generateCommentHTML = (text, translate) => {
    const result = text
        .toString()
        .replace(/@\{\{([A-Z_]*?)\}\}/g, (m, m1) => {
            return `<span id="${m1}" contenteditable="false" class="selected-mention">@${translate(
                m1
            )}</span>`;
        })
        .replaceAll("\n", "<br>");
    return result;
};
