import DataValidationError from "../errors/DataValidationError";
import { axiosInstance } from "../utils/axios";

export const getTasks = async (state) => {
  try {
    let response = await axiosInstance.get("/tasks", {
      params: state ? { state } : {},
    });
    if (response.status !== 200) {
      throw new Error("ERROR:OTHER");
    }
    return response.data;
  } catch (error) {
    if (!error.response && error.request) {
      throw new Error("ERROR:CONNECTION");
    } else {
      switch (error.response.status) {
        case 401:
          throw new Error("ERROR:UNAUTHORIZED");
        case 403:
          throw new Error("ERROR:FORBIDDEN");
        case 500:
          throw new Error("ERROR:SERVER");
        default:
          throw new Error("ERROR:OTHER");
      }
    }
  }
};

export const setTaskSeen = async (_id) => {
  try {
    let response = await axiosInstance.put(`/tasks/${_id}/set-seen`);
    if (response.status !== 200) {
      throw new Error("ERROR:OTHER");
    }
  } catch (error) {
    if (!error.response && error.request) {
      throw new Error("ERROR:CONNECTION");
    } else {
      switch (error.response.status) {
        case 400:
          switch (error.response.data.error) {
            case "DATA_VALIDATION":
              throw new DataValidationError(error.response.data.fields);
            default:
              throw new Error("ERROR:OTHER");
          }
        case 401:
          throw new Error("ERROR:UNAUTHORIZED");
        case 403:
          throw new Error("ERROR:FORBIDDEN");
        case 404:
          throw new Error("ERROR:NOTFOUND");
        case 500:
          throw new Error("ERROR:SERVER");
        default:
          throw new Error("ERROR:OTHER");
      }
    }
  }
};

export const saveTaskDraft = async (_id, data) => {
  try {
    let response = await axiosInstance.put(`/tasks/${_id}/save-draft`, data);
    if (response.status !== 200) {
      throw new Error("ERROR:OTHER");
    }
  } catch (error) {
    if (!error.response && error.request) {
      throw new Error("ERROR:CONNECTION");
    } else {
      switch (error.response.status) {
        case 400:
          switch (error.response.data.error) {
            case "DATA_VALIDATION":
              throw new DataValidationError(error.response.data.fields);
            default:
              throw new Error("ERROR:OTHER");
          }
        case 401:
          throw new Error("ERROR:UNAUTHORIZED");
        case 403:
          throw new Error("ERROR:FORBIDDEN");
        case 404:
          throw new Error("ERROR:NOTFOUND");
        case 500:
          throw new Error("ERROR:SERVER");
        default:
          throw new Error("ERROR:OTHER");
      }
    }
  }
};

export const submitTask = async (_id, data) => {
  try {
    let response = await axiosInstance.put(`/tasks/${_id}/submit`, data);
    if (response.status !== 200) {
      throw new Error("ERROR:OTHER");
    }
  } catch (error) {
    if (!error.response && error.request) {
      throw new Error("ERROR:CONNECTION");
    } else {
      switch (error.response.status) {
        case 400:
          switch (error.response.data.error) {
            case "DATA_VALIDATION":
              throw new DataValidationError(error.response.data.fields);
            default:
              throw new Error("ERROR:OTHER");
          }
        case 401:
          throw new Error("ERROR:UNAUTHORIZED");
        case 403:
          throw new Error("ERROR:FORBIDDEN");
        case 404:
          throw new Error("ERROR:NOTFOUND");
        case 500:
          throw new Error("ERROR:SERVER");
        default:
          throw new Error("ERROR:OTHER");
      }
    }
  }
};
