import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
    Collapse,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import "./SubMenu.scss";

function SubMenu({ title, icon, items, path }) {
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const intl = useIntl();
    const [isOpen, setIsOpen] = useState(true);

    return (
        <List disablePadding component={"div"} className="menu-container">
            <Link to={path}>
                <ListItemButton
                    sx={
                        isMobile
                            ? {
                                  padding: 0,
                              }
                            : {
                                  pl: 2,
                                  ml: 2,
                                  mr: 2,
                                  ...(location.pathname === path
                                      ? {
                                            color: `${theme.palette.secondary.main}!important`,
                                            border: `solid 2px ${theme.palette.secondary.main}`,
                                            borderRadius: "10px",
                                        }
                                      : {}),
                              }
                    }
                >
                    <ListItemIcon
                        sx={{
                            ...(isMobile
                                ? {
                                      width: 50,
                                      height: 50,
                                      maxWidth: 50,
                                      minWidth: 50,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                  }
                                : {}),
                            ...(location.pathname === path
                                ? {
                                      color: `${theme.palette.secondary.main}!important`,
                                  }
                                : {}),
                        }}
                    >
                        {icon}
                    </ListItemIcon>
                    <ListItemText
                        className="item-text"
                        primary={intl.formatMessage({
                            id: `SIDENAV:${title}`,
                        })}
                        sx={{
                            display: { xs: "none", md: "flex" },
                            ...(location.pathname === path
                                ? {
                                      color: `${theme.palette.secondary.main}!important`,
                                  }
                                : {}),
                        }}
                        disableTypography
                    />
                    {!isMobile && (
                        <ListItemButton
                            onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                setIsOpen(!isOpen);
                            }}
                            sx={{
                                flexGrow: "unset",
                                padding: "0!important",
                                borderRadius: 10,
                                ...(location.pathname === path
                                    ? {
                                          color: `${theme.palette.secondary.main}!important`,
                                      }
                                    : {}),
                            }}
                        >
                            {isOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                    )}
                </ListItemButton>
            </Link>
            <Collapse
                in={isMobile || isOpen}
                timeout="auto"
                unmountOnExit
                sx={{ marginLeft: "16px", marginRight: "16px" }}
            >
                <List disablePadding>
                    {items.map((item, index) => (
                        <Link to={item.path} key={index}>
                            <ListItemButton
                                sx={
                                    isMobile
                                        ? {
                                              padding: 0,
                                          }
                                        : {
                                              pl: 2,
                                              ml: 2,
                                              mr: 2,
                                              ...(location.pathname ===
                                              item.path
                                                  ? {
                                                        border: `solid 2px ${theme.palette.secondary.main}`,
                                                        borderRadius: "10px",
                                                    }
                                                  : {}),
                                          }
                                }
                            >
                                <ListItemIcon
                                    sx={{
                                        ...(isMobile
                                            ? {
                                                  width: 50,
                                                  height: 50,
                                                  maxWidth: 50,
                                                  minWidth: 50,
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                              }
                                            : {}),
                                        ...(location.pathname === item.path
                                            ? {
                                                  color: theme.palette.secondary
                                                      .main,
                                              }
                                            : {}),
                                    }}
                                >
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText
                                    className="item-text"
                                    primary={intl.formatMessage({
                                        id: `SIDENAV:${title}:${item.text}`,
                                    })}
                                    sx={{
                                        display: { xs: "none", md: "flex" },
                                        ...(location.pathname === item.path
                                            ? {
                                                  color: `${theme.palette.secondary.main}!important`,
                                              }
                                            : {}),
                                    }}
                                    disableTypography
                                />
                            </ListItemButton>
                        </Link>
                    ))}
                </List>
            </Collapse>
        </List>
    );
}

export default SubMenu;
