import {
    Avatar,
    Box,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from "@mui/material";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import "./Field.scss";

function Field({ data, index, icon }) {
    return (
        <Draggable
            draggableId={data.type}
            index={index}
            isDragDisabled={data.disabled}
        >
            {(provided, snapshot) => (
                <Box className={"field-container"}>
                    <ListItem
                        className={`field${
                            snapshot.isDragging ? " dragging" : ""
                        }${data.disabled ? " disabled" : ""}`}
                        id={data.label}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={provided.draggableProps.style}
                    >
                        <ListItemAvatar>
                            <Avatar>{icon}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={data.label}
                            secondary={data.description}
                        />
                    </ListItem>
                    {snapshot.isDragging && (
                        <ListItem
                            className={`field clone${
                                snapshot.isDragging ? " dragging" : ""
                            }`}
                        >
                            <ListItemAvatar>
                                <Avatar>{icon}</Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={data.label}
                                secondary={data.description}
                            />
                        </ListItem>
                    )}
                </Box>
            )}
        </Draggable>
    );
}

export default Field;
