import React, { useState, useEffect } from "react";
import "./AttachmentPreview.scss";
import {
    Cancel,
    NavigateBefore,
    NavigateNext,
    VisibilityOffTwoTone,
    VisibilityTwoTone,
} from "@mui/icons-material";
import { Box, IconButton, InputBase, Link } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Loading from "../../../../../../components/loading/Loading";
import { defaultStyles, FileIcon } from "react-file-icon";
import useAbility from "../../../../../../hooks/useAbility";

var goPrevious = null;
var goNext = null;

function AttachmentPreview({
    attachments,
    selectedAttachment,
    close,
    previous,
    next,
    saveName,
    saveVisibility,
    initialActiveDocument,
}) {
    const [name, setName] = useState(attachments[selectedAttachment].name);
    const [error, setError] = useState(false);
    const { can } = useAbility();

    const intl = useIntl();

    useEffect(() => {
        setName(attachments[selectedAttachment].name);
    }, [selectedAttachment]);

    const renderDocHeader = (state, previousDocument, nextDocument) => {
        goPrevious = previousDocument;
        goNext = nextDocument;
        return <Box></Box>;
    };

    const renderLoading = ({ document, fileName }) => {
        return (
            <Loading
                color="primary"
                container={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 99,
                    backgroundColor: "transparent",
                }}
            />
        );
    };

    const renderCustom = ({ document, fileName }) => {
        let ext = document.path.split(".").reverse()[0];
        return (
            <Box className="custom-render-container">
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Box width="30px">
                        <FileIcon extension={ext} {...defaultStyles[ext]} />
                    </Box>
                    <Link
                        component="span"
                        color="primary"
                        sx={{ fontSize: "30px" }}
                    >
                        <a
                            href={document.uri}
                            download={document.name}
                            target="_blank"
                            style={{
                                textDecoration: "inherit",
                                color: "inherit",
                                fontSize: "inherit",
                            }}
                        >
                            <FormattedMessage id="GLOBAL:DOWNLOAD" />
                        </a>
                    </Link>
                </Box>
            </Box>
        );
    };

    return (
        <Box className="attachment-preview-container">
            <IconButton className="close-button" onClick={close}>
                <Cancel fontSize="large" color="white" />
            </IconButton>
            <Box className="attachment-container">
                <Box className="attachment-name-container">
                    <InputBase
                        size="small"
                        value={name}
                        required
                        onChange={(event) => {
                            if (
                                can("RENAME_ATTACHMENTS", "ALL_SITES") ||
                                can("RENAME_ATTACHMENTS", "RELATED_SITES") ||
                                can("RENAME_ATTACHMENTS", "CREATED_SITES")
                            ) {
                                setName(event.target.value);
                            }
                        }}
                        onBlur={async (event) => {
                            if (
                                can("RENAME_ATTACHMENTS", "ALL_SITES") ||
                                can("RENAME_ATTACHMENTS", "RELATED_SITES") ||
                                can("RENAME_ATTACHMENTS", "CREATED_SITES")
                            ) {
                                let result = await saveName(
                                    attachments[selectedAttachment].path,
                                    event.target.value
                                );
                                if (result) {
                                    setError(false);
                                } else {
                                    setError(true);
                                }
                            }
                        }}
                        placeholder={intl.formatMessage({
                            id: "SITES:IMAGE_NAME_PLACEHOLDER",
                        })}
                        inputProps={{ className: "name" }}
                        fullWidth
                    ></InputBase>
                </Box>
                <DocViewer
                    className="attachment-viewer"
                    pluginRenderers={DocViewerRenderers}
                    documents={attachments.map((attachment) => ({
                        ...attachment,
                        uri: attachments[0].fullPath,
                    }))}
                    config={{
                        header: {
                            overrideComponent: renderDocHeader,
                            disableFileName: true,
                            retainURLParams: true,
                        },
                        loadingRenderer: {
                            overrideComponent: renderLoading,
                        },
                        noRenderer: {
                            overrideComponent: renderCustom,
                        },
                    }}
                    initialActiveDocument={initialActiveDocument}
                />
                {(can("VALIDATE_ATTACHMENTS", "ALL_SITES") ||
                    can("VALIDATE_ATTACHMENTS", "RELATED_SITES") ||
                    can("VALIDATE_ATTACHMENTS", "CREATED_SITES")) && (
                    <IconButton
                        className="visibility-button"
                        color={
                            !attachments[selectedAttachment].visible
                                ? "error"
                                : "success"
                        }
                        onClick={async (event) => {
                            event.stopPropagation();
                            await saveVisibility(
                                attachments[selectedAttachment].path,
                                !attachments[selectedAttachment].visible
                            );
                        }}
                    >
                        {!attachments[selectedAttachment].visible ? (
                            <VisibilityOffTwoTone fontSize="inherit" />
                        ) : (
                            <VisibilityTwoTone fontSize="inherit" />
                        )}
                    </IconButton>
                )}
            </Box>
            <IconButton
                className={`previous-button${previous ? "" : " disabled"}`}
                onClick={() => {
                    previous(goPrevious);
                }}
                disabled={!previous}
                sx={(theme) => ({
                    filter: `drop-shadow(1px 0px 6px ${theme.palette.primary.main})`,
                })}
            >
                <NavigateBefore fontSize="inherit" color="white" />
            </IconButton>
            <IconButton
                className={`next-button${next ? "" : " disabled"}`}
                onClick={() => {
                    next(goNext);
                }}
                disabled={!next}
                sx={(theme) => ({
                    filter: `drop-shadow(1px 0px 6px ${theme.palette.primary.main})`,
                })}
            >
                <NavigateNext fontSize="inherit" color="white" />
            </IconButton>
        </Box>
    );
}

export default AttachmentPreview;
