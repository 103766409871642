import React, { useState } from "react";
import { Box, IconButton, InputBase } from "@mui/material";
import { Delete, Save } from "@mui/icons-material";
import NodeButton from "../nodeButton/NodeButton";
import { useReactFlow } from "reactflow";
import { verifyWorkflow } from "../../../utils/workflow";
import { createWorkflow, updateWorkflow } from "../../../api/workflow";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import DataValidationError from "../../../errors/DataValidationError";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import { useConfirm } from "material-ui-confirm";
import useNodeTypes from "../../../hooks/useNodeTypes";
import useWorkflowContext from "../../../hooks/useWorkflowContext";
import "./BuilderToolbar.scss";
import useFormatNodes from "../../../hooks/useFormatNodes";
import { camelToSnakeUpperCase } from "../../../utils/general";
import { useEffect } from "react";
import { useCallback } from "react";

const BuilderToolbar = () => {
    const [saveLoading, setSaveLoading] = useState(false);
    const reactFlowInstance = useReactFlow();
    const { title, setTitle, data, setData } = useWorkflowContext();
    const intl = useIntl();
    const { id } = useParams();
    const navigate = useNavigate();
    const confirm = useConfirm();
    const { initialNodes } = useNodeTypes();
    const formattedNodes = useFormatNodes();

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const addWorkflow = async (data) => {
        try {
            setSaveLoading(true);
            let { _id } = await createWorkflow(data);
            toast.success(
                intl.formatMessage({ id: "WORKFLOWS:WORKFLOW_ADDED" })
            );
            setData({ nodes: data.nodes, edges: data.edges });
            setTimeout(() => {
                navigate(`/workflows/build/${_id}`);
                setSaveLoading(false);
            }, 1500);
        } catch (error) {
            if (error instanceof DataValidationError) {
                setSaveLoading(false);
                error.fields.forEach((field) => {
                    if (field.kind === "unique") {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:UNIQUE" },
                                {
                                    field: intl.formatMessage({
                                        id: `WORKFLOWS:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    } else {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:FORMAT" },
                                {
                                    field: intl.formatMessage({
                                        id: `WORKFLOWS:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    }
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        } finally {
            setSaveLoading(false);
        }
    };

    const saveUpdates = async (id, data) => {
        try {
            setSaveLoading(true);
            await updateWorkflow(id, data);
            toast.success(
                intl.formatMessage({ id: "WORKFLOWS:WORKFLOW_UPDATED" })
            );
            setData({ nodes: data.nodes, edges: data.edges });
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    if (field.kind === "unique") {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:UNIQUE" },
                                {
                                    field: intl.formatMessage({
                                        id: `WORKFLOWS:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    } else {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:FORMAT" },
                                {
                                    field: intl.formatMessage({
                                        id: `WORKFLOWS:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    }
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        } finally {
            setSaveLoading(false);
        }
    };

    const handleDelete = useCallback(async () => {
        try {
            await confirm({
                title: intl.formatMessage({ id: "WARNING" }),
                description: intl.formatMessage({
                    id: "WORKFLOWS:REMOVE_ALL_CONFIRM",
                }),
                confirmationText: intl.formatMessage({ id: "GLOBAL:CONFIRM" }),
                cancellationText: intl.formatMessage({ id: "GLOBAL:CANCEL" }),
            });
        } catch (error) {
            return;
        }
        reactFlowInstance.setEdges([]);
        reactFlowInstance.setNodes([]);
    }, [confirm, reactFlowInstance, intl]);

    const handleSave = useCallback(() => {
        let nodes = formattedNodes;
        let edges = reactFlowInstance.getEdges();
        if (
            true
            // verifyWorkflow(
            //     nodes,
            //     edges,
            //     reactFlowInstance.getNode("start-node")
            // )
        ) {
            if (data) {
                saveUpdates(id, { nodes, edges, name: title });
            } else {
                addWorkflow({ name: title, nodes, edges });
            }
        } else {
            toast.error(
                intl.formatMessage({ id: "WORKFLOWS:INVALID_WORKFLOW" })
            );
        }
    }, [
        formattedNodes,
        reactFlowInstance,
        data,
        intl,
        verifyWorkflow,
        saveUpdates,
        addWorkflow,
    ]);

    useEffect(() => {
        const handler = (event) => {
            if ((event.key === "s" || event.key === "S") && event.ctrlKey) {
                event.preventDefault();
                handleSave();
            } else if (event.key === "Delete" && event.shiftKey) {
                event.preventDefault();
                handleDelete();
            }
        };
        window.addEventListener("keydown", handler);
        return () => {
            window.removeEventListener("keydown", handler);
        };
    }, [handleSave, handleDelete]);

    return (
        <Box className="builder-toolbar">
            <Box className="header">
                <InputBase
                    size="small"
                    value={title}
                    required
                    onChange={handleTitleChange}
                    inputProps={{ className: "title" }}
                ></InputBase>
                <Box className="actions">
                    {saveLoading ? (
                        <Loading
                            container={{
                                backgroundColor: "transparent",
                                width: "50px",
                                height: "100%",
                            }}
                            loader={{
                                width: "35px!important",
                                height: "35px!important",
                                color: "white!important",
                            }}
                        />
                    ) : (
                        <IconButton onClick={handleSave}>
                            <Save fontSize="large" />
                        </IconButton>
                    )}
                    <IconButton sx={{ color: "red" }} onClick={handleDelete}>
                        <Delete fontSize="large" />
                    </IconButton>
                </Box>
            </Box>
            <Box className="tools">
                {initialNodes.map((node, index) => (
                    <NodeButton key={index} {...node} />
                ))}
            </Box>
        </Box>
    );
};

export default BuilderToolbar;
