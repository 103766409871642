import React, { useEffect, useMemo } from "react";
import {
    AccountTreeOutlined,
    AdminPanelSettingsOutlined,
    Event,
    FeedOutlined,
    GridView,
    GroupsOutlined,
    InsertChartOutlinedRounded,
    LocationOnOutlined,
    PersonOutlineOutlined,
    Public,
} from "@mui/icons-material";
import { List, useMediaQuery, useTheme } from "@mui/material";
import Menu from "./menu/Menu";
import "./Sidenav.scss";
import useSvgIcons from "../../../hooks/useSvgIcons";
import useAbility from "../../../hooks/useAbility";

function Sidenav() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const icons = useSvgIcons();

    const menus = [
        {
            title: "MAIN_MENU",
            items: [
                {
                    text: "DASHBOARD",
                    icon: icons.dashboard,
                    path: "/dashboard",
                },
                { text: "TASKS", icon: icons.tasks, path: "/tasks" },
            ],
        },
        {
            title: "MANAGEMENT",
            items: [
                {
                    text: "SITES",
                    icon: icons.sites,
                    path: "/sites",
                    abilities: [
                        {
                            resource: "ALL_SITES",
                            actions: ["CREATE", "CONSULT"],
                        },
                        {
                            resource: "RELATED_SITES",
                            actions: ["CREATE", "CONSULT"],
                        },
                        {
                            resource: "CREATED_SITES",
                            actions: ["CREATE", "CONSULT"],
                        },
                    ],
                    subItems: [
                        {
                            text: "KML_FILES",
                            icon: icons.kmlFiles,
                            path: "/kml",
                            abilities: [
                                {
                                    resource: "KML_FILES",
                                    actions: ["CREATE", "CONSULT"],
                                },
                            ],
                        },
                    ],
                },
                {
                    text: "CLIENTS",
                    icon: icons.clients,
                    path: "/clients",
                    abilities: [
                        { resource: "CLIENTS", actions: ["CREATE", "CONSULT"] },
                    ],
                    subItems: [
                        {
                            text: "COORDINATORS",
                            icon: icons.coordinators,
                            path: "/coordinators",
                            abilities: [
                                {
                                    resource: "CLIENT_COORDINATORS",
                                    actions: ["CREATE", "CONSULT"],
                                },
                            ],
                        },
                    ],
                },
                {
                    text: "ANALYTICS",
                    icon: icons.analytics,
                    path: "/analytics",
                    disabled: true,
                },
                {
                    text: "REPORTS",
                    icon: icons.reports,
                    path: "/reports",
                    disabled: true,
                },
            ],
        },
        {
            title: "WORKFLOW",
            items: [
                {
                    text: "WORKFLOWS",
                    icon: icons.workflows,
                    path: "/workflows",
                    abilities: [
                        {
                            resource: "WORKFLOWS",
                            actions: ["CREATE", "CONSULT"],
                        },
                    ],
                },
                {
                    text: "GLOBAL_VARIABLES",
                    icon: icons.globalVariables,
                    path: "/global-variables",
                    abilities: [
                        {
                            resource: "GLOBAL_VARIABLES",
                            actions: ["CREATE", "CONSULT"],
                        },
                    ],
                },
                {
                    text: "WORKFLOW_PHASES",
                    icon: icons.workflowPhases,
                    path: "/workflow-phases",
                    abilities: [
                        {
                            resource: "WORKFLOW_STEPS",
                            actions: ["CREATE", "CONSULT"],
                        },
                    ],
                },
            ],
        },
        {
            title: "AUTHORIZATIONS",
            items: [
                {
                    text: "USERS",
                    icon: icons.users,
                    path: "/users",
                    abilities: [
                        { resource: "USERS", actions: ["CREATE", "CONSULT"] },
                    ],
                },
                {
                    text: "ROLES_AND_PERMISSIONS",
                    icon: icons.roles,
                    path: "/roles",
                    abilities: [
                        { resource: "ROLES", actions: ["CREATE", "CONSULT"] },
                    ],
                },
            ],
        },
    ];
    const { can } = useAbility();

    return (
        <List
            className="sidenav-container"
            sx={{ width: isMobile ? 70 : 300 }}
            disablePadding
            component={"div"}
        >
            {menus
                .filter((menu) =>
                    menu.items.some(
                        (item) =>
                            !item.abilities || // If doesn't have abilities
                            item.abilities.some((el) =>
                                can(el.actions, el.resource)
                            ) || // or can do any of the abilities
                            (item.subItems && // or has subItems
                                item.subItems.some(
                                    // and one of the sub items
                                    (subItem) =>
                                        !subItem.abilities || // doesn't have abilities
                                        subItem.abilities.some((el) =>
                                        can(el.actions, el.resource)
                                    ) // or can do one of his abilities
                                ))
                    )
                )
                .map((menu, index) => (
                    <Menu key={index} title={menu.title} items={menu.items} />
                ))}
        </List>
    );
}

export default Sidenav;
