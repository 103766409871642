import { encode } from "js-base64";

export const camelToSnakeUpperCase = (str) =>
    str.replace(/[A-Z]/g, (letter) => `_${letter}`).toUpperCase();

export const capitalize = (str) =>
    str.replaceAll(/\b[a-z]/g, (c) => {
        return c.toUpperCase();
    });

export const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const stringify = (data) => {
    return JSON.stringify(data, (key, value) => {
        if (value instanceof RegExp) return "__REGEXP " + value.toString();
        else return value;
    });
};

export const parse = (data) => {
    return JSON.parse(data, (key, value) => {
        if (value && value.toString().indexOf("__REGEXP ") === 0) {
            var m = value.split("__REGEXP ")[1].match(/\/(.*)\/(.*)?/);
            return new RegExp(m[1], m[2] || "");
        } else return value;
    });
};

export const intersection = (list1, list2) =>
    list1.filter((element) => list2.includes(element));

export const copyAnyObject = (obj) => (obj ? parse(stringify(obj)) : obj);

export const textToBase64 = (text) => encode(text, true);

export const extractFromPath = (object, path) => {
    let result = object;
    for (let key of path.split(".")) {
        if (result[key]) {
            result = result[key];
        } else {
            return;
        }
    }
    return result;
};

export const groupBy = (data) => {
    let result = {};
    for (let element of data) {
        let group = element.group;
        let resource = element.resource;
        if (group) {
            if (!result[group]) {
                result[group] = {};
            }
            if (!result[group][resource]) {
                result[group][resource] = [];
            }
            result[group][resource].push(element);
        } else {
            if (!result[resource]) {
                result[resource] = [];
            }
            result[resource].push(element);
        }
    }
    return result;
};
/*
Current Output:
[
    {resource : [permissions] }
]
==> Desired Output:
[
    {resource : [permissions] },
    {group : {
        resource : [permissions] ,
        resource : [permissions] ,
    }}
]
*/
