import {
    alpha,
    AppBar,
    Avatar,
    Badge,
    Box,
    IconButton,
    InputBase,
    Menu,
    MenuItem,
    styled,
    Toolbar,
    useMediaQuery,
    useTheme,
    Popover,
    Topography,
    Dialog,
    DialogTitle,
    DialogContent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Header.scss";
import logo from "../../../assets/images/logo.png";
import logoMobile from "../../../assets/images/logo-mobile.png";
import {
    AccountCircleOutlined,
    LogoutOutlined,
    MoreVert,
    NotificationsOutlined,
    SearchOutlined,
    SettingsOutlined,
} from "@mui/icons-material";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { clear, selectInfo } from "../../../redux/slices/userSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { stringAvatar } from "../../../utils/avatar";
import Notifications from "./notifications/Notifications.jsx";
import useNotifications from "../../../hooks/useNotifications";
import useSettings from "../../../hooks/useSettings";
import useProfile from "../../../hooks/useProfile";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    boxShadow: "4px 4px 6px #EFF1FB",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "320px",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.secondary.main,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: theme.palette.primary.main,
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch",
        },
    },
}));

function Header() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const intl = useIntl();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const info = useSelector(selectInfo);
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const [notificationAnchor, setNotificationAnchor] = useState(null);
    const [notificationAnchorMobile, setNotificationAnchorMobile] =
        useState(null);
    const isNotificationOpen = Boolean(notificationAnchor);
    const isNotificationOpenMobile = Boolean(notificationAnchorMobile);
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const [openNotficationsDialog, setOpenNotificationsDialog] =
        useState(false);
    const { notifications, notificationsError, setupNotifications } =
        useNotifications();
    const { openSettings } = useSettings();
    const { openProfile } = useProfile();

    const handleOpenNotificationsDialog = () => {
        setOpenNotificationsDialog(true);
    };

    const handleCloseNotificationsDialog = () => {
        setOpenNotificationsDialog(false);
    };

    const handleOpenNotification = (event) => {
        setNotificationAnchor(event.currentTarget);
    };
    const handleCloseNotification = () => {
        setNotificationAnchor(null);
    };
    const handleOpenNotificationMobile = (event) => {
        setNotificationAnchorMobile(event.currentTarget);
    };
    const handleCloseNotificationMobile = () => {
        setNotificationAnchorMobile(null);
    };
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const logout = () => {
        handleMenuClose();
        handleMobileMenuClose();
        window.localStorage.clear();
        dispatch(clear());
        toast.warning(intl.formatMessage({ id: "WARNING:LOGOUT" }));
        navigate("/login", {
            replace: true,
            state: {
                fromApp: true,
            },
        });
    };

    const menuId = "primary-search-account-menu";
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={openProfile}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    color="inherit"
                >
                    <AccountCircleOutlined />
                </IconButton>
                <p>
                    <FormattedMessage id="HEADER:PROFILE" />
                </p>
            </MenuItem>
            <MenuItem onClick={logout}>
                <IconButton
                    size="large"
                    aria-label="show 4 new mails"
                    color="inherit"
                >
                    <LogoutOutlined />
                </IconButton>
                <p>
                    <FormattedMessage id="HEADER:LOGOUT" />
                </p>
            </MenuItem>
        </Menu>
    );

    const mobileMenuId = "primary-search-account-menu-mobile";
    const renderMobileMenu = (
        <>
            <Popover
                id={"notif-id-mobile"}
                open={isNotificationOpenMobile}
                anchorEl={notificationAnchorMobile}
                onClose={handleCloseNotificationMobile}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Notifications
                    notifications={notifications
                        .filter((notification) => !notification.seen)
                        .slice(0, 5)}
                    onSeeMore={
                        notifications.length > 0
                            ? () => {
                                  handleCloseNotification();
                                  handleOpenNotificationsDialog();
                              }
                            : null
                    }
                    error={notificationsError}
                    hideNotifications={() => {
                        handleCloseNotification();
                        handleCloseNotificationMobile();
                        handleCloseNotificationsDialog();
                    }}
                    refreshNotifications={async () => {
                        await setupNotifications();
                    }}
                />
            </Popover>
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                id={mobileMenuId}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={isMobileMenuOpen}
                onClose={handleMobileMenuClose}
            >
                <MenuItem onClick={openSettings}>
                    <IconButton
                        size="large"
                        aria-label="show 4 new mails"
                        color="inherit"
                    >
                        <SettingsOutlined />
                    </IconButton>
                    <p>
                        <FormattedMessage id="HEADER:SETTINGS" />
                    </p>
                </MenuItem>
                <MenuItem onClick={handleOpenNotificationMobile}>
                    <IconButton
                        size="large"
                        aria-label={`show ${notifications.length} new notifications`}
                        color="inherit"
                        onClick={handleOpenNotification}
                    >
                        <Badge
                            badgeContent={
                                notifications.filter((n) => !n.seen).length
                            }
                            color="error"
                        >
                            <NotificationsOutlined />
                        </Badge>
                    </IconButton>
                    <p>
                        <FormattedMessage id="HEADER:NOTIFICATIONS" />
                    </p>
                </MenuItem>
                <MenuItem onClick={openProfile}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        color="inherit"
                    >
                        <AccountCircleOutlined />
                    </IconButton>
                    <p>
                        <FormattedMessage id="HEADER:PROFILE" />
                    </p>
                </MenuItem>
                <MenuItem onClick={logout}>
                    <IconButton
                        size="large"
                        aria-label="show 4 new mails"
                        color="inherit"
                    >
                        <LogoutOutlined />
                    </IconButton>
                    <p>
                        <FormattedMessage id="HEADER:LOGOUT" />
                    </p>
                </MenuItem>
            </Menu>
        </>
    );

    return (
        <>
            <Box className="header-container">
                <AppBar position="relative">
                    <Toolbar className="toolbar">
                        <img
                            src={isMobile ? logoMobile : logo}
                            className="logo"
                            alt="logo"
                            onClick={() => {
                                navigate("/");
                            }}
                        ></img>
                        <Box sx={{ flexGrow: 1 }} />
                        <Search>
                            <SearchIconWrapper>
                                <SearchOutlined />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder={intl.formatMessage({
                                    id: "HEADER:SEARCH_PLACEHOLDER",
                                })}
                                inputProps={{ "aria-label": "search" }}
                            />
                        </Search>
                        <Box
                            sx={{
                                display: { xs: "none", md: "flex" },
                                color: theme.palette.tertiary.main,
                                alignItems: "center",
                            }}
                        >
                            <IconButton
                                size="large"
                                aria-label="go to settings"
                                color="inherit"
                                onClick={openSettings}
                            >
                                <SettingsOutlined />
                            </IconButton>
                            <IconButton
                                size="large"
                                aria-label={`show ${notifications.length} new notifications`}
                                color="inherit"
                                onClick={handleOpenNotification}
                            >
                                <Badge
                                    badgeContent={
                                        notifications.filter((n) => !n.seen)
                                            .length
                                    }
                                    color="error"
                                >
                                    <NotificationsOutlined />
                                </Badge>
                            </IconButton>
                            <IconButton
                                size="large"
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit"
                            >
                                <Avatar
                                    {...stringAvatar(
                                        `${info.firstName} ${info.lastName}`
                                    )}
                                />
                            </IconButton>
                        </Box>
                        <Box
                            sx={{
                                display: { xs: "flex", md: "none" },
                                color: theme.palette.tertiary.main,
                            }}
                        >
                            <IconButton
                                size="large"
                                aria-label="show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                            >
                                <MoreVert />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
                {renderMobileMenu}
                {renderMenu}
            </Box>
            <Popover
                id={"notif-id"}
                open={isNotificationOpen}
                anchorEl={notificationAnchor}
                onClose={handleCloseNotification}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Notifications
                    notifications={notifications
                        .filter((notification) => !notification.seen)
                        .slice(0, 5)}
                    onSeeMore={
                        notifications.length > 0
                            ? () => {
                                  handleCloseNotification();
                                  handleOpenNotificationsDialog();
                              }
                            : null
                    }
                    error={notificationsError}
                    hideNotifications={() => {
                        handleCloseNotification();
                        handleCloseNotificationMobile();
                        handleCloseNotificationsDialog();
                    }}
                    refreshNotifications={async () => {
                        await setupNotifications();
                    }}
                />
            </Popover>
            <Dialog
                open={openNotficationsDialog}
                onClose={handleCloseNotificationsDialog}
                // scroll={"paper"}
                fullWidth
                aria-labelledby="dialog-title"
            >
                <DialogTitle id="dialog-title">
                    {intl.formatMessage({ id: "HEADER:NOTIFICATIONS" })}
                </DialogTitle>
                <DialogContent
                    className="header-notifications-modal"
                    dividers={true}
                    sx={{ padding: 0 }}
                >
                    <Notifications
                        allNotifications={true}
                        notifications={notifications}
                        error={notificationsError}
                        hideNotifications={() => {
                            handleCloseNotification();
                            handleCloseNotificationMobile();
                            handleCloseNotificationsDialog();
                        }}
                        refreshNotifications={async () => {
                            await setupNotifications();
                        }}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}

export default Header;
