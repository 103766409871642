import { useMemo } from "react";
import { createContext, useState } from "react";
import { useIntl } from "react-intl";
import AddModal from "../components/addModal/AddModal";
import { getOfficer, updateOfficer } from "../api/officerAccount";
import { toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { selectId } from "../redux/slices/userSlice";
import DataValidationError from "../errors/DataValidationError";
import { camelToSnakeUpperCase } from "../utils/general";

export const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const intl = useIntl();

    const id = useSelector(selectId);

    const queryClient = useQueryClient();

    const profileQuery = useQuery(["profile", id], async () => {
        if (!id) {
            return null;
        }
        const result = await getOfficer(id);
        return { ...result, ...result.officerInfo };
    });

    const updateProfileMutation = useMutation(
        (data) => updateOfficer(id, data),
        {
            onSuccess: () => {
                toast.success(intl.formatMessage({ id: "USERS:USER_UPDATED" }));
                queryClient.invalidateQueries({ queryKey: ["profile"] });
            },
            onError: (error) => {
                if (error instanceof DataValidationError) {
                    error.fields.forEach((field) => {
                        if (field.kind === "unique") {
                            toast.error(
                                intl.formatMessage(
                                    { id: "ERROR:UNIQUE" },
                                    {
                                        field: intl.formatMessage({
                                            id: `USERS:${camelToSnakeUpperCase(
                                                field.path
                                            )}`,
                                        }),
                                        value: field.value,
                                    }
                                )
                            );
                        } else {
                            toast.error(
                                intl.formatMessage(
                                    { id: "ERROR:FORMAT" },
                                    {
                                        field: intl.formatMessage({
                                            id: `USERS:${camelToSnakeUpperCase(
                                                field.path
                                            )}`,
                                        }),
                                        value: field.value,
                                    }
                                )
                            );
                        }
                    });
                } else {
                    toast.error(intl.formatMessage({ id: error.message }));
                }
            },
        }
    );

    const fields = useMemo(
        () =>
            profileQuery.data
                ? [
                      {
                          name: "image",
                          label: intl.formatMessage({ id: "PROFILE:IMAGE" }),
                          type: "image",
                          defaultValue: profileQuery.data.image,
                      },
                      {
                          name: "username",
                          label: intl.formatMessage({ id: "PROFILE:USERNAME" }),
                          type: "text",
                          rules: {
                              required: intl.formatMessage({
                                  id: "FORM:ERROR_MESSAGES:REQUIRED",
                              }),
                          },
                          defaultValue: profileQuery.data.username,
                      },
                      {
                          name: "password",
                          label: intl.formatMessage({ id: "PROFILE:PASSWORD" }),
                          type: "password",
                          rules: {},
                          defaultValue: "",
                      },
                      {
                          name: "confirmPassword",
                          label: intl.formatMessage({
                              id: "PROFILE:CONFIRM_PASSWORD",
                          }),
                          type: "password",
                          rules: {},
                          defaultValue: "",
                      },
                      {
                          name: "firstName",
                          label: intl.formatMessage({
                              id: "PROFILE:FIRST_NAME",
                          }),
                          type: "text",
                          rules: {
                              required: intl.formatMessage({
                                  id: "FORM:ERROR_MESSAGES:REQUIRED",
                              }),
                          },
                          defaultValue: profileQuery.data.firstName,
                      },
                      {
                          name: "lastName",
                          label: intl.formatMessage({
                              id: "PROFILE:LAST_NAME",
                          }),
                          type: "text",
                          rules: {
                              required: intl.formatMessage({
                                  id: "FORM:ERROR_MESSAGES:REQUIRED",
                              }),
                          },
                          defaultValue: profileQuery.data.lastName,
                      },
                      {
                          name: "phone",
                          label: intl.formatMessage({ id: "PROFILE:PHONE" }),
                          type: "tel",
                          rules: {
                              required: intl.formatMessage({
                                  id: "FORM:ERROR_MESSAGES:REQUIRED",
                              }),
                              pattern: {
                                  value: /^([+])?\d+$/,
                                  message: intl.formatMessage({
                                      id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                                  }),
                              },
                          },
                          defaultValue: profileQuery.data.phone,
                      },
                      {
                          name: "email",
                          label: intl.formatMessage({ id: "PROFILE:EMAIL" }),
                          type: "email",
                          rules: {
                              required: intl.formatMessage({
                                  id: "FORM:ERROR_MESSAGES:REQUIRED",
                              }),
                              pattern: {
                                  value: /^[a-zA-Z0-9.! #$%&'*+/=? ^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$/,
                                  message: intl.formatMessage({
                                      id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                                  }),
                              },
                          },
                          defaultValue: profileQuery.data.email,
                      },
                  ]
                : [],
        [intl, profileQuery.data]
    );

    return (
        <ProfileContext.Provider
            value={{
                isOpen,
                setIsOpen,
            }}
        >
            {children}
            {isOpen && (
                <AddModal
                    open={isOpen}
                    handleClose={() => {
                        setIsOpen(false);
                    }}
                    fields={fields}
                    title={intl.formatMessage({ id: "PROFILE:PROFILE" })}
                    submit={updateProfileMutation.mutate}
                    buttons={[
                        {
                            props: {
                                type: "submit",
                                variant: "contained",
                                color: "secondary",
                            },
                            label: intl.formatMessage({ id: "GLOBAL:SAVE" }),
                        },
                    ]}
                    buttonsBoxProps={[]}
                    loading={
                        profileQuery.isLoading ||
                        updateProfileMutation.isLoading
                    }
                />
            )}
        </ProfileContext.Provider>
    );
};
