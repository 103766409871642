import React from "react";
import "./FieldProperties.scss";
import { Box, Divider, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import useFormBuilderContext from "../../../../../../hooks/useFormBuilderContext";
import FieldPropertiesForm from "./fieldPropertiesForm/FieldPropertiesForm";
import { useCallback } from "react";
import { copyAnyObject } from "../../../../../../utils/general";

function FieldProperties({ isLarge }) {
    const { selectedField, formFields, setFormFields } =
        useFormBuilderContext();
    const data = formFields?.find((field) => field.id === selectedField);

    const save = useCallback(
        (data) => {
            setFormFields(
                formFields.map((field) =>
                    field.id === selectedField
                        ? { ...field, ...copyAnyObject(data) }
                        : field
                )
            );
        },
        [formFields, setFormFields, selectedField]
    );

    return (
        <Box className={`fields-propeties-container${isLarge ? " large" : ""}`}>
            <Typography
                component="h2"
                fontSize="26px"
                fontWeight="bold"
                color="primary"
                marginBottom="15px"
            >
                <FormattedMessage id="FORM_BUILDER:PROPERTIES:TITLE" />
            </Typography>
            {selectedField && data && (
                <FieldPropertiesForm
                    key={selectedField}
                    data={data}
                    validateName={(value) =>
                        value === data.name ||
                        !formFields.find((field) => field.name === value)
                    }
                    save={save}
                />
            )}
        </Box>
    );
}

export default FieldProperties;
