import React, { useMemo } from "react";
import { useEffect } from "react";
import { useNodes } from "reactflow";
import useWorkflowContext from "./useWorkflowContext";

function useFormatNodes() {
    const { settings, nodes } = useWorkflowContext();
    const formattedNodes = useMemo(
        () =>
            nodes.map((node) => ({
                ...node,
                data: node.data
                    ? {
                          ...node.data,
                          ...(node.data.settings
                              ? { settings: settings[node.id] }
                              : {}),
                      }
                    : {},
            })),
        [nodes, settings]
    );

    return formattedNodes;
}

export default useFormatNodes;
