import { ExpandMore } from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Box,
    FormControlLabel,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import useDebounce from "../../../../../../../hooks/useDebounce";
import { compare } from "../../../../../../../utils/compare";
import FieldOptionsProperty from "./fieldOptionsProperty/FieldOptionsProperty";
import "./FieldPropertiesForm.scss";

function FieldPropertiesForm({ data, validateName, save }) {
    const intl = useIntl();
    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors, isValidating, isValid },
        getValues,
        watch,
        setValue,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: data,
    });

    const watchAll = useWatch({ control });
    const result = useDebounce(watchAll, 200, !isValidating && isValid);

    useEffect(() => {
        if (save && result && !compare(data, result)) {
            save(result);
        }
    }, [result, save]);

    return (
        <form className="field-properties-form">
            {data.name !== undefined && (
                <Box padding="10px 0">
                    <TextField
                        size="small"
                        type="text"
                        label={`${intl.formatMessage({
                            id: "FORM_BUILDER:PROPERTIES:NAME",
                        })} *`}
                        fullWidth
                        error={errors["name"] ? true : false}
                        helperText={errors["name"]?.message}
                        {...register("name", {
                            required: intl.formatMessage({
                                id: "FORM:ERROR_MESSAGES:REQUIRED",
                            }),
                            pattern: {
                                value: /^[a-zA-Z][a-zA-Z0-9\-_]+$/,
                                message: intl.formatMessage({
                                    id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                                }),
                            },
                            validate: {
                                unique: (value) =>
                                    validateName(value) ||
                                    intl.formatMessage({
                                        id: "FORM:ERROR_MESSAGES:EXISTS",
                                    }),
                            },
                        })}
                        multiline={false}
                    />
                </Box>
            )}
            {data.label !== undefined && (
                <Box padding="10px 0">
                    <TextField
                        size="small"
                        type="text"
                        label={`${intl.formatMessage({
                            id: "FORM_BUILDER:PROPERTIES:LABEL",
                        })}`}
                        fullWidth
                        error={errors["label"] ? true : false}
                        helperText={errors["label"]?.message}
                        {...register("label", {
                            // required: intl.formatMessage({
                            //     id: "FORM:ERROR_MESSAGES:REQUIRED",
                            // }),
                        })}
                        multiline={false}
                    />
                </Box>
            )}
            {data.value !== undefined && (
                <Box padding="10px 0">
                    <TextField
                        size="small"
                        type="text"
                        label={`${intl.formatMessage({
                            id: "FORM_BUILDER:PROPERTIES:VALUE",
                        })} *`}
                        fullWidth
                        error={errors["value"] ? true : false}
                        helperText={errors["value"]?.message}
                        {...register("value", {
                            required: intl.formatMessage({
                                id: "FORM:ERROR_MESSAGES:REQUIRED",
                            }),
                        })}
                        multiline={true}
                    />
                </Box>
            )}
            {data.placeholder !== undefined && (
                <Box padding="10px 0">
                    <TextField
                        size="small"
                        type="text"
                        label={intl.formatMessage({
                            id: "FORM_BUILDER:PROPERTIES:PLACEHOLDER",
                        })}
                        fullWidth
                        error={errors["placeholder"] ? true : false}
                        helperText={errors["placeholder"]?.message}
                        {...register("placeholder", {})}
                        multiline={false}
                    />
                </Box>
            )}
            {data.required !== undefined && (
                <Box padding="10px 0">
                    <FormControlLabel
                        size="small"
                        label={
                            <Typography color="primary">
                                {intl.formatMessage({
                                    id: "FORM_BUILDER:PROPERTIES:REQUIRED",
                                })}
                            </Typography>
                        }
                        labelPlacement="start"
                        control={
                            <Controller
                                size="small"
                                control={control}
                                name="required"
                                render={({ field }) => (
                                    <Switch
                                        size="small"
                                        color="primary"
                                        inputRef={field.ref}
                                        checked={field.value}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )}
                            />
                        }
                    />
                </Box>
            )}
            {data.multiline !== undefined && (
                <Box padding="10px 0">
                    <FormControlLabel
                        size="small"
                        label={
                            <Typography color="primary">
                                {intl.formatMessage({
                                    id: "FORM_BUILDER:PROPERTIES:MULTILINE",
                                })}
                            </Typography>
                        }
                        labelPlacement="start"
                        control={
                            <Controller
                                size="small"
                                control={control}
                                name="multiline"
                                render={({ field }) => (
                                    <Switch
                                        size="small"
                                        color="primary"
                                        inputRef={field.ref}
                                        checked={field.value}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )}
                            />
                        }
                    />
                </Box>
            )}
            {data.minLength !== undefined && (
                <Box padding="10px 0">
                    <TextField
                        size="small"
                        type="number"
                        label={intl.formatMessage({
                            id: "FORM_BUILDER:PROPERTIES:MIN_LENGTH",
                        })}
                        fullWidth
                        error={errors["minLength"] ? true : false}
                        helperText={errors["minLength"]?.message}
                        {...register("minLength", {
                            pattern: {
                                value: /^\d*$/,
                                message: intl.formatMessage({
                                    id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                                }),
                            },
                        })}
                        multiline={false}
                    />
                </Box>
            )}
            {data.maxLength !== undefined && (
                <Box padding="10px 0">
                    <TextField
                        size="small"
                        type="number"
                        label={intl.formatMessage({
                            id: "FORM_BUILDER:PROPERTIES:MAX_LENGTH",
                        })}
                        fullWidth
                        error={errors["maxLength"] ? true : false}
                        helperText={errors["maxLength"]?.message}
                        {...register("maxLength", {
                            pattern: {
                                value: /^\d*$/,
                                message: intl.formatMessage({
                                    id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                                }),
                            },
                        })}
                        multiline={false}
                    />
                </Box>
            )}
            {data.min !== undefined && (
                <Box padding="10px 0">
                    <TextField
                        size="small"
                        type={
                            data.type === "decimalNumber"
                                ? "number"
                                : data.type === "datetime"
                                ? "datetime-local"
                                : data.type
                        }
                        label={intl.formatMessage({
                            id: "FORM_BUILDER:PROPERTIES:MIN_VALUE",
                        })}
                        fullWidth
                        error={errors["min"] ? true : false}
                        helperText={errors["min"]?.message}
                        {...register("min", {
                            pattern: {
                                value:
                                    data.type === "number"
                                        ? /^\d*$/
                                        : data.type === "decimalNumber"
                                        ? /^(\d*)|(\d+(\.\d+)?)/
                                        : /.*/,
                                message: intl.formatMessage({
                                    id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                                }),
                            },
                        })}
                        inputProps={
                            data.type === "decimalNumber" ? { step: 0.1 } : {}
                        }
                        InputLabelProps={
                            ["date", "time", "datetime"].includes(data.type)
                                ? { shrink: true }
                                : {}
                        }
                        multiline={false}
                    />
                </Box>
            )}
            {data.max !== undefined && (
                <Box padding="10px 0">
                    <TextField
                        size="small"
                        type={
                            data.type === "decimalNumber"
                                ? "number"
                                : data.type === "datetime"
                                ? "datetime-local"
                                : data.type
                        }
                        label={intl.formatMessage({
                            id: "FORM_BUILDER:PROPERTIES:MAX_VALUE",
                        })}
                        fullWidth
                        error={errors["max"] ? true : false}
                        helperText={errors["max"]?.message}
                        {...register("max", {
                            pattern: {
                                value:
                                    data.type === "number"
                                        ? /^\d*$/
                                        : data.type === "decimalNumber"
                                        ? /^(\d*)|(\d+(\.\d+)?)/
                                        : /.*/,
                                message: intl.formatMessage({
                                    id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                                }),
                            },
                        })}
                        inputProps={
                            data.type === "decimalNumber" ? { step: 0.1 } : {}
                        }
                        InputLabelProps={
                            ["date", "time", "datetime"].includes(data.type)
                                ? { shrink: true }
                                : {}
                        }
                        multiline={false}
                    />
                </Box>
            )}
            {data.format !== undefined && (
                <Box padding="10px 0">
                    <Controller
                        size="small"
                        control={control}
                        name="format"
                        render={({ field }) => {
                            return (
                                <Autocomplete
                                    size="small"
                                    {...field}
                                    multiple={false}
                                    fullWidth
                                    options={[
                                        {
                                            label: "Email",
                                            value: "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$",
                                        },
                                        {
                                            label: "Phone",
                                            value: "^(\\+\\d{1,3}\\s?)?(\\d\\s?)+$",
                                        },
                                    ]}
                                    noOptionsText={intl.formatMessage({
                                        id: "GLOBAL:NO_FORMAT",
                                    })}
                                    getOptionLabel={(option) =>
                                        option ? option.label : ""
                                    }
                                    isOptionEqualToValue={(option, value) => {
                                        return option.value === value.value;
                                    }}
                                    onChange={(e, data) => field.onChange(data)}
                                    renderInput={(params) => (
                                        <TextField
                                            label={intl.formatMessage({
                                                id: "FORM_BUILDER:PROPERTIES:FORMAT",
                                            })}
                                            {...params}
                                            error={
                                                errors["format"] ? true : false
                                            }
                                            helperText={
                                                errors["format"]?.message
                                            }
                                        />
                                    )}
                                />
                            );
                        }}
                    />
                </Box>
            )}
            {data.options && (
                <Box padding="10px 0" className="options-container">
                    <Accordion
                        defaultExpanded={true}
                        square={true}
                        elevation={0}
                        sx={{
                            backgroundColor: "transparent!important",
                        }}
                        disableGutters={true}
                    >
                        <AccordionSummary
                            sx={{
                                padding: 0,
                                minHeight: "unset!important",
                            }}
                            expandIcon={<ExpandMore color="primary" />}
                        >
                            <Typography color="primary">
                                <FormattedMessage id="FORM_BUILDER:PROPERTIES:OPTIONS" />
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                padding: 0,
                                paddingTop: "10px",
                            }}
                        >
                            <FieldOptionsProperty
                                name="options"
                                control={control}
                                errors={errors}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Box>
            )}
        </form>
    );
}

export default FieldPropertiesForm;
