import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { copyAnyObject } from "../utils/general";

function useDebounce(value, delay, condition = true) {
    const [debouncedValue, setDebouncedValue] = useState();
    const timer = useRef();

    useEffect(() => {
        if (timer.current && condition) {
            clearTimeout(timer.current);
        }
        if (condition) {
            const copy = copyAnyObject(value);
            timer.current = setTimeout(() => {
                setDebouncedValue(copy);
            }, delay);
        }
    }, [value, condition]);

    return debouncedValue;
}

export default useDebounce;
