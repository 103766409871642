import { Box, Dialog, Slide } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import ReactLeafletKml from "react-leaflet-kml";
import "./KmlPreview.scss";

function KmlPreview({ open, handleClose, paths, lat, lng, showMarker }) {
    const [kmlFiles, setKmlFiles] = useState([]);

    useEffect(() => {
        if (paths) {
            Promise.all(
                paths.map((path) => fetch(path).then((res) => res.text()))
            ).then((kmlTexts) => {
                setKmlFiles(
                    kmlTexts.map((kmlText) => {
                        const parser = new DOMParser();
                        return parser.parseFromString(kmlText, "text/xml");
                    })
                );
            });
        }
    }, []);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen={true}
            sx={{ padding: "50px" }}
        >
            <MapContainer
                zoom={12}
                center={[lat ?? 48.8566, lng ?? 2.3522]}
                style={{ height: "100%", width: "100%" }}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                {showMarker && <Marker position={[lat, lng]}></Marker>}
                {kmlFiles.map((kml, index) => (
                    <ReactLeafletKml key={index} kml={kml} />
                ))}
            </MapContainer>
        </Dialog>
    );
}

export default KmlPreview;
