import React from "react";
import { Typography, Box, Dialog, Slide } from "@mui/material";
import { useDisclosure } from "@mantine/hooks";
import "./MdInput.scss";
import MdInputModal from "./mdInputModal/MdInputModal";
import usePrevious from "../../../../../hooks/usePrevious";
import { useEffect } from "react";
import useWorkflowContext from "../../../../../hooks/useWorkflowContext";
import { Controller } from "react-hook-form";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function MdInput({
    title,
    size,
    name,
    rules,
    control,
    label,
    error,
    pickData,
    dynamicData,
}) {
    const [opened, handlers] = useDisclosure(false);
    const previousOpened = usePrevious(opened);
    const { blockDeleteIncr, blockDeleteDecr } = useWorkflowContext();

    useEffect(() => {
        if (previousOpened && !opened) {
            blockDeleteDecr();
        } else if (!previousOpened && opened) {
            blockDeleteIncr();
        }
    }, [opened]);

    return (
        <Box sx={{ width: "100%" }} className="md-input-container">
            <button
                className="open-button"
                onClick={() => {
                    handlers.open();
                }}
                type="button"
                style={{ height: size === "small" ? "40px" : "56px" }}
            >
                <Typography>{label}</Typography>
            </button>
            <Dialog
                open={opened}
                TransitionComponent={Transition}
                className="md-input-modal-container"
                PaperProps={{
                    style: {
                        maxWidth: "80vw",
                        width: "80vw",
                        maxHeight: "80vh",
                        overflow: "hidden",
                        margin: 0,
                    },
                }}
            >
                <Controller
                    size="small"
                    control={control}
                    rules={rules}
                    name={name}
                    render={({ field }) => (
                        <MdInputModal
                            title={title}
                            submit={field.onChange}
                            close={handlers.close}
                            value={field.value}
                            pickData={pickData}
                            dynamicData={dynamicData}
                        />
                    )}
                />
            </Dialog>
        </Box>
    );
}

export default MdInput;
