export const extract = (object, fields, order = []) => {
    const newObject = {};
    for (let key of fields) {
        newObject[key] =
            object[key]?.constructor.name === "Array" && order.includes[key]
                ? [...object[key]].sort()
                : object[key];
    }
    return newObject;
};
