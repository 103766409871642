import React, { useMemo } from "react";
import andNodeImage from "../assets/images/nodes/andNode.png";
import orNodeImage from "../assets/images/nodes/orNode.png";
import mailNodeImage from "../assets/images/nodes/mailNode.png";
import whatsappNodeImage from "../assets/images/nodes/whatsappNode.png";
import sleepNodeImage from "../assets/images/nodes/sleepNode.png";
import conditionNodeImage from "../assets/images/nodes/conditionNode.png";
import formNodeImage from "../assets/images/nodes/formNode.png";
import commentNodeImage from "../assets/images/nodes/commentNode.png";
import editSiteNodeImage from "../assets/images/nodes/editSiteNode.png";
import checklistNode from "../assets/images/nodes/checklistNode.png";
import endNodeImage from "../assets/images/nodes/endNode.png";
import { useIntl } from "react-intl";

function useNodeTypes() {
    const intl = useIntl();
    const initialNodes = useMemo(
        () => [
            {
                type: "SLEEP",
                data: {
                    maxInput: 1,
                    settings: {
                        name: "",
                        description: "",
                        kind: "fields",
                        fields: {
                            duration: {
                                type: "number",
                                selectable: false,
                                value: "0",
                                rules: {
                                    required: "FORM:ERROR_MESSAGES:REQUIRED",
                                    min: {
                                        value: 0,
                                        message: "FORM:ERROR_MESSAGES:MIN",
                                    },
                                    pattern: {
                                        value: /^\d+$/,
                                        message:
                                            "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                                    },
                                },
                                fullWidth: true,
                            },
                        },
                    },
                },
            },
            {
                type: "CONDITION",
                data: {
                    maxInput: 1,
                    settings: {
                        kind: "conditionGenerator",
                        name: "",
                        description: "",
                        content: {
                            combinator: "and",
                            rules: [],
                        },
                    },
                },
            },
            { type: "AND" },
            { type: "OR" },
            {
                type: "MAIL",
                data: {
                    maxInput: 1,
                    settings: {
                        kind: "fields",
                        name: "",
                        description: "",
                        fields: {
                            fromName: {
                                type: "text",
                                selectable: true,
                                selectionModels: {
                                    users: {
                                        displayField: "fullName",
                                        fields: [
                                            "username",
                                            "firstName",
                                            "lastName",
                                            "fullName",
                                        ],
                                    },
                                    clients: {
                                        displayField: "name",
                                        fields: ["name"],
                                    },
                                    globalVariables: {
                                        displayField: "name",
                                        fields: ["name", "value"],
                                    },
                                },
                                value: "",
                                rules: {
                                    required: "FORM:ERROR_MESSAGES:REQUIRED",
                                },
                                fullWidth: true,
                            },
                            fromEmail: {
                                type: "email",
                                selectable: true,
                                selectionModels: {
                                    users: {
                                        displayField: "fullName",
                                        fields: ["email"],
                                    },
                                    clients: {
                                        displayField: "name",
                                        fields: ["email"],
                                    },
                                    globalVariables: {
                                        displayField: "name",
                                        fields: ["value"],
                                    },
                                },
                                value: "",
                                rules: {
                                    required: "FORM:ERROR_MESSAGES:REQUIRED",
                                    pattern: {
                                        value: /^([a-zA-Z0-9.! #$%&'*+/=? ^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*)|([#][{]{2}([a-zA-Z0-9_-]+::[a-zA-Z0-9_-]+)[}]{2})|([@][{]{2}([a-zA-Z0-9_-]+::[a-zA-Z0-9_-]+::[a-zA-Z0-9_-]*)[}]{2})$/,
                                        message:
                                            "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                                    },
                                },
                                fullWidth: true,
                            },
                            toEmail: {
                                type: "multiEmail",
                                selectable: true,
                                selectionModels: {
                                    users: {
                                        displayField: "fullName",
                                        fields: ["email"],
                                    },
                                    clients: {
                                        displayField: "name",
                                        fields: ["email"],
                                    },
                                    globalVariables: {
                                        displayField: "name",
                                        fields: ["value"],
                                    },
                                },
                                value: [],
                                rules: {
                                    required: "FORM:ERROR_MESSAGES:REQUIRED",
                                },
                                fullWidth: true,
                            },
                            subject: {
                                type: "text",
                                selectable: true,
                                selectionModels: {
                                    users: {
                                        displayField: "fullName",
                                        fields: [
                                            "firstName",
                                            "lastName",
                                            "fullName",
                                        ],
                                    },
                                    clients: {
                                        displayField: "name",
                                        fields: ["name"],
                                    },
                                    globalVariables: {
                                        displayField: "name",
                                        fields: ["name", "value"],
                                    },
                                },
                                value: "",
                                rules: {},
                                fullWidth: true,
                            },
                            content: {
                                type: "md",
                                selectable: true,
                                selectionModels: {
                                    users: {
                                        displayField: "fullName",
                                        fields: [
                                            "username",
                                            "firstName",
                                            "lastName",
                                            "fullName",
                                        ],
                                    },
                                    clients: {
                                        displayField: "name",
                                        fields: ["name"],
                                    },
                                    globalVariables: {
                                        displayField: "name",
                                        fields: ["name", "value"],
                                    },
                                },
                                value: { htmlContent: "", attachments: [] },
                                rules: {},
                                fullWidth: true,
                                multiline: true,
                            },
                        },
                    },
                },
            },
            {
                type: "FORM",
                data: {
                    maxInput: 1,
                    settings: {
                        kind: "formGenerator",
                        name: "",
                        description: "",
                        content: [],
                        targets: [],
                    },
                },
            },
            {
                type: "EDIT_SITE",
                data: {
                    maxInput: 1,
                    settings: {
                        kind: "editSiteComposer",
                        name: "",
                        description: "",
                        content: [],
                    },
                },
            },
            {
                type: "COMMENT",
                data: {
                    maxInput: 1,
                    settings: {
                        kind: "fields",
                        name: "",
                        description: "",
                        fields: {
                            text: {
                                type: "mention",
                                selectable: true,
                                selectionModels: {
                                    users: {
                                        displayField: "fullName",
                                        fields: [
                                            "username",
                                            "firstName",
                                            "lastName",
                                            "fullName",
                                        ],
                                    },
                                    clients: {
                                        displayField: "name",
                                        fields: ["name"],
                                    },
                                    globalVariables: {
                                        displayField: "name",
                                        fields: ["name", "value"],
                                    },
                                },
                                value: "",
                                fullWidth: true,
                            },
                        },
                    },
                },
            },
            {
                type: "CHECKLIST_BUILD",
                data: {
                    maxInput: 1,
                    settings: {
                        kind: "fields",
                        name: "",
                        description: "",
                        fields: {
                            buildTargets: {
                                type: "autocomplete",
                                options: [
                                    {
                                        value: "clientCoordinator",
                                        label: "FORM_BUILDER:FORM_TARGETS:CLIENT_COORDINATOR",
                                        translate: true,
                                    },
                                    {
                                        value: "internalCoordinators",
                                        label: "FORM_BUILDER:FORM_TARGETS:INTERNAL_COORDINATORS",
                                        translate: true,
                                    },
                                    {
                                        value: "technicians",
                                        label: "FORM_BUILDER:FORM_TARGETS:TECHNICIANS",
                                        translate: true,
                                    },
                                    {
                                        value: "measurers",
                                        label: "FORM_BUILDER:FORM_TARGETS:MEASURERS",
                                        translate: true,
                                    },
                                    {
                                        value: "managers",
                                        label: "FORM_BUILDER:FORM_TARGETS:MANAGERS",
                                        translate: true,
                                    },
                                ],
                                selectable: false,
                                value: [],
                                rules: {
                                    required: "FORM:ERROR_MESSAGES:REQUIRED",
                                },
                                fullWidth: true,
                            },
                            fillTargets: {
                                type: "autocomplete",
                                options: [
                                    {
                                        value: "clientCoordinator",
                                        label: "FORM_BUILDER:FORM_TARGETS:CLIENT_COORDINATOR",
                                        translate: true,
                                    },
                                    {
                                        value: "internalCoordinators",
                                        label: "FORM_BUILDER:FORM_TARGETS:INTERNAL_COORDINATORS",
                                        translate: true,
                                    },
                                    {
                                        value: "technicians",
                                        label: "FORM_BUILDER:FORM_TARGETS:TECHNICIANS",
                                        translate: true,
                                    },
                                    {
                                        value: "measurers",
                                        label: "FORM_BUILDER:FORM_TARGETS:MEASURERS",
                                        translate: true,
                                    },
                                    {
                                        value: "managers",
                                        label: "FORM_BUILDER:FORM_TARGETS:MANAGERS",
                                        translate: true,
                                    },
                                ],
                                selectable: false,
                                value: [],
                                rules: {
                                    required: "FORM:ERROR_MESSAGES:REQUIRED",
                                },
                                fullWidth: true,
                            },
                        },
                    },
                },
            },
            {
                type: "FILL_REPORT",
                data: {
                    maxInput: 1,
                    settings: {
                        kind: "fillReportForm",
                        name: "",
                        description: "",
                        content: {},
                    },
                },
            },
            {
                type: "END",
                data: { maxOutput: 0 },
            },
        ],
        []
    );

    return { initialNodes };
}

export default useNodeTypes;
