import React from "react";
import {
    Divider,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
} from "@mui/material";

function ContextMenu({
    data,
    open,
    onClose,
    anchorReference,
    anchorPosition,
    sections,
}) {
    return (
        <Menu
            open={open}
            onClose={onClose}
            anchorReference={anchorReference}
            anchorPosition={anchorPosition}
            onContextMenu={(event) => {
                event.preventDefault();
                onClose();
            }}
        >
            {sections.map((section, index) => [
                ...section.items.map((item, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => {
                            item.onClick(data);
                            onClose();
                        }}
                    >
                        <ListItemIcon>
                            <item.icon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{item.text}</ListItemText>
                        <Typography variant="body2" color="text.secondary">
                            {item.tail}
                        </Typography>
                    </MenuItem>
                )),
                ...(index > 0 ? [<Divider />] : []),
            ])}
        </Menu>
    );
}

export default ContextMenu;
