import React, { useState, useEffect } from "react";
import "./FilesInput.scss";
import { Dropzone } from "@mantine/dropzone";
import {
    AddCircleOutlineOutlined,
    HighlightOffTwoTone,
    Refresh,
} from "@mui/icons-material";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useIntl } from "react-intl";
import {
    camelToSnakeUpperCase,
    textToBase64,
} from "../../../../../utils/general";
import { toast } from "react-toastify";
import DataValidationError from "../../../../../errors/DataValidationError";
import FilePreview from "./filePreview/FilePreview";
import { defaultStyles, FileIcon } from "react-file-icon";

function FilesInput({ _id }) {
    const [files, setFiles] = useState([]);
    const [loadingFiles, setLoadingFiles] = useState(true);
    const [selectedFile, setSelectedFile] = useState(-1);
    const [fetchError, setFetchError] = useState(false);

    const intl = useIntl();
    const theme = useTheme();

    useEffect(() => {
        fetchFiles();
    }, []);

    const fetchFiles = async () => {
        try {
            setFiles([]);
            setFetchError(false);
            setLoadingFiles(true);
            let files = []; //await getSiteFilesById(_id);
            const token = window.localStorage.getItem("token") || "";
            let newFiles = files.map((file) => ({
                ...file,
                fullPath: `${
                    process.env.REACT_APP_BASE_URL
                }/static/tasks/${_id}/files/${file.path}?token=${textToBase64(
                    token
                )}`,
            }));
            setFiles(newFiles);
        } catch (error) {
            setFetchError(true);
            toast.error(intl.formatMessage({ id: error.message }));
        } finally {
            setLoadingFiles(false);
        }
    };

    const saveFileName = async (path, name) => {
        try {
            // await updateFileName(_id, { path, name });
            setFiles(
                files.map((file) =>
                    file.path === path ? { ...file, name } : file
                )
            );
            return true;
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    toast.error(
                        intl.formatMessage(
                            { id: "ERROR:FORMAT" },
                            {
                                field: intl.formatMessage({
                                    id: `SITES:${camelToSnakeUpperCase(
                                        field.path
                                    )}`,
                                }),
                                value: field.value,
                            }
                        )
                    );
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
            return false;
        }
    };

    const removeFile = async (path) => {
        try {
            // await deleteFileById(_id, textToBase64(path));
            setFiles(files.filter((iamge) => iamge.path !== path));
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        }
    };

    const handleFilesDrop = async (imgs) => {
        try {
            setLoadingFiles(true);
            let newFiles = []; //await uploadSiteFiles(_id, imgs);
            const token = window.localStorage.getItem("token") || "";
            setFiles(
                newFiles.map((file) => ({
                    ...file,
                    fullPath: `${
                        process.env.REACT_APP_BASE_URL
                    }/static/sites/${_id}/files/${
                        file.path
                    }?token=${textToBase64(token)}`,
                }))
            );
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    if (field.kind === "unique") {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:UNIQUE" },
                                {
                                    field: intl.formatMessage({
                                        id: `SITES:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    } else {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:FORMAT" },
                                {
                                    field: intl.formatMessage({
                                        id: `SITES:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    }
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        } finally {
            setLoadingFiles(false);
        }
    };

    return (
        <Box
            width="100%"
            boxSizing="border-box"
            padding="10px 10px 0 10px"
            className="files-input-container"
        >
            <Dropzone
                onDrop={handleFilesDrop}
                padding="0"
                style={{
                    width: "100%",
                    ...(fetchError
                        ? { borderColor: theme.palette.error.main }
                        : files.length === 0
                        ? {}
                        : {
                              borderColor: theme.palette.primary.main,
                          }),
                }}
                loading={loadingFiles}
                disabled={fetchError}
            >
                {(status) =>
                    fetchError ? (
                        <Box
                            className="dropzone-content"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <IconButton
                                color="error"
                                size="large"
                                onClick={fetchFiles}
                            >
                                <Refresh fontSize="large" />
                            </IconButton>
                        </Box>
                    ) : files.length === 0 ? (
                        <Box
                            className="dropzone-content"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography color="primary" fontSize="20px">
                                {intl.formatMessage({
                                    id: "GLOBAL:DRAG_ZONE_MESSAGE",
                                })}
                            </Typography>
                        </Box>
                    ) : (
                        <Box className="dropzone-content">
                            <Box
                                display="flex"
                                alignItems="center"
                                gap={1}
                                height="90px"
                                justifyContent="flex-start"
                                flexDirection="row"
                                padding="0 0 0 20px"
                                maxWidth="calc(100% - 100px)"
                                boxSizing="border-box"
                                margin="5px 0"
                                style={{
                                    overflowX: "auto",
                                }}
                            >
                                {files.map((file, index) => {
                                    let [ext, ...more] = file.path
                                        .split(".")
                                        .reverse();
                                    return (
                                        <Box
                                            key={index}
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            title={file.name}
                                            width="60px"
                                            gap={1}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setSelectedFile(index);
                                            }}
                                        >
                                            <Box
                                                display="flex"
                                                position="relative"
                                                overflow="visible"
                                                width="25px"
                                                minWidth="35px"
                                            >
                                                <IconButton
                                                    style={{
                                                        width: "25px",
                                                        height: "35px",
                                                        padding: 0,
                                                    }}
                                                >
                                                    <FileIcon
                                                        extension={ext}
                                                        {...defaultStyles[ext]}
                                                    />
                                                </IconButton>
                                                <IconButton
                                                    style={{
                                                        padding: 0,
                                                        position: "absolute",
                                                        color: "red",
                                                        top: "-8px",
                                                        left: "-8px",
                                                    }}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        removeFile(file.path);
                                                    }}
                                                >
                                                    <HighlightOffTwoTone fontSize="small" />
                                                </IconButton>
                                            </Box>
                                            <Typography
                                                component="span"
                                                textOverflow="ellipsis"
                                                width="100%"
                                                maxHeight="13px"
                                                color="primary"
                                                fontSize="13px"
                                                lineHeight="13px"
                                                overflow="hidden"
                                                letterSpacing={0.1}
                                            >
                                                {file.name}
                                            </Typography>
                                        </Box>
                                    );
                                })}
                            </Box>
                            <IconButton
                                color="primary"
                                sx={{
                                    height: "calc(100% - 40px)",
                                    width: "unset",
                                    aspectRatio: "1",
                                    padding: 0,
                                    margin: "20px",
                                    boxSizing: "border-box",
                                }}
                            >
                                <AddCircleOutlineOutlined fontSize="large" />
                            </IconButton>
                        </Box>
                    )
                }
            </Dropzone>
            {selectedFile >= 0 && selectedFile < files.length && (
                <FilePreview
                    files={files}
                    selectedFile={selectedFile}
                    initialActiveDocument={{
                        ...files[selectedFile],
                        uri: files[selectedFile].fullPath,
                    }}
                    close={() => {
                        setSelectedFile(-1);
                    }}
                    previous={
                        selectedFile > 0
                            ? (previousDocument) => {
                                  setSelectedFile(selectedFile - 1);
                                  previousDocument();
                              }
                            : null
                    }
                    next={
                        selectedFile < files.length - 1
                            ? (nextDocument) => {
                                  setSelectedFile(selectedFile + 1);
                                  nextDocument();
                              }
                            : null
                    }
                    saveName={saveFileName}
                />
            )}
        </Box>
    );
}

export default FilesInput;
