import { createSlice } from "@reduxjs/toolkit";
export const userSlice = createSlice({
	name: "user",
	initialState: {
		info: {},
		permissions: [],
		id: "",
		locale: "",
	},
	reducers: {
		setInfo: (state, action) => {
			state.info = action.payload;
		},
		setPermissions: (state, action) => {
			state.permissions = action.payload;
		},
		setId: (state, action) => {
			state.id = action.payload;
		},
		setLocale: (state, action) => {
			state.locale = action.payload;
		},
		clear: (state, action) => {
			state.info = {};
			state.permissions = [];
			state.id = "";
		},
	},
});

export const { setInfo, setPermissions, setId, setLocale, clear } =
	userSlice.actions;
export const selectInfo = (state) => state.user.info;
export const selectPermissions = (state) => state.user.permissions;
export const selectId = (state) => state.user.id;
export const selectLocale = (state) => state.user.locale;
export default userSlice.reducer;
