import React, { useState } from "react";
import "./Comments.scss";
import { Avatar, Box, IconButton, Link, Typography } from "@mui/material";
import { stringAvatar } from "../../../../../utils/avatar";
import Tribute from "tributejs";
import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Close, Delete, Refresh } from "@mui/icons-material";
import { toast } from "react-toastify";
import {
    deleteComment,
    getSiteCommentsById,
    pushComment,
} from "../../../../../api/site";
import { useSelector } from "react-redux";
import { selectInfo } from "../../../../../redux/slices/userSlice";
import { useEffect } from "react";
import DataValidationError from "../../../../../errors/DataValidationError";
import { useMemo } from "react";
import Loading from "../../../../../components/loading/Loading";
import {
    camelToSnakeUpperCase,
    capitalize,
} from "../../../../../utils/general";
import parse, { domToReact } from "html-react-parser";
import useAbility from "../../../../../hooks/useAbility";
function Comments({ _id, users }) {
    const [comments, setComments] = useState([]);
    const [selectedCommentId, setSelectedCommentId] = useState(null);
    const [loadingComments, setLoadingComments] = useState(true);
    const [fetchError, setFetchError] = useState(false);
    const {can}=useAbility()
    const populatedComments = useMemo(
        () =>
            comments.map((comment) =>
                comment.replyTo
                    ? {
                          ...comment,
                          replyTo:
                              comments.find(
                                  (el) => el._id === comment.replyTo
                              ) ?? null,
                      }
                    : comment
            ),
        [comments]
    );

    const selectedComment = useMemo(
        () => comments.find((comment) => comment._id === selectedCommentId),
        [comments, selectedCommentId]
    );

    const intl = useIntl();
    const info = useSelector(selectInfo);

    const tribute = useMemo(
        () =>
            new Tribute({
                values: users.map((user) => ({
                    value: user,
                    key: user._id,
                })),
                selectTemplate: (item) =>
                    `<span id="${item.original.key}" title="${capitalize(
                        `${item.original.value.firstName} ${item.original.value.lastName}`
                    )}" contenteditable=false class="selected-mention">@${
                        item.original.value.username
                    }</span>`,
                menuItemTemplate: (item) =>
                    `${item.original.value.officerInfo.firstName} ${item.original.value.officerInfo.lastName}`,
                lookup: (item) => {
                    return `${item.value.officerInfo.firstName} ${item.value.officerInfo.lastName}`;
                },
            }),
        [users]
    );

    const commentRef = useCallback((node) => {
        if (node !== null) {
            tribute.attach(node);
        }
    }, []);

    useEffect(() => {
        fetchComments();
    }, []);

    useEffect(() => {
        let [_, commentId] = window.location.href.split("#");
        if (commentId) {
            document.getElementById(commentId)?.scrollIntoView();
        }
    }, [comments]);

    const commentParserOptions = useMemo(
        () => ({
            replace: ({ attribs, children }) => {
                if (!attribs) {
                    return;
                }

                if (attribs.class === "comment-mention") {
                    return (
                        <Typography
                            component="span"
                            color="secondary"
                            fontSize="inherit"
                            title={attribs.title}
                            sx={{ cursor: "pointer" }}
                        >
                            {domToReact(children)}
                        </Typography>
                    );
                }
            },
        }),
        []
    );

    const renderCommentText = useCallback(
        (comment) =>
            parse(
                comment.text
                    .replaceAll(" ", "&nbsp;")
                    .replaceAll("\n", "<br>")
                    .replaceAll(
                        /@\{\{([a-fA-F0-9]{24}):(.*?)}}/g,
                        (m, userId, username) => {
                            let user = users.find((u) => u._id === userId);
                            return `<span class="comment-mention"${
                                user ? ` title="${user.username}"` : ""
                            }>@${
                                user
                                    ? capitalize(
                                          `${user.firstName} ${user.lastName}`
                                      )
                                    : username
                            }</span>`;
                        }
                    ),
                commentParserOptions
            ),
        [users, commentParserOptions]
    );

    const fetchComments = async () => {
        try {
            setFetchError(false);
            setLoadingComments(true);
            let comments = await getSiteCommentsById(_id);
            setComments(
                comments.map((comment) => ({
                    ...comment,
                    sender: comment.sender
                        ? {
                              ...comment.sender,
                              ...comment.sender.officerInfo,
                              _id: comment.sender._id,
                          }
                        : null,
                }))
            );
        } catch (error) {
            setFetchError(true);
            toast.error(intl.formatMessage({ id: error.message }));
        } finally {
            setLoadingComments(false);
        }
    };

    const sendComment = async (text, replyTo) => {
        try {
            let comments = await pushComment(_id, { text, replyTo });
            window.location.assign("#write-comment-container");
            setComments(
                comments.map((comment) => ({
                    ...comment,
                    sender: comment.sender
                        ? {
                              ...comment.sender,
                              ...comment.sender.officerInfo,
                              _id: comment.sender._id,
                          }
                        : null,
                }))
            );
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    toast.error(
                        intl.formatMessage(
                            { id: "ERROR:FORMAT" },
                            {
                                field: intl.formatMessage({
                                    id: `SITES:${camelToSnakeUpperCase(
                                        field.path
                                    )}`,
                                }),
                                value: field.value,
                            }
                        )
                    );
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        }
    };

    const removeComment = async (comment) => {
        try {
            let comments = await deleteComment(_id, comment);
            setComments(
                comments.map((comment) => ({
                    ...comment,
                    sender: comment.sender
                        ? {
                              ...comment.sender,
                              ...comment.sender.officerInfo,
                              _id: comment.sender._id,
                          }
                        : null,
                }))
            );
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    toast.error(
                        intl.formatMessage(
                            { id: "ERROR:FORMAT" },
                            {
                                field: intl.formatMessage({
                                    id: `SITES:${camelToSnakeUpperCase(
                                        field.path
                                    )}`,
                                }),
                                value: field.value,
                            }
                        )
                    );
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        }
    };

    return (
        <Box className="comments-container">
            {loadingComments && (
                <Loading
                    container={{
                        position: "absolute",
                        backgroundColor: "#000000aa",
                        zIndex: 99,
                        borderRadius: "13px",
                    }}
                    color="white"
                />
            )}
            {fetchError ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                    <IconButton
                        color="error"
                        size="large"
                        onClick={fetchComments}
                    >
                        <Refresh fontSize="large" />
                    </IconButton>
                </Box>
            ) : (
                <>
                    {populatedComments.map((comment, index) => (
                        <Box
                            id={comment._id}
                            className="comment-container"
                            key={comment._id}
                        >
                            {comment.sender?.image ? (
                                <Avatar
                                    src={`data:image;base64,${comment.sender.image}`}
                                    alt="avatar"
                                    sx={{ width: 40, height: 40 }}
                                />
                            ) : (
                                <Avatar
                                    {...stringAvatar(
                                        comment.sender
                                            ? `${comment.sender.firstName} ${comment.sender.lastName}`
                                            : "Work Flow"
                                    )}
                                />
                            )}
                            <Box
                                className={`comment${
                                    selectedCommentId === comment._id
                                        ? " selected"
                                        : ""
                                }`}
                            >
                                <Link
                                    className="reply-button"
                                    href="#write-comment-container"
                                    color="quaternary.main"
                                    fontWeight="bold"
                                    onClick={() => {
                                        setSelectedCommentId(comment._id);
                                    }}
                                >
                                    {intl.formatMessage({ id: "SITES:REPLY" })}
                                </Link>
                                {comment.replyTo ? (
                                    <Link
                                        href={`#${comment.replyTo._id}`}
                                        sx={{ textDecoration: "unset" }}
                                    >
                                        <Box className="reply">
                                            <Box
                                                className="comment-container"
                                                key={index}
                                            >
                                                {comment.replyTo.sender
                                                    ?.image ? (
                                                    <Avatar
                                                        src={`data:image;base64,${comment.replyTo.sender.image}`}
                                                        alt="avatar"
                                                        sx={{
                                                            width: 40,
                                                            height: 40,
                                                        }}
                                                    />
                                                ) : (
                                                    <Avatar
                                                        {...stringAvatar(
                                                            comment.replyTo
                                                                .sender
                                                                ? `${comment.replyTo.sender.firstName} ${comment.replyTo.sender.lastName}`
                                                                : "Work Flow"
                                                        )}
                                                    />
                                                )}
                                                <Box className="comment">
                                                    <Box className="header">
                                                        <Typography
                                                            component="span"
                                                            fontSize={14}
                                                            fontWeight="bold"
                                                            title={
                                                                comment.replyTo
                                                                    .sender
                                                                    ?.username ??
                                                                "Workflow"
                                                            }
                                                        >
                                                            {capitalize(
                                                                comment.replyTo
                                                                    .sender
                                                                    ? `${comment.replyTo.sender.firstName} ${comment.replyTo.sender.lastName}`
                                                                    : "Workflow"
                                                            )}
                                                        </Typography>
                                                        <Typography
                                                            fontSize={12}
                                                        >
                                                            {new Date(
                                                                comment.replyTo.timestamp
                                                            ).toLocaleString(
                                                                "en-GB"
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                    <Box className="text">
                                                        <Typography
                                                            fontSize={16}
                                                            sx={{
                                                                wordBreak:
                                                                    "break-word",
                                                            }}
                                                        >
                                                            {renderCommentText(
                                                                comment.replyTo
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Link>
                                ) : (
                                    comment.replyTo === null && (
                                        <Box className="reply">
                                            <FormattedMessage id="SITES:REPLY_DELETED" />
                                        </Box>
                                    )
                                )}
                                <Box className="header">
                                    <Typography
                                        component="span"
                                        fontSize={14}
                                        fontWeight="bold"
                                        title={
                                            comment.sender?.username ??
                                            "Workflow"
                                        }
                                    >
                                        {capitalize(
                                            comment.sender
                                                ? `${comment.sender.firstName} ${comment.sender.lastName}`
                                                : "Workflow"
                                        )}
                                    </Typography>
                                    <Typography fontSize={12}>
                                        {new Date(
                                            comment.timestamp
                                        ).toLocaleString("en-GB")}
                                    </Typography>
                                </Box>
                                <Box className="text">
                                    <Typography
                                        fontSize={16}
                                        sx={{ wordBreak: "break-word" }}
                                    >
                                        {renderCommentText(comment)}
                                    </Typography>
                                </Box>
                            </Box>
                            {(can("DELETE_COMMENTS", "ALL_SITES") ||
                    can("DELETE_COMMENTS", "RELATED_SITES") ||
                    can("DELETE_COMMENTS", "CREATED_SITES")) && <IconButton
                                onClick={() => {
                                    removeComment(comment._id);
                                }}
                            >
                                <Delete />
                            </IconButton>}
                        </Box>
                    ))}
                    <Box
                        display="flex"
                        flexDirection="column"
                        className="write-comment-box"
                    >
                        {selectedComment && (
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                            >
                                <Link
                                    href={`#${selectedComment._id}`}
                                    sx={{ textDecoration: "unset", flex: 1 }}
                                >
                                    <Box className="reply">
                                        <Box className="comment-container">
                                            {selectedComment.sender?.image ? (
                                                <Avatar
                                                    src={`data:image;base64,${selectedComment.sender.image}`}
                                                    alt="avatar"
                                                    sx={{
                                                        width: 40,
                                                        height: 40,
                                                    }}
                                                />
                                            ) : (
                                                <Avatar
                                                    {...stringAvatar(
                                                        selectedComment.sender
                                                            ? `${selectedComment.sender.firstName} ${selectedComment.sender.lastName}`
                                                            : "Workflow"
                                                    )}
                                                />
                                            )}
                                            <Box className="comment">
                                                <Box className="header">
                                                    <Typography
                                                        component="span"
                                                        fontSize={14}
                                                        fontWeight="bold"
                                                        title={
                                                            selectedComment
                                                                .sender
                                                                ?.username ??
                                                            "Workflow"
                                                        }
                                                    >
                                                        {capitalize(
                                                            selectedComment.sender
                                                                ? `${selectedComment.sender.firstName} ${selectedComment.sender.lastName}`
                                                                : "Workflow"
                                                        )}
                                                    </Typography>
                                                    <Typography fontSize={12}>
                                                        {new Date(
                                                            selectedComment.timestamp
                                                        ).toLocaleString(
                                                            "en-GB"
                                                        )}
                                                    </Typography>
                                                </Box>
                                                <Box className="text">
                                                    <Typography
                                                        fontSize={16}
                                                        sx={{
                                                            wordBreak:
                                                                "break-word",
                                                        }}
                                                    >
                                                        {renderCommentText(
                                                            selectedComment
                                                        )}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Link>
                                <IconButton
                                    onClick={() => {
                                        setSelectedCommentId(null);
                                    }}
                                >
                                    <Close color="quaternary" />
                                </IconButton>
                            </Box>
                        )}
                        {(can("SEND_COMMENTS", "ALL_SITES") ||
                    can("SEND_COMMENTS", "RELATED_SITES") ||
                    can("SEND_COMMENTS", "CREATED_SITES")) && <Box
                            id="write-comment-container"
                            className="write-comment-container"
                        >
                            {info.image ? (
                                <Avatar
                                    src={`data:image;base64,${info.image}`}
                                    alt="avatar"
                                    sx={{ width: 40, height: 40 }}
                                />
                            ) : (
                                <Avatar
                                    {...stringAvatar(
                                        `${info.firstName} ${info.lastName}`
                                    )}
                                />
                            )}
                            <div
                                contentEditable
                                suppressContentEditableWarning
                                ref={commentRef}
                                placeholder={intl.formatMessage({
                                    id: "SITES:COMMENT_PLACEHOLDER",
                                })}
                                className="my-comment-container"
                                onKeyDown={(event) => {
                                    if (
                                        event.key === "Enter" &&
                                        !event.shiftKey
                                    ) {
                                        event.preventDefault();
                                        let text = event.currentTarget.innerHTML
                                            .toString()
                                            .replaceAll(
                                                /<span id="([a-fA-F0-9]{24})" title="(.*?)" contenteditable="false" class="selected-mention">@(.*?)<\/span>/g,
                                                "@{{$1:$3}}"
                                            )
                                            .replaceAll("&nbsp;", " ")
                                            .replaceAll("<br>", "\n");
                                        sendComment(text, selectedCommentId);
                                        event.currentTarget.innerHTML = "";
                                        setSelectedCommentId(null);
                                    }
                                }}
                            ></div>
                        </Box>}
                    </Box>
                </>
            )}
        </Box>
    );
}

export default Comments;
