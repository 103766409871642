import React, { useState } from "react";
import { Button, Box, AppBar, Typography, IconButton } from "@mui/material";
import { RichTextEditor } from "@mantine/rte";
import Loading from "../../../../../../components/loading/Loading";
import "./MdInputModal.scss";
import { Dropzone } from "@mantine/dropzone";
import { FileIcon, defaultStyles } from "react-file-icon";
import {
    AddCircleOutlineOutlined,
    Colorize,
    HighlightOffTwoTone,
} from "@mui/icons-material";
import { useIntl } from "react-intl";
import { copyAnyObject, fileToBase64 } from "../../../../../../utils/general";
import { useEffect } from "react";
import DynamicPicker from "../../dynamicPicker/DynamicPicker";
import { useRef } from "react";
import { useCallback } from "react";

function MdInputModal({ close, submit, title, value, pickData, dynamicData }) {
    const [attachments, setAttachments] = useState(value.attachments ?? []);
    const [loading, setLoading] = useState(false);
    const [loadingAttachments, setLoadingAttachments] = useState(false);
    const [showPicker, setShowPicker] = useState(false);
    const intl = useIntl();
    const rteRef = useRef();

    useEffect(() => {
        const editor = rteRef.current.getEditor();
        const delta = editor.clipboard.convert(value.htmlContent);
        editor.setContents(delta);
    }, []);

    const pick = (value) => {
        const l = rteRef.current.getEditor().getLength();
        const i = rteRef.current.getEditor().getSelection()?.index ?? l;
        rteRef.current.getEditor().insertText(i, ` ${value} `);
    };

    const handleFilesDrop = async (files) => {
        setLoadingAttachments(true);
        let newFiles = [];
        for (let file of files) {
            try {
                let fileBase64 = await fileToBase64(file);
                newFiles.push({
                    name: file.name,
                    value: fileBase64,
                });
            } catch (error) {}
        }
        setAttachments([...attachments, ...newFiles]);
        setLoadingAttachments(false);
    };

    const handleSubmit = useCallback(() => {
        const editor = rteRef.current.getEditor();
        const result = editor.root.innerHTML;
        submit(
            copyAnyObject({
                htmlContent: result,
                attachments,
            })
        );
        close();
    }, [rteRef]);

    return (
        <React.Fragment>
            <AppBar
                sx={{
                    position: "relative",
                    height: "60px",
                }}
            >
                <Box
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant="h4" component="h1" color="white">
                        {title}
                    </Typography>
                </Box>
            </AppBar>
            {loading || attachments === null ? (
                <Loading
                    container={{ marginTop: "30px", marginBottom: "30px" }}
                />
            ) : (
                <React.Fragment>
                    <Box className="md-input-rte-container">
                        <RichTextEditor
                            sticky
                            className="md-input-rte"
                            ref={rteRef}
                        />
                        <Box
                            width="100%"
                            boxSizing="border-box"
                            padding="10px 10px 10px 10px"
                        >
                            <Dropzone
                                onDrop={handleFilesDrop}
                                padding="0"
                                style={{ width: "100%" }}
                                loading={loadingAttachments}
                            >
                                {(status) =>
                                    attachments.length === 0 ? (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gap={2}
                                            height="80px"
                                            justifyContent="center"
                                            flexDirection="row"
                                        >
                                            <Typography
                                                color="primary"
                                                fontSize="20px"
                                            >
                                                {intl.formatMessage({
                                                    id: "GLOBAL:DRAG_ZONE_MESSAGE",
                                                })}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Box display="flex" flexDirection="row">
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                gap={2}
                                                height="80px"
                                                justifyContent="flex-start"
                                                flexDirection="row"
                                                padding="0 0 0 20px"
                                                maxWidth="calc(100% - 80px)"
                                                boxSizing="border-box"
                                                style={{
                                                    overflowX: "auto",
                                                }}
                                            >
                                                {attachments.map(
                                                    (attachment, index) => {
                                                        let [ext, ...more] =
                                                            attachment.name
                                                                .split(".")
                                                                .reverse();
                                                        return (
                                                            <Box
                                                                key={index}
                                                                display="flex"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                width="35px"
                                                                minWidth="35px"
                                                                title={
                                                                    attachment.name
                                                                }
                                                                position="relative"
                                                                overflow="visible"
                                                            >
                                                                <FileIcon
                                                                    extension={
                                                                        ext
                                                                    }
                                                                    {...defaultStyles[
                                                                        ext
                                                                    ]}
                                                                />
                                                                <IconButton
                                                                    style={{
                                                                        padding: 0,
                                                                        position:
                                                                            "absolute",
                                                                        color: "red",
                                                                        top: "-8px",
                                                                        left: "-8px",
                                                                    }}
                                                                    onClick={(
                                                                        event
                                                                    ) => {
                                                                        event.stopPropagation();
                                                                        setAttachments(
                                                                            attachments.filter(
                                                                                (
                                                                                    _,
                                                                                    i
                                                                                ) =>
                                                                                    i !==
                                                                                    index
                                                                            )
                                                                        );
                                                                    }}
                                                                >
                                                                    <HighlightOffTwoTone fontSize="small" />
                                                                </IconButton>
                                                            </Box>
                                                        );
                                                    }
                                                )}
                                            </Box>
                                            <IconButton
                                                color="primary"
                                                sx={{
                                                    width: "80px",
                                                    padding: 0,
                                                }}
                                            >
                                                <AddCircleOutlineOutlined fontSize="large" />
                                            </IconButton>
                                        </Box>
                                    )
                                }
                            </Dropzone>
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        alignItems="center"
                        height="60px"
                        flexDirection="row"
                        justifyContent="space-between"
                        paddingLeft="20px"
                        paddingRight="20px"
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            gap={2}
                            height="60px"
                            flexDirection="row"
                        >
                            <Button onClick={close} aria-label="close">
                                {intl.formatMessage({
                                    id: "GLOBAL:CANCEL",
                                })}
                            </Button>
                            <Button onClick={handleSubmit} aria-label="confirm">
                                {intl.formatMessage({
                                    id: "GLOBAL:CONFIRM",
                                })}
                            </Button>
                        </Box>
                        <Button
                            onClick={() => {
                                setShowPicker(true);
                            }}
                            aria-label="close"
                        >
                            <Colorize />
                        </Button>
                    </Box>
                </React.Fragment>
            )}
            {pickData && (
                <DynamicPicker
                    open={showPicker}
                    handleClose={() => {
                        setShowPicker(false);
                    }}
                    onConfirm={pick}
                    data={pickData}
                    dynamicData={dynamicData}
                />
            )}
        </React.Fragment>
    );
}

export default MdInputModal;
