import React from "react";
import { Handle } from "reactflow";
import "./FormNode.scss";
import { Article } from "@mui/icons-material";
import useVerifyConnection from "../../../../../hooks/useVerifyConnection";

function FormNode({ selected, isValid }) {
    const verifyConnection = useVerifyConnection();

    return (
        <>
            <Handle
                type="source"
                position="right"
                isValidConnection={verifyConnection}
                id="SUCCESS"
            />
            <Handle
                type="source"
                position="bottom"
                isValidConnection={verifyConnection}
                id="ERROR"
            />
            <div
                className={`form-node-container${selected ? " selected" : ""}`}
            >
                <div className="form-content">
                    <Article fontSize="large" />
                </div>
            </div>
            <Handle
                type="target"
                position="left"
                isValidConnection={verifyConnection}
            />
        </>
    );
}

export default FormNode;
