import React, { useState, useEffect } from "react";
import { Box, IconButton } from "@mui/material";
import { ReactFlowProvider } from "reactflow";
import BuilderToolbar from "./builderToolBar/BuilderToolbar";
import BuilderSettings from "./builderSettings/BuilderSettings";
import Flow from "./flow/Flow";
import "./BuildWorkflow.scss";
import {
    CloseFullscreen,
    OpenInFull,
    Visibility,
    VisibilityOff,
} from "@mui/icons-material";
import { useParams } from "react-router-dom";
import WorkflowContext from "../../contexts/WorkflowContext";
import Loading from "../../components/loading/Loading";
import { getWorkflowById } from "../../api/workflow";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { useFullscreen } from "@mantine/hooks";
import { getOfficers } from "../../api/officerAccount";
import { getClients } from "../../api/client";
import { getGlobalVariables } from "../../api/globalVariable";
import { getWorkflowPhases } from "../../api/workflowPhase";

function BuildWorkflow() {
    const { toggle, fullscreen } = useFullscreen();
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState("Untitled");
    const [data, setData] = useState(null);
    const [error, setError] = useState("");
    const [blockDelete, setBlockDelete] = useState(0);
    const [settings, setSettings] = useState({});
    const [selectedNode, setSelectedNode] = useState(null);
    const [users, setUsers] = useState([]);
    const [clients, setClients] = useState([]);
    const [globalVariables, setGlobalVariables] = useState([]);
    const [nodes, setNodes] = useState([]);
    const [draggingNode, setDraggingNode] = useState(false);
    const [showFlowControls, setShowFlowControls] = useState(true);
    const [workflowPhases, setWorkflowPhases] = useState([]);
    const { id } = useParams();
    const intl = useIntl();

    useEffect(() => {
        setLoading(true);
        const promises = [
            setupUsers(),
            setupClients(),
            setupGlobalVariables(),
            setupWorkflowPhases(),
        ];
        if (id) {
            promises.push(getData(id));
        }
        Promise.all([promises]).finally(() => {
            setLoading(false);
        });
    }, [id]);

    const getData = async (id) => {
        try {
            let data = await getWorkflowById(id);
            setData({ nodes: data.nodes, edges: data.edges });
            setSettings(
                data.nodes.reduce((previous, current) => {
                    return current.data.settings
                        ? {
                              ...previous,
                              [current.id]: current.data.settings,
                          }
                        : previous;
                }, {})
            );
            setTitle(data.name);
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
            setError(intl.formatMessage({ id: error.message }));
        }
    };

    const setupUsers = async (count = 0) => {
        try {
            const data = await getOfficers();
            setUsers(
                data.map((element) => ({
                    _id: element._id,
                    username: element.username,
                    firstName: element.officerInfo.firstName,
                    lastName: element.officerInfo.lastName,
                    fullName: `${element.officerInfo.firstName} ${element.officerInfo.lastName}`,
                    email: element.officerInfo.email,
                    phone: element.officerInfo.phone,
                    function: element.officerInfo.function,
                    employer: element.officerInfo.employer,
                }))
            );
        } catch (error) {
            if (count < 3) {
                setupUsers(++count);
            }
            console.log(error);
        }
    };

    const setupClients = async (count = 0) => {
        try {
            const data = await getClients();
            setClients(
                data.map((element) => ({
                    _id: element._id,
                    name: element.name,
                    email: element.email,
                    phone: element.phone,
                }))
            );
        } catch (error) {
            if (count < 3) {
                setupClients(++count);
            }
            console.log(error);
        }
    };

    const setupGlobalVariables = async (count = 0) => {
        try {
            const data = await getGlobalVariables();
            setGlobalVariables(
                data.map((element) => ({
                    _id: element._id,
                    name: element.name,
                    value: element.value,
                }))
            );
        } catch (error) {
            if (count < 3) {
                setupGlobalVariables(++count);
            }
            console.log(error);
        }
    };

    const setupWorkflowPhases = async (count = 0) => {
        try {
            const data = await getWorkflowPhases();
            setWorkflowPhases(
                data.map((step) => ({
                    _id: step._id,
                    name: step.name,
                    color: step.color,
                }))
            );
        } catch (error) {
            if (count < 3) {
                setupWorkflowPhases(++count);
            }
            console.log(error);
        }
    };

    return (
        <Box className={`wokflows-container${fullscreen ? " fullscreen" : ""}`}>
            {loading ? (
                <Loading />
            ) : error ? (
                <Box className="error">{error}</Box>
            ) : (
                <WorkflowContext.Provider
                    value={{
                        title,
                        setTitle,
                        data,
                        setData,
                        blockDelete,
                        blockDeleteIncr: () => {
                            setBlockDelete(blockDelete + 1);
                        },
                        blockDeleteDecr: () => {
                            setBlockDelete(blockDelete - 1);
                        },
                        settings,
                        setSettings,
                        selectedNode,
                        setSelectedNode,
                        selection: { users, clients, globalVariables },
                        nodes,
                        setNodes,
                        draggingNode,
                        setDraggingNode,
                        showFlowControls,
                        setShowFlowControls,
                        workflowPhases,
                    }}
                >
                    <ReactFlowProvider>
                        <BuilderToolbar />
                        <Box
                            sx={{
                                position: "relative",
                                width: "100%",
                                height: "calc(100% - 120px)",
                            }}
                        >
                            <Flow />
                            <BuilderSettings />
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "20px",
                                    left: "20px",
                                    zIndex: 99,
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <IconButton onClick={toggle}>
                                    {fullscreen ? (
                                        <CloseFullscreen />
                                    ) : (
                                        <OpenInFull />
                                    )}
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        setShowFlowControls(!showFlowControls);
                                    }}
                                >
                                    {showFlowControls ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </Box>
                        </Box>
                    </ReactFlowProvider>
                </WorkflowContext.Provider>
            )}
        </Box>
    );
}

export default BuildWorkflow;
