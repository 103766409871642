import React from "react";
import "./FormField.scss";
import { Draggable } from "react-beautiful-dnd";
import { Cancel, DragIndicator } from "@mui/icons-material";
import { useIntl } from "react-intl";
import {
    Autocomplete,
    Box,
    FormControlLabel,
    IconButton,
    ListItem,
    ListItemIcon,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import useFormBuilderContext from "../../../../../../../hooks/useFormBuilderContext";
import { useMemo } from "react";
import {
    camelToSnakeUpperCase,
    intersection,
} from "../../../../../../../utils/general";
import { useEffect } from "react";
import { Controller } from "react-hook-form";

function FormField({ index, register, unregister, control, error }) {
    const { selectedField, setSelectedField, formFields, setFormFields } =
        useFormBuilderContext();

    const rules = useMemo(
        () => ["required", "minLength", "maxLength", "min", "max"],
        []
    );

    const data = formFields[index];

    const intl = useIntl();

    useEffect(() => {
        return () => {
            unregister(data.name);
        };
    }, []);

    const renderField = () => {
        switch (data.type) {
            case "staticText":
                return (
                    <TextField
                        size="small"
                        type="text"
                        label={data.label}
                        fullWidth
                        disabled={true}
                        value={data.value ?? ""}
                        sx={
                            selectedField !== data.id
                                ? {
                                      pointerEvents: "none!important",
                                      cursor: "grab!important",
                                  }
                                : {}
                        }
                        multiline={true}
                    />
                );
            case "text":
                return (
                    <TextField
                        size="small"
                        type="text"
                        label={data.label}
                        placeholder={data.placeholder}
                        multiline={!!data.multiline}
                        fullWidth
                        error={selectedField === data.id && !!error}
                        helperText={
                            selectedField === data.id ? error?.message : ""
                        }
                        disabled={selectedField !== data.id}
                        {...register(data.name, {
                            ...intersection(rules, Object.keys(data)).reduce(
                                (previous, current) => ({
                                    ...previous,
                                    [current]: {
                                        value: data[current] || undefined,
                                        message: intl.formatMessage(
                                            {
                                                id: `FORM:ERROR_MESSAGES:${camelToSnakeUpperCase(
                                                    current
                                                )}`,
                                            },
                                            { value: data[current] }
                                        ),
                                    },
                                }),
                                {}
                            ),
                            pattern: {
                                value: new RegExp(
                                    data["format"]?.value || ".*"
                                ),
                                message: intl.formatMessage({
                                    id: `FORM:ERROR_MESSAGES:WRONG_FORMAT`,
                                }),
                            },
                        })}
                        sx={
                            selectedField !== data.id
                                ? {
                                      pointerEvents: "none!important",
                                      cursor: "grab!important",
                                  }
                                : {}
                        }
                    />
                );
            case "number":
                return (
                    <TextField
                        size="small"
                        type="number"
                        label={data.label}
                        placeholder={data.placeholder}
                        fullWidth
                        error={selectedField === data.id && !!error}
                        helperText={
                            selectedField === data.id ? error?.message : ""
                        }
                        disabled={selectedField !== data.id}
                        {...register(
                            data.name,
                            intersection(rules, Object.keys(data)).reduce(
                                (previous, current) => ({
                                    ...previous,
                                    [current]: {
                                        value: data[current] || undefined,
                                        message: intl.formatMessage(
                                            {
                                                id: `FORM:ERROR_MESSAGES:${camelToSnakeUpperCase(
                                                    current
                                                )}`,
                                            },
                                            { value: data[current] }
                                        ),
                                    },
                                }),
                                {
                                    pattern: {
                                        value: /^\d*$/,
                                        message: intl.formatMessage({
                                            id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                                        }),
                                    },
                                }
                            )
                        )}
                        sx={
                            selectedField !== data.id
                                ? {
                                      pointerEvents: "none!important",
                                      cursor: "grab!important",
                                  }
                                : {}
                        }
                    />
                );
            case "decimalNumber":
                return (
                    <TextField
                        size="small"
                        type="number"
                        label={data.label}
                        placeholder={data.placeholder}
                        fullWidth
                        error={selectedField === data.id && !!error}
                        helperText={
                            selectedField === data.id ? error?.message : ""
                        }
                        disabled={selectedField !== data.id}
                        {...register(
                            data.name,
                            intersection(rules, Object.keys(data)).reduce(
                                (previous, current) => ({
                                    ...previous,
                                    [current]: {
                                        value: data[current] || undefined,
                                        message: intl.formatMessage(
                                            {
                                                id: `FORM:ERROR_MESSAGES:${camelToSnakeUpperCase(
                                                    current
                                                )}`,
                                            },
                                            { value: data[current] }
                                        ),
                                    },
                                }),
                                {
                                    pattern: {
                                        value: /^(\d*)|(\d+(\.\d+)?)/,
                                        message: intl.formatMessage({
                                            id: "FORM:ERROR_MESSAGES:WRONG_FORMAT",
                                        }),
                                    },
                                }
                            )
                        )}
                        inputProps={{ step: 0.1 }}
                        sx={
                            selectedField !== data.id
                                ? {
                                      pointerEvents: "none!important",
                                      cursor: "grab!important",
                                  }
                                : {}
                        }
                    />
                );
            case "date":
                return (
                    <TextField
                        size="small"
                        type="date"
                        label={data.label}
                        placeholder={data.placeholder}
                        fullWidth
                        error={selectedField === data.id && !!error}
                        helperText={
                            selectedField === data.id ? error?.message : ""
                        }
                        disabled={selectedField !== data.id}
                        {...register(
                            data.name,
                            intersection(rules, Object.keys(data)).reduce(
                                (previous, current) => ({
                                    ...previous,
                                    [current]: {
                                        value: data[current] || undefined,
                                        message: intl.formatMessage(
                                            {
                                                id: `FORM:ERROR_MESSAGES:${camelToSnakeUpperCase(
                                                    current
                                                )}`,
                                            },
                                            {
                                                value: new Date(
                                                    data[current]
                                                ).toLocaleDateString("en-GB"),
                                            }
                                        ),
                                    },
                                }),
                                {}
                            )
                        )}
                        InputLabelProps={{ shrink: true }}
                        sx={
                            selectedField !== data.id
                                ? {
                                      pointerEvents: "none!important",
                                      cursor: "grab!important",
                                  }
                                : {}
                        }
                    />
                );
            case "time":
                return (
                    <TextField
                        size="small"
                        type="time"
                        label={data.label}
                        placeholder={data.placeholder}
                        fullWidth
                        error={selectedField === data.id && !!error}
                        helperText={
                            selectedField === data.id ? error?.message : ""
                        }
                        disabled={selectedField !== data.id}
                        {...register(data.name, {
                            ...intersection(rules, Object.keys(data)).reduce(
                                (previous, current) => ({
                                    ...previous,
                                    [current]: {
                                        value: data[current] || undefined,
                                        message: intl.formatMessage(
                                            {
                                                id: `FORM:ERROR_MESSAGES:${camelToSnakeUpperCase(
                                                    current
                                                )}`,
                                            },
                                            {
                                                value: data[current],
                                            }
                                        ),
                                    },
                                }),
                                {}
                            ),
                            validate: {
                                ...(data.min
                                    ? {
                                          min: (value) =>
                                              value >= data.min ||
                                              intl.formatMessage(
                                                  {
                                                      id: "FORM:ERROR_MESSAGES:MIN",
                                                  },
                                                  {
                                                      value: data.min,
                                                  }
                                              ),
                                          max: (value) =>
                                              value <= data.max ||
                                              intl.formatMessage(
                                                  {
                                                      id: "FORM:ERROR_MESSAGES:MAX",
                                                  },
                                                  {
                                                      value: data.max,
                                                  }
                                              ),
                                      }
                                    : {}),
                            },
                        })}
                        InputLabelProps={{ shrink: true }}
                        sx={
                            selectedField !== data.id
                                ? {
                                      pointerEvents: "none!important",
                                      cursor: "grab!important",
                                  }
                                : {}
                        }
                    />
                );
            case "datetime":
                return (
                    <TextField
                        size="small"
                        type="datetime-local"
                        label={data.label}
                        placeholder={data.placeholder}
                        fullWidth
                        error={selectedField === data.id && !!error}
                        helperText={
                            selectedField === data.id ? error?.message : ""
                        }
                        disabled={selectedField !== data.id}
                        {...register(
                            data.name,
                            intersection(rules, Object.keys(data)).reduce(
                                (previous, current) => ({
                                    ...previous,
                                    [current]: {
                                        value: data[current] || undefined,
                                        message: intl.formatMessage(
                                            {
                                                id: `FORM:ERROR_MESSAGES:${camelToSnakeUpperCase(
                                                    current
                                                )}`,
                                            },
                                            {
                                                value: new Date(
                                                    data[current]
                                                ).toLocaleString("en-GB"),
                                            }
                                        ),
                                    },
                                }),
                                {}
                            )
                        )}
                        InputLabelProps={{ shrink: true }}
                        sx={
                            selectedField !== data.id
                                ? {
                                      pointerEvents: "none!important",
                                      cursor: "grab!important",
                                  }
                                : {}
                        }
                    />
                );
            case "switch":
                return (
                    <Box width="100%">
                        <FormControlLabel
                            sx={{ marginLeft: 0 }}
                            size="small"
                            label={<Typography>{data.label}</Typography>}
                            labelPlacement="start"
                            control={
                                <Controller
                                    size="small"
                                    control={control}
                                    name={data.name}
                                    render={({ field }) => (
                                        <Switch
                                            size="small"
                                            color="primary"
                                            inputRef={field.ref}
                                            checked={field.value ?? false}
                                            value={field.value ?? false}
                                            onChange={field.onChange}
                                        />
                                    )}
                                />
                            }
                        />
                    </Box>
                );
            case "select":
                return (
                    <Controller
                        size="small"
                        control={control}
                        rules={intersection(rules, Object.keys(data)).reduce(
                            (previous, current) => ({
                                ...previous,
                                [current]: {
                                    value: data[current] || undefined,
                                    message: intl.formatMessage(
                                        {
                                            id: `FORM:ERROR_MESSAGES:${camelToSnakeUpperCase(
                                                current
                                            )}`,
                                        },
                                        { value: data[current] }
                                    ),
                                },
                            }),
                            {}
                        )}
                        name={data.name}
                        render={({ field }) => {
                            return (
                                <Autocomplete
                                    size="small"
                                    {...field}
                                    multiple={false}
                                    fullWidth
                                    options={data.options}
                                    noOptionsText={""}
                                    getOptionLabel={(option) =>
                                        option?.label || option?.value || ""
                                    }
                                    isOptionEqualToValue={(option, value) => {
                                        return option.value === value.value;
                                    }}
                                    onChange={(e, data) => field.onChange(data)}
                                    renderInput={(params) => (
                                        <TextField
                                            label={data.label}
                                            {...params}
                                            error={!!error}
                                            helperText={error?.message || ""}
                                        />
                                    )}
                                />
                            );
                        }}
                    />
                );
            case "multiSelect":
                return (
                    <Controller
                        size="small"
                        control={control}
                        rules={intersection(rules, Object.keys(data)).reduce(
                            (previous, current) => ({
                                ...previous,
                                [current]: {
                                    value: data[current] || undefined,
                                    message: intl.formatMessage(
                                        {
                                            id: `FORM:ERROR_MESSAGES:${camelToSnakeUpperCase(
                                                current
                                            )}`,
                                        },
                                        { value: data[current] }
                                    ),
                                },
                            }),
                            {}
                        )}
                        name={data.name}
                        render={({ field }) => {
                            return (
                                <Autocomplete
                                    size="small"
                                    {...field}
                                    multiple={true}
                                    fullWidth
                                    options={data.options}
                                    noOptionsText={""}
                                    getOptionLabel={(option) =>
                                        option?.label || option?.value || ""
                                    }
                                    isOptionEqualToValue={(option, value) => {
                                        return option.value === value.value;
                                    }}
                                    onChange={(e, data) => field.onChange(data)}
                                    renderInput={(params) => (
                                        <TextField
                                            label={data.label}
                                            {...params}
                                            error={!!error}
                                            helperText={error?.message || ""}
                                        />
                                    )}
                                />
                            );
                        }}
                    />
                );
            case "files":
                return (
                    <TextField
                        size="small"
                        type="file"
                        label={data.label}
                        placeholder={data.placeholder}
                        fullWidth
                        error={selectedField === data.id && !!error}
                        helperText={
                            selectedField === data.id ? error?.message : ""
                        }
                        disabled={selectedField !== data.id}
                        {...register(data.name, {
                            ...intersection(rules, Object.keys(data)).reduce(
                                (previous, current) => ({
                                    ...previous,
                                    [current]: {
                                        value: data[current] || undefined,
                                        message: intl.formatMessage(
                                            {
                                                id: `FORM:ERROR_MESSAGES:${camelToSnakeUpperCase(
                                                    current
                                                )}`,
                                            },
                                            { value: data[current] }
                                        ),
                                    },
                                }),
                                {}
                            ),
                        })}
                        inputProps={{ multiple: true }}
                        InputLabelProps={{ shrink: true }}
                        sx={
                            selectedField !== data.id
                                ? {
                                      pointerEvents: "none!important",
                                      cursor: "grab!important",
                                  }
                                : {}
                        }
                    />
                );
            default:
                return <></>;
        }
    };

    return (
        <Draggable draggableId={`${data.name}-${index}`} index={index}>
            {(provided, snapshot) => (
                <Box
                    className={`form-field-container${
                        selectedField === data.id ? " selected" : ""
                    }`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onClick={(event) => {
                        event.stopPropagation();
                        setSelectedField(data.id);
                    }}
                >
                    <ListItem
                        className={`field${
                            snapshot.isDragging ? " dragging" : ""
                        }`}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                marginRight: "16px",
                                padding: "8px",
                            }}
                        >
                            <DragIndicator />
                        </ListItemIcon>
                        {renderField()}
                        <IconButton
                            sx={{ marginLeft: "16px" }}
                            onClick={() => {
                                setFormFields(
                                    formFields.filter(
                                        (field) => field.id !== data.id
                                    )
                                );
                            }}
                        >
                            <Cancel />
                        </IconButton>
                    </ListItem>
                </Box>
            )}
        </Draggable>
    );
}

export default FormField;
