import React from "react";
import {
    Fade,
    Box,
    Button,
    IconButton,
    Typography,
    Badge,
} from "@mui/material";
import { Cached, GridView, TableView } from "@mui/icons-material";
import "./DataContainer.scss";
import Loading from "../loading/Loading";

const DataContainer = ({
    containerProps = {},
    headerProps = {},
    contentProps = {},
    title = "",
    subtitle,
    buttons,
    others,
    onRefresh,
    children,
    loading,
    cardsView,
    setCardsView,
    badge,
}) => {
    return (
        <Fade in={true} timeout={1000}>
            <Box
                p={2}
                height="100%"
                width="100%"
                className="data-container"
                {...containerProps}
            >
                <Box
                    display="flex"
                    justifyContent={"space-between"}
                    alignItems="center"
                    height="80px"
                    {...headerProps}
                >
                    <Box display="flex" alignItems="center" gap={2}>
                        {(title || subtitle) && (
                            <Box display="flex" flexDirection={"column"}>
                                {title && (
                                    <Badge badgeContent={badge} color="error">
                                        <Typography
                                            variant="h6"
                                            component="h1"
                                            fontWeight={"bold"}
                                            color="primary"
                                        >
                                            {title}
                                        </Typography>
                                    </Badge>
                                )}
                                {subtitle && (
                                    <Typography
                                        variant="body2"
                                        color="gray"
                                        paddingLeft={0.3}
                                        sx={{
                                            display: {
                                                xs: "none",
                                                sm: "block",
                                            },
                                        }}
                                        gutterBottom
                                    >
                                        {subtitle}
                                    </Typography>
                                )}
                            </Box>
                        )}

                        {onRefresh && (
                            <IconButton onClick={onRefresh} color="primary">
                                <Cached />
                            </IconButton>
                        )}

                        {typeof cardsView === "boolean" && setCardsView && (
                            <IconButton
                                onClick={() => {
                                    setCardsView(!cardsView);
                                }}
                                color="primary"
                            >
                                {cardsView ? <TableView /> : <GridView />}
                            </IconButton>
                        )}
                    </Box>
                    <Box>
                        {buttons && (
                            <Box display="flex" alignItems="center" gap={2}>
                                {buttons.map((element, index) => {
                                    return (
                                        <Button key={index} {...element.props}>
                                            {element.name}
                                        </Button>
                                    );
                                })}
                            </Box>
                        )}
                        {others && (
                            <Box display="flex" alignItems="center" gap={2}>
                                {others.map((element, index) => {
                                    return element;
                                })}
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box
                    {...contentProps}
                    height={`calc(100% - ${headerProps.height ?? "80px"})`}
                    sx={{ position: "relative" }}
                >
                    {loading && (
                        <Loading
                            container={{
                                position: "absolute",
                                zIndex: 99,
                                backgroundColor: "#ffffff90",
                            }}
                        />
                    )}
                    {children}
                </Box>
            </Box>
        </Fade>
    );
};

export default DataContainer;
