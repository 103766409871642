import { Box, Button, Grid } from "@mui/material";
import React, { useState } from "react";
import "./EditSiteComposer.scss";
import { FormattedMessage } from "react-intl";
import EditSiteComposerDialog from "./editSiteComposerDialog/EditSiteComposerDialog";
import usePrevious from "../../../../hooks/usePrevious";
import useWorkflowContext from "../../../../hooks/useWorkflowContext";
import { useEffect } from "react";

function EditSiteComposer({ dynamicData, content, save }) {
    const [isOpen, setIsOpen] = useState(false);
    const previousIsOpen = usePrevious(isOpen);
    const { blockDeleteIncr, blockDeleteDecr } = useWorkflowContext();

    useEffect(() => {
        if (previousIsOpen && !isOpen) {
            blockDeleteDecr();
        } else if (!previousIsOpen && isOpen) {
            blockDeleteIncr();
        }
    }, [isOpen]);

    return (
        <Box width={"100%"} className="edit-site-composer-container">
            {isOpen && (
                <EditSiteComposerDialog
                    dynamicData={dynamicData}
                    content={content}
                    save={save}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                />
            )}
            <Box display="flex" flexDirection="row" alignItems={"top"}>
                <Grid container p={2} spacing={2}>
                    <Grid item xs={12}>
                        <Box
                            height="40px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Button
                                size="large"
                                color="white"
                                className="pick-button"
                                onClick={() => {
                                    setIsOpen(true);
                                }}
                            >
                                <FormattedMessage id="EDIT_SITE_COMPOSER:COMPOSER" />
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default EditSiteComposer;
