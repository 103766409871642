import React, { useState, useMemo, useEffect } from "react";
import {
    Box,
    Button,
    Dialog,
    Divider,
    Grid,
    List,
    ListItem,
    ListSubheader,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { copyAnyObject } from "../../../../../utils/general";
import "./EditSiteComposerDialog.scss";
import { nanoid } from "nanoid";
import Xarrow, { Xwrapper } from "react-xarrows";

function EditSiteComposerDialog({
    dynamicData,
    content,
    save,
    isOpen,
    setIsOpen,
}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const isLarge = useMediaQuery(theme.breakpoints.up("lg"));

    const [selectedSiteField, setSelectedSiteField] = useState(null);
    const [data, setData] = useState(content);

    const intl = useIntl();

    useEffect(() => {
        const handler = (event) => {
            if (
                (event.key === "Delete" || event.key === "Backspace") &&
                selectedSiteField
            ) {
                event.preventDefault();
                const result = { ...data };
                delete result[selectedSiteField];
                setData(result);
            }
        };
        window.addEventListener("keydown", handler);
        return () => {
            window.removeEventListener("keydown", handler);
        };
    }, [data, setData, selectedSiteField]);

    const siteFields = useMemo(() => {
        return {
            contactName: {
                label: intl.formatMessage({ id: "SITES:CONTACT_NAME" }),
            },
            contactEmail: {
                label: intl.formatMessage({ id: "SITES:CONTACT_EMAIL" }),
            },
            contactPhone: {
                label: intl.formatMessage({ id: "SITES:CONTACT_PHONE" }),
            },
            bpe: {
                label: intl.formatMessage({ id: "SITES:BPE" }),
            },
            room: {
                label: intl.formatMessage({ id: "SITES:ROOM" }),
            },
            rop: {
                label: intl.formatMessage({ id: "SITES:ROP" }),
            },
            cls: {
                label: intl.formatMessage({ id: "SITES:CLS" }),
            },
            fci: {
                label: intl.formatMessage({ id: "SITES:FCI" }),
            },
            rdv: {
                label: intl.formatMessage({ id: "SITES:RDV" }),
            },
            contractDate: {
                label: intl.formatMessage({ id: "SITES:CONTRACT_DATE" }),
            },
            orderDate: {
                label: intl.formatMessage({ id: "SITES:ORDER_DATE" }),
            },
        };
    }, [intl]);

    useEffect(() => {
        save({ content: data });
    }, [data]);

    return (
        <Dialog
            className="edit-site-composer-dialog"
            maxWidth={false}
            open={isOpen}
            onClose={() => {
                setIsOpen(false);
            }}
            sx={{
                backgroundColor: "transparent",
            }}
            PaperProps={{
                sx: {
                    backgroundColor: "transparent",
                    margin: "0px",
                    maxHeight: "unset",
                },
            }}
        >
            <Box className="dialog-content" display="flex" flexDirection="row">
                <Xwrapper>
                    <Grid container height="100%" overflow="auto" spacing={0}>
                        <Grid
                            item
                            xs={12}
                            md
                            maxHeight={isMobile ? "unset" : "100%"}
                            overflow={"auto"}
                        >
                            <List
                                subheader={
                                    <ListSubheader>
                                        <FormattedMessage id="GLOBAL:FIELDS" />
                                    </ListSubheader>
                                }
                                disablePadding
                            >
                                {Object.keys(siteFields).map((key) => (
                                    <ListItem key={key}>
                                        <Button
                                            id={key}
                                            variant="contained"
                                            color={
                                                selectedSiteField === key
                                                    ? "secondary"
                                                    : "primary"
                                            }
                                            onClick={() => {
                                                if (selectedSiteField !== key) {
                                                    setSelectedSiteField(key);
                                                }
                                            }}
                                            fullWidth
                                            sx={{
                                                textTransform: "unset",
                                            }}
                                        >
                                            {siteFields[key].label}
                                        </Button>
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                        {!isMobile && (
                            <Divider orientation="vertical" flexItem />
                        )}
                        <Grid
                            item
                            xs={12}
                            md={4}
                            maxHeight={isMobile ? "unset" : "100%"}
                            overflow={"auto"}
                        ></Grid>
                        {!isMobile && (
                            <Divider orientation="vertical" flexItem />
                        )}
                        <Grid
                            item
                            xs={12}
                            md={4}
                            maxHeight={isMobile ? "unset" : "100%"}
                            overflow={"auto"}
                        >
                            {Object.keys(dynamicData).map((key) => (
                                <List
                                    key={key}
                                    subheader={
                                        <ListSubheader>
                                            {dynamicData[key].name || key}
                                        </ListSubheader>
                                    }
                                    disablePadding
                                >
                                    {dynamicData &&
                                        Object.keys(
                                            dynamicData[key].content
                                        ).map((subKey) => (
                                            <ListItem key={subKey}>
                                                <Button
                                                    id={`${key}.${subKey}`}
                                                    variant="contained"
                                                    color={"primary"}
                                                    onClick={() => {
                                                        setData({
                                                            ...data,
                                                            [selectedSiteField]: `${key}.${subKey}`,
                                                        });
                                                        setSelectedSiteField(
                                                            null
                                                        );
                                                    }}
                                                    disabled={
                                                        !selectedSiteField
                                                    }
                                                    fullWidth
                                                    sx={{
                                                        textTransform: "unset",
                                                    }}
                                                >
                                                    {dynamicData[key].content[
                                                        subKey
                                                    ].label ||
                                                        dynamicData[key]
                                                            .content[subKey]
                                                            .name}
                                                </Button>
                                            </ListItem>
                                        ))}
                                </List>
                            ))}
                        </Grid>
                    </Grid>
                    {Object.keys(data).map((key) => (
                        <Xarrow key={key} start={key} end={data[key]} />
                    ))}
                </Xwrapper>
            </Box>
        </Dialog>
    );
}

export default EditSiteComposerDialog;
