import React from "react";
import { Handle } from "reactflow";
import useVerifyConnection from "../../../../../hooks/useVerifyConnection";
import { Timer } from "@mui/icons-material";
import "./SleepNode.scss";

function SleepNode({ selected, idValid }) {
    const verifyConnection = useVerifyConnection();

    return (
        <>
            <Handle
                type="source"
                position="right"
                isValidConnection={verifyConnection}
            />
            <div
                className={`sleep-node-container${selected ? " selected" : ""}`}
            >
                <div className="sleep-content">
                    <Timer fontSize="large" />
                </div>
                {idValid || <div className="invalid-marker"></div>}
            </div>
            <Handle
                type="target"
                position="left"
                isValidConnection={verifyConnection}
            />
        </>
    );
}

export default SleepNode;
