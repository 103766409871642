import React, { useEffect, useState } from "react";
import "./Workflows.scss";
import { Card, Chip } from "@mui/material";
import {
    Add,
    ContentCopy,
    Delete,
    DriveFileRenameOutline,
    Edit,
    Info,
    Publish,
} from "@mui/icons-material";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import DataContainer from "../../components/dataContainer/DataContainer";
import {
    CustomNoRowsOverlay,
    CustomLoadingOverlay,
} from "../../utils/gridOverlays";
import { useIntl } from "react-intl";
import {
    deleteWorkflow,
    duplicateWorkflowById,
    getWorkflows,
    publishWorkflow,
    renameWorkflow,
} from "../../api/workflow";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router-dom";
import useAbility from "../../hooks/useAbility";
import AddModal from "../../components/addModal/AddModal";
import { camelToSnakeUpperCase } from "../../utils/general";
import DataValidationError from "../../errors/DataValidationError";

const Workflows = () => {
    //#region States
    const { can } = useAbility();
    const [dataRows, setDataRows] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [dataLoading, setDataLoading] = useState(true);
    const [modalLoading, setModalLoading] = useState(false);
    const [selectedWorkflowId, setSelectedWorkflowId] = useState(null);
    const selectedWorkflow = dataRows.find(
        (row) => row.id === selectedWorkflowId
    );

    //#region other Hooks
    const intl = useIntl();
    const confirm = useConfirm();
    const navigate = useNavigate();
    //#endregion

    //#region useEffect
    useEffect(() => {
        getData();
    }, []);
    //#endregion

    //#region Handlers
    const handleCloseUpdate = () => {
        setSelectedWorkflowId(null);
    };
    const handleOpenUpdate = (workflow) => {
        setSelectedWorkflowId(workflow);
    };

    const getData = async () => {
        setDataLoading(true);
        try {
            let data = await getWorkflows();
            setDataRows(
                data.map((row) => ({
                    ...row,
                    id: row._id,
                    _id: row._id,
                }))
            );
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        } finally {
            setDataLoading(false);
        }
    };

    const updateWorkflowName = async (data) => {
        try {
            setModalLoading(true);
            await renameWorkflow(selectedWorkflowId, data);
            toast.success(
                intl.formatMessage({
                    id: "WORKFLOWS:WORKFLOW_RENAMED",
                })
            );
            setSelectedWorkflowId(null);
            await getData();
        } catch (error) {
            if (error instanceof DataValidationError) {
                error.fields.forEach((field) => {
                    if (field.kind === "unique") {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:UNIQUE" },
                                {
                                    field: intl.formatMessage({
                                        id: `WORKFLOWS:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    } else {
                        toast.error(
                            intl.formatMessage(
                                { id: "ERROR:FORMAT" },
                                {
                                    field: intl.formatMessage({
                                        id: `WORKFLOWS:${camelToSnakeUpperCase(
                                            field.path
                                        )}`,
                                    }),
                                    value: field.value,
                                }
                            )
                        );
                    }
                });
            } else {
                toast.error(intl.formatMessage({ id: error.message }));
            }
        } finally {
            setModalLoading(false);
        }
    };

    const removeWorkflow = async (params) => {
        try {
            await confirm({
                title: intl.formatMessage({ id: "WARNING" }),
                description: intl.formatMessage(
                    { id: "WORKFLOWS:WORKFLOW_DELETE_CONFIRM" },
                    { name: params.row.name }
                ),
                confirmationText: intl.formatMessage({ id: "GLOBAL:CONFIRM" }),
                cancellationText: intl.formatMessage({ id: "GLOBAL:CANCEL" }),
            });
        } catch (error) {
            return;
        }
        setDataLoading(true);
        try {
            await deleteWorkflow(params.row._id);
            toast.success(
                intl.formatMessage({ id: "WORKFLOWS:WORKFLOW_DELETED" })
            );
            await getData();
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        } finally {
            setDataLoading(false);
        }
    };

    const publish = async (data) => {
        try {
            await confirm({
                title: intl.formatMessage({ id: "WARNING" }),
                description: intl.formatMessage(
                    { id: "WORKFLOWS:WORKFLOW_PUBLISH_CONFIRM" },
                    { name: data.name }
                ),
                confirmationText: intl.formatMessage({ id: "GLOBAL:CONFIRM" }),
                cancellationText: intl.formatMessage({ id: "GLOBAL:CANCEL" }),
            });
        } catch (error) {
            return;
        }
        setDataLoading(true);
        try {
            await publishWorkflow(data._id);
            toast.success(
                intl.formatMessage({ id: "WORKFLOWS:WORKFLOW_PUBLISHED" })
            );
            await getData();
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        } finally {
            setDataLoading(false);
        }
    };

    const duplicateWorkflow = async (params) => {
        setDataLoading(true);
        try {
            await duplicateWorkflowById(params.row._id);
            toast.success(
                intl.formatMessage({ id: "WORKFLOWS:WORKFLOW_DUPLICATED" })
            );
            await getData();
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
        } finally {
            setDataLoading(false);
        }
    };
    //#endregion

    //#region constants
    const dataColumns = [
        {
            field: "actions",
            type: "actions",
            minWidth: 50,
            maxWidth: 50,
            getActions: (params) => [
                ...(params.row.published
                    ? []
                    : [
                          ...(can("PUBLISH", "WORKFLOWS")
                              ? [
                                    <GridActionsCellItem
                                        icon={<Publish />}
                                        onClick={() => publish(params.row)}
                                        label={intl.formatMessage({
                                            id: "ACTIONS:PUBLISH",
                                        })}
                                        showInMenu
                                    />,
                                ]
                              : []),
                          ...(can("UPDATE", "WORKFLOWS")
                              ? [
                                    <GridActionsCellItem
                                        icon={<Edit />}
                                        onClick={() => {
                                            navigate(
                                                `/workflows/build/${params.id}`
                                            );
                                        }}
                                        label={intl.formatMessage({
                                            id: "ACTIONS:EDIT",
                                        })}
                                        showInMenu
                                    />,
                                ]
                              : []),
                      ]),
                ...(can("RENAME", "WORKFLOWS")
                    ? [
                          <GridActionsCellItem
                              icon={<DriveFileRenameOutline />}
                              onClick={() => {
                                  handleOpenUpdate(params.row.id);
                              }}
                              label={intl.formatMessage({
                                  id: "ACTIONS:RENAME",
                              })}
                              showInMenu
                          />,
                      ]
                    : []),
                <GridActionsCellItem
                    icon={<Info />}
                    onClick={() => {}}
                    label={intl.formatMessage({ id: "ACTIONS:DETAILS" })}
                    showInMenu
                />,
                ...(can("DUPLICATE", "WORKFLOWS")
                    ? [
                          <GridActionsCellItem
                              icon={<ContentCopy />}
                              onClick={() => duplicateWorkflow(params)}
                              label={intl.formatMessage({
                                  id: "ACTIONS:DUPLICATE",
                              })}
                              showInMenu
                          />,
                      ]
                    : []),
                ...(can("DELETE", "WORKFLOWS")
                    ? [
                          <GridActionsCellItem
                              icon={<Delete />}
                              onClick={() => removeWorkflow(params)}
                              label={intl.formatMessage({
                                  id: "ACTIONS:DELETE",
                              })}
                              showInMenu
                          />,
                      ]
                    : []),
            ],
        },
        {
            field: "published",
            headerName: intl.formatMessage({ id: "WORKFLOWS:STATE" }),
            renderCell: ({ value }) => (
                <Chip
                    size="small"
                    variant="outlined"
                    label={intl.formatMessage({
                        id: `WORKFLOWS:${value ? "PUBLISHED" : "DRAFT"}`,
                    })}
                    color={value ? "success" : "info"}
                />
            ),
            minWidth: 100,
        },
        {
            field: "name",
            headerName: intl.formatMessage({ id: "WORKFLOWS:NAME" }),
            flex: 1,
            minWidth: 160,
        },
        {
            field: "description",
            headerName: intl.formatMessage({ id: "WORKFLOWS:DESCRIPTION" }),
            flex: 1,
            minWidth: 170,
        },
        {
            field: "createdAt",
            headerName: intl.formatMessage({ id: "WORKFLOWS:CREATEDAT" }),
            flex: 1,
            minWidth: 170,
            valueFormatter: ({ value }) => new Date(value).toLocaleString(),
        },
        {
            field: "updatedAt",
            headerName: intl.formatMessage({ id: "WORKFLOWS:UPDATEDAT" }),
            flex: 1,
            minWidth: 170,
            valueFormatter: ({ value }) => new Date(value).toLocaleString(),
        },
    ];

    const headerButtons = [
        ...(can("CREATE", "WORKFLOWS")
            ? [
                  {
                      name: intl.formatMessage({ id: "WORKFLOWS:CREATE" }),
                      props: {
                          onClick: () => {
                              navigate("/workflows/build");
                          },
                          variant: "contained",
                          color: "secondary",
                          endIcon: <Add />,
                      },
                  },
              ]
            : []),
    ];
    const modalFields = [
        {
            name: "name",
            label: intl.formatMessage({ id: "WORKFLOWS:NAME" }),
            type: "text",
            rules: {
                required: intl.formatMessage({
                    id: "FORM:ERROR_MESSAGES:REQUIRED",
                }),
            },
            fullWidth: true,
        },
    ];

    var updateFields = selectedWorkflow
        ? {
              name: selectedWorkflow.name,
              value: selectedWorkflow.value,
              description: selectedWorkflow.description,
          }
        : null;
    //#endregion

    return (
        <DataContainer
            buttons={headerButtons}
            onRefresh={() => {
                getData();
            }}
        >
            {updateFields && (
                <AddModal
                    open={!!updateFields}
                    handleClose={handleCloseUpdate}
                    fields={modalFields
                        .filter((field) =>
                            Object.keys(updateFields).includes(field.name)
                        )
                        .map((field) => ({
                            ...field,
                            defaultValue: updateFields[field.name],
                        }))}
                    title={intl.formatMessage({
                        id: "WORKFLOWS:RENAME",
                    })}
                    submit={updateWorkflowName}
                    buttons={[
                        {
                            props: {
                                type: "submit",
                                variant: "contained",
                                color: "secondary",
                            },
                            label: intl.formatMessage({ id: "GLOBAL:SUBMIT" }),
                        },
                    ]}
                    buttonsBoxProps={[]}
                    loading={modalLoading}
                />
            )}
            {can("CONSULT", "WORKFLOWS") ? (
                <Card sx={{ height: "100%" }}>
                    <DataGrid
                        loading={dataLoading}
                        columns={dataColumns}
                        rows={dataRows}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) =>
                            setPageSize(newPageSize)
                        }
                        rowsPerPageOptions={[10, 20, 30, 50, 100]}
                        components={{
                            Toolbar: GridToolbar,
                            LoadingOverlay: CustomLoadingOverlay,
                            NoRowsOverlay: () =>
                                CustomNoRowsOverlay(
                                    intl.formatMessage({
                                        id: "WORKFLOWS:NO_WORKFLOWS",
                                    })
                                ),
                            NoResultsOverlay: () =>
                                CustomNoRowsOverlay(
                                    intl.formatMessage({
                                        id: "WORKFLOWS:NO_RESULTS",
                                    })
                                ),
                        }}
                        disableSelectionOnClick
                        onCellDoubleClick={
                            can("UPDATE", "WORKFLOWS")
                                ? ({ row }) => {
                                      navigate(`/workflows/build/${row.id}`);
                                  }
                                : () => {}
                        }
                    />
                </Card>
            ) : (
                <>THIS IS A PLACEHOLDER</>
            )}
        </DataContainer>
    );
};

export default Workflows;
