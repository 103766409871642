import {
    Box,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
    Divider,
    CircularProgress,
} from "@mui/material";
import {
    ArrowForward,
    LockOutlined,
    PersonOutlined,
    Visibility,
    VisibilityOff,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import "./Login.scss";
import GoogleLogin from "react-google-login";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import {
    clear,
    setId,
    setInfo,
    setLocale,
    setPermissions,
} from "../../redux/slices/userSlice";
import { toast } from "react-toastify";
import { checkToken, login } from "../../api/auth";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import useAbility from "../../hooks/useAbility";

const LoginScreen = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation();
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const { updateAbilities } = useAbility();

    useEffect(() => {
        setup();
    }, [location]);

    const setup = async () => {
        setPageLoading(true);
        if (!window.localStorage.getItem("token")) {
            window.localStorage.clear();
            dispatch(clear());
            setPageLoading(false);
        } else if (!location.state || !location.state.fromApp) {
            try {
                let { id, token, info } = await checkToken();
                window.localStorage.setItem("token", token);
                dispatch(setInfo(info));
                dispatch(setId(id));
                if (info.lang) {
                    changeLang(info.lang);
                }
                setPageLoading(false);
                navigate("/", {
                    replace: true,
                    state: {
                        fromLogin: true,
                    },
                });
            } catch (error) {
                window.localStorage.clear();
                dispatch(clear());
                setPageLoading(false);
            }
        } else {
            window.localStorage.clear();
            dispatch(clear());
            setPageLoading(false);
        }
    };
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const responseGoogle = (response) => {
        console.log("response");
        console.log(response);
        console.log("response");
    };
    const changeLang = (locale) => {
        dispatch(setLocale(locale));
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        let data = new FormData(event.currentTarget);
        try {
            let { id, token, info, permissions } = await login(
                data.get("username"),
                data.get("password")
            );
            window.localStorage.setItem("token", token);
            dispatch(setInfo(info));
            dispatch(setId(id));
            dispatch(setPermissions(permissions));
            updateAbilities(permissions);
            if (info.lang) {
                changeLang(info.lang);
            }
            toast.success(intl.formatMessage({ id: "SUCCESS:LOGIN" }));
            setLoading(false);
            navigate("/", {
                replace: true,
                state: {
                    fromLogin: true,
                },
            });
        } catch (error) {
            toast.error(intl.formatMessage({ id: error.message }));
            setLoading(false);
        }
    };

    return (
        <Grid container className="login-container">
            {pageLoading ? (
                <Loading
                    loader={{
                        height: "10vmin!important",
                        width: "10vmin!important",
                    }}
                />
            ) : (
                <React.Fragment>
                    <Grid
                        item
                        className="left"
                        md={6}
                        sx={{ display: { xs: "none", md: "flex" } }}
                    >
                        <div className="logo-box"></div>
                        <div className="image-box"></div>
                    </Grid>
                    <Grid
                        item
                        className="right"
                        xs={12}
                        md={6}
                        sx={{ display: "flex" }}
                    >
                        <Grid item xs={2}></Grid>
                        <Grid item className="form-container" xs={8}>
                            <Box
                                className="mobile-logo-box"
                                sx={{ display: { xs: "flex", md: "none" } }}
                            ></Box>
                            <header>
                                <Typography variant="h1">
                                    <FormattedMessage id="LOGIN:LOGIN" />
                                </Typography>
                            </header>
                            <Box component="form" onSubmit={handleSubmit}>
                                <TextField
                                    label={intl.formatMessage({
                                        id: "LOGIN:USERNAME",
                                    })}
                                    color="secondary"
                                    name="username"
                                    autoComplete="username"
                                    autoFocus
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonOutlined />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    label={intl.formatMessage({
                                        id: "LOGIN:PASSWORD",
                                    })}
                                    variant="outlined"
                                    color="secondary"
                                    name="password"
                                    autoComplete="current-password"
                                    required
                                    type={showPassword ? "text" : "password"}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockOutlined />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={
                                                        handleClickShowPassword
                                                    }
                                                    onMouseDown={
                                                        handleMouseDownPassword
                                                    }
                                                >
                                                    {showPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <div className="actions">
                                    <a href="/">
                                        <Typography>
                                            <FormattedMessage id="LOGIN:FORGOT_PASSWORD" />
                                        </Typography>
                                    </a>
                                    <button type="submit" disabled={loading}>
                                        {loading ? (
                                            <CircularProgress
                                                color="white"
                                                sx={{
                                                    color: "white",
                                                    width: "65%!important",
                                                    height: "65%!important",
                                                }}
                                            />
                                        ) : (
                                            <ArrowForward />
                                        )}
                                    </button>
                                </div>
                            </Box>
                            <Divider />
                            <GoogleLogin
                                clientId="414950099873-me5193rhokqgcb9c2aib8qp1lih955qd.apps.googleusercontent.com"
                                buttonText={intl.formatMessage({
                                    id: "LOGIN:GOOGLE_LOGIN",
                                })}
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                cookiePolicy={"single_host_origin"}
                                disabled={loading}
                                // disabled={true}
                            />
                        </Grid>
                        <div className="language">
                            <button onClick={() => changeLang("en-US")}>
                                English
                            </button>
                            &nbsp;.&nbsp;
                            <button onClick={() => changeLang("fr-FR")}>
                                Français
                            </button>
                        </div>
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    );
};

export default LoginScreen;
