import React from "react";
import { Handle } from "reactflow";
import "./MailNode.scss";
import { Mail } from "@mui/icons-material";
import useVerifyConnection from "../../../../../hooks/useVerifyConnection";

function MailNode({ selected, isValid }) {
    const verifyConnection = useVerifyConnection();

    return (
        <>
            <Handle
                type="source"
                position="right"
                isValidConnection={verifyConnection}
                id="SUCCESS"
            />
            <Handle
                type="source"
                position="bottom"
                isValidConnection={verifyConnection}
                id="ERROR"
            />
            <div
                className={`mail-node-container${selected ? " selected" : ""}`}
            >
                <div className="mail-content">
                    <Mail fontSize="large" />
                </div>
                {isValid || <div className="invalid-marker"></div>}
            </div>
            <Handle
                type="target"
                position="left"
                isValidConnection={verifyConnection}
            />
        </>
    );
}

export default MailNode;
