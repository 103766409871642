import React, { useState, useEffect, useRef } from "react";
import "./MultiEmailInput.scss";
import { Box, FormControl, FormHelperText, InputLabel } from "@mui/material";
import { Controller } from "react-hook-form";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/style.css";
import { useIntl } from "react-intl";
import useActiveElement from "../../../../../hooks/useActiveElement";

function MultiEmailInput({ name, rules, control, label, error }) {
    const intl = useIntl();
    const activeElement = useActiveElement();
    const inputRef = useRef(null);
    const isInputActive =
        inputRef.current?.emailInputRef?.current === activeElement;

    return (
        <Controller
            size="small"
            control={control}
            rules={rules}
            name={name}
            render={({ field, fieldState }) => {
                return (
                    <FormControl
                        className="multi-email-container"
                        size="small"
                        fullWidth
                    >
                        <InputLabel
                            shrink={isInputActive || field.value.length > 0}
                            children={label}
                            sx={{
                                ...(error
                                    ? {}
                                    : {
                                          color: "#ffffffdd!important",
                                      }),
                            }}
                            required={!!rules?.required}
                            error={!!error}
                        />
                        <Box className="emails-input-box">
                            <ReactMultiEmail
                                ref={inputRef}
                                emails={field.value}
                                onChange={(emails) => {
                                    field.onChange(emails);
                                }}
                                validateEmail={(email) => {
                                    return (
                                        isEmail(email) ||
                                        /^#\{\{[A-Za-z0-9_-]{42}::[a-zA-Z][a-zA-Z0-9\-_]+\}\}$/.test(
                                            email
                                        )
                                    );
                                }}
                                getLabel={(email, index, removeEmail) => {
                                    return (
                                        <div
                                            data-tag
                                            key={index}
                                            style={{
                                                textOverflow: "clip",
                                                wordBreak: "break-word",
                                            }}
                                        >
                                            {email}
                                            <span
                                                data-tag-handle
                                                onClick={() =>
                                                    removeEmail(index)
                                                }
                                            >
                                                x
                                            </span>
                                        </div>
                                    );
                                }}
                            />
                            <fieldset
                                {...(error
                                    ? {
                                          className: "fieldset-error",
                                      }
                                    : {})}
                                aria-hidden="true"
                            >
                                <legend
                                    className={
                                        isInputActive || field.value.length > 0
                                            ? "legend-shrinked"
                                            : "legend-not-shrinked"
                                    }
                                >
                                    <span>
                                        {label}
                                        {rules?.required ? " *" : ""}
                                    </span>
                                </legend>
                            </fieldset>
                        </Box>
                        {!!error && (
                            <FormHelperText error={true}>
                                {intl.formatMessage({
                                    id: error?.message,
                                })}
                            </FormHelperText>
                        )}
                    </FormControl>
                );
            }}
        />
    );
}

export default MultiEmailInput;
