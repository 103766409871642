import React from "react";
import { Handle } from "reactflow";
import useVerifyConnection from "../../../../../hooks/useVerifyConnection";
import "./AndNode.scss";

function AndNode({ selected }) {
    const verifyConnection = useVerifyConnection();

    return (
        <>
            <Handle
                type="source"
                position="right"
                isValidConnection={verifyConnection}
            />
            <div className={`and-node-container${selected ? " selected" : ""}`}>
                <div className="and-content">&</div>
            </div>
            <Handle
                type="target"
                position="left"
                isValidConnection={verifyConnection}
            />
        </>
    );
}

export default AndNode;
