import React, { useState, useMemo, useEffect } from "react";
import { Box, Dialog, Divider, useMediaQuery, useTheme } from "@mui/material";
import { useIntl } from "react-intl";
import FieldsContainer from "./fieldsContainer/FieldsContainer";
import FormContent from "./formContent/FormContent";
import { DragDropContext } from "react-beautiful-dnd";
import { copyAnyObject } from "../../../../../utils/general";
import FormBuilderContext from "../../../../../contexts/FormBuilderContext";
import "./FormBuilderDialog.scss";
import FieldProperties from "./fieldProperties/FieldProperties";
import { nanoid } from "nanoid";

function FormBuilderDialog({ formContent, save, isOpen, setIsOpen }) {
    const [formFields, setFormFields] = useState(
        formContent.map((el) => ({ ...el, id: `${nanoid()}${nanoid()}` }))
    );
    const [selectedField, setSelectedField] = useState(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const isLarge = useMediaQuery(theme.breakpoints.up("lg"));

    const intl = useIntl();

    const fields = useMemo(
        () => [
            {
                type: "staticText",
                label: intl.formatMessage({ id: "FORM_BUILDER:STATIC_TEXT" }),
                description: intl.formatMessage({
                    id: "FORM_BUILDER:STATIC_TEXT_DESCRIPTION",
                }),
                properties: {
                    value: "",
                },
            },
            {
                type: "text",
                label: intl.formatMessage({ id: "FORM_BUILDER:TEXT" }),
                description: intl.formatMessage({
                    id: "FORM_BUILDER:TEXT_DESCRIPTION",
                }),
                properties: {
                    placeholder: "",
                    required: true,
                    multiline: false,
                    minLength: "",
                    maxLength: "",
                    format: null,
                },
            },
            {
                type: "richText",
                label: intl.formatMessage({ id: "FORM_BUILDER:RICH_TEXT" }),
                description: intl.formatMessage({
                    id: "FORM_BUILDER:RICH_TEXT_DESCRIPTION",
                }),
                disabled: true,
            },
            {
                type: "number",
                label: intl.formatMessage({ id: "FORM_BUILDER:NUMBER" }),
                description: intl.formatMessage({
                    id: "FORM_BUILDER:NUMBER_DESCRIPTION",
                }),
                properties: {
                    placeholder: "",
                    required: true,
                    min: "",
                    max: "",
                },
            },
            {
                type: "decimalNumber",
                label: intl.formatMessage({
                    id: "FORM_BUILDER:DECIMAL_NUMBER",
                }),
                description: intl.formatMessage({
                    id: "FORM_BUILDER:DECIMAL_NUMBER_DESCRIPTION",
                }),
                properties: {
                    placeholder: "",
                    required: true,
                    min: "",
                    max: "",
                },
            },
            {
                type: "date",
                label: intl.formatMessage({ id: "FORM_BUILDER:DATE" }),
                description: intl.formatMessage({
                    id: "FORM_BUILDER:DATE_DESCRIPTION",
                }),
                properties: {
                    required: true,
                    min: "",
                    max: "",
                },
            },
            {
                type: "time",
                label: intl.formatMessage({ id: "FORM_BUILDER:TIME" }),
                description: intl.formatMessage({
                    id: "FORM_BUILDER:TIME_DESCRIPTION",
                }),
                properties: {
                    required: true,
                    min: "",
                    max: "",
                },
            },
            {
                type: "datetime",
                label: intl.formatMessage({ id: "FORM_BUILDER:DATE_TIME" }),
                description: intl.formatMessage({
                    id: "FORM_BUILDER:DATE_TIME_DESCRIPTION",
                }),
                properties: {
                    required: true,
                    min: "",
                    max: "",
                },
            },
            {
                type: "switch",
                label: intl.formatMessage({ id: "FORM_BUILDER:SWITCH" }),
                description: intl.formatMessage({
                    id: "FORM_BUILDER:SWITCH_DESCRIPTION",
                }),
                properties: {},
            },
            {
                type: "select",
                label: intl.formatMessage({ id: "FORM_BUILDER:SELECT" }),
                description: intl.formatMessage({
                    id: "FORM_BUILDER:SELECT_DESCRIPTION",
                }),
                properties: {
                    required: true,
                    options: [],
                },
            },
            {
                type: "multiSelect",
                label: intl.formatMessage({ id: "FORM_BUILDER:MULTI_SELECT" }),
                description: intl.formatMessage({
                    id: "FORM_BUILDER:MULTI_SELECT_DESCRIPTION",
                }),
                properties: {
                    required: true,
                    options: [],
                },
            },
            {
                type: "files",
                label: intl.formatMessage({ id: "FORM_BUILDER:FILES" }),
                description: intl.formatMessage({
                    id: "FORM_BUILDER:FILES_DESCRIPTION",
                }),
                properties: { required: true },
            },
        ],
        [intl]
    );

    useEffect(() => {
        save({ content: formFields });
    }, [formFields]);

    const onDragEnd = ({ destination, source }) => {
        if (!destination || destination.droppableId === "fields") return;

        let result = copyAnyObject(formFields);

        if (source.droppableId === "fields") {
            let names = result
                .filter((field) => /^field-\d+$/.test(field.name))
                .map((field) => parseInt(field.name.split("-")[1]));
            let name =
                names.length === 0
                    ? `field-${result.length + 1}`
                    : `field-${
                          names.reduce(
                              (previous, current) =>
                                  Math.max(previous, current),
                              Number.NEGATIVE_INFINITY
                          ) + 1
                      }`;
            result.splice(destination.index, 0, {
                type: fields[source.index].type,
                ...fields[source.index].properties,
                name,
                label: name,
                id: `${nanoid()}${nanoid()}`,
            });
        } else if (source.droppableId === "form") {
            const [removed] = result.splice(source.index, 1);
            result.splice(destination.index, 0, copyAnyObject(removed));
        }
        setFormFields(result);
    };

    return (
        <Dialog
            className="form-builder-dialog"
            maxWidth={false}
            open={isOpen}
            onClose={() => {
                setIsOpen(false);
                setFormFields([]);
            }}
            sx={{
                backgroundColor: "transparent",
            }}
            PaperProps={{
                sx: {
                    backgroundColor: "transparent",
                    margin: "0px",
                    maxHeight: "unset",
                },
            }}
        >
            <FormBuilderContext.Provider
                value={{
                    fields,
                    formFields,
                    setFormFields,
                    selectedField,
                    setSelectedField,
                }}
            >
                <Box
                    className="dialog-content"
                    display="flex"
                    flexDirection="row"
                >
                    <DragDropContext onDragEnd={onDragEnd}>
                        {!isMobile && <FieldsContainer isLarge={isLarge} />}
                        <Divider orientation="vertical" light />
                        <FormContent />
                        <Divider orientation="vertical" light />
                    </DragDropContext>
                    {!isMobile && <FieldProperties isLarge={isLarge} />}
                </Box>
            </FormBuilderContext.Provider>
        </Dialog>
    );
}

export default FormBuilderDialog;
