import { axiosInstance } from "../utils/axios";

export const login = async (username, password) => {
	try {
		let response = await axiosInstance.post("/auth/local-login", {
			username: username,
			password: password,
		});
		if (response.status !== 200) {
			throw new Error("ERROR:OTHER");
		}
		return response.data;
	} catch (error) {
		if (!error.response && error.request) {
			throw new Error("ERROR:CONNECTION");
		} else if (error.response.status === 401) {
			throw new Error("ERROR:WRONG_CREDENTIALS");
		} else if (error.response.status === 403) {
			throw new Error("ERROR:FORBIDDEN");
		} else if (error.response.status === 500) {
			throw new Error("ERROR:SERVER");
		} else {
			throw new Error("ERROR:OTHER");
		}
	}
};

export const checkToken = async () => {
	try {
		let response = await axiosInstance.get("/auth/check-token");
		if (response.status !== 200) {
			throw new Error("ERROR:OTHER");
		}
		return response.data;
	} catch (error) {
		if (!error.response && error.request) {
			throw new Error("ERROR:CONNECTION");
		} else if (error.response.status === 401) {
			throw new Error("ERROR:UNAUTHORIZED");
		} else if (error.response.status === 500) {
			throw new Error("ERROR:SERVER");
		} else {
			throw new Error("ERROR:OTHER");
		}
	}
};
