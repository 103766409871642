import { Box, Dialog, useMediaQuery, useTheme } from "@mui/material";
import { QueryBuilderMaterial } from "@react-querybuilder/material";
import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import QueryBuilder, {
    defaultOperators,
    ValueSelector,
} from "react-querybuilder";
import { QueryBuilderDnD } from "@react-querybuilder/dnd";
import * as ReactDnD from "react-dnd";
import * as ReactDndHtml5Backend from "react-dnd-html5-backend";
import "./ConditionBuilderDialog.scss";
import "react-querybuilder/dist/query-builder.scss";
import CustomValueSelector from "./customValueSelector/CustomValueSelector";
import fieldTypes from "./fieldTypes";

function ConditionBuilderDialog({
    conditionContent,
    save,
    dynamicData,
    isOpen,
    setIsOpen,
}) {
    const intl = useIntl();

    const fields = useMemo(() => {
        if (!dynamicData) {
            return [];
        }
        const result = Object.values(dynamicData).reduce((prev, curr) => {
            return [
                ...prev,
                ...Object.values(curr.content)
                    .filter((el) => !!fieldTypes[el.type])
                    .map((el) => ({
                        ...el,
                        id: `${curr.id}.${el.id}`,
                        name: `${curr.id}.${el.name}`,
                        parent: { id: curr.id, name: curr.name },
                        ...fieldTypes[el.type],
                    })),
            ];
        }, []);
        return result;
    }, [dynamicData]);

    const [query, setQuery] = useState(conditionContent);

    useEffect(() => {
        save({ content: query });
    }, [query]);

    return (
        <Dialog
            className="condition-builder-dialog"
            maxWidth={false}
            open={isOpen}
            onClose={() => {
                setIsOpen(false);
            }}
            sx={{
                backgroundColor: "transparent",
            }}
            PaperProps={{
                sx: {
                    backgroundColor: "transparent",
                    margin: "0px",
                    maxHeight: "unset",
                },
            }}
        >
            <Box className="dialog-content" display="flex" flexDirection="row">
                <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
                    <QueryBuilderMaterial>
                        <QueryBuilder
                            controlClassnames={{
                                queryBuilder: "queryBuilder-branches",
                            }}
                            fields={fields}
                            query={query}
                            onQueryChange={(q) => {
                                setQuery(q);
                            }}
                            addRuleToNewGroups
                            showCloneButtons
                            showLockButtons
                            showNotToggle
                            enableDragAndDrop
                            independentCombinators={false}
                            controlElements={{
                                fieldSelector: CustomValueSelector,
                            }}
                        />
                    </QueryBuilderMaterial>
                </QueryBuilderDnD>
            </Box>
        </Dialog>
    );
}

export default ConditionBuilderDialog;
