import { Cancel } from "@mui/icons-material";
import {
    Box,
    Chip,
    Dialog,
    Divider,
    Grid,
    IconButton,
    Typography,
} from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import "./TaskDetails.scss";

function TaskDetails({ open, handleClose, data }) {
    const intl = useIntl();

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={false}>
            <Box className="task-details-container">
                <Box className="header">
                    <Box display="flex" flexDirection="column">
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                        >
                            <Typography
                                variant="h1"
                                color="primary"
                                fontSize={24}
                                fontWeight="bold"
                            >
                                <FormattedMessage id="TASKS:TASK" />
                            </Typography>
                            &nbsp;
                            <Typography
                                variant="h1"
                                color="secondary"
                                fontSize={24}
                                fontWeight="bold"
                            >
                                {
                                    data.workflowInstance.nodes.find(
                                        (node) => node.id === data.node
                                    )?.data?.name
                                }
                            </Typography>
                            &nbsp; &nbsp;
                            <Chip
                                size="small"
                                label={intl.formatMessage({
                                    id:
                                        data.state === "PENDING"
                                            ? "TASKS:PENDING"
                                            : data.state === "COMPLETED"
                                            ? "TASKS:COMPLETED"
                                            : "",
                                })}
                                color={`${data.state.toLowerCase()}`}
                                variant="outlined"
                            />
                        </Box>
                        <Typography
                            color="grey.main"
                            fontSize={16}
                            fontWeight="500"
                        >
                            {
                                data.workflowInstance.nodes.find(
                                    (node) => node.id === data.node
                                )?.data?.description
                            }
                        </Typography>
                    </Box>
                    <IconButton onClick={handleClose}>
                        <Cancel fontSize="large" color="quaternary" />
                    </IconButton>
                </Box>
                <Box className="body">
                    <Box padding="20px">
                        <Grid container spacing={2} className="info">
                            <Grid item xs={6} sm={4}>
                                <Box className="item-container">
                                    <Typography color="grey.main" fontSize={16}>
                                        <FormattedMessage id="TASKS:KIND" />
                                        &nbsp;:
                                    </Typography>
                                    <Typography
                                        color="primary"
                                        fontSize={16}
                                        fontWeight={600}
                                    >
                                        {data.kind}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <Box className="item-container">
                                    <Typography color="grey.main" fontSize={16}>
                                        <FormattedMessage id="TASKS:CLIENT" />
                                        &nbsp;:
                                    </Typography>
                                    <Typography
                                        color="primary"
                                        fontSize={16}
                                        fontWeight={600}
                                    >
                                        {data.workflowInstance.site.client.name}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <Box className="item-container">
                                    <Typography color="grey.main" fontSize={16}>
                                        <FormattedMessage id="TASKS:SITE_NAME" />
                                        &nbsp;:
                                    </Typography>
                                    <Typography
                                        color="primary"
                                        fontSize={16}
                                        fontWeight={600}
                                    >
                                        {data.workflowInstance.site.name}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <Box className="item-container">
                                    <Typography color="grey.main" fontSize={16}>
                                        <FormattedMessage id="TASKS:SITE_REF" />
                                        &nbsp;:
                                    </Typography>
                                    <Typography
                                        color="primary"
                                        fontSize={16}
                                        fontWeight={600}
                                    >
                                        {data.workflowInstance.site.ref}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box className="item-container">
                                    <Typography color="grey.main" fontSize={16}>
                                        <FormattedMessage id="TASKS:CREATED_AT" />
                                        &nbsp;:
                                    </Typography>
                                    <Typography
                                        color="primary"
                                        fontSize={16}
                                        fontWeight={600}
                                    >
                                        {new Date(
                                            data.createdAt
                                        ).toLocaleString("en-GB")}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box className="item-container">
                                    <Typography color="grey.main" fontSize={16}>
                                        <FormattedMessage id="TASKS:UPDATED_AT" />
                                        &nbsp;:
                                    </Typography>
                                    <Typography
                                        color="primary"
                                        fontSize={16}
                                        fontWeight={600}
                                    >
                                        {new Date(
                                            data.updatedAt
                                        ).toLocaleString("en-GB")}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Box className="item-container">
                                    <Typography color="grey.main" fontSize={16}>
                                        <FormattedMessage id="TASKS:CLIENT_COORDINATOR" />
                                        &nbsp;:
                                    </Typography>
                                    <Stack direction="row" spacing={1}>
                                        <Chip
                                            avatar={
                                                data.clientCoordinator.account
                                                    .officerInfo.image ? (
                                                    <Avatar
                                                        alt={
                                                            data
                                                                .clientCoordinator
                                                                .account
                                                                .username
                                                        }
                                                        src={`data:image;base64,${data.clientCoordinator.account.officerInfo.image}`}
                                                    />
                                                ) : (
                                                    <Avatar
                                                        {...stringAvatar(
                                                            `${data.clientCoordinator.account.officerInfo.firstName} ${data.clientCoordinator.account.officerInfo.lastName}`,
                                                            "white!important"
                                                        )}
                                                    />
                                                )
                                            }
                                            label={`${data.clientCoordinator.account.officerInfo.firstName} ${data.clientCoordinator.account.officerInfo.lastName}`}
                                            variant="outlined"
                                            color="secondary"
                                        />
                                    </Stack>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box className="item-container">
                                    <Typography color="grey.main" fontSize={16}>
                                        <FormattedMessage id="TASKS:INTERNAL_COORDINATORS" />
                                        &nbsp;:
                                    </Typography>
                                    <Stack direction="row" spacing={1}>
                                        {data.internalCoordinators.map(
                                            (internalCoordinator, index) => (
                                                <Chip
                                                    key={index}
                                                    avatar={
                                                        internalCoordinator
                                                            .officerInfo
                                                            .image ? (
                                                            <Avatar
                                                                alt={
                                                                    internalCoordinator.username
                                                                }
                                                                src={`data:image;base64,${internalCoordinator.officerInfo.image}`}
                                                            />
                                                        ) : (
                                                            <Avatar
                                                                {...stringAvatar(
                                                                    `${internalCoordinator.officerInfo.firstName} ${internalCoordinator.officerInfo.lastName}`,
                                                                    "white!important"
                                                                )}
                                                            />
                                                        )
                                                    }
                                                    label={`${internalCoordinator.officerInfo.firstName} ${internalCoordinator.officerInfo.lastName}`}
                                                    variant="outlined"
                                                    color="secondary"
                                                />
                                            )
                                        )}
                                    </Stack>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box className="item-container">
                                    <Typography color="grey.main" fontSize={16}>
                                        <FormattedMessage id="TASKS:TECHNICIANS" />
                                        &nbsp;:
                                    </Typography>
                                    <Stack direction="row" spacing={1}>
                                        {data.technicians.map(
                                            (technician, index) => (
                                                <Chip
                                                    key={index}
                                                    avatar={
                                                        technician.officerInfo
                                                            .image ? (
                                                            <Avatar
                                                                alt={
                                                                    technician.username
                                                                }
                                                                src={`data:image;base64,${technician.officerInfo.image}`}
                                                            />
                                                        ) : (
                                                            <Avatar
                                                                {...stringAvatar(
                                                                    `${technician.officerInfo.firstName} ${technician.officerInfo.lastName}`,
                                                                    "white!important"
                                                                )}
                                                            />
                                                        )
                                                    }
                                                    label={`${technician.officerInfo.firstName} ${technician.officerInfo.lastName}`}
                                                    variant="outlined"
                                                    color="secondary"
                                                />
                                            )
                                        )}
                                    </Stack>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box className="item-container">
                                    <Typography color="grey.main" fontSize={16}>
                                        <FormattedMessage id="TASKS:MANAGERS" />
                                        &nbsp;:
                                    </Typography>
                                    <Stack direction="row" spacing={1}>
                                        {data.managers.map((manager, index) => (
                                            <Chip
                                                key={index}
                                                avatar={
                                                    manager.officerInfo
                                                        .image ? (
                                                        <Avatar
                                                            alt={
                                                                manager.username
                                                            }
                                                            src={`data:image;base64,${manager.officerInfo.image}`}
                                                        />
                                                    ) : (
                                                        <Avatar
                                                            {...stringAvatar(
                                                                `${manager.officerInfo.firstName} ${manager.officerInfo.lastName}`,
                                                                "white!important"
                                                            )}
                                                        />
                                                    )
                                                }
                                                label={`${manager.officerInfo.firstName} ${manager.officerInfo.lastName}`}
                                                variant="outlined"
                                                color="secondary"
                                            />
                                        ))}
                                    </Stack>
                                </Box>
                            </Grid> */}
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
}

export default TaskDetails;
