import {
    ArrowDownward,
    ArrowUpward,
    ChevronLeft,
    ChevronRight,
    Close,
    DoNotDisturb,
    DoNotDisturbAlt,
} from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { extractFromPath } from "../../utils/general";
import ContextMenu from "../contextMenu/ContextMenu";
import Loading from "../loading/Loading";
import "./DataCards.scss";

function DataCards({ loading, data, DataCard, filters, sortList, getActions }) {
    const [filtersValues, setFiltersValues] = useState({});
    const [sortBy, setSortBy] = useState(0);
    const [asc, setAsc] = useState(false);
    const [showFilters, setShowFilters] = useState(true);
    const [sortedData, setSortedData] = useState([]);
    const [contextMenu, setContextMenu] = useState(null);

    const actions = useMemo(() => {
        if (!getActions || !contextMenu) {
            return [];
        }
        return getActions(contextMenu.data);
    }, [getActions, contextMenu]);

    const intl = useIntl();

    useEffect(() => {
        if (data) {
            if (sortList && sortList.length > 0 && sortBy > 0) {
                let sorted = [...data];
                if (asc) {
                    sorted.sort((a, b) =>
                        a[sortList[sortBy - 1]["name"]] <
                        b[sortList[sortBy - 1]["name"]]
                            ? -1
                            : a[sortList[sortBy - 1]["name"]] >
                              b[sortList[sortBy - 1]["name"]]
                            ? 1
                            : 0
                    );
                } else {
                    sorted.sort((a, b) =>
                        a[sortList[sortBy - 1]["name"]] <
                        b[sortList[sortBy - 1]["name"]]
                            ? 1
                            : a[sortList[sortBy - 1]["name"]] >
                              b[sortList[sortBy - 1]["name"]]
                            ? -1
                            : 0
                    );
                }
                setSortedData(sorted);
            } else {
                setSortedData(data);
            }
        }
    }, [data, sortBy, asc]);

    return (
        <Card
            sx={{
                height: "100%",
                background: "transparent",
                borderRadius: "28px",
                border: "1px solid #8C9DE7",
                boxShadow: "inset 9px 7px 12px rgba(186, 189, 239, 0.33)",
                p: 3,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                position: "relative",
                boxSizing: "border-box",
            }}
            elevation={0}
        >
            {loading && (
                <Loading
                    container={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 1,
                        backgroundColor: "#ffffff90",
                    }}
                    loader={{
                        height: "50px!important",
                        width: "50px!important",
                    }}
                />
            )}
            <Box
                height="100%"
                display="flex"
                flex={1}
                gap={2}
                pr={1}
                flexWrap="wrap"
                className="cards-container"
                justifyContent={"flex-start"}
                sx={{ overflowY: "auto", paddingTop: "20px" }}
            >
                {sortedData
                    .filter((element) => {
                        return Object.keys(filtersValues).every(
                            (key) =>
                                !filtersValues[key] ||
                                filters
                                    .find((filter) => filter.name === key)
                                    .verify(
                                        extractFromPath(element, key),
                                        filtersValues[key]
                                    )
                        );
                    })
                    .map((element, index) => (
                        <DataCard
                            key={index}
                            data={element}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                setContextMenu(
                                    contextMenu === null
                                        ? {
                                              mouseX: event.clientX + 2,
                                              mouseY: event.clientY - 6,
                                              data: element,
                                          }
                                        : null
                                );
                            }}
                        />
                    ))}
            </Box>
            {((filters && filters.length !== 0) ||
                (sortList && sortList.length !== 0)) && (
                <Box
                    display="flex"
                    flexDirection="row"
                    height="100%"
                    marginLeft="4px"
                >
                    <Button
                        color="quaternary"
                        variant="contained"
                        onClick={() => {
                            setShowFilters(!showFilters);
                        }}
                        sx={{
                            padding: "0px",
                            minWidth: "0px",
                            borderRadius: "0",
                        }}
                    >
                        {showFilters ? (
                            <ChevronRight color="white" />
                        ) : (
                            <ChevronLeft color="white" />
                        )}
                    </Button>
                    <Box
                        sx={{
                            width: "200px",
                            paddingLeft: "4px",
                            height: "100%",
                            overflowY: "auto",
                            ...(showFilters ? {} : { display: "none" }),
                        }}
                        className="filters-container"
                    >
                        {sortList && sortList.length !== 0 && (
                            <React.Fragment>
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography
                                        variant="h2"
                                        color="quaternary.main"
                                        fontSize="20px"
                                        fontWeight="bold"
                                    >
                                        <FormattedMessage id="GLOBAL:SORTBY" />
                                    </Typography>
                                </Box>
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                >
                                    <FormControl fullWidth>
                                        <Select
                                            color="quaternary"
                                            inputProps={{
                                                style: { color: "black" },
                                            }}
                                            size="small"
                                            sx={{
                                                marginTop: "10px",
                                                marginBottom: "10px",
                                            }}
                                            value={sortBy}
                                            onChange={(event) => {
                                                setSortBy(event.target.value);
                                            }}
                                        >
                                            {[
                                                {
                                                    label: intl.formatMessage({
                                                        id: "GLOBAL:DEFAULT",
                                                    }),
                                                },
                                                ...sortList,
                                            ].map((e, index) => {
                                                return (
                                                    <MenuItem
                                                        key={index}
                                                        value={index}
                                                    >
                                                        {e.label}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                    {sortBy > 0 && (
                                        <React.Fragment>
                                            <IconButton
                                                size="small"
                                                color={
                                                    asc
                                                        ? "quaternary"
                                                        : undefined
                                                }
                                                onClick={() => {
                                                    setAsc(true);
                                                }}
                                            >
                                                <ArrowUpward />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                color={
                                                    !asc
                                                        ? "quaternary"
                                                        : undefined
                                                }
                                                onClick={() => {
                                                    setAsc(false);
                                                }}
                                            >
                                                <ArrowDownward />
                                            </IconButton>
                                        </React.Fragment>
                                    )}
                                </Box>
                            </React.Fragment>
                        )}
                        {filters && filters.length !== 0 && (
                            <React.Fragment>
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography
                                        variant="h2"
                                        color="quaternary.main"
                                        fontSize="20px"
                                        fontWeight="bold"
                                    >
                                        <FormattedMessage id="GLOBAL:FILTERS" />
                                    </Typography>
                                    <IconButton
                                        color="quaternary"
                                        sx={{
                                            padding: "2px",
                                            minWidth: "0px",
                                        }}
                                        onClick={() => {
                                            setFiltersValues({});
                                        }}
                                    >
                                        <DoNotDisturbAlt />
                                    </IconButton>
                                </Box>
                                {filters.map((filter, index) =>
                                    filter.type === "select" ? (
                                        <FormControl
                                            key={index}
                                            fullWidth
                                            sx={{
                                                marginTop: "10px",
                                            }}
                                            size="small"
                                        >
                                            <InputLabel
                                                id={`select-label-${index}`}
                                            >
                                                {filter.label}
                                            </InputLabel>
                                            <Select
                                                labelId={`select-label-${index}`}
                                                label={filter.label}
                                                id={`select-${index}`}
                                                color="quaternary"
                                                inputProps={{
                                                    style: { color: "black" },
                                                }}
                                                value={
                                                    filtersValues[
                                                        filter.name
                                                    ] || ""
                                                }
                                                onChange={(event) => {
                                                    setFiltersValues({
                                                        ...filtersValues,
                                                        [filter.name]:
                                                            event.target.value,
                                                    });
                                                }}
                                            >
                                                {[
                                                    {
                                                        text: intl.formatMessage(
                                                            {
                                                                id: "GLOBAL:ALL",
                                                            }
                                                        ),
                                                        value: "",
                                                    },
                                                    ...filter.options,
                                                ].map((option, index) => {
                                                    return (
                                                        <MenuItem
                                                            key={index}
                                                            value={option.value}
                                                        >
                                                            {option.text}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        <TextField
                                            key={index}
                                            type={filter.type}
                                            label={filter.label}
                                            fullWidth
                                            multiline={
                                                filter.multiline ? true : false
                                            }
                                            color="quaternary"
                                            inputProps={{
                                                style: { color: "black" },
                                            }}
                                            size="small"
                                            sx={{ marginTop: "10px" }}
                                            onChange={(event) => {
                                                setFiltersValues({
                                                    ...filtersValues,
                                                    [filter.name]:
                                                        event.target.value,
                                                });
                                            }}
                                            value={
                                                filtersValues[filter.name] ?? ""
                                            }
                                        />
                                    )
                                )}
                            </React.Fragment>
                        )}
                    </Box>
                </Box>
            )}
            {actions && actions.length > 0 && (
                <ContextMenu
                    data={contextMenu?.data}
                    sections={actions}
                    open={contextMenu !== null}
                    onClose={() => {
                        setContextMenu(null);
                    }}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? {
                                  top: contextMenu.mouseY,
                                  left: contextMenu.mouseX,
                              }
                            : undefined
                    }
                />
            )}
        </Card>
    );
}

export default DataCards;
